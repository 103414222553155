import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CategoriesActions, CategoriesActionTypes } from './categories.actions';
import { ICategory } from 'src/app/models/ICategory';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
  categories: CategoriesState;
}

export interface CategoriesState extends IGardiffStoreSliceBase {
  categories: ICategory[];
}

const initialState: CategoriesState = {
  categories: [],
  apiError: null,
  status: new GardiffStoreSliceStatus()
};

// create the selectors
const getCategoriesFeatureState = createFeatureSelector<CategoriesState>('categories');
export const getCategoriesState = createSelector(
  getCategoriesFeatureState,
  (state) => state
);

export const getCategories = createSelector(
  getCategoriesFeatureState,
  (state) => state.categories
);

export const getApiError = createSelector(
  getCategoriesFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getCategoriesFeatureState,
  state => state.status
);

export function reducer(state: CategoriesState = initialState, action: CategoriesActions): CategoriesState {
  switch (action.type) {
    case CategoriesActionTypes.LoadCategories:
      return {
        ...state,
        categories: null,
        apiError: null,
        status: null
      };

    case CategoriesActionTypes.LoadCategoriesSuccessful:
      return {
        ...state,
        categories: action.payload,
        apiError: null,
        status: new GardiffStoreSliceStatus(true)
      };

    case CategoriesActionTypes.LoadCategoriesFailed:
      return {
        ...state,
        categories: [],
        apiError: action.payload,
        status: new GardiffStoreSliceStatus()
      };

    case CategoriesActionTypes.SaveCategories:
      return {
        ...state,
        categories: null,
        apiError: null,
        status: null
      };

    case CategoriesActionTypes.SaveCategoriesWithoutTax:
      return {
        ...state,
        categories: null,
        apiError: null,
        status: null
      };

    case CategoriesActionTypes.SaveCategoriesSuccessful:
      return {
        ...state,
        categories: action.payload,
        apiError: null,
        status: new GardiffStoreSliceStatus(true)
      };

    case CategoriesActionTypes.SaveCategoriesFailed:
      return {
        ...state,
        categories: action.oldData,
        apiError: action.error,
        status: new GardiffStoreSliceStatus()
      };

    case CategoriesActionTypes.RestoreBackup:
      return {
          ...state,
          categories: null
      };

    case CategoriesActionTypes.RestoreBackupSuccessful:
        return {
            ...state,
            categories: action.payload
        };

    case CategoriesActionTypes.RestoreBackupFailed:
        return {
            ...state,
            categories: []
        };

    case CategoriesActionTypes.ResetStore:
      return {
        ...state,
        categories: initialState.categories,
        apiError: initialState.apiError,
        status: initialState.status
      };

    default:
      return state;
  }
}


