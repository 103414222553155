import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import * as kitchenvideosActions from './kitchenvideos.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { KitchenVideosService } from 'src/app/services/kitchen-videos.service';
import { IKitchenVideo } from 'src/app/models/IKitchenVideo';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';

@Injectable()
export class KitchenVideosEffects {
  constructor(private action$: Actions, private kvService: KitchenVideosService, private estateService: EstateService) {

  }

  loadKitchenVideos$ = createEffect(
    () => this.action$.pipe(
    ofType(kitchenvideosActions.KitchenVideosActionTypes.LoadKitchenVideos),
    mergeMap((action: kitchenvideosActions.LoadKitchenVideos) =>
      this.kvService.GetKitchenVideos(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((videos: IKitchenVideo[]) => (new kitchenvideosActions.LoadKitchenVideosSuccessful(videos))),
        catchError((error: HttpErrorResponse) => of(new kitchenvideosActions.LoadKitchenVideosFailed(interpretateApiError(error))))
      )
    ))
  );

  saveKitchenVideos$ = createEffect(
    () => this.action$.pipe(
    ofType(kitchenvideosActions.KitchenVideosActionTypes.SaveKitchenVideos),
    mergeMap((action: kitchenvideosActions.SaveKitchenVideos) =>
      this.kvService.SaveKitchenVideos(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.oldData, action.newData).pipe(
        map((videos: IKitchenVideo[]) => new kitchenvideosActions.SaveKitchenVideosSuccessful(videos)),
        catchError((error: HttpErrorResponse) => of(new kitchenvideosActions.SaveKitchenVideosFailed(interpretateApiError(error, HttpVerbType.POST), action.newData)))
      )
    ))
  );
}
