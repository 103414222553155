import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import { LoginService } from '../../../services/login.service';

import * as loginActions from './login.actions';
import * as estateSelectorActions from '../../estate/state/estate.actions';
import * as adminActions from '../../../NGRX/admin/admin.actions';

import * as eodReportActions from '../../eod/state/eodReport.actions';
import * as eowReportActions from '../../eow/state/eowReport.actions';
import * as salesReportActions from '../../sales-report/state/sales-report.actions';
import * as paymentsReportActions from '../../payments-report/state/payments-report.actions';
import * as discountReportActions from '../../../NGRX/discount-report/discount-report.actions';
import * as voidReportActions from '../../../NGRX/void-report/void-report.actions';

import * as productEditorActions from '../../product-editor/state/productEditor.actions';
import * as productsActions from '../../../NGRX/products/products.actions';
import * as categoryEditorActions from '../../category-editor/state/categories.actions';
import * as categoriesActions from '../../../NGRX/categories/categories.actions';
import * as parentCategoryActions from '../../../NGRX/parent-categories/parent-category.actions';
import * as courseActions from 'src/app/NGRX/courses/courses.actions';
import * as pricelevelActions from 'src/app/NGRX/pricelevels/pricelevels.actions';
import * as salesareaActions from 'src/app/NGRX/sales-area/salesarea.actions';
import * as schedulePriceLevelActions from '../../../NGRX/schedule-pricelevels/schedule-pricelevels.actions';
import * as addonsEditorActions from '../../addons/state/addons-editor.actions';
import * as addonsActions from '../../../NGRX/addons/addons.actions';
import * as discountsActions from '../../../NGRX/discounts/discounts.actions';
import * as taxBandsActions from '../../../NGRX/tax-bands/taxbands.actions';

import * as menusActions from '../../../NGRX/menus/menus.actions';
import * as tablePlanActions from '../../../NGRX/table-plan/tableplan.actions';

import * as functionKeysActions from '../../../NGRX/function-keys/function-keys.actions';
import * as rolesActions from '../../../NGRX/roles/roles.actions';
import * as usersActions from '../../../NGRX/users/users.actions';
import * as logonScreenActions from '../../../NGRX/logon-screen/logon.actions';

import * as eposSettingsActions from 'src/app/NGRX/epos-settings/epossettings.actions';
import * as possetupActions from '../../../NGRX/pos-setup/possetup.actions';
import * as printersActions from '../../../NGRX/printers/printers.actions';
import * as kitchenVideosActions from '../../../NGRX/kitchen-videos/kitchenvideos.actions';
import * as weightMeasuresActions from '../../../NGRX/weight-measures/weightmeasures.actions';
import * as zreportSetupActions from '../../../NGRX/zreport/zreport.actions';

import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

@Injectable()
export class LoginEffects {
    constructor(private action$: Actions, private loginService: LoginService) {

    }

    // @Effect()
    // login$ = this.action$.pipe(
    //     ofType(loginActions.LoginActionTypes.Login),
    //     mergeMap((action: loginActions.Login) =>
    //         this.loginService.login(action.payload).pipe(
    //             map((data) => {
    //                 if (action.payload.UserName === this.loginService.PreviousUserName) {
    //                     return new loginActions.LoginSuccessful(action.payload.UserName);
    //                 } else {
    //                     return new loginActions.LoginSuccessfulWithDifferentUser(action.payload.UserName);
    //                 }
    //             }),
    //             catchError((err: string) => {
    //                 console.log('error login response : ' + JSON.stringify(err));
    //                 return of(new loginActions.LoginFailed(err));
    //             }
    //     )),
    // ));

    // newUserLoggedIn$ = createEffect(
    //   () => this.action$.pipe(
    //     ofType(loginActions.LoginActionTypes.LoginSuccessfulWithDifferentUser),
    //     switchMap(() =>  [
    //         new loginActions.ResetStore()
    //     ])
    //   )
    // );

    LogOut$ = createEffect(
      () => this.action$.pipe(
        ofType(loginActions.LoginActionTypes.LogOut),
        switchMap(() =>  [
            new estateSelectorActions.ResetStore(),
            new loginActions.ResetStore()
        ])
      )
    );

    resetStore$ = createEffect(
      () => this.action$.pipe(
        ofType(loginActions.LoginActionTypes.ResetStore),
        switchMap(() =>  [
            //new estateSelectorActions.ResetStore(),
            new adminActions.ResetStore(),

            // REPORTS
            new eodReportActions.ResetStore(),
            new eowReportActions.ResetStore(),
            new salesReportActions.ResetStore(),
            new paymentsReportActions.ResetStore(),
            new discountReportActions.ResetStore(),
            new voidReportActions.ResetStore(),

            // Catalogue
            new courseActions.ResetStore(),
            new parentCategoryActions.ResetStore(),
            new categoryEditorActions.ResetStore(),
            new categoriesActions.ResetStore(),
            new productEditorActions.ResetStore(),
            new productsActions.ResetStore(),
            new addonsEditorActions.ResetStore(),
            new addonsActions.ResetStore(),
            new weightMeasuresActions.ResetStore(),

            // Pricing
            new pricelevelActions.ResetStore(),
            new schedulePriceLevelActions.ResetStore(),
            new discountsActions.ResetStore(),
            new taxBandsActions.ResetStore(),

            // Plans
            new menusActions.ResetStore(),
            new tablePlanActions.ResetStore(),

            // Users
            new logonScreenActions.ResetStore(),
            new rolesActions.ResetStore(),
            new usersActions.ResetStore(),
            new functionKeysActions.ResetStore(),

            // Setup
            new eposSettingsActions.ResetStore(),
            new possetupActions.ResetStore(),
            new printersActions.ResetStore(),
            new kitchenVideosActions.ResetStore(),
            new zreportSetupActions.ResetStore(),
            new salesareaActions.ResetStore(),
        ])
      )
    );
}
