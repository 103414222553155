import * as fromRoot from '../../../NGRX/app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentsReportActions, PaymentsReportActionTypes } from './payments-report.actions';
import { IPaymentsReportDocument, PaymentsReportDocument } from 'src/app/models/IPaymentsReportDocument';
import { PaymentReportGroupBy } from 'src/app/enums/PaymentReportGroupByType';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import { IPaymentsReportFormParameters, PaymentsReportFormParameters } from 'src/app/models/IPaymentsReportFormParameters';

export interface State extends fromRoot.State {
    PaymentsReports: PaymentsReportState;
}

export interface PaymentsReportState extends IGardiffStoreSliceBase {
    paymentsReportFormParameters: PaymentsReportFormParameters;
    paymentsReport: IPaymentsReportDocument;
    downloadedFile: ArrayBuffer;
}

const initialState: PaymentsReportState = {
    paymentsReportFormParameters: new PaymentsReportFormParameters(),
    paymentsReport: null,
    downloadedFile: null,
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getPaymentsReportFeatureState = createFeatureSelector<PaymentsReportState>('paymentsReport');
export const getPaymentsReportState = createSelector(
    getPaymentsReportFeatureState,
    state => state
);

export const getParameters = createSelector(
    getPaymentsReportFeatureState,
    state => state.paymentsReportFormParameters
);

export const getPaymentsReport = createSelector(
    getPaymentsReportFeatureState,
    state => state.paymentsReport
);

export const getDownloadedFile = createSelector(
  getPaymentsReportFeatureState,
  state => state.downloadedFile
);

export const getApiError = createSelector(
  getPaymentsReportFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getPaymentsReportFeatureState,
  state => state.status
);

export function reducer(state: PaymentsReportState = initialState, action: PaymentsReportActions): PaymentsReportState {
    switch (action.type) {
        case PaymentsReportActionTypes.LoadPaymentsReport:
            return {
                ...state,
                paymentsReportFormParameters: action.payload,
                paymentsReport: null,
                apiError: null,
                status: null
            };

        case PaymentsReportActionTypes.LoadPaymentsReportSuccessful:
            return {
                ...state,
                paymentsReport: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case PaymentsReportActionTypes.LoadPaymentsReportFailed:
            return {
                ...state,
                paymentsReport: new PaymentsReportDocument(),
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case PaymentsReportActionTypes.ResetStore:
            return {
                ...state,
                paymentsReportFormParameters : initialState.paymentsReportFormParameters,
                paymentsReport: initialState.paymentsReport,
                apiError: initialState.apiError,
                status: initialState.status
            };

        case PaymentsReportActionTypes.SaveSearchParameters:
            return {
                ...state,
                paymentsReportFormParameters: action.payload
            };

        case PaymentsReportActionTypes.DownloadEOD:
          return {
              ...state,
              downloadedFile: null,
          };

        case PaymentsReportActionTypes.DownloadEODSuccessful:
            return {
                ...state,
                downloadedFile: action.payload,
            };

        case PaymentsReportActionTypes.DownloadEODFailed:
            return {
                ...state,
                downloadedFile: new ArrayBuffer(0)
            };

        case PaymentsReportActionTypes.CleanUpFileBuffer:
            return {
                ...state,
                downloadedFile: initialState.downloadedFile
            };

        default:
            return state;
    }
}
