import { ProductSalesReportGroupOptionType } from 'src/app/enums/ProductSalesReportGroupOptionType';
import { ProductSalesReportSubGroupOptionType } from 'src/app/enums/ProductSalesReportSubGroupOptionType';

export interface IProductSalesReportDefinitionDTO {
  filterCategoryGuid: string;
  filterCourseGuid: string;
  filterParentCategoryGuid: string;
  filterPosDeviceGuid: string;
  filterUserGuid: string;
  hideGroupDetail: boolean;
  mainGroup: ProductSalesReportGroupOptionType;
  rollUpProducts: boolean;
  salesDateFrom: Date;
  salesDateTo: Date;
  subGroup: ProductSalesReportSubGroupOptionType;
  includeTax: boolean;
  timeZone: string;
}

export class ProductSalesReportDefinitionDTO implements IProductSalesReportDefinitionDTO {
  filterCategoryGuid: string;
  filterCourseGuid: string;
  filterParentCategoryGuid: string;
  filterPosDeviceGuid: string;
  filterUserGuid: string;
  hideGroupDetail: boolean;
  mainGroup: ProductSalesReportGroupOptionType;
  rollUpProducts: boolean;
  salesDateFrom: Date;
  salesDateTo: Date;
  subGroup: ProductSalesReportSubGroupOptionType;
  includeTax: boolean;
  timeZone: string;

  constructor() {
    this.filterCategoryGuid = '00000000-0000-0000-0000-000000000000';
    this.filterCourseGuid = '00000000-0000-0000-0000-000000000000';
    this.filterParentCategoryGuid = '00000000-0000-0000-0000-000000000000';
    this.filterPosDeviceGuid = '00000000-0000-0000-0000-000000000000';
    this.filterUserGuid = '00000000-0000-0000-0000-000000000000';
    this.hideGroupDetail = false;
    this.mainGroup = ProductSalesReportGroupOptionType.None;
    this.rollUpProducts = true;
    this.salesDateFrom = new Date();
    this.salesDateTo = new Date();
    this.subGroup = ProductSalesReportSubGroupOptionType.None;
    this.includeTax = true;
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
