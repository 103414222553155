import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import * as weightMeasuresActions from './weightmeasures.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { MeasuresService } from 'src/app/services/measures.service';
import { IMeasure } from 'src/app/models/IMeasure';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';

@Injectable()
export class WeightMeasuresEffects {
  constructor(private action$: Actions,
    private estateService: EstateService,
    private measuresService: MeasuresService) {

  }

  loadWeightMeasures$ = createEffect(
    () => this.action$.pipe(
    ofType(weightMeasuresActions.WeightMeasuresActionTypes.LoadWeightMeasures),
    mergeMap((action: weightMeasuresActions.LoadWeightMeasures) =>
      this.measuresService.GetMeasures(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((weightMeasures: IMeasure[]) => (new weightMeasuresActions.LoadWeightMeasuresSuccessful(weightMeasures))),
        catchError((error: HttpErrorResponse) => of(new weightMeasuresActions.LoadWeightMeasuresFailed(interpretateApiError(error))))
      )
    ))
  );

  saveWeightMeasures$ = createEffect(
    () => this.action$.pipe(
    ofType(weightMeasuresActions.WeightMeasuresActionTypes.SaveWeightMeasures),
    mergeMap((action: weightMeasuresActions.SaveWeightMeasures) =>
      this.measuresService.SaveMeasures(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.oldData, action.newData).pipe(
        map((weightMeasures: IMeasure[]) => new weightMeasuresActions.SaveWeightMeasuresSuccessful(weightMeasures)),
        catchError((error: HttpErrorResponse) => of(new weightMeasuresActions.SaveWeightMeasuresFailed(interpretateApiError(error, HttpVerbType.POST), action.newData)))
      )
    ))
  );
}
