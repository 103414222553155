import { Action } from '@ngrx/store';
import { IMenu } from 'src/app/models/IMenu';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';

export enum MenusActionTypes {
    LoadMenus = '[Menus] Load Menus',
    LoadMenusSuccessful = '[Menus] Load Menus - Successful',
    LoadMenusFailed = '[Menus] Load Menus - Failed',

    SaveMenus = '[Menus] Save Menus',
    SaveMenusSuccessful = '[Menus] Save Menus - Successful',
    SaveMenusFailed = '[Menus] Save Menus - Failed',

    ResetStore = '[Menus] Reset Store',
}

export class LoadMenus implements Action {
    readonly type = MenusActionTypes.LoadMenus;

    constructor() { }
}

export class LoadMenusSuccessful implements Action {
    readonly type = MenusActionTypes.LoadMenusSuccessful;

    constructor(public payload: IMenu[]) { }
}

export class LoadMenusFailed implements Action {
    readonly type = MenusActionTypes.LoadMenusFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveMenus implements Action {
    readonly type = MenusActionTypes.SaveMenus;

    constructor(public oldData: IMenu[], public newData: IMenu[]) { }
}

export class SaveMenusSuccessful implements Action {
    readonly type = MenusActionTypes.SaveMenusSuccessful;

    constructor(public payload: IMenu[]) { }
}

export class SaveMenusFailed implements Action {
    readonly type = MenusActionTypes.SaveMenusFailed;

    constructor(public error: IGenericError, public oldData: IMenu[]) { }
}

export class ResetStore implements Action {
    readonly type = MenusActionTypes.ResetStore;

    constructor() { }
}

export type MenusActions = LoadMenus
    | LoadMenusSuccessful
    | LoadMenusFailed
    | SaveMenus
    | SaveMenusSuccessful
    | SaveMenusFailed
    | ResetStore;
