export enum ProductSalesReportSubGroupOptionType {
  None = 0,
  PriceLevel = 1
}

export const ProductSalesReportSubGroupOptionLabel = new Map<number, string>([
  [ProductSalesReportSubGroupOptionType.None, 'None'],
  [ProductSalesReportSubGroupOptionType.PriceLevel, 'Price Level']
]);

// how to use it
//
// ProductSalesReportSubGroupOptionLabel.get(ProductSalesReportSubGroupOptionType.None);
