import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ICourseDTO } from '../models/DTOs/ICourseDTO';
import { ICourse } from '../models/ICourse';
import { LoginService } from './login.service';
import { ChangeType } from '../enums/ChangeType';
import { EditDataList, IEditDataList } from '../models/DTOs/IEditDataList';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  private CoursesEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/Courses';

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetCourses(CorporationID: string, SiteID: string): Observable<ICourse[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.CoursesEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http
        .get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<ICourseDTO[]>) => {

            if (data.ok) {
              return this.formatData(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  private formatData(dataList: ICourseDTO[]): ICourse[] {
    let list: ICourse[] = [];

    // convert list
    dataList.forEach(item => {
      list.push({
        id: item.id,
        courseGuid: item.courseGuid,
        courseName: item.courseName,
        colorCode: item.colorCode,
        isActive: item.isActive,
        isSelected: false,
        changeState: ChangeType.None
      });
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: ICourse[]): ICourse[] {
    return list.sort((Item1: ICourse, Item2: ICourse) => {
      if (Item1.courseName.toLowerCase() > Item2.courseName.toLowerCase()) {
        return 1;
      }

      if (Item1.courseName.toLowerCase() < Item2.courseName.toLowerCase()) {
        return -1;
      }
    });
  }

  SaveCourses(CorporationID: string, SiteID: string, oldData: ICourse[], newData: ICourse[]): Observable<ICourse[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.CoursesEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IEditDataList<ICourseDTO> = this.SplitItems(newData);

      return this.http
        .post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<ICourseDTO[]>) => {

            if (data.ok) {
              return this.formatData(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        );
    }
  }

  private DeflateItems(list: ICourse[]): ICourseDTO[] {
    const newData: ICourseDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        newData.push({
          id: item.id,
          courseGuid: item.courseGuid,
          courseName: item.courseName,
          colorCode: item.colorCode,
          isActive: item.isActive
        });
      });
    }

    return newData;
  }

  private SplitItems(data: ICourse[]): IEditDataList<ICourseDTO> {
    let newData: IEditDataList<ICourseDTO> = new EditDataList<ICourseDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }
}
