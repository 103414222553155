import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { ITablePlanArea } from "src/app/models/ITablePlanArea";

export enum TablePlanActionTypes {
  LoadTablePlan = '[TablePlan] Load TablePlan',
  LoadTablePlanSuccessful = '[TablePlan] Load TablePlan - Successful',
  LoadTablePlanFailed = '[TablePlan] Load TablePlan - Failed',
  SaveTablePlan = '[TablePlan] Save TablePlan',
  SaveTablePlanSuccessful = '[TablePlan] Save TablePlan - Successful',
  SaveTablePlanFailed = '[TablePlan] Save TablePlan - Failed',
  ResetStore = '[TablePlan] Reset Store',
}

export class LoadTablePlan implements Action {
  readonly type = TablePlanActionTypes.LoadTablePlan;

  constructor() { }
}

export class LoadTablePlanSuccessful implements Action {
  readonly type = TablePlanActionTypes.LoadTablePlanSuccessful;

  constructor(public payload: ITablePlanArea[]) { }
}

export class LoadTablePlanFailed implements Action {
  readonly type = TablePlanActionTypes.LoadTablePlanFailed;

  constructor(public payload: IGenericError) { }
}

export class SaveTablePlan implements Action {
  readonly type = TablePlanActionTypes.SaveTablePlan;

  constructor(public payload: ITablePlanArea[]) { }
}

export class SaveTablePlanSuccessful implements Action {
  readonly type = TablePlanActionTypes.SaveTablePlanSuccessful;

  constructor(public payload: ITablePlanArea[]) { }
}

export class SaveTablePlanFailed implements Action {
  readonly type = TablePlanActionTypes.SaveTablePlanFailed;

  constructor(public error: IGenericError, public oldData: ITablePlanArea[]) { }
}

export class ResetStore implements Action {
  readonly type = TablePlanActionTypes.ResetStore;

  constructor() { }
}

export type TablePlanActions = LoadTablePlan
  | LoadTablePlanSuccessful
  | LoadTablePlanFailed
  | SaveTablePlan
  | SaveTablePlanSuccessful
  | SaveTablePlanFailed
  | ResetStore;
