import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { ISalesArea } from 'src/app/models/ISalesArea';

export enum SalesAreaActionTypes {
    LoadSalesArea = '[Sales Area] Load Sales Area',
    LoadSalesAreaSuccessful = '[Sales Area] Load Sales Area - Successful',
    LoadSalesAreaFailed = '[Sales Area] Load Sales Area - Failed',
    SaveSalesArea = '[Sales Area] Save Sales Area',
    SaveSalesAreaSuccessful = '[Sales Area] Save Sales Area - Successful',
    SaveSalesAreaFailed = '[Sales Area] Save Sales Area - Failed',
    ResetStore = '[Sales Area] Reset Store',
}

export class LoadSalesArea implements Action {
    readonly type = SalesAreaActionTypes.LoadSalesArea;

    constructor() { }
}

export class LoadSalesAreaSuccessful implements Action {
    readonly type = SalesAreaActionTypes.LoadSalesAreaSuccessful;

    constructor(public payload: ISalesArea[]) { }
}

export class LoadSalesAreaFailed implements Action {
    readonly type = SalesAreaActionTypes.LoadSalesAreaFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveSalesArea implements Action {
    readonly type = SalesAreaActionTypes.SaveSalesArea;

    constructor(public oldData: ISalesArea[], public newData: ISalesArea[]) { }
}

export class SaveSalesAreaSuccessful implements Action {
    readonly type = SalesAreaActionTypes.SaveSalesAreaSuccessful;

    constructor(public payload: ISalesArea[]) { }
}

export class SaveSalesAreaFailed implements Action {
    readonly type = SalesAreaActionTypes.SaveSalesAreaFailed;

    constructor(public error: IGenericError, public oldData: ISalesArea[]) { }
}

export class ResetStore implements Action {
    readonly type = SalesAreaActionTypes.ResetStore;

    constructor() { }
}

export type SalesAreaActions = LoadSalesArea
    | LoadSalesAreaSuccessful
    | LoadSalesAreaFailed
    | SaveSalesArea
    | SaveSalesAreaSuccessful
    | SaveSalesAreaFailed
    | ResetStore;
