import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { EstateService } from './estate.service';
import { environment } from '../../environments/environment';

import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { IEODItem } from 'src/app/models/IEODItem';
import { EodReportDocumentDTO, IEodReportDocumentDTO } from '../models/DTOs/IEodReportDocumentDTO';
import { EopReportSectionDTO, IEopReportSectionDTO } from '../models/DTOs/IEopReportSectionDTO';
import { IEodReportDocument } from '../models/IEodReportDocument';
import { IEOWItem } from '../models/IEOWItem';
import { IEOWItemDTO } from '../models/DTOs/IEOWItemDTO';
import { IEowReportDocument } from '../models/IEowReportDocument';
import { EowReportDocumentDTO, IEowReportDocumentDTO } from '../models/DTOs/IEowReportDocumentDTO';
import { EopReportSectionDetailAmountDTO } from '../models/DTOs/EopReportSectionDetailAmountDTO';
import { EopReportSectionDetailCountAmountDTO } from '../models/DTOs/EopReportSectionDetailCountAmountDTO';
import { EopReportSectionDetailDescriptionDTO } from '../models/DTOs/EopReportSectionDetailDescriptionDTO';
import { EopReportSectionDetailVarianceDTO } from '../models/DTOs/EopReportSectionDetailVarianceDTO';
import { EopReportSection } from '../models/IEopReportSection';
import { IEopReportSectionDetail } from '../models/IEopReportSectionDetail';
import { EopReportSectionDetailAmount } from '../models/EopReportSectionDetailAmount';
import { EopReportSectionDetailCountAmount } from '../models/EopReportSectionDetailCountAmount';
import { EopReportSectionDetailDescription } from '../models/EopReportSectionDetailDescription';
import { EopReportSectionDetailVariance } from '../models/EopReportSectionDetailVariance';
import { JsonSerializer } from 'typescript-json-serializer';
import { EopReportSectionDetailTaxtDTO } from '../models/DTOs/EopReportSectionDetailTaxDTO';
import { EopReportSectionDetailTax } from '../models/EopReportSectionDetail';
import { TimeZoneDTO } from '../models/DTOs/TimeZoneDTO';

const APIEndpoint = environment.APIEndpoint;


@Injectable({
  providedIn: 'root'
})
export class ReportService {

   constructor(private http: HttpClient, private loginService: LoginService, private estateService: EstateService) { }

  _ProductReportURL = '';
  _PaymentReportURL = '';

  private token: string;


  //DataService for returning a list of EOD's
  getEODReportsList(dateFrom: Date, dateTo: Date): Observable<IEODItem[]> {
    this.token = this.loginService.AccessToken.authToken;

    const _EODReportListURL = APIEndpoint + 'frontend/corporation/' + this.estateService.CorporationID
                                          + '/site/' + this.estateService.SelectedSite.siteId
                                          + '/endofdayreport?datefrom=' + dateFrom.toISOString()
                                          + '&dateto=' + dateTo.toISOString()
                                          + '&reportGrouping=0';

    return this.http.get<IEODItem[]>(_EODReportListURL, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token), observe: 'response' }).pipe(
      map((data: HttpResponse<IEODItem[]>) => {

        if (data.ok) {
          return data.body;
        }

      }), catchError((err: HttpErrorResponse) => {
        return throwError(() => err);
      }));
  }

  //DataService for returning a list of EOW's
  getEOWReportsList(dateFrom: Date, dateTo: Date): Observable<IEOWItem[]> {
    this.token = this.loginService.AccessToken.authToken;

    const _EOWReportListURL = APIEndpoint + 'frontend/corporation/' + this.estateService.CorporationID
                                          + '/site/' + this.estateService.SelectedSite.siteId
                                          + '/EndOfWeekReport?datefrom=' + dateFrom.toISOString()
                                          + '&dateto=' + dateTo.toISOString()
                                          + '&reportGrouping=0';

    return this.http.get<IEOWItemDTO[]>(_EOWReportListURL, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token), observe: 'response' }).pipe(
      map((data: HttpResponse<IEOWItemDTO[]>) => {

        if (data.ok) {
          return data.body;
        }

      }), catchError((err: HttpErrorResponse) => {
        return throwError(() => err);
      }));
  }

  // method for returning single EOD document
  getEODReport(EodID: string): Observable<IEodReportDocument> {
    // Bearer token from the login service
    this.token = this.loginService.AccessToken.authToken;

    // EOD report EndPoint
    const _EODReportURL = APIEndpoint + 'frontend/corporation/' + this.estateService.CorporationID
                                      + '/site/' + this.estateService.SelectedSite.siteId
                                      + '/endofdayreport/' + EodID;

    return this.http.get<EodReportDocumentDTO>(_EODReportURL, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token) }).pipe(
      map((data: any) => {
        // now we deserialize the first level of the report document object
        const deserializedData: IEodReportDocumentDTO = new JsonSerializer().deserializeObject<EodReportDocumentDTO>(data, EodReportDocumentDTO);

        // at this point all the properties for each section are still formatted [SA, SF, SB, and so on]
        // so we need to set section as empty list.
        deserializedData.sections = [];

        // at this point we make an instance of IEodReportDocument
        // and start the data convertion from DTO to BO object.
        // now we also inflate our set of data with reportGuid property
        // where we stick Eod Document Guid that we don't get back from the API
        const dataInflated: IEodReportDocument = {
          reportGuid: EodID,
          createdByUser: deserializedData.createdByUser,
          createdOnPosDevice: deserializedData.createdOnPosDevice,
          posDevicesIncluded: deserializedData.posDevicesIncluded,
          paymentTotals: deserializedData.paymentTotals,
          productSalesTotal: deserializedData.productSalesTotal,
          reportDate: deserializedData.reportDate,
          reportDescription: deserializedData.reportDescription,
          reportNumber: deserializedData.reportNumber,
          reportVersion: deserializedData.reportVersion,
          sections: deserializedData.sections,
        };

        // now we need process all the sections into the API response (data: any)
        // EH = sections
        data.EH.forEach((element: IEopReportSectionDTO) => {
          // deserialize any element and convert them from DTO to BO
          const sectionData: EopReportSection = this.deserializeSection(element);

          // add the new section on the report document
          dataInflated.sections.push(sectionData);
        });

        return dataInflated;

      }), catchError((err: HttpErrorResponse) => {
        return throwError(() => err.message);
      }));
  }

  // method for returning single EOW document
  getEOWReport(EowID: string): Observable<IEowReportDocument> {
    // Bearer token from the login service
    this.token = this.loginService.AccessToken.authToken;

    // EOW report EndPoint
    const _EODReportURL = APIEndpoint + 'frontend/corporation/' + this.estateService.CorporationID
                                      + '/site/' + this.estateService.SelectedSite.siteId
                                      + '/EndOfWeekReport/' + EowID;

    return this.http.get<EowReportDocumentDTO>(_EODReportURL, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token) }).pipe(
      map((data: any) => {
        // now we deserialize the first level of the report document object
        const deserializedData: IEowReportDocumentDTO = new JsonSerializer().deserializeObject<EowReportDocumentDTO>(data, EowReportDocumentDTO);

        // at this point all the properties for each section are still formatted [SA, SF, SB, and so on]
        // so we need to set section as empty list.
        deserializedData.sections = [];

        // at this point we make an instance of IEodReportDocument
        // and start the data convertion from DTO to BO object.
        // now we also inflate our set of data with reportGuid property
        // where we stick Eod Document Guid that we don't get back from the API
        const dataInflated: IEowReportDocument = {
          reportGuid: EowID,
          createdByUser: deserializedData.createdByUser,
          createdOnPosDevice: deserializedData.createdOnPosDevice,
          productSalesTotal: deserializedData.productSalesTotal,
          reportDate: deserializedData.reportDate,
          reportDescription: deserializedData.reportDescription,
          reportNumber: deserializedData.reportNumber,
          reportVersion: deserializedData.reportVersion,
          sections: deserializedData.sections,
        };

        // now we need process all the sections into the API response (data: any)
        // EG = sections
        data.EG.forEach((element: IEopReportSectionDTO) => {
          // deserialize any element and convert them from DTO to BO
          const sectionData: EopReportSection = this.deserializeSection(element);

          // add the new section on the report document
          dataInflated.sections.push(sectionData);
        });

        return dataInflated;

      }), catchError((err: HttpErrorResponse) => {
        return throwError(() => err.message);
      }));
  }

  private deserializeSection(section: any): EopReportSection {
    const details: IEopReportSectionDetail[] = [];
    const deserializer = new JsonSerializer();

    // SA => sectionDetails
    if (section.SA != null) {

      // SF => sectionDetailType
      // we have to deserialize different concrete class based on sectionDetailType
      // and convert dtos in bo objects
      switch (section.SF) {
        case 0:
          section.SA.forEach(detail => {
            const dto = deserializer.deserializeObject<EopReportSectionDetailAmountDTO>(detail, EopReportSectionDetailAmountDTO);
            const bo: EopReportSectionDetailAmount = {
              description: dto.description,
              amount: dto.amount
            };

            details.push(bo);
          });
          break;

        case 1:
          section.SA.forEach(detail => {
            const dto = deserializer.deserializeObject<EopReportSectionDetailCountAmountDTO>(detail, EopReportSectionDetailCountAmountDTO);
            const bo: EopReportSectionDetailCountAmount = {
              description: dto.description,
              count: dto.count,
              amount: dto.amount
            };

            details.push(bo);
          });
          break;

        case 2:
          section.SA.forEach(detail => {
            const dto = deserializer.deserializeObject<EopReportSectionDetailDescriptionDTO>(detail, EopReportSectionDetailDescriptionDTO);
            const bo: EopReportSectionDetailDescription = {
              description: dto.description,
            };

            details.push(bo);
          });
          break;

        case 3:
          section.SA.forEach(detail => {
            const dto = deserializer.deserializeObject<EopReportSectionDetailVarianceDTO>(detail, EopReportSectionDetailVarianceDTO);
            const bo: EopReportSectionDetailVariance = {
              description: dto.description,
              amount: dto.amount,
              expected: dto.expected,
              variance: dto.variance
            };

            details.push(bo);
          });
          break;

        case 4:
          section.SA.forEach(detail => {
            const dto = deserializer.deserializeObject<EopReportSectionDetailTaxtDTO>(detail, EopReportSectionDetailTaxtDTO);
            const bo: EopReportSectionDetailTax = {
              description: dto.description,
              gross: dto.gross,
              net: dto.net,
              tax: dto.tax
            };

            details.push(bo);
          });
          break;

        default:
          // when sectionDetailType is -1 (NOT_SET)
          // we have to left the details as empty list
          break;
      }

    }

    // recoursive process for subSections
    const subSections: EopReportSection[] = [];
    if (section.SD != null) {
      section.SD.forEach(subSection => {
        subSections.push( this.deserializeSection(subSection) );
      });
    }

    // deserialize the section DTO object and convert into BO object
    const mainSectionDTO: EopReportSectionDTO = deserializer.deserializeObject<EopReportSectionDTO>(section, EopReportSectionDTO);
    const mainSectionBO: EopReportSection = {
      title: mainSectionDTO.title,
      sectionType: mainSectionDTO.sectionType,
      sectionDetailType: mainSectionDTO.sectionDetailType,
      sectionDetails: details,
      showTotal: mainSectionDTO.showTotal,
      subSections: subSections
    };

    return mainSectionBO;
  }

  // return Eod pdf file
  getEodReportFile(EodGuid: string): Observable<ArrayBuffer> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const eodFileEndPoint = APIEndpoint + 'frontend/corporation/' + this.estateService.CorporationID
                                          + '/site/' + this.estateService.SelectedSite.siteId
                                          + '/EndOfDayReport/' + EodGuid
                                          + '/PDF';

      return this.http
        .post(eodFileEndPoint, new TimeZoneDTO(), { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), responseType: 'arraybuffer'}).pipe(
          map((data: ArrayBuffer) => {

            return data;

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  // return Eow pdf file
  getEowReportFile(EowGuid: string): Observable<ArrayBuffer> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const eowFileEndPoint = APIEndpoint + 'frontend/corporation/' + this.estateService.CorporationID
                                          + '/site/' + this.estateService.SelectedSite.siteId
                                          + '/EndOfWeekReport/' + EowGuid
                                          + '/PDF';

      return this.http
        .post(eowFileEndPoint, new TimeZoneDTO(), { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), responseType: 'arraybuffer'}).pipe(
          map((data: ArrayBuffer) => {

            return data;

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }
}

