import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { IEopSectionType } from 'src/app/models/IEopSectionType';

export enum ZReportActionTypes {
    LoadZReport = '[Z Report] Load Z Report Sections',
    LoadZReportSuccessful = '[Z Report] Load Z Report Sections - Successful',
    LoadZReportFailed = '[Z Report] Load Z Report Sections - Failed',
    SaveZReport = '[Z Report] Save Z Report Sections',
    SaveZReportSuccessful = '[Z Report] Save Z Report Sections - Successful',
    SaveZReportFailed = '[Z Report] Save Z Report Sections - Failed',
    ResetStore = '[Z Report] Reset Store',
}

export class LoadZReport implements Action {
    readonly type = ZReportActionTypes.LoadZReport;

    constructor() { }
}

export class LoadZReportSuccessful implements Action {
    readonly type = ZReportActionTypes.LoadZReportSuccessful;

    constructor(public payload: IEopSectionType[]) { }
}

export class LoadZReportFailed implements Action {
    readonly type = ZReportActionTypes.LoadZReportFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveZReport implements Action {
    readonly type = ZReportActionTypes.SaveZReport;

    constructor(public payload: IEopSectionType[]) { }
}

export class SaveZReportSuccessful implements Action {
    readonly type = ZReportActionTypes.SaveZReportSuccessful;

    constructor(public payload: IEopSectionType[]) { }
}

export class SaveZReportFailed implements Action {
    readonly type = ZReportActionTypes.SaveZReportFailed;

    constructor(public payload: IGenericError) { }
}

export class ResetStore implements Action {
    readonly type = ZReportActionTypes.ResetStore;

    constructor() { }
}

export type ZReportActions = LoadZReport
    | LoadZReportSuccessful
    | LoadZReportFailed
    | SaveZReport
    | SaveZReportSuccessful
    | SaveZReportFailed
    | ResetStore;
