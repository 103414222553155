import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KitchenVideosActions, KitchenVideosActionTypes } from './kitchenvideos.actions';
import { IKitchenVideo } from 'src/app/models/IKitchenVideo';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    kitchenVideos: KitchenVideosState;
}

export interface KitchenVideosState extends IGardiffStoreSliceBase {
    kitchenVideos: IKitchenVideo[];
}

const initialState: KitchenVideosState = {
    kitchenVideos: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getKitchenVideosFeatureState = createFeatureSelector<KitchenVideosState>('kitchenVideos');
export const getKitchenVideosState = createSelector(
    getKitchenVideosFeatureState,
    state => state
);

export const getKitchenVideos = createSelector(
    getKitchenVideosFeatureState,
    state => state.kitchenVideos
);

export const getApiError = createSelector(
  getKitchenVideosFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getKitchenVideosFeatureState,
  state => state.status
);

export function reducer(state: KitchenVideosState = initialState, action: KitchenVideosActions): KitchenVideosState {
    switch (action.type) {
        case KitchenVideosActionTypes.LoadKitchenVideos:
            return {
                ...state,
                kitchenVideos: null,
                apiError: null,
                status: null
            };

        case KitchenVideosActionTypes.LoadKitchenVideosSuccessful:
            return {
                ...state,
                kitchenVideos: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case KitchenVideosActionTypes.LoadKitchenVideosFailed:
            return {
                ...state,
                kitchenVideos: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case KitchenVideosActionTypes.SaveKitchenVideos:
            return {
                ...state,
                kitchenVideos: null,
                apiError: null,
                status: null
            };

        case KitchenVideosActionTypes.SaveKitchenVideosSuccessful:
            return {
                ...state,
                kitchenVideos: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case KitchenVideosActionTypes.SaveKitchenVideosFailed:
            return {
                ...state,
                kitchenVideos: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case KitchenVideosActionTypes.ResetStore:
            return {
                ...state,
                kitchenVideos: initialState.kitchenVideos,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
