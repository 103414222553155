import { ReportDefinition } from "./Components/IReportDefinition";
import { ReportPDFDefinition } from "./Components/IReportPDFDefinition";
import { ITransactionReportGroup } from "./ITransactionReportGroup";

export interface ITransactionReport {
    reportVersion: number;
    groups: ITransactionReportGroup[];
    totalTransactions: number;
    totalTransactionsValue: number;
    reportDefinition: ReportDefinition;
    reportPDFDefinition: ReportPDFDefinition;
  }

  export class TransactionReport implements ITransactionReport {
    reportVersion: number;
    groups: ITransactionReportGroup[];
    totalTransactions: number;
    totalTransactionsValue: number;
    reportDefinition: ReportDefinition;
    reportPDFDefinition: ReportPDFDefinition;
  }
