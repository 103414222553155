export enum ProductSalesReportGroupOptionType {
  None = 0,
  TopCategory = 1,
  Category = 2,
  Course = 3,
  User = 4,
  Pos = 5,
  TaxBand = 6
}

export const ProductSalesReportGroupOptionLabel = new Map<number, string>([
  [ProductSalesReportGroupOptionType.None, 'None'],
  [ProductSalesReportGroupOptionType.TopCategory, 'Top Category'],
  [ProductSalesReportGroupOptionType.Category, 'Category'],
  [ProductSalesReportGroupOptionType.Course, 'Course'],
  [ProductSalesReportGroupOptionType.User, 'User'],
  [ProductSalesReportGroupOptionType.Pos, 'Pos Device'],
  [ProductSalesReportGroupOptionType.TaxBand, 'Tax Band']
]);

// how to use it
//
// ProductSalesReportGroupOptionLabel.get(ProductSalesReportGroupOptionType.None);
