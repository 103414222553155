import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as categoryEditorActions from './categories.actions';

@Injectable()
export class CategoryEditorEffects {
  constructor() { }

}
