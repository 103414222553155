import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';


import * as fromProductEditor from '../feature-modules/product-editor/state/productEditor.reducer';
import * as fromEstateSelector from '../feature-modules/estate/state/estate.reducer';
import * as fromEodReport from '../feature-modules/eod/state/eodReport.reducer';
import * as fromEowReport from '../feature-modules/eow/state/eowReport.reducer';
import * as fromSalesReport from '../feature-modules/sales-report/state/sales-report.reducer';
import * as fromPaymentsReport from '../feature-modules/payments-report/state/payments-report.reducer';
import * as fromDiscountReport from './discount-report/discount-report.reducer';
import * as fromVoidReport from './void-report/void-report.reducer';

// NEW SLICES
import * as fromCourses from '../NGRX/courses/courses.reducer';
import * as fromPricelevels from '../NGRX/pricelevels/pricelevels.reducer';
import * as fromSalesArea from '../NGRX/sales-area/salesarea.reducer';
import * as fromKitchenVideos from '../NGRX/kitchen-videos/kitchenvideos.reducer';
import * as fromWeightMeasures from '../NGRX/weight-measures/weightmeasures.reducer';
import * as fromEposSettings from '../NGRX/epos-settings/epossettings.reducer';
import * as fromCategoryEditor from '../feature-modules/category-editor/state/categories.reducer';
import * as fromCategories from '../NGRX/categories/categories.reducer';
import * as fromSchedulePricelevel from '../NGRX/schedule-pricelevels/schedule-pricelevels.reducer';
import * as fromFunctionKeys from '../NGRX/function-keys/function-keys.reducer';
import * as fromRoles from '../NGRX/roles/roles.reducer';
import * as fromUsers from '../NGRX/users/users.reducer';
import * as fromPrinters from '../NGRX/printers/printers.reducer';
import * as fromPosSetup from '../NGRX/pos-setup/possetup.reducer';
import * as fromMenus from '../NGRX/menus/menus.reducer';
import * as fromZReport from '../NGRX/zreport/zreport.reducer';
import * as fromParentCategory from '../NGRX/parent-categories/parent-category.reducer';
import * as fromAddonsEditor from '../feature-modules/addons/state/addons-editor.reducer';
import * as fromAddons from '../NGRX/addons/addons.reducer';
import * as fromProducts from '../NGRX/products/products.reducer';
import * as fromTablePlan from '../NGRX/table-plan/tableplan.reducer';
import * as fromMonitor from '../NGRX/monitor/monitor.reducer';
import * as fromDiscounts from '../NGRX/discounts/discounts.reducer';
import * as fromLogonScreen from '../NGRX/logon-screen/logon.reducer';
import * as fromTaxBands from '../NGRX/tax-bands/taxbands.reducer';
import * as fromAdmin from '../NGRX/admin/admin.reducer';
import * as fromTransactionReport from '../NGRX/transaction-report/transaction-report.reducer';

// F.G. at the moment I removed the package ngrx-store-localstorage from the config file package.json
// I am migrating the project from angular 12 to angular 13 and there isn't a new version of this library.

//import { localStorageSync } from 'ngrx-store-localstorage';

export const FEATURE_NAME = 'AppStoreState';
// tslint:disable-next-line: max-line-length
const STORE_KEYS_TO_PERSIST = ['admin',
                               'login',
                               'monitor',
                               'catalogue',
                               'priceEditor',
                               'estateselector',
                               'eodReport',
                               'eowReport',
                               'productReport',
                               'payments',
                               'discountReport',
                               'productEditor',
                               'categories',
                               'parentCategoryEditor',
                               'courses',
                               'pricelevels',
                               'salesArea',
                               'posSetup',
                               'printers',
                               'kitchenVideos',
                               'weightMeasures',
                               'eposSettings',
                               'roleUsers',
                               'roleFunctions',
                               'menuPlan',
                               'tablePlan'
                              ];

/*
  This interface define the shape of the NGRX Store
*/
export interface AppStoreState {
  admin: fromAdmin.AdminState;
  estateselector: fromEstateSelector.EstateState;
  monitor: fromMonitor.MonitorState;

  // Reports
  eodReport: fromEodReport.EodReportState;
  eowReport: fromEowReport.EowReportState;
  salesReport: fromSalesReport.SalesReportState;
  paymentsReport: fromPaymentsReport.PaymentsReportState;
  discountReport: fromDiscountReport.DiscountReportState;
  voidReport: fromVoidReport.VoidReportState;
  transactionReport: fromTransactionReport.TransactionReportState;

  // Products
  productEditor: fromProductEditor.ProductEditorState;
  products: fromProducts.ProductsState;
  categoryEditor: fromCategoryEditor.CategoryEditorState;
  categories: fromCategories.CategoriesState;
  parentCategory: fromParentCategory.ParentCategoryState;
  courses: fromCourses.CoursesState;
  pricelevels: fromPricelevels.PriceLevelsState;
  salesArea: fromSalesArea.SalesAreaState;
  schedulePriceLevels: fromSchedulePricelevel.SchedulePriceLevelsState;
  addonsEditor: fromAddonsEditor.AddonsEditorState;
  addons: fromAddons.AddonsState;
  discounts: fromDiscounts.DiscountsState;
  taxBands: fromTaxBands.TaxBandsState;

  // Plans
  menus: fromMenus.MenusState;
  tablePlan: fromTablePlan.TablePlanState;
  logonScreen: fromLogonScreen.LogonScreenState;

  // Users
  functionKeys: fromFunctionKeys.FunctionKeysState;
  roles: fromRoles.RolesState;
  users: fromUsers.UsersState;

  //Settings
  eposSettings: fromEposSettings.EposSettingsState;
  posSetup: fromPosSetup.PosSetupState;
  printers: fromPrinters.PrintersState;
  kitchenVideos: fromKitchenVideos.KitchenVideosState;
  weightMeasures: fromWeightMeasures.WeightMeasuresState;
  zReport: fromZReport.ZReportState;
}

export const reducers: ActionReducerMap<AppStoreState> = {
  admin: fromAdmin.reducer,
  estateselector: fromEstateSelector.reducer,
  monitor: fromMonitor.reducer,

  eodReport: fromEodReport.reducer,
  eowReport: fromEowReport.reducer,
  salesReport: fromSalesReport.reducer,
  paymentsReport: fromPaymentsReport.reducer,
  discountReport: fromDiscountReport.reducer,
  voidReport: fromVoidReport.reducer,
  transactionReport: fromTransactionReport.reducer,

  productEditor: fromProductEditor.reducer,
  products: fromProducts.reducer,
  categoryEditor: fromCategoryEditor.reducer,
  categories: fromCategories.reducer,
  parentCategory: fromParentCategory.reducer,
  courses: fromCourses.reducer,
  pricelevels: fromPricelevels.reducer,
  salesArea: fromSalesArea.reducer,
  schedulePriceLevels: fromSchedulePricelevel.reducer,
  addonsEditor: fromAddonsEditor.reducer,
  addons: fromAddons.reducer,
  discounts: fromDiscounts.reducer,
  taxBands: fromTaxBands.reducer,

  menus: fromMenus.reducer,
  tablePlan: fromTablePlan.reducer,
  logonScreen: fromLogonScreen.reducer,

  functionKeys: fromFunctionKeys.reducer,
  roles: fromRoles.reducer,
  users: fromUsers.reducer,

  eposSettings: fromEposSettings.reducer,
  posSetup: fromPosSetup.reducer,
  printers: fromPrinters.reducer,
  kitchenVideos: fromKitchenVideos.reducer,
  weightMeasures: fromWeightMeasures.reducer,
  zReport: fromZReport.reducer
};

export const getAppStoreState = createFeatureSelector<AppStoreState>(FEATURE_NAME);

/* F.G. at the moment we are not intrested to make a persistent store
   so the code below should be commented out */

// export function localStorageSyncReducer(reducer: ActionReducer<AppStoreState>): ActionReducer<AppStoreState> {
//   return localStorageSync({
//     keys: STORE_KEYS_TO_PERSIST,
//     rehydrate: true,
//   })(reducer);
// }

// export const metaReducers: Array<MetaReducer<AppStoreState, Action>> = [localStorageSyncReducer];

/* F.G. - remember to register di effects in ./ngrx-store.module.ts */
