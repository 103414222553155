import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GuidService {
  private GuidGeneratorEndPoint = environment.APIEndpoint + 'frontend/Helper/Guids/';
  private GuidNumber = 100;

  private list: string[];

  constructor(private httpClient: HttpClient, private loginService: LoginService) {
    this.list = [];

    Promise.resolve().then(_ => {
      this.refreshList();
      //console.log('GUID List refreshed');
    });

    //console.log('GUID Service initialized');
  }

  public get Guid(): string {
    let value = '';

    if (this.list != null && this.list.length > 0) {
      value = this.list[0];
      this.list.splice(0, 1);

      //console.log('GUIDs in list : ' + this.list.length);

      if (this.list.length === 0) {
        Promise.resolve().then(_ => {
          this.refreshList();
        });
      }
    }

    return value;
  }

  private async refreshList() {
    const Token: string = this.loginService.AccessToken.authToken;

    //.get(this.GuidGeneratorEndPoint + this.GuidNumber, { responseType: 'text' }).subscribe(data => {

    // tslint:disable-next-line: max-line-length
    this.httpClient.get<string[]>(this.GuidGeneratorEndPoint + this.GuidNumber, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token) }).subscribe(data => {

        if (data != null) {
          //const newList: string[] = data.split('\n');
          const newList: string[] = data;

          newList.forEach(guid => {
            if (guid !== '') {
              this.list.push(guid);
            }
          });

          //console.log('GUIDs in list : ' + this.list.length);
        }

      });
  }
}
