import * as fromRoot from '../../../NGRX/app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SalesReportActions, SalesReportActionTypes } from './sales-report.actions';
import { ISalesReportDocument, SalesReportDocument } from 'src/app/models/ISalesReportDocument';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import { SalesReportFormParameters } from 'src/app/models/ISalesReportFormParameters';

export interface State extends fromRoot.State {
    SalesReports: SalesReportState;
}

export interface SalesReportState extends IGardiffStoreSliceBase {
    SalesReportFormParameters: SalesReportFormParameters;
    SalesReport: ISalesReportDocument;
    downloadedFile: ArrayBuffer;
}

const initialState: SalesReportState = {
    SalesReportFormParameters: new SalesReportFormParameters(),
    SalesReport: null,
    downloadedFile: null,
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getSalesReportFeatureState = createFeatureSelector<SalesReportState>('salesReport');
export const getSalesReportState = createSelector(
    getSalesReportFeatureState,
    state => state
);

export const getParameters = createSelector(
    getSalesReportFeatureState,
    state => state.SalesReportFormParameters
);

export const getSalesReport = createSelector(
    getSalesReportFeatureState,
    state => state.SalesReport
);

export const getDownloadedFile = createSelector(
  getSalesReportFeatureState,
  state => state.downloadedFile
);

export const getApiError = createSelector(
  getSalesReportFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getSalesReportFeatureState,
  state => state.status
);

export function reducer(state: SalesReportState = initialState, action: SalesReportActions): SalesReportState {
    switch (action.type) {
        case SalesReportActionTypes.LoadSalesReport:
            return {
                ...state,
                SalesReportFormParameters: action.payload,
                SalesReport: null,
                apiError: null,
                status: null
            };

        case SalesReportActionTypes.LoadSalesReportSuccessful:
            return {
                ...state,
                SalesReport: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case SalesReportActionTypes.LoadSalesReportFailed:
            return {
                ...state,
                SalesReport: new SalesReportDocument(),
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case SalesReportActionTypes.ResetStore:
            return {
                ...state,
                SalesReportFormParameters : initialState.SalesReportFormParameters,
                SalesReport: initialState.SalesReport,
                apiError: initialState.apiError,
                status: initialState.status
            };

        case SalesReportActionTypes.SaveSearchParameters:
            return {
                ...state,
                SalesReportFormParameters: action.payload
            };

        case SalesReportActionTypes.DownloadEOD:
          return {
              ...state,
              downloadedFile: null,
          };

        case SalesReportActionTypes.DownloadEODSuccessful:
            return {
                ...state,
                downloadedFile: action.payload,
            };

        case SalesReportActionTypes.DownloadEODFailed:
            return {
                ...state,
                downloadedFile: new ArrayBuffer(0)
            };

        case SalesReportActionTypes.CleanUpFileBuffer:
            return {
                ...state,
                downloadedFile: initialState.downloadedFile
            };

        default:
            return state;
    }
}
