import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { ICorporation } from 'src/app/models/ICorporation';
import { EstateView } from 'src/app/models/IEstateView';

export enum MonitorActionTypes {
    LoadCorporations = '[Monitor] Load Corporations',
    LoadCorporationsSuccessful = '[Monitor] Load Corporations - Successful',
    LoadCorporationsFailed = '[Monitor] Load Corporations - Failed',

    ResetStore = '[Monitor] Reset Store',
}

export class LoadCorporations implements Action {
    readonly type = MonitorActionTypes.LoadCorporations;

    constructor() { }
}

export class LoadCorporationsSuccessful implements Action {
    readonly type = MonitorActionTypes.LoadCorporationsSuccessful;

    constructor(public payload: ICorporation[]) { }
}

export class LoadCorporationsFailed implements Action {
    readonly type = MonitorActionTypes.LoadCorporationsFailed;

    constructor(public payload: IGenericError) { }
}



export class ResetStore implements Action {
    readonly type = MonitorActionTypes.ResetStore;

    constructor() { }
}

export type MonitorActions = LoadCorporations
    | LoadCorporationsSuccessful
    | LoadCorporationsFailed
    | ResetStore;
