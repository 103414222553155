import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersActions, UsersActionTypes } from './users.actions';
import { IPosUser } from 'src/app/models/IPosUser';
import { IRole } from 'src/app/models/IRole';
import { IFunctionKey } from 'src/app/models/IFunctionKey';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { IPriceLevel } from 'src/app/models/IPriceLevel';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    users: UsersState;
}

export interface UsersState extends IGardiffStoreSliceBase {
    users: IPosUser[];
    filterByName: string;
}

const initialState: UsersState = {
    users: [],
    filterByName: '',
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getRoleUsersFeatureState = createFeatureSelector<UsersState>('users');
export const getRoleUsersState = createSelector(
    getRoleUsersFeatureState,
    state => state
);

export const getUsers = createSelector(
    getRoleUsersFeatureState,
    state => state.users
);

export const getFilterByName = createSelector(
  getRoleUsersFeatureState,
  (state) => state.filterByName
);

export const getApiError = createSelector(
  getRoleUsersFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getRoleUsersFeatureState,
  state => state.status
);

export function reducer(state: UsersState = initialState, action: UsersActions): UsersState {
    switch (action.type) {
        case UsersActionTypes.LoadUsers:
            return {
                ...state,
                users: null,
                apiError: null,
                status: null
            };

        case UsersActionTypes.LoadUsersSuccessful:
            return {
                ...state,
                users: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case UsersActionTypes.LoadUsersFailed:
            return {
                ...state,
                users: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case UsersActionTypes.StoreFilterByName:
            return {
              ...state,
              filterByName: action.payload
            };

        case UsersActionTypes.SaveUsers:
            return {
                ...state,
                users: null,
                apiError: null,
                status: null
            };

        case UsersActionTypes.SaveUsersSuccessful:
            return {
                ...state,
                users: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case UsersActionTypes.SaveUsersFailed:
            return {
                ...state,
                users: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case UsersActionTypes.RestoreBackup:
          return {
              ...state,
              users: null
          };

        case UsersActionTypes.RestoreBackup:
          return {
              ...state,
              users: null
          };

        case UsersActionTypes.RestoreBackupSuccessful:
            return {
                ...state,
                users: action.payload
            };

        case UsersActionTypes.RestoreBackupFailed:
            return {
                ...state,
                users: []
            };

        case UsersActionTypes.ResetStore:
            return {
                ...state,
                users: initialState.users,
                filterByName: initialState.filterByName,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}

// function updateUsers(list: IPosUser[], user: IPosUser): IPosUser[] {
//   let bFound = false;

//   if (list != null && user != null) {
//     for (let p = 0; p < list.length; p++) {
//       const element = list[p];
//       if (element.user_GUID === user.user_GUID) {
//         list[p] = user;

//         bFound = true;
//         break;
//       }
//     }

//     if (!bFound) {
//       list.push(user);
//     }
//   }

//   return list;
// }
