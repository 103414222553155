import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RolesActions, RolesActionTypes } from './roles.actions';
import { IRole } from 'src/app/models/IRole';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    roles: RolesState;
}

export interface RolesState extends IGardiffStoreSliceBase {
    roles: IRole[];
}

const initialState: RolesState = {
    roles: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getRolesFeatureState = createFeatureSelector<RolesState>('roles');
export const getRoleUsersState = createSelector(
    getRolesFeatureState,
    state => state
);

export const getRoles = createSelector(
    getRolesFeatureState,
    state => state.roles
);

export const getApiError = createSelector(
  getRolesFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getRolesFeatureState,
  state => state.status
);

export function reducer(state: RolesState = initialState, action: RolesActions): RolesState {
    switch (action.type) {

        case RolesActionTypes.LoadRoles:
            return {
                ...state,
                roles: null,
                apiError: null,
                status: null
            };

        case RolesActionTypes.LoadRolesSuccessful:
            return {
                ...state,
                roles: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case RolesActionTypes.LoadRolesFailed:
            return {
                ...state,
                roles: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case RolesActionTypes.SaveRoles:
            return {
                ...state,
                roles: null,
                apiError: null,
                status: null
            };

        case RolesActionTypes.SaveRolesSuccessful:
            return {
                ...state,
                roles: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case RolesActionTypes.SaveRolesFailed:
            return {
                ...state,
                roles: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case RolesActionTypes.ResetStore:
            return {
                ...state,
                roles: initialState.roles,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
