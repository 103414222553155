import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import * as roleUsersActions from './users.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { IPosUser } from 'src/app/models/IPosUser';
import { EstateService } from 'src/app/services/estate.service';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';

@Injectable()
export class UsersEffects {
  constructor(private action$: Actions,
    private userService: UserService,
    private estateService: EstateService) {

  }

  loadRoleUsers$ = createEffect(
    () => this.action$.pipe(
    ofType(roleUsersActions.UsersActionTypes.LoadUsers),
    mergeMap((action: roleUsersActions.LoadUsers) =>
      this.userService.GetRoleUsers().pipe(
        map((users: IPosUser[]) => (new roleUsersActions.LoadUsersSuccessful(users))),
        catchError((error: HttpErrorResponse) => of(new roleUsersActions.LoadUsersFailed(interpretateApiError(error, HttpVerbType.GET))))
      )
    ))
  );

  restoreBackup$ = createEffect(
    () => this.action$.pipe(
    ofType(roleUsersActions.UsersActionTypes.RestoreBackup),
    mergeMap((action: roleUsersActions.RestoreBackup) =>
      this.userService.GetBackup().pipe(
        map((users: IPosUser[]) => (new roleUsersActions.RestoreBackupSuccessful(users))),
        catchError(error => of(new roleUsersActions.RestoreBackupFailed()))
      ),
    ))
  );

  saveRoleUsers$ = createEffect(
    () => this.action$.pipe(
    ofType(roleUsersActions.UsersActionTypes.SaveUsers),
    mergeMap((action: roleUsersActions.SaveRoleUsers) =>
      this.userService.SaveRoleUsers(action.payload).pipe(
        map((users: IPosUser[]) => new roleUsersActions.SaveRoleUsersSuccessful(users)),
        catchError((error: HttpErrorResponse) => of(new roleUsersActions.SaveRoleUsersFailed(interpretateApiError(error, HttpVerbType.POST), action.payload)))
      )
    ))
  );
}
