import { IArea } from './IArea';

export interface IBrand {
    brandId: string;
    brandName: string;
    accessible: boolean;
    areas: IArea[];
}

export class Brand implements IBrand {
    brandId: string;
    brandName: string;
    accessible: boolean;
    areas: IArea[];

    constructor() {
        this.brandId = '';
        this.brandName = '';
        this.accessible = false;
        this.areas = [];
    }
}
