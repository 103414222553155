import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DiscountReportActions, DiscountReportActionTypes } from './discount-report.actions';
import { IReport, Report } from 'src/app/models/Report/Components/IReport';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import { DiscountReportParameters } from 'src/app/models/Report/ReportParameters/IDiscountReportParameters';

export interface State extends fromRoot.State {
  DiscountReports: DiscountReportState;
}

export interface DiscountReportState extends IGardiffStoreSliceBase {
  DiscountReportParameters: DiscountReportParameters;
  DiscountReport: IReport;
  downloadedFile: ArrayBuffer;
}

const initialState: DiscountReportState = {
  DiscountReportParameters: new DiscountReportParameters(),
  DiscountReport: null,
  downloadedFile: null,
  apiError: null,
  status: new GardiffStoreSliceStatus()
};

// create the selectors
const getDiscountReportFeatureState = createFeatureSelector<DiscountReportState>('discountReport');
export const getDiscountReportState = createSelector(
    getDiscountReportFeatureState,
    state => state
);

export const getParameters = createSelector(
    getDiscountReportFeatureState,
    state => state.DiscountReportParameters
);

export const getDiscountReport = createSelector(
    getDiscountReportFeatureState,
    state => state.DiscountReport
);

export const getDownloadedFile = createSelector(
  getDiscountReportFeatureState,
  state => state?.downloadedFile
);

export const getApiError = createSelector(
  getDiscountReportFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getDiscountReportFeatureState,
  state => state.status
);

export function reducer(state: DiscountReportState = initialState, action: DiscountReportActions): DiscountReportState {
    switch (action.type) {
        case DiscountReportActionTypes.LoadDiscountReport:
            return {
                ...state,
                DiscountReportParameters: action.payload,
                DiscountReport: null,
                apiError: null,
                status: null
            };

        case DiscountReportActionTypes.LoadDiscountReportSuccessful:
            return {
                ...state,
                DiscountReport: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case DiscountReportActionTypes.LoadDiscountReportFailed:
            return {
                ...state,
                DiscountReport: null,
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case DiscountReportActionTypes.ResetStore:
          initialState.DiscountReportParameters.clearParameters()
            return {
                ...state,
                DiscountReportParameters : new DiscountReportParameters(),
                DiscountReport: initialState.DiscountReport,
                apiError: initialState.apiError,
                status: initialState.status
            };

        case DiscountReportActionTypes.SaveSearchParameters:
            return {
                ...state,
                DiscountReportParameters: action.payload
            };

        case DiscountReportActionTypes.DownloadPDF:
          return {
              ...state,
              downloadedFile: null,
          };

        case DiscountReportActionTypes.DownloadPDFSuccessful:
            return {
                ...state,
                apiError: null,
                downloadedFile: action.payload,
            };

        case DiscountReportActionTypes.DownloadPDFFailed:
            return {
                ...state,
                apiError: action.payload,
                downloadedFile: null
            };

        case DiscountReportActionTypes.CleanUpFileBuffer:
            return {
                ...state,
                downloadedFile: initialState.downloadedFile
            };

        default:
            return state;
    }
}
