import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import * as VoidReportActions from './void-report.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { of } from 'rxjs';
import { VoidReportService } from 'src/app/services/Reports/Void/void-report.service';
import { IReport } from 'src/app/models/Report/Components/IReport';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';

@Injectable()
export class VoidReportEffects {
  constructor(private action$: Actions, private voidReportService: VoidReportService) { }

  loadReport$ = createEffect(
    () => this.action$.pipe(
      ofType(VoidReportActions.VoidReportActionTypes.LoadVoidReport),
      mergeMap((action: VoidReportActions.LoadVoidReport) => {

        // store the parameters into the localstorage
        action.payload.storeParameters();

        return this.voidReportService.getVoidReport(action.payload).pipe(
          map((report: IReport) => (new VoidReportActions.LoadVoidReportSuccessful(report))),
          catchError((error: HttpErrorResponse) => of(new VoidReportActions.LoadVoidReportFailed(interpretateApiError(error)))
        ));
      })
    )
  );

  downloadVoidReportFile$ = createEffect(
    () => this.action$.pipe(
      ofType(VoidReportActions.VoidReportActionTypes.DownloadPDF),
      mergeMap((action: VoidReportActions.DownloadPDF) => {

        return this.voidReportService.getReportFilePDF(action.definition, action.pdfDefinition, action.sortDefinition).pipe(
          map((file: ArrayBuffer) => (new VoidReportActions.DownloadPDFSuccessful(file))),
          catchError((error: HttpErrorResponse) => of(new VoidReportActions.DownloadPDFFailed(interpretateApiError(error)))
        ));
      })
    )
  );
}
