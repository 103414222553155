import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ITaxBandDTO, TaxBandDTO } from '../models/DTOs/ITaxBandDTO';
import { ITaxBand } from '../models/ITaxBand';
import { ChangeType } from '../enums/ChangeType';
import { ChangeDTO } from '../models/DTOs/ChangeDTO';
import { ChangeResultDTO, IChangeResultDTO } from '../models/DTOs/ChangeResultDTO';
import { toOperationType } from '../helpers/ChangeTypeHelper';
import { toChangeType } from '../helpers/OperationTypeHelper';

@Injectable({
  providedIn: 'root'
})
export class TaxBandsService {
  private TaxBandsEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/TaxBands';

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetTaxBands(CorporationID: string, SiteID: string): Observable<ITaxBand[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.TaxBandsEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http
        .get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<ITaxBandDTO[]>) => {

            if (data.ok) {
              return this.toModel(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  SaveTaxBands(CorporationID: string, SiteID: string, taxBands: ITaxBand[]): Observable<ITaxBand[]> {
    const Token: string = this.loginService.AccessToken.authToken;

    if (Token !== '') {
      const endPoint: string = this.TaxBandsEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID) + "/batch";

      const data: ChangeDTO<ITaxBandDTO>[] = this.SplitItems(taxBands);

      return this.http
        .post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((response: HttpResponse<ChangeResultDTO<ITaxBandDTO>>) => {

            if (response.ok) {
              return this.formatData(response.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        );
    }
  }

  private toModel(dataList: ITaxBandDTO[]): ITaxBand[] {
    let list: ITaxBand[] = [];

    // convert list
    dataList.forEach(item => {
      list.push({
        id: item.id,
        name: item.name,
        percentage: item.percentage,
        isSelected: false,
        changeState: ChangeType.None,
        hasSavingError: false,
        savingErrorDescription: ''
      });
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private formatData(dataList: IChangeResultDTO<ITaxBandDTO>): ITaxBand[] {
    let list: ITaxBand[] = [];

    let failedIds: string[] = dataList.failed.map(failedChangeDTO => failedChangeDTO.dto.id);

    // convert list
    dataList.current.filter(item => !failedIds.includes(item.id)).forEach(item => {
      list.push({
        id: item.id,
        name: item.name,
        percentage: item.percentage,
        isSelected: false,
        changeState: ChangeType.None,
        hasSavingError: false,
        savingErrorDescription: ''
      });
    });

    dataList.failed.forEach(item => {
      let state: ChangeType = toChangeType(item.operation)

      if (state === ChangeType.Deleted) {
        state = ChangeType.None
      }

      list.push({
        id: item.dto.id,
        name: item.dto.name,
        percentage: item.dto.percentage,
        isSelected: false,
        changeState: state,
        hasSavingError: true,
        savingErrorDescription: item.message
      });
    })

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: ITaxBand[]): ITaxBand[] {
    return list.sort((Item1: ITaxBand, Item2: ITaxBand) => {
      if (Item1.name.toLowerCase() > Item2.name.toLowerCase()) {
        return 1;
      }

      if (Item1.name.toLowerCase() < Item2.name.toLowerCase()) {
        return -1;
      }
    });
  }

  private SplitItems(data: ITaxBand[]): ChangeDTO<ITaxBandDTO>[] {
    let newData: ChangeDTO<ITaxBandDTO>[] = [];

    data.filter(taxBand =>
      taxBand.changeState != ChangeType.None)
      .forEach(taxBand =>
        newData.push(new ChangeDTO<TaxBandDTO>(toOperationType(taxBand.changeState), this.DeflateItem(taxBand)))
    )

    return newData;
  }

  private DeflateItem(data: ITaxBand): TaxBandDTO {
    return {
      id: data.id,
      name: data.name,
      percentage: data.percentage
    }
  }
}
