import { Action } from "@ngrx/store";
import { IGenericError } from "src/app/models/apiErrorDTOs/IGenericError";
import { IMenu } from "src/app/models/IMenu";

export enum LogonActionTypes {
    LoadLogonScreen = '[Logon Screen] Load Logon Screen',
    LoadLogonScreenSuccessful = '[Logon Screen] Load Logon Screen - Successful',
    LoadLogonScreenFailed = '[Logon Screen] Load Logon Screen - Failed',

    SaveLogonScreen = '[Logon Screen] Save Logon Screen',
    SaveLogonScreenSuccessful = '[Logon Screen] Save Logon Screen - Successful',
    SaveLogonScreenFailed = '[Logon Screen] Save Logon Screen - Failed',

    ResetStore = '[Logon Screen] Reset Store',
}

export class LoadLogonScreen implements Action {
    readonly type = LogonActionTypes.LoadLogonScreen;

    constructor() { }
}

export class LoadLogonScreenSuccessful implements Action {
    readonly type = LogonActionTypes.LoadLogonScreenSuccessful;

    constructor(public payload: IMenu) { }
}

export class LoadLogonScreenFailed implements Action {
    readonly type = LogonActionTypes.LoadLogonScreenFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveLogonScreen implements Action {
    readonly type = LogonActionTypes.SaveLogonScreen;

    constructor(public payload: IMenu) { }
}

export class SaveLogonScreenSuccessful implements Action {
    readonly type = LogonActionTypes.SaveLogonScreenSuccessful;

    constructor(public payload: IMenu) { }
}

export class SaveLogonScreenFailed implements Action {
    readonly type = LogonActionTypes.SaveLogonScreenFailed;

    constructor(public error: IGenericError, public oldData: IMenu) { }
}

export class ResetStore implements Action {
    readonly type = LogonActionTypes.ResetStore;

    constructor() { }
}

export type LogonScreenActions = LoadLogonScreen
    | LoadLogonScreenSuccessful
    | LoadLogonScreenFailed
    | SaveLogonScreen
    | SaveLogonScreenSuccessful
    | SaveLogonScreenFailed
    | ResetStore;
