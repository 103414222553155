import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductsActionTypes, ProductsActions } from './products.actions';
import { ICategory, Category } from 'src/app/models/ICategory';
import { IPrinter } from 'src/app/models/IPrinter';
import { IKitchenVideo } from 'src/app/models/IKitchenVideo';
import { IAddonDTO } from 'src/app/models/DTOs/IAddonDTO';
import { IMeasure } from 'src/app/models/IMeasure';
import { IPriceLevel } from 'src/app/models/IPriceLevel';
import { ICourse } from 'src/app/models/ICourse';
import { IProduct, Product } from 'src/app/models/IProduct';
import { ISchedulePriceLevel } from 'src/app/models/ISchedulePriceLevel';
import { IPosSetup } from 'src/app/models/IPosSetup';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    products: ProductsState;
}

export interface ProductsState extends IGardiffStoreSliceBase {
    products: IProduct[];
}

const initialState: ProductsState = {
    products: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getProductsFeatureState = createFeatureSelector<ProductsState>('products');
export const getProductEditorState = createSelector(
    getProductsFeatureState,
    state => state
);

export const getProducts = createSelector(
    getProductsFeatureState,
    state => state.products
);

export const getApiError = createSelector(
  getProductsFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getProductsFeatureState,
  state => state.status
);

export function reducer(state: ProductsState = initialState, action: ProductsActions): ProductsState {
    switch (action.type) {
        case ProductsActionTypes.LoadProducts:
            return {
                ...state,
                products: null,
                apiError: null,
                status: null
            };

        case ProductsActionTypes.LoadProductsSuccessful:
            return {
                ...state,
                products: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case ProductsActionTypes.LoadProductsFailed:
            return {
                ...state,
                products: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case ProductsActionTypes.SaveProducts:
          return {
              ...state,
              products: null,
              apiError: null,
              status: null
          };

        case ProductsActionTypes.SaveProductsSuccessful:
            return {
                ...state,
                products: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case ProductsActionTypes.SaveProductsFailed:
            return {
                ...state,
                products: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case ProductsActionTypes.RestoreBackup:
          return {
              ...state,
              products: null
          };

        case ProductsActionTypes.RestoreBackupSuccessful:
            return {
                ...state,
                products: action.payload
            };

        case ProductsActionTypes.RestoreBackupFailed:
            return {
                ...state,
                products: []
            };

        case ProductsActionTypes.ResetStore:
            return {
                ...state,
                products: initialState.products,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}

// function updateCatalogue(products: IProduct[], product: IProduct): IProduct[] {
//   let bFound = false;

//   for (let p = 0; p < products.length; p++) {
//     const element = products[p];
//     if (element.productGuid === product.productGuid) {
//       products[p] = product;

//       bFound = true;
//       break;
//     }
//   }

//   if (!bFound) {
//     products.push(product);
//   }

//   return products;
// }
