import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, Subscriber } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeOperationType } from '../enums/ChangeOperationType';
import { ChangeType } from '../enums/ChangeType';
import { EditDataList, IEditDataList } from '../models/DTOs/IEditDataList';
import { IEnvelopeInDTO } from '../models/DTOs/IEnvelopeInDTO';
import { IEnvelopeOutDTO } from '../models/DTOs/IEnvelopeOutDTO';
import { IPrinterDTO } from '../models/DTOs/IPrinterDTO';
import { IPrinter } from '../models/IPrinter';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class PrintersService {
  private PrinterSetupEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/Printers';

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetPrinters(CorporationID: string, SiteID: string): Observable<IPrinter[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.PrinterSetupEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http.get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<IPrinterDTO[]>) => {
          
          if (data.ok) {
            return this.formatData(data.body);
          }

        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private formatData(dataList: IPrinterDTO[]): IPrinter[] {
    let list: IPrinter[] = [];

    // convert list
    dataList.forEach(item => {
      list.push({
        id: item.id,
        printerGuid: item.printerGuid,
        printerDeviceId: item.printerDeviceId,
        printerName: item.printerName,
        usesComPort: item.usesComPort,
        comPortName: item.comPortName,
        ipAddress: item.ipAddress,
        posHostId: item.posHostId,
        baudRate: item.baudRate,
        printerDriver: item.printerDriver,
        isSelected: false,
        changeState: ChangeType.None
      });
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: IPrinter[]): IPrinter[] {
    return list.sort((Item1: IPrinter, Item2: IPrinter) => {
      if (Item1.printerDeviceId > Item2.printerDeviceId) {
        return 1;
      }

      if (Item1.printerDeviceId < Item2.printerDeviceId) {
        return -1;
      }
    });
  }

  SavePrinters(CorporationID: string, SiteID: string, oldData: IPrinter[], newData: IPrinter[]): Observable<IPrinter[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.PrinterSetupEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IEditDataList<IPrinterDTO> = this.SplitItems(newData);

      return this.http.post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<IPrinterDTO[]>) => {
          
          if (data.ok) {
            return this.formatData(data.body);
          }

        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private DeflateItems(list: IPrinter[]): IPrinterDTO[] {
    const newData: IPrinterDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        newData.push({
          id: item.id,
          printerGuid: item.printerGuid,
          printerDeviceId: item.printerDeviceId,
          printerName: item.printerName,
          usesComPort: item.usesComPort,
          comPortName: item.comPortName,
          ipAddress: item.ipAddress,
          posHostId: item.posHostId,
          baudRate: item.baudRate,
          printerDriver: item.printerDriver,
        });
      });
    }

    return newData;
  }

  private SplitItems(data: IPrinter[]): IEditDataList<IPrinterDTO> {
    let newData: IEditDataList<IPrinterDTO> = new EditDataList<IPrinterDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }
}
