import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeOperationType } from '../enums/ChangeOperationType';
import { ChangeType } from '../enums/ChangeType';
import { EditDataList, IEditDataList } from '../models/DTOs/IEditDataList';
import { IEnvelopeInDTO } from '../models/DTOs/IEnvelopeInDTO';
import { IEnvelopeOutDTO } from '../models/DTOs/IEnvelopeOutDTO';
import { IProductDTO } from '../models/DTOs/IProductDTO';
import { IProductPrinterPosRuleCopiesDTO } from '../models/DTOs/IProductPrinterPosRuleCopiesDTO';
import { IProductPrinterPosRuleDTO } from '../models/DTOs/IProductPrinterPosRuleDTO';
import { ConvertPriceMatrix, ConvertPriceMatrixDTO } from '../models/IPriceMatrix';
import { IProduct } from '../models/IProduct';
import { IProductPrinterPosRule, ProductPrinterPosRule } from '../models/IProductPrinterPosRule';
import { IProductPrinterPosRuleCopies } from '../models/IProductPrinterPosRuleCopies';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private ProductCatalogueEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/Products';

  private products: IProduct[] = [];

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetProducts(CorporationID: string, SiteID: string): Observable<IProduct[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      let endPoint: string = this.ProductCatalogueEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http.get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<IProductDTO[]>) => {

          if (data.ok) {
            // store a copy for backup
            this.products = this.formatData(data.body);
            return JSON.parse(JSON.stringify(this.products));
          }

        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private formatData(dataList: IProductDTO[]): IProduct[] {
    // convert list
    const list: IProduct[] = this.ConvertProductsDTO(dataList);

    return list;
  }

  private ConvertProductsDTO(products: IProductDTO[]): IProduct[] {
    const newList: IProduct[] = [];

    products.forEach(product => {
      newList.push(
        this.ConvertProductDTO(product)
      );
    });

    return newList;
  }

  private ConvertProductDTO(product: IProductDTO): IProduct {

    // format prices
    product.priceMatrix.priceGroupList.forEach(measurement => {
      measurement.priceLevelList.forEach(pricelevel => {
        pricelevel.price = Number(pricelevel.price).toFixed(2);
      });
    });

    const newProduct: IProduct = {
      id: product.id,
      productGuid: product.productGuid,
      name: product.name,
      displayName: product.displayName,
      isActive: product.isActive,
      createdDateTime: product.createdDateTime,
      categoryGuid: product.categoryGuid,
      categoryName: product.categoryName,
      courseGuid: product.courseGuid,
      courseName: product.courseName,
      createdBy: product.createdBy,
      isOpenPrice: product.isOpenPrice,
      isMeasureVisible: product.isMeasureVisible,
      priceMatrix: ConvertPriceMatrixDTO(product.priceMatrix),
      kitchenVideos: [],
      kitchenVideoRules: [],
      kitchenPrinters: [],
      kitchenPrinterRules: [],
      addonHeaders: product.addonHeaders,
      preparationDevices: [],
      isSelected: false,
      priceToDisplay: 0,
      categoryColor: '#FFF',
      categoryColorText: '#000',
      changeState: ChangeType.None
    };

    if (product.kitchenVideos != null) {
      product.kitchenVideos.forEach(kv => {
        newProduct.kitchenVideos.push({
          id: kv.id,
          kitchenVideoGuid: kv.kitchenVideoGuid,
          kitchenVideoDeviceId: kv.kitchenVideoDeviceId,
          kitchenVideoName: kv.kitchenVideoName,
          screenId: kv.screenId,
          ipAddress: kv.ipAddress,
          isDefaultDevice: kv.isDefaultDevice,
          isSelected: false,
          isChanged: ChangeType.None
        });
      });
    }

    if (product.kitchenVideoRules != null) {
      product.kitchenVideoRules.forEach(kv => {
        newProduct.kitchenVideoRules.push({
          isInstead: kv.isInstead,
          posGuid: kv.posGuid,
          screenGuids: kv.screenGuids,
          isSelected: false,
          changeState: ChangeType.None
        });
      });
    }


    if (product.kitchenPrinters != null) {
      product.kitchenPrinters.forEach(kp => {
        newProduct.kitchenPrinters.push({
          id: kp.id,
          printerGuid: kp.printerGuid,
          printerDeviceId: kp.printerDeviceId,
          printerName: kp.printerName,
          usesComPort: kp.usesComPort,
          comPortName: kp.comPortName,
          ipAddress: kp.ipAddress,
          posHostId: kp.posHostId,
          baudRate: kp.baudRate,
          printerDriver: kp.printerDriver,
          isDefaultDevice: kp.isDefaultDevice,
          isSelected: false,
          isChanged: ChangeType.None
        });
      });
    }

    // check with Ben and Dave why sometimes I get null for kitchenPrinterRules
    // only when I am applying a change to an existing object, maybe there is
    // some error when the process into the cloud create the update instruction
    if (product.kitchenPrinterRules != null) {
      product.kitchenPrinterRules.forEach(rule => {
        const newRule: IProductPrinterPosRule = {
          copies: [],
          isInstead: rule.isInstead,
          posGuid: rule.posGuid
        };

        rule.copies.forEach(item => {
          newRule.copies.push({
            numberOfCopies: item.numberOfCopies,
            printerDeviceGuid: item.printerDeviceGuid,
            printerDeviceName: ''
          });
        });

        newProduct.kitchenPrinterRules.push(newRule);
        // newProduct.kitchenPrinterRules.push(this.convertPrinterRuleDTO(rule));
      });
    }

    return newProduct;
  }

  SaveProducts(CorporationID: string, SiteID: string, newProducts: IProduct[]): Observable<IProduct[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.ProductCatalogueEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IEditDataList<IProductDTO> = this.SplitItems(newProducts);

      return this.http.post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<IProductDTO[]>) => {

          if (data.ok) {
            // store a copy for backup
            this.products = this.formatData(data.body);

            return JSON.parse(JSON.stringify(this.products));
          }
          

        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private DeflateItems(list: IProduct[]): IProductDTO[] {
    const newData: IProductDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        newData.push(
          this.ConvertProduct(item)
        );
      });
    }

    return newData;
  }

  private ConvertProduct(product: IProduct): IProductDTO {

    const newProduct: IProductDTO = {
      id: product.id,
      productGuid: product.productGuid,
      name: product.name,
      displayName: product.displayName,
      isActive: product.isActive,
      createdDateTime: product.createdDateTime,
      categoryGuid: product.categoryGuid,
      categoryName: product.categoryName,
      courseGuid: product.courseGuid,
      courseName: product.courseName,
      createdBy: product.createdBy,
      isOpenPrice: product.isOpenPrice,
      isMeasureVisible: product.isMeasureVisible,
      priceMatrix: ConvertPriceMatrix(product.priceMatrix),
      kitchenVideos: [],
      kitchenVideoRules: [],
      kitchenPrinters: [],
      kitchenPrinterRules: [],
      addonHeaders: product.addonHeaders
    };

    product.kitchenVideos.forEach(kv => {
      newProduct.kitchenVideos.push({
        id: kv.id,
        kitchenVideoGuid: kv.kitchenVideoGuid,
        kitchenVideoDeviceId: kv.kitchenVideoDeviceId,
        kitchenVideoName: kv.kitchenVideoName,
        screenId: kv.screenId,
        ipAddress: kv.ipAddress,
        isDefaultDevice: kv.isDefaultDevice
      });
    });

    product.kitchenVideoRules.forEach(kv => {
      newProduct.kitchenVideoRules.push({
        isInstead: kv.isInstead,
        posGuid: kv.posGuid,
        screenGuids: kv.screenGuids
      });
    });

    product.kitchenPrinters.forEach(kp => {
      newProduct.kitchenPrinters.push({
        id: kp.id,
        printerGuid: kp.printerGuid,
        printerDeviceId: kp.printerDeviceId,
        printerName: kp.printerName,
        usesComPort: kp.usesComPort,
        comPortName: kp.comPortName,
        ipAddress: kp.ipAddress,
        posHostId: kp.posHostId,
        baudRate: kp.baudRate,
        printerDriver: kp.printerDriver,
        isDefaultDevice: kp.isDefaultDevice
      });
    });

    product.kitchenPrinterRules.forEach(rule => {
      const newRule: IProductPrinterPosRuleDTO = {
        copies: [],
        isInstead: rule.isInstead,
        posGuid: rule.posGuid
      };

      rule.copies.forEach(item => {
        newRule.copies.push({
          numberOfCopies: item.numberOfCopies,
          printerDeviceGuid: item.printerDeviceGuid
        });
      });

      newProduct.kitchenPrinterRules.push(newRule);
    });

    return newProduct;
  }

  private SplitItems(data: IProduct[]): IEditDataList<IProductDTO> {
    let newData: IEditDataList<IProductDTO> = new EditDataList<IProductDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }

  GetBackup(): Observable<IProduct[]> {
    const backup: IProduct[] = JSON.parse(JSON.stringify(this.products));
    return new Observable<IProduct[]>((subscriber: Subscriber<IProduct[]>) => subscriber.next(backup));
  }
}
