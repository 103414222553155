import { ChangeType } from '../enums/ChangeType';
import { IPriceLevelListDTO } from './DTOs/IPriceLevelListDTO';

export interface IPriceLevelList extends IPriceLevelListDTO {
    isChanged: ChangeType;
    isSelected: boolean;
}

export class PriceLevelList implements IPriceLevelList {
    priceLevelPosition: number;
    priceLevelName: string;
    price: string;
    isChanged: ChangeType;
    isSelected: boolean;

    constructor() {
        this.priceLevelPosition = 0;
        this.priceLevelName = '';
        this.price = '0';
        this.isChanged = ChangeType.None;
        this.isSelected = false;
    }
}

export function ConvertPriceLevelListDTO(list: IPriceLevelListDTO[]): IPriceLevelList[] {
  const newList: IPriceLevelList[] = [];

  list.forEach(item => {
    newList.push({
      priceLevelName: item.priceLevelName,
      priceLevelPosition: item.priceLevelPosition,
      price: item.price,
      isChanged: ChangeType.None,
      isSelected: false
    });
  });

  return newList;
}

export function ConvertPriceLevelList(list: IPriceLevelList[]): IPriceLevelListDTO[] {
  const newList: IPriceLevelListDTO[] = [];

  list.forEach(item => {
    newList.push({
      priceLevelName: item.priceLevelName,
      priceLevelPosition: item.priceLevelPosition,
      price: item.price.toString()
    });
  });

  return newList;
}
