import { ChangeType } from "../enums/ChangeType";
import { OperationType } from "../enums/OperationType";

export function toOperationType(operationType: ChangeType): OperationType{
  switch(operationType){
    case ChangeType.New:
      return OperationType.New;
    case ChangeType.Edited:
      return OperationType.Edited;
    case ChangeType.Deleted:
      return OperationType.Deleted;
    default:
      throw new Error("Changetype")
  }
}
