import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import * as SalesReportActions from './sales-report.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { of } from 'rxjs';
import { SalesReportService } from 'src/app/services/sales-report.service';
import { ISalesReportDocument } from 'src/app/models/ISalesReportDocument';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';

@Injectable()
export class SalesReportEffects {
    constructor(private action$: Actions, private salesReportService: SalesReportService) { }

    loadReport$ = createEffect(
      () => this.action$.pipe(
        ofType(SalesReportActions.SalesReportActionTypes.LoadSalesReport),
        mergeMap((action: SalesReportActions.LoadSalesReport) => {
            let fromDate: Date;
            let toDate: Date;

            fromDate = action.payload.searchFromDate;
            fromDate.setHours( Number(action.payload.searchFromHour), Number(action.payload.searchFromMinute), 0, 0);

            toDate = action.payload.searchToDate;
            toDate.setHours( Number(action.payload.searchToHour), Number(action.payload.searchToMinute), 59, 999);

            // console.log('from Date : ' + fromDate.toISOString());
            // console.log('to Date : ' + toDate.toISOString());

            // store the parameters into the localstorage
            action.payload.storeParameters();

            return this.salesReportService.getSalesReport(fromDate,
                                                          toDate,
                                                          action.payload.groupBy,
                                                          action.payload.subGroupBy,
                                                          action.payload.selectedPosMachineID,
                                                          action.payload.selectedClerkID,
                                                          action.payload.selectedCategoryID,
                                                          action.payload.selectedParentCategoryID,
                                                          action.payload.selectedCourseID,
                                                          action.payload.showDetails,
                                                          action.payload.includeTax).pipe(
                map((report: ISalesReportDocument) => (new SalesReportActions.LoadSalesReportSuccessful(report)),
                catchError((error: HttpErrorResponse) => of(new SalesReportActions.LoadSalesReportFailed(interpretateApiError(error))))
            ));
          }
        )
      )
    );

    downloadSalesReportFile$ = createEffect(
      () => this.action$.pipe(
        ofType(SalesReportActions.SalesReportActionTypes.DownloadEOD),
        mergeMap((action: SalesReportActions.DownloadEOD) => {

          let fromDate: Date;
          let toDate: Date;

          fromDate = action.payload.searchFromDate;
          fromDate.setHours( Number(action.payload.searchFromHour), Number(action.payload.searchFromMinute), 0, 0);

          toDate = action.payload.searchToDate;
          toDate.setHours( Number(action.payload.searchToHour), Number(action.payload.searchToMinute), 59, 999);

          return this.salesReportService.getReportFilePDF(fromDate,
                                                          toDate,
                                                          action.payload.groupBy,
                                                          action.payload.subGroupBy,
                                                          action.payload.selectedPosMachineID,
                                                          action.payload.selectedClerkID,
                                                          action.payload.selectedCategoryID,
                                                          action.payload.selectedParentCategoryID,
                                                          action.payload.selectedCourseID,
                                                          action.payload.showDetails,
                                                          action.payload.includeTax).pipe(
            map((file: ArrayBuffer) => (new SalesReportActions.DownloadEODSuccessful(file)),
            catchError((error: HttpErrorResponse) => of(new SalesReportActions.DownloadEODFailed(interpretateApiError(error))))
          ));
        }
      ))
    );
}
