export interface IGardiffStoreSliceStatus {
  isLoaded: boolean;
  date: Date;
}

export class GardiffStoreSliceStatus implements IGardiffStoreSliceStatus {
  date: Date;

  constructor(public isLoaded: boolean = false) {
    this.date = new Date();
  }
}
