import { IEowReportDocumentDTO } from './DTOs/IEowReportDocumentDTO';
import { IEopReportSection } from './IEopReportSection';

export interface IEowReportDocument extends IEowReportDocumentDTO {
  reportGuid: string;
  createdByUser: string;
  createdOnPosDevice: string;
  productSalesTotal: number;
  reportDate: Date;
  reportDescription: string;
  reportNumber: number;
  reportVersion: number;
  sections: IEopReportSection[];
}

export class EowReportDocument implements IEowReportDocument {
  public reportGuid: string;
  public createdByUser: string;
  public createdOnPosDevice: string;
  public productSalesTotal: number;
  public reportDate: Date;
  public reportDescription: string;
  public reportNumber: number;
  public reportVersion: number;
  public sections: IEopReportSection[];

  constructor() {
    this.sections = [];
  }
}
