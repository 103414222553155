import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PriceLevelsActions, PriceLevelsActionTypes } from './pricelevels.actions';
import { IPriceLevel } from 'src/app/models/IPriceLevel';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    pricelevels: PriceLevelsState;
}

export interface PriceLevelsState extends IGardiffStoreSliceBase {
    pricelevels: IPriceLevel[];
}

const initialState: PriceLevelsState = {
    pricelevels: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getPricelevelsFeatureState = createFeatureSelector<PriceLevelsState>('pricelevels');
export const getPricelevelsState = createSelector(
    getPricelevelsFeatureState,
    state => state
);

export const getPricelevels = createSelector(
    getPricelevelsFeatureState,
    state => state.pricelevels
);

export const getApiError = createSelector(
  getPricelevelsFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getPricelevelsFeatureState,
  state => state.status
);

export function reducer(state: PriceLevelsState = initialState, action: PriceLevelsActions): PriceLevelsState {
    switch (action.type) {
        case PriceLevelsActionTypes.LoadPriceLevels:
            return {
                ...state,
                pricelevels: null,
                apiError: null,
                status: null
            };

        case PriceLevelsActionTypes.LoadPriceLevelsSuccessful:
            return {
                ...state,
                pricelevels: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case PriceLevelsActionTypes.LoadPriceLevelsFailed:
            return {
                ...state,
                pricelevels: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case PriceLevelsActionTypes.SavePriceLevels:
            return {
                ...state,
                pricelevels: null,
                apiError: null,
                status: null
            };

        case PriceLevelsActionTypes.SavePriceLevelsSuccessful:
            return {
                ...state,
                pricelevels: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case PriceLevelsActionTypes.SavePriceLevelsFailed:
            return {
                ...state,
                pricelevels: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case PriceLevelsActionTypes.ResetStore:
            return {
                ...state,
                pricelevels: initialState.pricelevels,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
