import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeOperationType } from '../enums/ChangeOperationType';
import { ChangeType } from '../enums/ChangeType';
import { EditDataList, IEditDataList } from '../models/DTOs/IEditDataList';
import { IEnvelopeInDTO } from '../models/DTOs/IEnvelopeInDTO';
import { IEnvelopeOutDTO } from '../models/DTOs/IEnvelopeOutDTO';
import { IPosSetupDTO } from '../models/DTOs/IPosSetupDTO';
import { IPosSetup, PosSetup } from '../models/IPosSetup';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class PosSetupService {
  private PosSetupEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/Tills';

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetPosSetup(CorporationID: string, SiteID: string): Observable<PosSetup[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.PosSetupEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http.get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<IPosSetupDTO[]>) => {
          
          if (data.ok) {
            return this.formatData(data.body);
          }

        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private formatData(dataList: IPosSetupDTO[]): PosSetup[] {
    let list: PosSetup[] = [];

    // convert list
    dataList.forEach(item => {
      list.push({
        id: item.id,
        posSetupGuid: item.posSetupGuid,
        posId: item.posId,
        ipAddress: item.ipAddress,
        posName: item.posName,
        defaultPrinterGuid: item.defaultPrinterGuid,
        defaultMenuGuid: item.defaultMenuGuid,
        defaultSalesAreaGuid: item.defaultSalesAreaGuid,
        defaultEftTerminalGuid: item.defaultEftTerminalGuid,
        deviceID: item.deviceID,
        isActive: item.isActive,
        isSelected: false,
        changeState: ChangeType.None,
        selectedMenu: null
      });
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: PosSetup[]): PosSetup[] {
    return list.sort((Item1: PosSetup, Item2: PosSetup) => {
      if (Item1.posId > Item2.posId) {
        return 1;
      }

      if (Item1.posId < Item2.posId) {
        return -1;
      }
    });
  }

  SavePosSetup(CorporationID: string, SiteID: string, oldData: IPosSetup[], newData: IPosSetup[]): Observable<PosSetup[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.PosSetupEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IEditDataList<IPosSetupDTO> = this.SplitItems(newData);

      return this.http.post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<IPosSetupDTO[]>) => {
          
          if (data.ok) {
            return this.formatData(data.body);
          }

        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private DeflateItems(list: PosSetup[]): IPosSetupDTO[] {
    const newData: IPosSetupDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        newData.push({
          id: item.id,
          posSetupGuid: item.posSetupGuid,
          posId: item.posId,
          ipAddress: item.ipAddress,
          posName: item.posName,
          defaultPrinterGuid: item.defaultPrinterGuid,
          defaultMenuGuid: item.defaultMenuGuid,
          defaultSalesAreaGuid: item.defaultSalesAreaGuid,
          defaultEftTerminalGuid: item.defaultEftTerminalGuid,
          deviceID: item.deviceID,
          isActive: item.isActive
        });
      });
    }

    return newData;
  }

  private SplitItems(data: IPosSetup[]): IEditDataList<IPosSetupDTO> {
    let newData: IEditDataList<IPosSetupDTO> = new EditDataList<IPosSetupDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }
}
