import { Action } from '@ngrx/store';
import { IPosUser } from 'src/app/models/IPosUser';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';

export enum UsersActionTypes {
    LoadUsers = '[Users] Load Users',
    LoadUsersSuccessful = '[Users] Load Users - Successful',
    LoadUsersFailed = '[Users] Load Users - Failed',

    StoreFilterByName = '[Users] Store Filter by Name',

    SaveUsers = '[Users] Save Users',
    SaveUsersSuccessful = '[Users] Save Users - Successful',
    SaveUsersFailed = '[Users] Save Users - Failed',

    RestoreBackup = '[Users] Restore Backup',
    RestoreBackupSuccessful = '[Users] Restore Backup - Successful',
    RestoreBackupFailed = '[Users] Restore Backup - Failed',

    ResetStore = '[Users] Reset Store',
}

export class LoadUsers implements Action {
    readonly type = UsersActionTypes.LoadUsers;

    constructor() { }
}

export class LoadUsersSuccessful implements Action {
    readonly type = UsersActionTypes.LoadUsersSuccessful;

    constructor(public payload: IPosUser[]) { }
}

export class LoadUsersFailed implements Action {
    readonly type = UsersActionTypes.LoadUsersFailed;

    constructor(public payload: IGenericError) { }
}

export class StoreFilterByName implements Action {
  readonly type = UsersActionTypes.StoreFilterByName;

  constructor(public payload: string) { }
}

export class SaveRoleUsers implements Action {
    readonly type = UsersActionTypes.SaveUsers;

    constructor(public payload: IPosUser[]) { }
}

export class SaveRoleUsersSuccessful implements Action {
    readonly type = UsersActionTypes.SaveUsersSuccessful;

    constructor(public payload: IPosUser[]) { }
}

export class SaveRoleUsersFailed implements Action {
    readonly type = UsersActionTypes.SaveUsersFailed;

    constructor(public error: IGenericError, public oldData: IPosUser[]) { }
}

export class RestoreBackup implements Action {
  readonly type = UsersActionTypes.RestoreBackup;

  constructor() { }
}

export class RestoreBackupSuccessful implements Action {
  readonly type = UsersActionTypes.RestoreBackupSuccessful;

  constructor(public payload: IPosUser[]) { }
}

export class RestoreBackupFailed implements Action {
  readonly type = UsersActionTypes.RestoreBackupFailed;

  constructor() { }
}

export class ResetStore implements Action {
    readonly type = UsersActionTypes.ResetStore;

    constructor() { }
}

export type UsersActions = LoadUsers
    | LoadUsersSuccessful
    | LoadUsersFailed
    | StoreFilterByName
    | SaveRoleUsers
    | SaveRoleUsersSuccessful
    | SaveRoleUsersFailed
    | RestoreBackup
    | RestoreBackupSuccessful
    | RestoreBackupFailed
    | ResetStore;
