import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { IParentCategory } from 'src/app/models/IParentCategory';
import { IParentCategoryItem } from 'src/app/models/IParentCategoryItem';
import { ParentCategoriesService } from 'src/app/services/parent-categories.service';
import * as categoryEditorActions from '../categories/categories.actions';
import * as parentCategoryActions from './parent-category.actions';

@Injectable()
export class ParentCategoryEditorEffects {
    constructor(private action$: Actions,
      private estateService: EstateService,
      private parentCategoriesService: ParentCategoriesService) {

    }

    loadParentCategories$ = createEffect(
      () => this.action$.pipe(
        ofType(parentCategoryActions.ParentCategoryActionTypes.LoadParentCategories),
        mergeMap((action: parentCategoryActions.LoadParentCategories) =>
            this.parentCategoriesService.GetParentCategories(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
                map((parentCateogries: IParentCategory[]) => (new parentCategoryActions.LoadParentCategoriesSuccessful(parentCateogries)),
                catchError((error: HttpErrorResponse) => of(new parentCategoryActions.LoadParentCategoriesFailed(interpretateApiError(error))))
        )),
      ))
    );

    saveParentCategories$ = createEffect(
      () =>  this.action$.pipe(
        ofType(parentCategoryActions.ParentCategoryActionTypes.SaveParentCategories),
        mergeMap((action: parentCategoryActions.SaveParentCategories) =>
            this.parentCategoriesService.SaveParentCategories(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.payload).pipe(
                map((result: IParentCategory[]) => new parentCategoryActions.SaveParentCategoriesSuccessful(result)),
                catchError((error: HttpErrorResponse) => of(new parentCategoryActions.SaveParentCategoriesFailed(interpretateApiError(error))))
            ),
      ))
    );

    loadParentCategoriesFlatList$ = createEffect(
      () => this.action$.pipe(
        ofType(parentCategoryActions.ParentCategoryActionTypes.LoadParentCategoriesFlatList),
        mergeMap((action: parentCategoryActions.LoadParentCategoriesFlatList) =>
            this.parentCategoriesService.GetParentCategoriesFlatList(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
                map((parentCateogries: IParentCategoryItem[]) => (new parentCategoryActions.LoadParentCategoriesFlatListSuccessful(parentCateogries)),
                catchError((error: HttpErrorResponse) => of(new parentCategoryActions.LoadParentCategoriesFlatListFailed(interpretateApiError(error))))
        )),
      ))
    );

    resetCategories$ = createEffect(
      () => this.action$.pipe(
        ofType(parentCategoryActions.ParentCategoryActionTypes.SaveParentCategoriesSuccessful),
        mergeMap(() =>
          of(new categoryEditorActions.ResetStore())
        )
      )
    );
}
