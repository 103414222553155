import { Action } from '@ngrx/store';
import { IPaymentsReportDocument } from 'src/app/models/IPaymentsReportDocument';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { IPaymentsReportFormParameters, PaymentsReportFormParameters } from 'src/app/models/IPaymentsReportFormParameters';

export enum PaymentsReportActionTypes {
    LoadPaymentsReport = '[Payments Report] Load Payments Report',
    LoadPaymentsReportSuccessful = '[Payments Report] Load Payments Report - Successful',
    LoadPaymentsReportFailed = '[Payments Report] Load Payments Report - Failed',
    ResetStore = '[Payments Report] Reset Store',
    SaveSearchParameters = '[Payments Report] Save Search Parameters',
    DownloadEOD = '[Payments Reports] Download Payments Reports',
    DownloadEODSuccessful = '[Payments Reports] Download Payments Reports - Successful',
    DownloadEODFailed = '[Payments Reports] Download Payments Reports - Failed',
    CleanUpFileBuffer = '[Payments Reports] Clean up file buffer'
}

export class LoadPaymentsReport implements Action {
    readonly type = PaymentsReportActionTypes.LoadPaymentsReport;

    constructor(public payload: PaymentsReportFormParameters) { }
}

export class LoadPaymentsReportSuccessful implements Action {
    readonly type = PaymentsReportActionTypes.LoadPaymentsReportSuccessful;

    constructor(public payload: IPaymentsReportDocument) { }
}

export class LoadPaymentsReportFailed implements Action {
    readonly type = PaymentsReportActionTypes.LoadPaymentsReportFailed;

    constructor(public payload: IGenericError) { }
}

export class ResetStore implements Action {
    readonly type = PaymentsReportActionTypes.ResetStore;

    constructor() { }
}

export class SaveSearchParameters implements Action {
    readonly type = PaymentsReportActionTypes.SaveSearchParameters;

    constructor(public payload: PaymentsReportFormParameters) { }
}

export class DownloadEOD implements Action {
  readonly type = PaymentsReportActionTypes.DownloadEOD;

  constructor(public payload: PaymentsReportFormParameters) { }
}

export class DownloadEODSuccessful implements Action {
  readonly type = PaymentsReportActionTypes.DownloadEODSuccessful;

  constructor(public payload: ArrayBuffer) { }
}

export class DownloadEODFailed implements Action {
  readonly type = PaymentsReportActionTypes.DownloadEODFailed;

  constructor(public payload: IGenericError) { }
}

export class CleanUpFileBuffer implements Action {
  readonly type = PaymentsReportActionTypes.CleanUpFileBuffer;

  constructor() { }
}

export type PaymentsReportActions = LoadPaymentsReport
    | LoadPaymentsReportSuccessful
    | LoadPaymentsReportFailed
    | ResetStore
    | SaveSearchParameters
    | DownloadEOD
    | DownloadEODSuccessful
    | DownloadEODFailed
    | CleanUpFileBuffer;
