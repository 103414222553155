import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { AddonsService } from 'src/app/services/addons.service';
import { EstateService } from 'src/app/services/estate.service';

@Injectable()
export class AddonEditorEffects {
  constructor() {}

}
