import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { ITablePlanArea } from 'src/app/models/ITablePlanArea';
import { EstateService } from 'src/app/services/estate.service';
import { SalesAreasService } from 'src/app/services/sales-areas.service';
import { TablePlanService } from 'src/app/services/table-plan.service';

import * as tablePlanActions from './tableplan.actions';

@Injectable()
export class TablePlanEffects {
  constructor(private action$: Actions,
    private tablePlanService: TablePlanService,
    private estateService: EstateService,
    private salesAreasService: SalesAreasService) {

  }

  loadTablePlan$ = createEffect(
    () => this.action$.pipe(
    ofType(tablePlanActions.TablePlanActionTypes.LoadTablePlan),
    mergeMap((action: tablePlanActions.LoadTablePlan) =>
      this.tablePlanService.GetTablePlan(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((tablePlanAreas: ITablePlanArea[]) => (new tablePlanActions.LoadTablePlanSuccessful(tablePlanAreas))),
        catchError((error: HttpErrorResponse) => of(new tablePlanActions.LoadTablePlanFailed(interpretateApiError(error))))
      )
    ))
  );

  saveTablePlan$ = createEffect(
    () => this.action$.pipe(
    ofType(tablePlanActions.TablePlanActionTypes.SaveTablePlan),
    mergeMap((action: tablePlanActions.SaveTablePlan) =>
      this.tablePlanService.SaveTablePlan(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.payload).pipe(
        map((tablePlanAreas: ITablePlanArea[]) => new tablePlanActions.SaveTablePlanSuccessful(tablePlanAreas)),
        catchError((error: HttpErrorResponse) => of(new tablePlanActions.SaveTablePlanFailed(interpretateApiError(error, HttpVerbType.POST), action.payload)))
      )
    ))
  );
}
