import { TransactionReportField } from "src/app/enums/Report/ReportField/TransactionReportField";
import { IReportSortDefinition } from "../Components/IReportSortDefinition";

const localStorageFormParametersProperty = 'TransactionReportParameters';

export class TransactionReportParameters {
  reportGroup: TransactionReportField;
  posId: number;
  clerkId: number;
  tableNumber: number;
  transactionDateFromDay: Date;
  transactionDateFromHour: string;
  transactionDateFromMinute: string;
  transactionDateToDay: Date;
  transactionDateToHour: string;
  transactionDateToMinute: string;
  sort: IReportSortDefinition[];

  constructor() {
      this.getParameters();
  }

  public storeParameters() {
      localStorage.setItem(localStorageFormParametersProperty, JSON.stringify(this));
  }

  public clearParameters() {
      localStorage.removeItem(localStorageFormParametersProperty);
  }

  private getParameters() {
    const storedParameters: TransactionReportParameters = JSON.parse(localStorage.getItem(localStorageFormParametersProperty));

    if (storedParameters != null) {
      // we found the parameters into the local storage
      this.reportGroup = <TransactionReportField>storedParameters.reportGroup;
      this.posId = storedParameters.posId;
      this.clerkId = storedParameters.clerkId;
      this.tableNumber = storedParameters.tableNumber;
      this.transactionDateFromDay = new Date(storedParameters.transactionDateFromDay);
      this.transactionDateFromHour = storedParameters.transactionDateFromHour;
      this.transactionDateFromMinute = storedParameters.transactionDateFromMinute;
      this.transactionDateToDay = new Date(storedParameters.transactionDateToDay);
      this.transactionDateToHour = storedParameters.transactionDateToHour;
      this.transactionDateToMinute = storedParameters.transactionDateToMinute;

    } else {
      // we need to generate default value
      this.reportGroup = TransactionReportField.None;
      this.posId = -1;
      this.clerkId = -1;
      this.tableNumber = null;
      this.transactionDateFromDay = new Date(new Date().setDate(new Date().getDate() - 7));
      this.transactionDateFromHour = '00';
      this.transactionDateFromMinute = '00';
      this.transactionDateToDay = new Date();
      this.transactionDateToHour = '23';
      this.transactionDateToMinute = '59';

      this.storeParameters();
    }
  }
}
