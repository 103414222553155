import { Action } from '@ngrx/store';

export enum CategoryEditorActionTypes {
    StoreFilterByName = '[Category Editor] Store Filter by Name',
    ResetStore = '[Category Editor] Reset Store',
}

export class StoreFilterByName implements Action {
  readonly type = CategoryEditorActionTypes.StoreFilterByName;

  constructor(public payload: string) { }
}

export class ResetStore implements Action {
    readonly type = CategoryEditorActionTypes.ResetStore;

    constructor() { }
}

export type CategoryEditorActions = StoreFilterByName
    | ResetStore;
