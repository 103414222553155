export enum OperatorType
{
    Equal,
    NotEqual,
    GreaterThan,
    GreaterThanOrEqual,
    LessThan,
    LessThanOrEqual,
    MatchedInArray
}

export const OperatorTypeLabel = new Map<number, string>([
  [OperatorType.Equal, '='],
  [OperatorType.NotEqual, '≠'],
  [OperatorType.GreaterThan, '>'],
  [OperatorType.GreaterThanOrEqual, '≥'],
  [OperatorType.LessThan, '<'],
  [OperatorType.LessThanOrEqual, '≤'],
  [OperatorType.MatchedInArray, 'in']
]);
