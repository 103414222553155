import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginService } from './login.service';
import { LoginCanActiveService } from './login-can-active.service';
import { EstateService } from './estate.service';
import { ReportService } from './report.service';
import { DashboardWidgetsService } from './dashboard-widgets.service';
import { GuidService } from './guid.service';
import { SetupService } from './setup.service';
import { UserService } from './user.service';
import { PricelevelService } from './pricelevel.service';
import { CategoriesService } from './categories.service';
import { CoursesService } from './courses.service';
import { MenuPlanService } from './menu-plan.service';
import { ParentCategoriesService } from './parent-categories.service';
import { SalesAreasService } from './sales-areas.service';
import { SchedulePricelevelsService } from './schedule-pricelevels.service';
import { TablePlanService } from './table-plan.service';
import { CorporationsService } from './corporations.service';
import { DiscountReportService } from './discount-report.service';
import { DiscountsService } from './discounts.service';
import { LogonService } from './logon.service';
import { MaterialColorPaletteService } from './material-color-palette.service';
import { TablePlanValidatorService } from './table-plan-validator.service';
import { EditorPendingChangesService } from './editor-pending-changes.service';
import { TaxBandsService } from './tax-bands.service';
import { RealmAppService } from './realm-app.service';
import { DashboardLiveService } from './dashboard-live.service';
import { VoidReportService } from './Reports/Void/void-report.service';
import { TransactionReportService } from './transaction-report.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    /* List of All Data Services */
    LoginService,
    LoginCanActiveService,
    EstateService,
    ReportService,
    DashboardWidgetsService,
    GuidService,
    UserService,
    SetupService,
    PricelevelService,
    CategoriesService,
    CoursesService,
    MenuPlanService,
    LogonService,
    ParentCategoriesService,
    SalesAreasService,
    SchedulePricelevelsService,
    TablePlanService,
    CorporationsService,
    DiscountReportService,
    DiscountsService,
    MaterialColorPaletteService,
    TablePlanValidatorService,
    EditorPendingChangesService,
    TaxBandsService,
    RealmAppService,
    DashboardLiveService,
    VoidReportService,
    TransactionReportService
  ],
})
export class ServicesModule { }
