import { IBrand } from './IBrand';

export interface IEstateView {
    corporationId: string;
    corporationName: string;
    brands: IBrand[];
}

export class EstateView implements IEstateView {
    corporationId: string;
    corporationName: string;
    brands: IBrand[];

    constructor() {
        this.corporationId = '';
        this.corporationName = '';
        this.brands = [];
    }
}
