import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import { AdminActionTypes, AdminActions } from './admin.actions';
import { IEposManagerVersion } from 'src/app/models/IEposManagerVersion';

export interface State extends fromRoot.State {
    shared: AdminState;
}

export interface AdminState extends IGardiffStoreSliceBase {
    eposManagerVersion: string;
    eposManagerVersionDetails: IEposManagerVersion;
    enableTax: boolean;
    enableLiveDashboard: boolean;
    enableVoidReport: boolean;
    enableTransactionReport: boolean;
    enableDiscountReport: boolean;
    darkMode: boolean;
}

const initialState: AdminState = {
    eposManagerVersion: '',
    eposManagerVersionDetails: {
        major: 0,
        minor: 0,
        patch: 0
    },
    enableTax: true,
    enableLiveDashboard: false,
    enableVoidReport: false,
    enableTransactionReport: false,
    enableDiscountReport: false,
    darkMode: isDarkMode(),
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

export function isDarkMode(): boolean {
  const storedValue = localStorage.getItem('darkMode');
  if (storedValue == null) {
    return false;
  } else {
    return JSON.parse(storedValue);
  }
}

// create the selectors
const getSharedFeatureState = createFeatureSelector<AdminState>('admin');
export const getSharedState = createSelector(
    getSharedFeatureState,
    state => state
);

export const getEposManagerVersion = createSelector(
    getSharedFeatureState,
    state => state.eposManagerVersion
);

export const getEposManagerVersionDetails = createSelector(
    getSharedFeatureState,
    state => state.eposManagerVersionDetails
);

export const getEnableTax = createSelector(
    getSharedFeatureState,
    state => state.enableTax
);

export const getEnableLiveDashboard = createSelector(
    getSharedFeatureState,
    state => state.enableLiveDashboard
);

export const getEnableVoidReport = createSelector(
  getSharedFeatureState,
  state => state.enableVoidReport
);

export const getEnableTransactionReport = createSelector(
  getSharedFeatureState,
  state => state.enableTransactionReport
);

export const getEnableDiscountReport = createSelector(
  getSharedFeatureState,
  state => state.enableDiscountReport
);

export const getDarkMode = createSelector(
  getSharedFeatureState,
  state => state.darkMode
)

export function reducer(state: AdminState = initialState, action: AdminActions): AdminState {
    switch (action.type) {
        case AdminActionTypes.LoadEposManagerVersion:
            return {
                ...state,
                eposManagerVersion: null,
                apiError: null,
                status: null
            };

        case AdminActionTypes.LoadEposManagerVersionSuccessful:
            return {
                ...state,
                eposManagerVersion: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case AdminActionTypes.LoadEposManagerVersionFailed:
            return {
                ...state,
                eposManagerVersion: '',
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case AdminActionTypes.SplitEposManagerVersion:
            return {
                ...state,
                eposManagerVersionDetails: null,
            };

        case AdminActionTypes.SplitEposManagerVersionSuccessful:
            return {
                ...state,
                eposManagerVersionDetails: action.payload,
            };

        case AdminActionTypes.SplitEposManagerVersionFailed:
            return {
                ...state,
                eposManagerVersionDetails: {
                    major: 0,
                    minor: 0,
                    patch: 0
                }
            };

        case AdminActionTypes.ActivateTaxFeature:
            return {
                ...state,
                enableTax: action.payload,
            };

        case AdminActionTypes.ActivateLiveDashboardFeature:
            return {
                ...state,
                enableLiveDashboard: action.payload,
            };

        case AdminActionTypes.ActivateVoidReportFeature:
            return {
                ...state,
                enableVoidReport: action.payload,
            };

        case AdminActionTypes.ActivateTransactionReportFeature:
            return {
                ...state,
                enableTransactionReport: action.payload,
            };

        case AdminActionTypes.ActivateDiscountReportFeature:
            return {
                ...state,
                enableDiscountReport: action.payload,
            };

        case AdminActionTypes.ActivateDarkMode:
            return {
                ...state,
                darkMode: action.payload,
            };

        case AdminActionTypes.ResetStore:
            return {
                ...state,
                eposManagerVersion: initialState.eposManagerVersion,
                eposManagerVersionDetails: initialState.eposManagerVersionDetails,
                enableTax: initialState.enableTax,
                enableLiveDashboard: initialState.enableLiveDashboard,
                enableVoidReport: initialState.enableVoidReport,
                enableTransactionReport: initialState.enableTransactionReport,
                enableDiscountReport: initialState.enableDiscountReport,
                darkMode: isDarkMode(),
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
