import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeType } from '../enums/ChangeType';
import { ICorporationDTO } from '../models/DTOs/ICorporationDTO';
import { ICorporation } from '../models/ICorporation';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class CorporationsService {
  private CorporationsEndPoint = environment.APIEndpoint +  'frontend/gardiff/GetCorporations/';

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetCorporations(): Observable<ICorporation[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {

      return this.http
        .get(this.CorporationsEndPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<ICorporationDTO[]>) => {

            if (data.ok) {
              return this.applyChanges(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  private applyChanges(data: ICorporationDTO[]): ICorporation[] {
    let list: ICorporation[] = [];

    // convert list
    data.forEach(item => {
      list.push({
        corporationID: item.corporationID,
        corporationName: item.corporationName,
        addressLine1: item.addressLine1,
        addressLine2: item.addressLine2,
        postCode: item.postCode,
        email: item.email,
        phoneNumber: item.phoneNumber,
        isSelected: false,
        changeState: ChangeType.None
      });
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: ICorporation[]): ICorporation[] {
    return list.sort((Item1: ICorporation, Item2: ICorporation) => {
      if (Item1.corporationName.toLowerCase() > Item2.corporationName.toLowerCase()) {
        return 1;
      }

      if (Item1.corporationName.toLowerCase() < Item2.corporationName.toLowerCase()) {
        return -1;
      }
    });
  }

}
