import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IEnvelopeInDTO } from '../models/DTOs/IEnvelopeInDTO';
import { IFunctionKeyDTO } from '../models/DTOs/IFunctionKeyDTO';
import { IFunctionKey } from '../models/IFunctionKey';
import { EstateService } from './estate.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class FunctionKeysService {
  private FunctionKeysEndPoint = environment.APIEndpoint + 'frontend/corporation/{corporationId}/site/{siteId}/FunctionKeys';

  constructor(private loginService: LoginService, private http: HttpClient, private estateService: EstateService) { }

  GetFunctionKeys(): Observable<IFunctionKey[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.FunctionKeysEndPoint.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId);

      return this.http
        .get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IFunctionKeyDTO[]>) => {

            if (data.ok) {
              return this.formatData(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  private formatData(dataList: IFunctionKeyDTO[]): IFunctionKey[] {
    let list: IFunctionKey[] = [];

    // convert list
    dataList.forEach(item => {
      list.push({
        id: item.id,
        functionKey_GUID: item.functionKey_GUID,
        functionKey_ID: item.functionKey_ID,
        name: item.name,
        description: item.description,
        isMultiKey: item.isMultiKey,
        category: item.category,
        actionNumber: item.actionNumber,
        isSelected: false
      });
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: IFunctionKey[]): IFunctionKey[] {
    return list.sort((Item1: IFunctionKey, Item2: IFunctionKey) => {
      if (Item1.actionNumber > Item2.actionNumber) {
        return 1;
      }

      if (Item1.actionNumber < Item2.actionNumber) {
        return -1;
      }
    });
  }
}
