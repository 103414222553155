import { Injectable } from '@angular/core';
import { ITablePlanArea } from '../models/ITablePlanArea';
import { ITablePlanValidatorItem } from '../models/ITablePlanValidatorItem';

@Injectable({
  providedIn: 'root'
})
export class TablePlanValidatorService {

  constructor() { }

  validate(tablePlan: ITablePlanArea[]): ITablePlanValidatorItem[] {
    let tables: ITablePlanValidatorItem[] = []

    // make a table of all dining tables
    tablePlan.forEach(area => {
      area.diningTables.forEach(table => {

        if (table.tableNumber > 0) {
          tables = this.addTable(tables, table.tableNumber, area.name);
        }
        
      });
    });

    // verify areas without tables
    tablePlan.forEach(area => {
      let tablesFiltered = area.diningTables.filter(t => t.tableNumber != -1)
      if (tablesFiltered.length == 0) {
        // no tables, no walls
        tables = this.addTable(tables, 0, area.name);
      }
    });

    return tables.filter(t => t.count > 1 || t.tableNumber == 0);
  }

  private addTable(tables: ITablePlanValidatorItem[], number: number, area: string): ITablePlanValidatorItem[]{
    let checkTable = null;

    if (number > 0) {
      checkTable = tables.find(t => t.tableNumber === number);
    }

    if (checkTable != null) {
      // found the table
      // add only the area

      // increase the counter
      checkTable.count += 1;

      let checkArea = checkTable.areas.find(a => a == area);
      if (checkArea == null) {
        // area not found
        // add the new area
        checkTable.areas.push(area);
      }

    } else {
      // table not found
      tables.push({
        tableNumber: number,
        count: 1,
        areas: [ area ]
      });
    }

    return tables;
  }
}
