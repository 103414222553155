import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { IKitchenVideo } from 'src/app/models/IKitchenVideo';

export enum KitchenVideosActionTypes {
    LoadKitchenVideos = '[Kitchen Videos] Load KitchenVideos',
    LoadKitchenVideosSuccessful = '[Kitchen Videos] Load KitchenVideos - Successful',
    LoadKitchenVideosFailed = '[Kitchen Videos] Load KitchenVideos - Failed',
    SaveKitchenVideos = '[Kitchen Videos] Save KitchenVideos',
    SaveKitchenVideosSuccessful = '[Kitchen Videos] Save KitchenVideos - Successful',
    SaveKitchenVideosFailed = '[Kitchen Videos] Save KitchenVideos - Failed',
    ResetStore = '[Kitchen Videos] Reset Store',
}

export class LoadKitchenVideos implements Action {
    readonly type = KitchenVideosActionTypes.LoadKitchenVideos;

    constructor() { }
}

export class LoadKitchenVideosSuccessful implements Action {
    readonly type = KitchenVideosActionTypes.LoadKitchenVideosSuccessful;

    constructor(public payload: IKitchenVideo[]) { }
}

export class LoadKitchenVideosFailed implements Action {
    readonly type = KitchenVideosActionTypes.LoadKitchenVideosFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveKitchenVideos implements Action {
    readonly type = KitchenVideosActionTypes.SaveKitchenVideos;

    constructor(public oldData: IKitchenVideo[], public newData: IKitchenVideo[]) { }
}

export class SaveKitchenVideosSuccessful implements Action {
    readonly type = KitchenVideosActionTypes.SaveKitchenVideosSuccessful;

    constructor(public payload: IKitchenVideo[]) { }
}

export class SaveKitchenVideosFailed implements Action {
    readonly type = KitchenVideosActionTypes.SaveKitchenVideosFailed;

    constructor(public error: IGenericError, public oldData: IKitchenVideo[]) { }
}

export class ResetStore implements Action {
    readonly type = KitchenVideosActionTypes.ResetStore;

    constructor() { }
}

export type KitchenVideosActions = LoadKitchenVideos
    | LoadKitchenVideosSuccessful
    | LoadKitchenVideosFailed
    | SaveKitchenVideos
    | SaveKitchenVideosSuccessful
    | SaveKitchenVideosFailed
    | ResetStore;
