import * as fromRoot from '../../../NGRX/app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductEditorActionTypes, ProductEditorActions } from './productEditor.actions';
import { ICategory, Category } from 'src/app/models/ICategory';
import { IProduct, Product } from 'src/app/models/IProduct';

export interface State extends fromRoot.State {
    priceEditor: ProductEditorState;
}

export interface ProductEditorState {
    selectedCategory: ICategory;
    selectedProduct: IProduct;
}

const initialState: ProductEditorState = {
    selectedCategory: new Category(),
    selectedProduct: new Product(),
};

// create the selectors
const getProductEditorFeatureState = createFeatureSelector<ProductEditorState>('productEditor');
export const getProductEditorState = createSelector(
    getProductEditorFeatureState,
    state => state
);

export const getSelectedCategory = createSelector(
    getProductEditorFeatureState,
    state => state.selectedCategory
);

export const getSelectedProduct = createSelector(
    getProductEditorFeatureState,
    state => state.selectedProduct
);

export function reducer(state: ProductEditorState = initialState, action: ProductEditorActions): ProductEditorState {
    switch (action.type) {
        case ProductEditorActionTypes.SelectCategory:
            return {
                ...state,
                selectedCategory: action.payload,
            };

        case ProductEditorActionTypes.SelectProduct:
            return {
                ...state,
                selectedProduct: action.payload,
            };

        case ProductEditorActionTypes.ResetSlice:
            return {
                ...state,
                selectedCategory: initialState.selectedCategory,
                selectedProduct: initialState.selectedProduct,
            };

        default:
            return state;
    }
}
