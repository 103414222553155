import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import * as pricelevelsActions from './pricelevels.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { IPriceLevel } from 'src/app/models/IPriceLevel';
import { PricelevelService } from 'src/app/services/pricelevel.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';

@Injectable()
export class PricelevelsEffects {
    constructor(private action$: Actions,
      private estateService: EstateService,
      private pricelevelService: PricelevelService) {

    }

    loadPricelevels$ = createEffect(
      () => this.action$.pipe(
      ofType(pricelevelsActions.PriceLevelsActionTypes.LoadPriceLevels),
      mergeMap((action: pricelevelsActions.LoadPriceLevels) =>
        this.pricelevelService.GetPriceLevels(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
          map((priceLevels: IPriceLevel[]) => new pricelevelsActions.LoadPriceLevelsSuccessful(priceLevels)),
          catchError((error: HttpErrorResponse) => of(new pricelevelsActions.LoadPriceLevelsFailed(interpretateApiError(error))))
        )
      ))
    );

    savePricelevels$ = createEffect(
      () => this.action$.pipe(
        ofType(pricelevelsActions.PriceLevelsActionTypes.SavePriceLevels),
        mergeMap((action: pricelevelsActions.SavePriceLevels) =>
          this.pricelevelService.SavePriceLevels(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.oldData, action.newData).pipe(
            map((priceLevels: IPriceLevel[]) => new pricelevelsActions.SavePriceLevelsSuccessful(priceLevels)),
            catchError((error: HttpErrorResponse) => of(new pricelevelsActions.SavePriceLevelsFailed(interpretateApiError(error, HttpVerbType.POST), action.newData)))
          )
      ))
    );
}
