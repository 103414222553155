import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import * as printersActions from './printers.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SetupService } from 'src/app/services/setup.service';
import { PosSetupService } from 'src/app/services/pos-setup.service';
import { PrintersService } from 'src/app/services/printers.service';
import { IPrinter } from 'src/app/models/IPrinter';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';

@Injectable()
export class PrintersEffects {
  constructor(
    private action$: Actions,
    private estateService: EstateService,
    private printerService: PrintersService
  ) {}

  loadPrinters$ = createEffect(
    () => this.action$.pipe(
    ofType(printersActions.PrintersActionTypes.LoadPrinters),
    mergeMap((action: printersActions.LoadPrinters) =>
      this.printerService.GetPrinters(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((printers: IPrinter[]) => new printersActions.LoadPrintersSuccessful(printers)),
        catchError((error: HttpErrorResponse) => of(new printersActions.LoadPrintersFailed(interpretateApiError(error))))
      )
    ))
  );

  savePrinters$ = createEffect(
    () => this.action$.pipe(
    ofType(printersActions.PrintersActionTypes.SavePrinters),
    mergeMap((action: printersActions.SavePrinters) =>
      this.printerService
        .SavePrinters(
          this.estateService.CorporationID, 
          this.estateService.SelectedSite.siteId,
          action.oldData,
          action.newData
        ).pipe(
          map((printers: IPrinter[]) => new printersActions.SavePrintersSuccessful(printers)),
          catchError((error: HttpErrorResponse) => of(new printersActions.SavePrintersFailed(interpretateApiError(error, HttpVerbType.POST), action.newData)))
        )
    ))
  );
}
