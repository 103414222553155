import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, Subscriber } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getTextColorContrast } from '../feature-modules/shared/colorUtils';
import { IEnvelopeOutDTO } from '../models/DTOs/IEnvelopeOutDTO';
import { LoginService } from './login.service';
import { IEnvelopeInDTO } from '../models/DTOs/IEnvelopeInDTO';
import { ChangeType } from '../enums/ChangeType';
import { ChangeOperationType } from '../enums/ChangeOperationType';
import { IMeasure } from '../models/IMeasure';
import { checkHexColor } from '../helpers/ColorHelper';
import { EditDataList, IEditDataList } from '../models/DTOs/IEditDataList';
import { ICategoryDTO } from '../models/DTOs/ICategoryDTO';
import { ICategory } from '../models/ICategory';
import { IParentCategorySlimDTO } from "../models/DTOs/IParentCategorySlimDTO";
import { IParentCategorySlim, ParentCategorySlim } from "../models/IParentCategorySlim";
import { ITaxBand } from '../models/ITaxBand';
import { ITaxBandDTO } from '../models/DTOs/ITaxBandDTO';
import { ICategoryWithoutTaxDTO } from '../models/DTOs/ICategoryWithoutTaxDTO';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private CategoriesEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/Categories';

  private categories: ICategory[] = [];

  constructor(private loginService: LoginService, private http: HttpClient) {

  }

  GetCategories(CorporationID: string, SiteID: string): Observable<ICategory[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.CategoriesEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http.get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<ICategoryDTO[]>) => {

          if (data.ok) {
            // store a copy for backup
            this.categories = this.formatData(data.body);
            return JSON.parse(JSON.stringify(this.categories));
          }

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private formatData(dataList: ICategoryDTO[]): ICategory[] {
    let list: ICategory[] = [];

    // convert CategoryDTO items => Category
    dataList.forEach(category => {
      let parentCategorySlim: IParentCategorySlim = null
      let taxBand: ITaxBand = null

      if (category.parentCategory != null){
        parentCategorySlim = {
          id: category.parentCategory.id,
          parentCategoryGuid: category.parentCategory.parentCategoryGuid,
          description: category.parentCategory.description
        }
      }

      if (category.taxBand != null) {
        taxBand = {
          id: category.taxBand.id,
          name: category.taxBand.name,
          percentage: category.taxBand.percentage,
          isSelected: false,
          changeState: ChangeType.None,
          hasSavingError: false,
          savingErrorDescription: ''
        }
      }

      const newCategory: ICategory = {
        id: category.id,
        categoryGuid: category.categoryGuid,
        parentCategory: parentCategorySlim,
        taxBand: taxBand,
        categoryName: category.categoryName,
        colorCode: checkHexColor(category.colorCode),
        colorCodeText: getTextColorContrast(category.colorCode),
        isActive: category.isActive,
        measurements: [],
        isSelected: false,
        changeState: ChangeType.None
      };

      category.measurements.forEach(measurement => {
        newCategory.measurements.push({
          id: measurement.id,
          measureGuid: measurement.measureGuid,
          description: measurement.description,
          type: measurement.type,
          isActive: measurement.isActive,
          volume: measurement.volume,
          system: measurement.system,
          buttonDescription: measurement.buttonDescription,
          isSelected: false,
          changeState: ChangeType.None
        });
      });

      list.push(newCategory);
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: ICategory[]): ICategory[] {
    list = list.sort((Item1: ICategory, Item2: ICategory) => {
      if (Item1.categoryName.toLowerCase() > Item2.categoryName.toLowerCase()) {
        return 1;
      }

      if (Item1.categoryName.toLowerCase() < Item2.categoryName.toLowerCase()) {
        return -1;
      }
    });

    return list;
  }

  SaveCategories(CorporationID: string, SiteID: string, newCategories: ICategory[]): Observable<ICategory[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      // replacing siteId as usual + switch on v2 for taxes
      const endPoint: string = this.CategoriesEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID).replace('/v1/', '/v2/');

      const data: IEditDataList<ICategoryDTO> = this.SplitItems(newCategories);

      return this.http.post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<ICategoryDTO[]>) => {

          if (data.ok) {
            // store a copy for backup
            this.categories = this.formatData(data.body);
            return JSON.parse(JSON.stringify(this.categories));
          }

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private DeflateItems(list: ICategory[]): ICategoryDTO[] {
    const newData: ICategoryDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        let parentCategorySlim: IParentCategorySlimDTO = null
        let taxBand: ITaxBandDTO = null

        if (item.parentCategory != null){
          parentCategorySlim = {
            id: item.parentCategory.id,
            parentCategoryGuid: item.parentCategory.parentCategoryGuid,
            description: item.parentCategory.description
          }
        }

        if (item.taxBand != null) {
          taxBand = {
            id: item.taxBand.id,
            name: item.taxBand.name,
            percentage: item.taxBand.percentage,
          }
        }

        const newCategory: ICategoryDTO = {
          id: item.id,
          categoryGuid: item.categoryGuid,
          parentCategory: parentCategorySlim,
          taxBand: taxBand,
          categoryName: item.categoryName,
          colorCode: item.colorCode,
          isActive: item.isActive,
          measurements: []
        };

        item.measurements.forEach(measurement => {
          newCategory.measurements.push({
            id: measurement.id,
            measureGuid: measurement.measureGuid,
            description: measurement.description,
            type: measurement.type,
            isActive: measurement.isActive,
            volume: measurement.volume,
            system: measurement.system,
            buttonDescription: measurement.buttonDescription
          });
        });

        newData.push(newCategory);

        });
      }

    return newData;
  }

  private SplitItems(data: ICategory[]): IEditDataList<ICategoryDTO> {
    let newData: IEditDataList<ICategoryDTO> = new EditDataList<ICategoryDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }

  GetBackup(): Observable<ICategory[]> {
    const backup: ICategory[] = JSON.parse(JSON.stringify(this.categories));
    return new Observable<ICategory[]>((subscriber: Subscriber<ICategory[]>) => subscriber.next(backup));
  }

  SaveCategoriesWithoutTax(CorporationID: string, SiteID: string, newCategories: ICategory[]): Observable<ICategory[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      // replacing siteId as usual + keeping v1 for old version of Categories without taxes
      const endPoint: string = this.CategoriesEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IEditDataList<ICategoryWithoutTaxDTO> = this.SplitItemsWithoutTax(newCategories);

      return this.http.post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<ICategoryDTO[]>) => {

          if (data.ok) {
            // store a copy for backup
            this.categories = this.formatData(data.body);
            return JSON.parse(JSON.stringify(this.categories));
          }

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private SplitItemsWithoutTax(data: ICategory[]): IEditDataList<ICategoryWithoutTaxDTO> {
    let newData: IEditDataList<ICategoryWithoutTaxDTO> = new EditDataList<ICategoryWithoutTaxDTO>();

    newData.newItems = this.DeflateItemsWithoutTax(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItemsWithoutTax(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItemsWithoutTax(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }

  private DeflateItemsWithoutTax(list: ICategory[]): ICategoryWithoutTaxDTO[] {
    const newData: ICategoryWithoutTaxDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        let parentCategorySlim: IParentCategorySlimDTO = null

        if (item.parentCategory != null){
          parentCategorySlim = {
            id: item.parentCategory.id,
            parentCategoryGuid: item.parentCategory.parentCategoryGuid,
            description: item.parentCategory.description
          }
        }

        const newCategory: ICategoryWithoutTaxDTO = {
          id: item.id,
          categoryGuid: item.categoryGuid,
          parentCategory: parentCategorySlim,
          categoryName: item.categoryName,
          colorCode: item.colorCode,
          isActive: item.isActive,
          measurements: []
        };

        item.measurements.forEach(measurement => {
          newCategory.measurements.push({
            id: measurement.id,
            measureGuid: measurement.measureGuid,
            description: measurement.description,
            type: measurement.type,
            isActive: measurement.isActive,
            volume: measurement.volume,
            system: measurement.system,
            buttonDescription: measurement.buttonDescription
          });
        });

        newData.push(newCategory);

        });
      }

    return newData;
  }
}
