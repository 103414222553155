import { ISalesReportFilter } from './ISalesReportFilter';
import { ISalesReportItem } from './ISalesReportItem';

export interface ISalesReportDocument {
  filters: ISalesReportFilter[];
  items: ISalesReportItem[];
  reportVersion: number;
  salesDateFrom: Date;
  salesDateTo: Date;
  groupBy: string;
  subGroupBy: string;
  subGroupGrandTotals: ISalesReportItem[];
  totalGrossAmount: number;
  totalNetAmount: number;
  totalTaxAmount: number;
  totalQuantity: number;
  showDetails: boolean;
  includeTax: boolean;
}

export class SalesReportDocument implements ISalesReportDocument {
  filters: ISalesReportFilter[];
  items: ISalesReportItem[];
  reportVersion: number;
  salesDateFrom: Date;
  salesDateTo: Date;
  groupBy: string;
  subGroupBy: string;
  subGroupGrandTotals: ISalesReportItem[];
  totalGrossAmount: number;
  totalNetAmount: number;
  totalTaxAmount: number;
  totalQuantity: number;
  showDetails: boolean;
  includeTax: boolean;

  constructor() {
    this.filters = [];
    this.items = [];
    this.reportVersion = 0;
    this.salesDateFrom = new Date();
    this.salesDateTo = new Date();
    this.groupBy = '';
    this.subGroupBy = '';
    this.subGroupGrandTotals = [];
    this.totalGrossAmount = 0;
    this.totalNetAmount = 0;
    this.totalTaxAmount = 0;
    this.totalQuantity = 0;
    this.showDetails = false;
    this.includeTax = true;
  }
}
