import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PrintersActions, PrintersActionTypes } from './printers.actions';
import { IPrinter } from 'src/app/models/IPrinter';
import { IPosSetup } from 'src/app/models/IPosSetup';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    printers: PrintersState;
}

export interface PrintersState extends IGardiffStoreSliceBase {
    printers: IPrinter[];
}

const initialState: PrintersState = {
    printers: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getPrintersFeatureState = createFeatureSelector<PrintersState>('printers');
export const getPrintersState = createSelector(
    getPrintersFeatureState,
    state => state
);

export const getPrinters = createSelector(
    getPrintersFeatureState,
    state => state.printers
);

export const getApiError = createSelector(
  getPrintersFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getPrintersFeatureState,
  state => state.status
);

export function reducer(state: PrintersState = initialState, action: PrintersActions): PrintersState {
    switch (action.type) {
        case PrintersActionTypes.LoadPrinters:
            return {
                ...state,
                printers: null,
                apiError: null,
                status: null
            };

        case PrintersActionTypes.LoadPrintersSuccessful:
            return {
                ...state,
                printers: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case PrintersActionTypes.LoadPrintersFailed:
            return {
                ...state,
                printers: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case PrintersActionTypes.SavePrinters:
            return {
                ...state,
                printers: null,
                apiError: null,
                status: null
            };

        case PrintersActionTypes.SavePrintersSuccessful:
            return {
                ...state,
                printers: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case PrintersActionTypes.SavePrintersFailed:
            return {
                ...state,
                printers: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case PrintersActionTypes.ResetStore:
            return {
                ...state,
                printers: initialState.printers,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
