<!-- Loading stuff -->
<div class="loading-overlay" *ngIf="DownloadingModule">
  <mat-spinner diameter="200" color="accent"></mat-spinner>

  <div class="alert alert-primary p-2 m-2" role="alert">
    Downloading module ...
  </div>
</div>

<router-outlet></router-outlet>
