import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SalesAreaActions, SalesAreaActionTypes } from './salesarea.actions';
import { ISalesArea } from 'src/app/models/ISalesArea';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    salesArea: SalesAreaState;
}

export interface SalesAreaState extends IGardiffStoreSliceBase {
    salesArea: ISalesArea[];
}

const initialState: SalesAreaState = {
    salesArea: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getSalesAreaFeatureState = createFeatureSelector<SalesAreaState>('salesArea');
export const getSalesAreaState = createSelector(
    getSalesAreaFeatureState,
    state => state
);

export const getSalesArea = createSelector(
    getSalesAreaFeatureState,
    state => state.salesArea
);

export const getApiError = createSelector(
  getSalesAreaFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getSalesAreaFeatureState,
  state => state.status
);

export function reducer(state: SalesAreaState = initialState, action: SalesAreaActions): SalesAreaState {
    switch (action.type) {
        case SalesAreaActionTypes.LoadSalesArea:
            return {
                ...state,
                salesArea: null,
                apiError: null,
                status: null
            };

        case SalesAreaActionTypes.LoadSalesAreaSuccessful:
            return {
                ...state,
                salesArea: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case SalesAreaActionTypes.LoadSalesAreaFailed:
            return {
                ...state,
                salesArea: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case SalesAreaActionTypes.SaveSalesArea:
            return {
                ...state,
                salesArea: null,
                apiError: null,
                status: null
            };

        case SalesAreaActionTypes.SaveSalesAreaSuccessful:
            return {
                ...state,
                salesArea: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case SalesAreaActionTypes.SaveSalesAreaFailed:
            return {
                ...state,
                salesArea: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case SalesAreaActionTypes.ResetStore:
            return {
                ...state,
                salesArea: initialState.salesArea,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
