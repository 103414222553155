import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EposSettingsActions, EposSettingsActionTypes } from './epossettings.actions';
import { IEposSetting } from 'src/app/models/IEposSetting';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    eposSettings: EposSettingsState;
}

export interface EposSettingsState extends IGardiffStoreSliceBase {
    eposSettings: IEposSetting[];
}

const initialState: EposSettingsState = {
    eposSettings: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getEposSettingsFeatureState = createFeatureSelector<EposSettingsState>('eposSettings');
export const getEposSettingsState = createSelector(
    getEposSettingsFeatureState,
    state => state
);

export const getEposSettings = createSelector(
    getEposSettingsFeatureState,
    state => state.eposSettings
);

export const getApiError = createSelector(
  getEposSettingsFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getEposSettingsFeatureState,
  state => state.status
);

export function reducer(state: EposSettingsState = initialState, action: EposSettingsActions): EposSettingsState {
    switch (action.type) {
        case EposSettingsActionTypes.LoadEposSettings:
            return {
                ...state,
                eposSettings: null,
                apiError: null,
                status: null
            };

        case EposSettingsActionTypes.LoadEposSettingsSuccessful:
            return {
                ...state,
                eposSettings: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case EposSettingsActionTypes.LoadEposSettingsFailed:
            return {
                ...state,
                eposSettings: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case EposSettingsActionTypes.SaveEposSettings:
            return {
                ...state,
                eposSettings: null,
                apiError: null,
                status: null
            };

        case EposSettingsActionTypes.SaveEposSettingsSuccessful:
            return {
                ...state,
                eposSettings: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case EposSettingsActionTypes.SaveEposSettingsFailed:
            return {
                ...state,
                eposSettings: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case EposSettingsActionTypes.ResetStore:
            return {
                ...state,
                eposSettings: initialState.eposSettings,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
