import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { EodFormParameters } from 'src/app/models/IEodFormParameters';
import { IEODItem } from 'src/app/models/IEODItem';
import { IEodReportDocument } from 'src/app/models/IEodReportDocument';

export enum EodReportActionTypes {
    LoadEOD = '[EOD Reports] Load EODs',
    LoadEODSuccessful = '[EOD Reports] Load EODs - Successful',
    LoadEODFailed = '[EOD Reports] Load EODs - Failed',
    SelectEOD = '[EOD Reports] Select EOD',
    SelectedEOD = '[EOD Reports] Selected EOD',
    SelectEODFailed = '[EOD Reports] Select EOD - Failed',
    ResetStore = '[EOD Reports] Reset Store',
    SaveSearchParameters = '[EOD Reports] Save Search Parameters',
    //LoadEODbyGuid = '[EOD Reports] Load EOD by Guid'
    DownloadEOD = '[EOD Reports] Download EOD',
    DownloadEODSuccessful = '[EOD Reports] Download EOD - Successful',
    DownloadEODFailed = '[EOD Reports] Download EOD - Failed',
    CleanUpFileBuffer = '[EOD Reports] Clean up file buffer'
}

export class LoadEOD implements Action {
    readonly type = EodReportActionTypes.LoadEOD;

    constructor(public payload: EodFormParameters) { }
}

export class LoadEODSuccessful implements Action {
    readonly type = EodReportActionTypes.LoadEODSuccessful;

    constructor(public payload: IEODItem[]) { }
}

export class LoadEODFailed implements Action {
    readonly type = EodReportActionTypes.LoadEODFailed;

    constructor(public payload: IGenericError) { }
}

export class SelectEOD implements Action {
    readonly type = EodReportActionTypes.SelectEOD;

    constructor(public payload: IEODItem) { }
}

export class SelectedEOD implements Action {
    readonly type = EodReportActionTypes.SelectedEOD;

    constructor(public payload: IEodReportDocument) { }
}

export class SelectEODFailed implements Action {
    readonly type = EodReportActionTypes.SelectEODFailed;

    constructor() { }
}

export class ResetStore implements Action {
    readonly type = EodReportActionTypes.ResetStore;

    constructor() { }
}

export class SaveSearchParameters implements Action {
    readonly type = EodReportActionTypes.SaveSearchParameters;

    constructor(public payload: EodFormParameters) { }
}

export class DownloadEOD implements Action {
  readonly type = EodReportActionTypes.DownloadEOD;

  constructor(public payload: string) { }
}

export class DownloadEODSuccessful implements Action {
  readonly type = EodReportActionTypes.DownloadEODSuccessful;

  constructor(public payload: ArrayBuffer) { }
}

export class DownloadEODFailed implements Action {
  readonly type = EodReportActionTypes.DownloadEODFailed;

  constructor(public payload: IGenericError) { }
}

export class CleanUpFileBuffer implements Action {
  readonly type = EodReportActionTypes.CleanUpFileBuffer;

  constructor() { }
}

// export class LoadEODbyGuid implements Action {
//     readonly type = EodReportActionTypes.LoadEODbyGuid;

//     constructor(public payload: string) { }
// }

export type EodReportActions = LoadEOD
    | LoadEODSuccessful
    | LoadEODFailed
    | SelectEOD
    | SelectedEOD
    | SelectEODFailed
    | ResetStore
    | SaveSearchParameters
    | DownloadEOD
    | DownloadEODSuccessful
    | DownloadEODFailed
    | CleanUpFileBuffer;

    //| LoadEODbyGuid;
