import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import { EstateService } from '../../../services/estate.service';

import * as estateActions from './estate.actions';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';

import { IEstateView } from '../../../models/IEstateView';

import { of } from 'rxjs';

// import * as pendingChangesActions from '../../../NGRX/pending-changes/pendingchanges.actions';
// import * as eodReportActions from '../../eod/state/eodReport.actions';
// import * as salesReportActions from '../../sales-report/state/sales-report.actions';
// import * as productEditorActions from '../../product-editor/state/productEditor.actions';
// import * as categoryEditorActions from '../../../NGRX/categories/categories.actions';
// import * as parentCategoryEditorActions from '../../../NGRX/parent-categories/parent-category.actions';
import * as loginActions from '../../login/state/login.actions';
import * as adminActions from '../../../NGRX/admin/admin.actions';

import { ISiteDetails } from 'src/app/models/ISiteDetails';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { IMonitorEnvelope } from 'src/app/models/IMonitorEnvelope';
import { LoginService } from 'src/app/services/login.service';

@Injectable()
export class EstateEffects {
    constructor(private action$: Actions, private estateService: EstateService, private loginService: LoginService) {

    }

    loadEstate$ = createEffect(
      () => this.action$.pipe(
        ofType(estateActions.EstateActionTypes.LoadEstate),
        mergeMap((action: estateActions.LoadEstate) =>
            this.estateService.getUserEstate().pipe(
                map((estateView: IEstateView) => (new estateActions.LoadEstateSuccessful(estateView)),
                catchError(err => of(new estateActions.LoadEstateFailed()))
        )),
      ))
    );


    loadEstateView$ = createEffect(
      () => this.action$.pipe(
      ofType(estateActions.EstateActionTypes.LoadEstateViewMonitor),
      mergeMap((action: estateActions.LoadEstateViewMonitor) =>
        this.estateService.getMonitorEstateView(action.CorporationID).pipe(
        map((estateView: IEstateView) => {
          return new estateActions.LoadEstateSuccessful(estateView);
        }),
        catchError((error: HttpErrorResponse) => of(new estateActions.LoadEstateFailed()))
        )
      ))
    );

    loadInitialSite$ = createEffect(
      () => this.action$.pipe(
        ofType(estateActions.EstateActionTypes.LoadInitialSite),
        mergeMap((action: estateActions.LoadInitialSite) => {
            return this.estateService.getInitialSite().pipe(
                map((estateView: IEstateView) =>  {
                  return new estateActions.LoadInitialSiteSuccessful(estateView, this.estateService.SelectedBrand, this.estateService.SelectedArea, this.estateService.SelectedSite);
                },
                catchError(err => of(new estateActions.LoadInitialSiteFailed()))
            ));
        }))
    );

    loadInitialSiteDetails$ = createEffect(
      () => this.action$.pipe(
        ofType(estateActions.EstateActionTypes.LoadInitialSiteSuccessful),
        switchMap(() => [
            new estateActions.LoadSiteDetails(this.estateService.SelectedSite.siteId)
        ])
      )
    );

    changeSite$ = createEffect(
      () => this.action$.pipe(
        ofType(estateActions.EstateActionTypes.ChangeSite),
        switchMap(() =>  [
            new loginActions.ResetStore(),
        ])
      )
    );

    loadSiteDetailsAfterChangeSite$ = createEffect(
      () => this.action$.pipe(
        ofType(estateActions.EstateActionTypes.ChangeSite),
        switchMap((action: estateActions.ChangeSite) =>  [
            new estateActions.LoadSiteDetails(action.payload.site.siteId)
        ])
      )
    );

    loadSiteDetails$ = createEffect(
      () => this.action$.pipe(
        ofType(estateActions.EstateActionTypes.LoadSiteDetails),
        mergeMap((action: estateActions.LoadSiteDetails) =>
            this.estateService.getSiteDetails(this.estateService.CorporationID, action.payload).pipe(
                map((site: ISiteDetails) => (new estateActions.LoadSiteDetailsSuccessful(site)),
                catchError(err => of(new estateActions.LoadSiteDetailsFailed(err)))
        ))
      ))
    );

    saveSiteDetails$ = createEffect(
      () => this.action$.pipe(
        ofType(estateActions.EstateActionTypes.SaveSiteDetails),
        mergeMap((action: estateActions.SaveSiteDetails) =>
            this.estateService.saveSiteDetails(this.estateService.CorporationID, action.payload).pipe(
                map((site: ISiteDetails) => (new estateActions.SaveSiteDetailsSuccessful(site)),
                catchError(err => of(new estateActions.SaveSiteDetailsFailed(err)))
        ))
      ))
    );

    loadEposManagerVersion$ = createEffect(
      () => this.action$.pipe(
        ofType(estateActions.EstateActionTypes.LoadSiteDetails),
        switchMap(() =>  [
            new adminActions.LoadEposManagerVersion(),
        ])
      )
    );
}
