import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ZReportActions, ZReportActionTypes } from './zreport.actions';
import { IEopSectionType } from 'src/app/models/IEopSectionType';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    zReport: ZReportState;
}

export interface ZReportState extends IGardiffStoreSliceBase {
  zReport: IEopSectionType[];
}

const initialState: ZReportState = {
  zReport: [],
  apiError: null,
  status: new GardiffStoreSliceStatus()
};

// create the selectors
const getZReportSetupFeatureState = createFeatureSelector<ZReportState>('zReport');
export const getZReportSetupState = createSelector(
    getZReportSetupFeatureState,
    state => state
);

export const getZReportSetup = createSelector(
    getZReportSetupFeatureState,
    state => state.zReport
);

export const getApiError = createSelector(
  getZReportSetupFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getZReportSetupFeatureState,
  state => state.status
);

export function reducer(state: ZReportState = initialState, action: ZReportActions): ZReportState {
    switch (action.type) {
        case ZReportActionTypes.LoadZReport:
            return {
                ...state,
                zReport: null,
                apiError: null,
                status: null
            };

        case ZReportActionTypes.LoadZReportSuccessful:
            return {
                ...state,
                zReport: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case ZReportActionTypes.LoadZReportFailed:
            return {
                ...state,
                zReport: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case ZReportActionTypes.SaveZReport:
            return {
                ...state,
                zReport: null,
                apiError: null,
                status: null
            };

        case ZReportActionTypes.SaveZReportSuccessful:
            return {
                ...state,
                zReport: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case ZReportActionTypes.SaveZReportFailed:
            return {
                ...state,
                zReport: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case ZReportActionTypes.ResetStore:
            return {
                ...state,
                zReport: initialState.zReport,
                apiError: null,
                status: new GardiffStoreSliceStatus()
            };

        default:
            return state;
    }
}
