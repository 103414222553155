export interface ISite {
    siteId: string;
    siteName: string;
    accessible: boolean;
}

export class Site implements ISite {
    siteId: string;
    siteName: string;
    accessible: boolean;

    constructor() {
        this.siteId = '';
        this.siteName = '';
        this.accessible = false;
    }
}
