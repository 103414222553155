import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IReport, Report } from '../../../models/Report/Components/IReport';
import { EstateService } from '../../estate.service';
import { LoginService } from '../../login.service';
import { WODiscountReportField } from '../../../enums/Report/ReportField/WODiscountReportField';
import { IReportDefinition, ReportDefinition } from '../../../models/Report/Components/IReportDefinition';
import { OperatorType } from '../../../enums/Report/Components/OperatorType';
import { DiscountReportParameters } from '../../../models/Report/ReportParameters/IDiscountReportParameters';
import { IReportPDFDefinition, ReportPDFDefinition } from '../../../models/Report/Components/IReportPDFDefinition';
import { ReportPDFEnvelope } from '../../../models/Report/Components/IReportPDFEnvelope';
import { IReportSortDefinition } from '../../../models/Report/Components/IReportSortDefinition';
import { IReportSectionSortDefinition } from 'src/app/models/Report/Components/IReportSectionSortDefinition';
import { LIDiscountReportField } from 'src/app/enums/Report/ReportField/LIDiscountReportField';

@Injectable({
  providedIn: 'root'
})
export class DiscountReportService {

  private defaultLISort: IReportSortDefinition = {
    fieldNumber: LIDiscountReportField.AppliedDate - 1,
    isAscending: false
  };
  private defaultWOSort: IReportSortDefinition = {
    fieldNumber: WODiscountReportField.AppliedDate - 1,
    isAscending: false
  };

  private token: string;
  private DiscountReportEndpointTemplate = environment.APIEndpoint + 'frontend/corporation/{corporationId}/site/{siteId}/DiscountReport/';

  constructor(private http: HttpClient, private loginService: LoginService, private estateService: EstateService) { }

  getDiscountReport(parameter: DiscountReportParameters): Observable<IReport> {
    this.token = this.loginService.AccessToken.authToken;

    let definition: IReportDefinition = this.generateDiscountReportDefinition(parameter);

    const _DiscountReportURL = this.DiscountReportEndpointTemplate.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId);

    return this.http.post<IReport>(_DiscountReportURL, definition, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token) }).pipe(
      map((data: IReport) => {

        if (data == null) {
          data = new Report();
        }

        data.reportDefinition = definition
        data.reportPDFDefinition = new ReportPDFDefinition()

        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(() => err);
      })
    );
  }

  // Returns Discount Report PDF
  getReportFilePDF(definition: IReportDefinition, pdfDefinition: IReportPDFDefinition, sortDefinitions: IReportSortDefinition[]): Observable<ArrayBuffer> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const _DiscountReportPDFURL = this.DiscountReportEndpointTemplate.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId)  + 'PDF';

      definition.sectionSorts = this.generateSectionSort(sortDefinitions)

      let envelope: ReportPDFEnvelope = {
        reportDefinition: definition,
        reportPDFDefinition: pdfDefinition
      }

      return this.http.post(_DiscountReportPDFURL, envelope, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), responseType: 'arraybuffer'}).pipe(
        map((data: ArrayBuffer) => {

          return data;

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private generateSectionSort(sortDefinitions: IReportSortDefinition[]): IReportSectionSortDefinition[] {
    let sectionSorts = [{
      reportSectionName: "Line Item Discounts",
      sorts: [this.defaultLISort]
    },{
      reportSectionName: "Whole Order Discounts",
      sorts: [this.defaultWOSort]
    }]

    if (sortDefinitions != null){
      sectionSorts[0].sorts.push(sortDefinitions[0])
      sectionSorts[1].sorts.push(sortDefinitions[1])
    }

    return sectionSorts;
  }

  private generateDiscountReportDefinition(parameter: DiscountReportParameters): IReportDefinition {
    let reportDefinition = new ReportDefinition();

    reportDefinition.groupedFieldEnumValue = parameter.reportGroup

    let salesDateFrom = this.getFullDate(parameter.salesDateFromDay, parameter.salesDateFromHour, parameter.salesDateFromMinute)

    reportDefinition.filters.push({
      fieldEnumValue: WODiscountReportField.AppliedDate,
      operatorType: OperatorType.GreaterThanOrEqual,
      value: salesDateFrom
    });

    let salesDateTo = this.getFullDate(parameter.salesDateToDay, parameter.salesDateToHour, parameter.salesDateToMinute)

    reportDefinition.filters.push({
      fieldEnumValue: WODiscountReportField.AppliedDate,
      operatorType: OperatorType.LessThan,
      value: salesDateTo
    });

    if (parameter.posId != -1){
      reportDefinition.filters.push({
        fieldEnumValue: WODiscountReportField.Pos,
        operatorType: OperatorType.Equal,
        value: parameter.posId
      });
    }

    if (parameter.clerkId != -1){
      reportDefinition.filters.push({
        fieldEnumValue: WODiscountReportField.User,
        operatorType: OperatorType.Equal,
        value: parameter.clerkId
      });
    }

    //default sort
    reportDefinition.sectionSorts = this.generateSectionSort(null)

    return reportDefinition;
  }

  private getFullDate(day: Date, hours: string, minutes: string): Date {

    let fullDate: Date;

    fullDate = day;
    fullDate.setHours( Number(hours), Number(minutes), 0, 0);

    return fullDate;
  }
}
