import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as productEditorActions from './productEditor.actions';

@Injectable()
export class ProductEditorEffects {
  constructor() {}

}
