import { ChangeType } from '../enums/ChangeType';
import { IPriceGroupListDTO } from './DTOs/IPriceGroupListDTO';
import { ConvertPriceLevelList, ConvertPriceLevelListDTO, PriceLevelList } from './IPriceLevelList';

export interface IPriceGroupList extends IPriceGroupListDTO {
  isChanged: ChangeType;
  priceLevelList: PriceLevelList[];
}

export class PriceGroupList implements IPriceGroupList {
  measurement_GUID: string;
  measurementName: string;
  priceLevelList: PriceLevelList[];
  isChanged: ChangeType;

  constructor() {
    this.measurement_GUID = '';
    this.measurementName = '';
    this.priceLevelList = [];
    this.isChanged = ChangeType.None;
  }
}

export function ConvertPriceGroupListDTO(group: IPriceGroupListDTO[]): IPriceGroupList[] {
  const newGroup: IPriceGroupList[] = [];

  group.forEach(item => {
    newGroup.push({
      measurement_GUID: item.measurement_GUID,
      measurementName: item.measurementName,
      priceLevelList: ConvertPriceLevelListDTO(item.priceLevelList),
      isChanged: ChangeType.None
    });
  });

  return newGroup;
}

export function ConvertPriceGroupList(group: IPriceGroupList[]): IPriceGroupListDTO[] {
  const newGroup: IPriceGroupListDTO[] = [];

  group.forEach(item => {
    newGroup.push({
      measurement_GUID: item.measurement_GUID,
      measurementName: item.measurementName,
      priceLevelList: ConvertPriceLevelList(item.priceLevelList)
    });
  });

  return newGroup;
}
