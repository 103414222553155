import { HttpHeaders, HttpErrorResponse, HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, Subscriber } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeOperationType } from '../enums/ChangeOperationType';
import { ChangeType } from '../enums/ChangeType';
import { EditDataList, IEditDataList } from '../models/DTOs/IEditDataList';
import { IEnvelopeInDTO } from '../models/DTOs/IEnvelopeInDTO';
import { IEnvelopeOutDTO } from '../models/DTOs/IEnvelopeOutDTO';
import { ISchedulePriceLevelDTO } from '../models/DTOs/ISchedulePriceLevelDTO';
import { IScheduleDay } from '../models/IScheduleDay';
import { ISchedulePriceLevel } from '../models/ISchedulePriceLevel';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulePricelevelsService {
  private SchedulePriceLevelEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/PriceLevelSchedule';

  private schedules: ISchedulePriceLevel[] = [];

  constructor(private loginService: LoginService, private http: HttpClient) {

  }

  GetSchedulePriceLevels(CorporationID: string, SiteID: string): Observable<ISchedulePriceLevel[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.SchedulePriceLevelEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http.get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<ISchedulePriceLevelDTO[]>) => {

          if (data.ok) {
            // store a copy for backup
            this.schedules = this.formatData(data.body);
            return JSON.parse(JSON.stringify(this.schedules));
          }
          

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private formatData(dataList: ISchedulePriceLevelDTO[]): ISchedulePriceLevel[] {
    // convert list
    let list: ISchedulePriceLevel[] = this.ConvertSchedulesDTO(dataList);

    // sort data
    list = this.sortData(list);

    return list;
  }

  private ConvertSchedulesDTO(schedules: ISchedulePriceLevelDTO[]): ISchedulePriceLevel[] {
    const newList: ISchedulePriceLevel[] = [];

    schedules.forEach(schedule => {
      newList.push(
        this.ConvertScheduleDTO(schedule)
      );
    });

    return newList;
  }

  private ConvertScheduleDTO(schedule: ISchedulePriceLevelDTO): ISchedulePriceLevel {

    const newSchedule: ISchedulePriceLevel = {
      id: schedule.id,
      priceLevelScheduleGUID: schedule.priceLevelScheduleGUID,
      description: schedule.description,
      startDate: schedule.startDate,
      endDate: schedule.endDate,
      isActive: schedule.isActive,
      isSelected: schedule.isSelected,
      priceLevel: schedule.priceLevel,
      schedule: {
        days: []
      },
      changeState: ChangeType.None
    };

    schedule.schedule.days.forEach(day => {
      const newDay: IScheduleDay = {
        day: day.day,
        times: [],
        isEnabled: day.isEnabled
      };

      day.times.forEach(time => {
        newDay.times.push({
          startTime: time.startTime,
          endTime: time.endTime
        });
      });

      newSchedule.schedule.days.push(newDay);
    });

    return newSchedule;
  }

  private sortData(list: ISchedulePriceLevel[]): ISchedulePriceLevel[] {
    return list.sort((Item1: ISchedulePriceLevel, Item2: ISchedulePriceLevel) => {
      if (Item1.description.toLowerCase() > Item2.description.toLowerCase()) {
        return 1;
      }

      if (Item1.description.toLowerCase() < Item2.description.toLowerCase()) {
        return -1;
      }
    });
  }

  SaveSchedulePriceLevels(CorporationID: string, SiteID: string, newSchedules: ISchedulePriceLevel[]): Observable<ISchedulePriceLevel[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.SchedulePriceLevelEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IEditDataList<ISchedulePriceLevelDTO> = this.SplitItems(newSchedules);

      return this.http.post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<ISchedulePriceLevelDTO[]>) => {

          if (data.ok) {
            // store a copy for backup
            this.schedules = this.formatData(data.body);
            return JSON.parse(JSON.stringify(this.schedules));
          }

        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private DeflateItems(list: ISchedulePriceLevel[]): ISchedulePriceLevelDTO[] {
    let newData: ISchedulePriceLevelDTO[] = [];

    if (list != null) {
      newData = this.ConvertSchedules(list);
    }

    return newData;
  }

  private ConvertSchedules(schedules: ISchedulePriceLevel[]): ISchedulePriceLevelDTO[] {
    const newList: ISchedulePriceLevelDTO[] = [];

    schedules.forEach(schedule => {
      newList.push(
        this.ConvertSchedule(schedule)
      );
    });

    return newList;
  }

  private ConvertSchedule(schedule: ISchedulePriceLevel): ISchedulePriceLevelDTO {

    const newSchedule: ISchedulePriceLevelDTO = {
      id: schedule.id,
      priceLevelScheduleGUID: schedule.priceLevelScheduleGUID,
      description: schedule.description,
      startDate: schedule.startDate,
      endDate: schedule.endDate,
      isActive: schedule.isActive,
      isSelected: schedule.isSelected,
      priceLevel: schedule.priceLevel,
      schedule: {
        days: []
      }
    };

    schedule.schedule.days.forEach(day => {
      const newDay: IScheduleDay = {
        day: day.day,
        times: [],
        isEnabled: day.isEnabled
      };

      day.times.forEach(time => {
        newDay.times.push({
          startTime: time.startTime,
          endTime: time.endTime
        });
      });

      newSchedule.schedule.days.push(newDay);
    });

    return newSchedule;
  }

  GetBackup(): Observable<ISchedulePriceLevel[]> {
    const backup: ISchedulePriceLevel[] = JSON.parse(JSON.stringify(this.schedules));
    return new Observable<ISchedulePriceLevel[]>((subscriber: Subscriber<ISchedulePriceLevel[]>) => subscriber.next(backup));
  }

  private SplitItems(data: ISchedulePriceLevel[]): IEditDataList<ISchedulePriceLevelDTO> {
    let newData: IEditDataList<ISchedulePriceLevelDTO> = new EditDataList<ISchedulePriceLevelDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }
}
