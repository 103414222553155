import { JsonProperty, JsonObject } from 'typescript-json-serializer';
import { EopReportSectionDTO, IEopReportSectionDTO } from './IEopReportSectionDTO';


export interface IEodReportDocumentDTO {
  createdByUser: string;
  createdOnPosDevice: string;
  posDevicesIncluded: number[];
  paymentTotals: { [key: string]: number; };
  productSalesTotal: number;
  reportDate: Date;
  reportDescription: string;
  reportNumber: number;
  reportVersion: number;
  sections: IEopReportSectionDTO[];
}

@JsonObject()
export class EodReportDocumentDTO implements IEodReportDocumentDTO {

  @JsonProperty('EA')
  public createdByUser: string;

  @JsonProperty('EC')
  public createdOnPosDevice: string;

  @JsonProperty('ED')
  public posDevicesIncluded: number[];

  @JsonProperty('EI')
  public paymentTotals: { [key: string]: number; };

  @JsonProperty('EE')
  public productSalesTotal: number;

  @JsonProperty('EB')
  public reportDate: Date;

  @JsonProperty('EF')
  public reportDescription: string;

  @JsonProperty('EG')
  public reportNumber: number;

  @JsonProperty('EZ')
  public reportVersion: number;

  @JsonProperty('EH')
  public sections: IEopReportSectionDTO[];

  constructor() {
    this.paymentTotals = {};
    this.sections = [];
  }
}
