import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { IMeasure } from 'src/app/models/IMeasure';

export enum WeightMeasuresActionTypes {
    LoadWeightMeasures = '[Weight Measure] Load WeightMeasures',
    LoadWeightMeasuresSuccessful = '[Weight Measure] Load WeightMeasures - Successful',
    LoadWeightMeasuresFailed = '[Weight Measure] Load WeightMeasures - Failed',
    SaveWeightMeasures = '[Weight Measure] Save WeightMeasures',
    SaveWeightMeasuresSuccessful = '[Weight Measure] Save WeightMeasures - Successful',
    SaveWeightMeasuresFailed = '[Weight Measure] Save WeightMeasures - Failed',
    ResetStore = '[Weight Measure] Reset Store',
}

export class LoadWeightMeasures implements Action {
    readonly type = WeightMeasuresActionTypes.LoadWeightMeasures;

    constructor() { }
}

export class LoadWeightMeasuresSuccessful implements Action {
    readonly type = WeightMeasuresActionTypes.LoadWeightMeasuresSuccessful;

    constructor(public payload: IMeasure[]) { }
}

export class LoadWeightMeasuresFailed implements Action {
    readonly type = WeightMeasuresActionTypes.LoadWeightMeasuresFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveWeightMeasures implements Action {
    readonly type = WeightMeasuresActionTypes.SaveWeightMeasures;

    constructor(public oldData: IMeasure[], public newData: IMeasure[]) { }
}

export class SaveWeightMeasuresSuccessful implements Action {
    readonly type = WeightMeasuresActionTypes.SaveWeightMeasuresSuccessful;

    constructor(public payload: IMeasure[]) { }
}

export class SaveWeightMeasuresFailed implements Action {
    readonly type = WeightMeasuresActionTypes.SaveWeightMeasuresFailed;

    constructor(public error: IGenericError, public oldData: IMeasure[]) { }
}

export class ResetStore implements Action {
    readonly type = WeightMeasuresActionTypes.ResetStore;

    constructor() { }
}

export type WeightMeasuresActions = LoadWeightMeasures
    | LoadWeightMeasuresSuccessful
    | LoadWeightMeasuresFailed
    | SaveWeightMeasures
    | SaveWeightMeasuresSuccessful
    | SaveWeightMeasuresFailed
    | ResetStore;
