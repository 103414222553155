export interface IDiscountReportDefinitionDTO {
  salesDateFrom: Date;
  salesDateTo: Date;
  timeZone: string;
}

export class DiscountReportDefinitionDTO implements IDiscountReportDefinitionDTO {
  salesDateFrom: Date;
  salesDateTo: Date;
  timeZone: string;

  constructor() {
    this.salesDateFrom = new Date();
    this.salesDateTo = new Date();
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
