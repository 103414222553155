import { Injectable } from '@angular/core';
import { IMaterialMainColor } from '../models/IMaterialMainColor';
import { IMaterialSubColor } from '../models/IMaterialSubColor';

@Injectable({
  providedIn: 'root'
})
export class MaterialColorPaletteService {

  // Material Design Palette
  private colors: IMaterialMainColor[] = [
    {
      "name": "Red",
      "colors": [
        {
          "name": "50",
          "rgb": [
            255,
            235,
            238
          ],
          "color": "#FFEBEE"
        },
        {
          "name": "100",
          "rgb": [
            255,
            205,
            210
          ],
          "color": "#FFCDD2"
        },
        {
          "name": "200",
          "rgb": [
            239,
            154,
            154
          ],
          "color": "#EF9A9A"
        },
        {
          "name": "300",
          "rgb": [
            229,
            115,
            115
          ],
          "color": "#E57373"
        },
        {
          "name": "400",
          "rgb": [
            239,
            83,
            80
          ],
          "color": "#EF5350"
        },
        {
          "name": "500",
          "rgb": [
            244,
            67,
            54
          ],
          "color": "#F44336"
        },
        {
          "name": "600",
          "rgb": [
            229,
            57,
            53
          ],
          "color": "#E53935"
        },
        {
          "name": "700",
          "rgb": [
            211,
            47,
            47
          ],
          "color": "#D32F2F"
        },
        {
          "name": "800",
          "rgb": [
            198,
            40,
            40
          ],
          "color": "#C62828"
        },
        {
          "name": "900",
          "rgb": [
            183,
            28,
            28
          ],
          "color": "#B71C1C"
        },
        {
          "name": "A100",
          "rgb": [
            255,
            138,
            128
          ],
          "color": "#FF8A80"
        },
        {
          "name": "A200",
          "rgb": [
            255,
            82,
            82
          ],
          "color": "#FF5252"
        },
        {
          "name": "A400",
          "rgb": [
            255,
            23,
            68
          ],
          "color": "#FF1744"
        },
        {
          "name": "A700",
          "rgb": [
            213,
            0,
            0
          ],
          "color": "#D50000"
        }
      ]
    },
    {
      "name": "Pink",
      "colors": [
        {
          "name": "50",
          "rgb": [
            252,
            228,
            236
          ],
          "color": "#FCE4EC"
        },
        {
          "name": "100",
          "rgb": [
            248,
            187,
            208
          ],
          "color": "#F8BBD0"
        },
        {
          "name": "200",
          "rgb": [
            244,
            143,
            177
          ],
          "color": "#F48FB1"
        },
        {
          "name": "300",
          "rgb": [
            240,
            98,
            146
          ],
          "color": "#F06292"
        },
        {
          "name": "400",
          "rgb": [
            236,
            64,
            122
          ],
          "color": "#EC407A"
        },
        {
          "name": "500",
          "rgb": [
            233,
            30,
            99
          ],
          "color": "#E91E63"
        },
        {
          "name": "600",
          "rgb": [
            216,
            27,
            96
          ],
          "color": "#D81B60"
        },
        {
          "name": "700",
          "rgb": [
            194,
            24,
            91
          ],
          "color": "#C2185B"
        },
        {
          "name": "800",
          "rgb": [
            173,
            20,
            87
          ],
          "color": "#AD1457"
        },
        {
          "name": "900",
          "rgb": [
            136,
            14,
            79
          ],
          "color": "#880E4F"
        },
        {
          "name": "A100",
          "rgb": [
            255,
            128,
            171
          ],
          "color": "#FF80AB"
        },
        {
          "name": "A200",
          "rgb": [
            255,
            64,
            129
          ],
          "color": "#FF4081"
        },
        {
          "name": "A400",
          "rgb": [
            245,
            0,
            87
          ],
          "color": "#F50057"
        },
        {
          "name": "A700",
          "rgb": [
            197,
            17,
            98
          ],
          "color": "#C51162"
        }
      ]
    },
    {
      "name": "Purple",
      "colors": [
        {
          "name": "50",
          "rgb": [
            243,
            229,
            245
          ],
          "color": "#F3E5F5"
        },
        {
          "name": "100",
          "rgb": [
            225,
            190,
            231
          ],
          "color": "#E1BEE7"
        },
        {
          "name": "200",
          "rgb": [
            206,
            147,
            216
          ],
          "color": "#CE93D8"
        },
        {
          "name": "300",
          "rgb": [
            186,
            104,
            200
          ],
          "color": "#BA68C8"
        },
        {
          "name": "400",
          "rgb": [
            171,
            71,
            188
          ],
          "color": "#AB47BC"
        },
        {
          "name": "500",
          "rgb": [
            156,
            39,
            176
          ],
          "color": "#9C27B0"
        },
        {
          "name": "600",
          "rgb": [
            142,
            36,
            170
          ],
          "color": "#8E24AA"
        },
        {
          "name": "700",
          "rgb": [
            123,
            31,
            162
          ],
          "color": "#7B1FA2"
        },
        {
          "name": "800",
          "rgb": [
            106,
            27,
            154
          ],
          "color": "#6A1B9A"
        },
        {
          "name": "900",
          "rgb": [
            74,
            20,
            140
          ],
          "color": "#4A148C"
        },
        {
          "name": "A100",
          "rgb": [
            234,
            128,
            252
          ],
          "color": "#EA80FC"
        },
        {
          "name": "A200",
          "rgb": [
            224,
            64,
            251
          ],
          "color": "#E040FB"
        },
        {
          "name": "A400",
          "rgb": [
            213,
            0,
            249
          ],
          "color": "#D500F9"
        },
        {
          "name": "A700",
          "rgb": [
            170,
            0,
            255
          ],
          "color": "#AA00FF"
        }
      ]
    },
    {
      "name": "Deep Purple",
      "colors": [
        {
          "name": "50",
          "rgb": [
            237,
            231,
            246
          ],
          "color": "#EDE7F6"
        },
        {
          "name": "100",
          "rgb": [
            209,
            196,
            233
          ],
          "color": "#D1C4E9"
        },
        {
          "name": "200",
          "rgb": [
            179,
            157,
            219
          ],
          "color": "#B39DDB"
        },
        {
          "name": "300",
          "rgb": [
            149,
            117,
            205
          ],
          "color": "#9575CD"
        },
        {
          "name": "400",
          "rgb": [
            126,
            87,
            194
          ],
          "color": "#7E57C2"
        },
        {
          "name": "500",
          "rgb": [
            103,
            58,
            183
          ],
          "color": "#673AB7"
        },
        {
          "name": "600",
          "rgb": [
            94,
            53,
            177
          ],
          "color": "#5E35B1"
        },
        {
          "name": "700",
          "rgb": [
            81,
            45,
            168
          ],
          "color": "#512DA8"
        },
        {
          "name": "800",
          "rgb": [
            69,
            39,
            160
          ],
          "color": "#4527A0"
        },
        {
          "name": "900",
          "rgb": [
            49,
            27,
            146
          ],
          "color": "#311B92"
        },
        {
          "name": "A100",
          "rgb": [
            179,
            136,
            255
          ],
          "color": "#B388FF"
        },
        {
          "name": "A200",
          "rgb": [
            124,
            77,
            255
          ],
          "color": "#7C4DFF"
        },
        {
          "name": "A400",
          "rgb": [
            101,
            31,
            255
          ],
          "color": "#651FFF"
        },
        {
          "name": "A700",
          "rgb": [
            98,
            0,
            234
          ],
          "color": "#6200EA"
        }
      ]
    },
    {
      "name": "Indigo",
      "colors": [
        {
          "name": "50",
          "rgb": [
            232,
            234,
            246
          ],
          "color": "#E8EAF6"
        },
        {
          "name": "100",
          "rgb": [
            197,
            202,
            233
          ],
          "color": "#C5CAE9"
        },
        {
          "name": "200",
          "rgb": [
            159,
            168,
            218
          ],
          "color": "#9FA8DA"
        },
        {
          "name": "300",
          "rgb": [
            121,
            134,
            203
          ],
          "color": "#7986CB"
        },
        {
          "name": "400",
          "rgb": [
            92,
            107,
            192
          ],
          "color": "#5C6BC0"
        },
        {
          "name": "500",
          "rgb": [
            63,
            81,
            181
          ],
          "color": "#3F51B5"
        },
        {
          "name": "600",
          "rgb": [
            57,
            73,
            171
          ],
          "color": "#3949AB"
        },
        {
          "name": "700",
          "rgb": [
            48,
            63,
            159
          ],
          "color": "#303F9F"
        },
        {
          "name": "800",
          "rgb": [
            40,
            53,
            147
          ],
          "color": "#283593"
        },
        {
          "name": "900",
          "rgb": [
            26,
            35,
            126
          ],
          "color": "#1A237E"
        },
        {
          "name": "A100",
          "rgb": [
            140,
            158,
            255
          ],
          "color": "#8C9EFF"
        },
        {
          "name": "A200",
          "rgb": [
            83,
            109,
            254
          ],
          "color": "#536DFE"
        },
        {
          "name": "A400",
          "rgb": [
            61,
            90,
            254
          ],
          "color": "#3D5AFE"
        },
        {
          "name": "A700",
          "rgb": [
            48,
            79,
            254
          ],
          "color": "#304FFE"
        }
      ]
    },
    {
      "name": "Blue",
      "colors": [
        {
          "name": "50",
          "rgb": [
            227,
            242,
            253
          ],
          "color": "#E3F2FD"
        },
        {
          "name": "100",
          "rgb": [
            187,
            222,
            251
          ],
          "color": "#BBDEFB"
        },
        {
          "name": "200",
          "rgb": [
            144,
            202,
            249
          ],
          "color": "#90CAF9"
        },
        {
          "name": "300",
          "rgb": [
            100,
            181,
            246
          ],
          "color": "#64B5F6"
        },
        {
          "name": "400",
          "rgb": [
            66,
            165,
            245
          ],
          "color": "#42A5F5"
        },
        {
          "name": "500",
          "rgb": [
            33,
            150,
            243
          ],
          "color": "#2196F3"
        },
        {
          "name": "600",
          "rgb": [
            30,
            136,
            229
          ],
          "color": "#1E88E5"
        },
        {
          "name": "700",
          "rgb": [
            25,
            118,
            210
          ],
          "color": "#1976D2"
        },
        {
          "name": "800",
          "rgb": [
            21,
            101,
            192
          ],
          "color": "#1565C0"
        },
        {
          "name": "900",
          "rgb": [
            13,
            71,
            161
          ],
          "color": "#0D47A1"
        },
        {
          "name": "A100",
          "rgb": [
            130,
            177,
            255
          ],
          "color": "#82B1FF"
        },
        {
          "name": "A200",
          "rgb": [
            68,
            138,
            255
          ],
          "color": "#448AFF"
        },
        {
          "name": "A400",
          "rgb": [
            41,
            121,
            255
          ],
          "color": "#2979FF"
        },
        {
          "name": "A700",
          "rgb": [
            41,
            98,
            255
          ],
          "color": "#2962FF"
        }
      ]
    },
    {
      "name": "Light Blue",
      "colors": [
        {
          "name": "50",
          "rgb": [
            225,
            245,
            254
          ],
          "color": "#E1F5FE"
        },
        {
          "name": "100",
          "rgb": [
            179,
            229,
            252
          ],
          "color": "#B3E5FC"
        },
        {
          "name": "200",
          "rgb": [
            129,
            212,
            250
          ],
          "color": "#81D4FA"
        },
        {
          "name": "300",
          "rgb": [
            79,
            195,
            247
          ],
          "color": "#4FC3F7"
        },
        {
          "name": "400",
          "rgb": [
            41,
            182,
            246
          ],
          "color": "#29B6F6"
        },
        {
          "name": "500",
          "rgb": [
            3,
            169,
            244
          ],
          "color": "#03A9F4"
        },
        {
          "name": "600",
          "rgb": [
            3,
            155,
            229
          ],
          "color": "#039BE5"
        },
        {
          "name": "700",
          "rgb": [
            2,
            136,
            209
          ],
          "color": "#0288D1"
        },
        {
          "name": "800",
          "rgb": [
            2,
            119,
            189
          ],
          "color": "#0277BD"
        },
        {
          "name": "900",
          "rgb": [
            1,
            87,
            155
          ],
          "color": "#01579B"
        },
        {
          "name": "A100",
          "rgb": [
            128,
            216,
            255
          ],
          "color": "#80D8FF"
        },
        {
          "name": "A200",
          "rgb": [
            64,
            196,
            255
          ],
          "color": "#40C4FF"
        },
        {
          "name": "A400",
          "rgb": [
            0,
            176,
            255
          ],
          "color": "#00B0FF"
        },
        {
          "name": "A700",
          "rgb": [
            0,
            145,
            234
          ],
          "color": "#0091EA"
        }
      ]
    },
    {
      "name": "Cyan",
      "colors": [
        {
          "name": "50",
          "rgb": [
            224,
            247,
            250
          ],
          "color": "#E0F7FA"
        },
        {
          "name": "100",
          "rgb": [
            178,
            235,
            242
          ],
          "color": "#B2EBF2"
        },
        {
          "name": "200",
          "rgb": [
            128,
            222,
            234
          ],
          "color": "#80DEEA"
        },
        {
          "name": "300",
          "rgb": [
            77,
            208,
            225
          ],
          "color": "#4DD0E1"
        },
        {
          "name": "400",
          "rgb": [
            38,
            198,
            218
          ],
          "color": "#26C6DA"
        },
        {
          "name": "500",
          "rgb": [
            0,
            188,
            212
          ],
          "color": "#00BCD4"
        },
        {
          "name": "600",
          "rgb": [
            0,
            172,
            193
          ],
          "color": "#00ACC1"
        },
        {
          "name": "700",
          "rgb": [
            0,
            151,
            167
          ],
          "color": "#0097A7"
        },
        {
          "name": "800",
          "rgb": [
            0,
            131,
            143
          ],
          "color": "#00838F"
        },
        {
          "name": "900",
          "rgb": [
            0,
            96,
            100
          ],
          "color": "#006064"
        },
        {
          "name": "A100",
          "rgb": [
            132,
            255,
            255
          ],
          "color": "#84FFFF"
        },
        {
          "name": "A200",
          "rgb": [
            24,
            255,
            255
          ],
          "color": "#18FFFF"
        },
        {
          "name": "A400",
          "rgb": [
            0,
            229,
            255
          ],
          "color": "#00E5FF"
        },
        {
          "name": "A700",
          "rgb": [
            0,
            184,
            212
          ],
          "color": "#00B8D4"
        }
      ]
    },
    {
      "name": "Teal",
      "colors": [
        {
          "name": "50",
          "rgb": [
            224,
            242,
            241
          ],
          "color": "#E0F2F1"
        },
        {
          "name": "100",
          "rgb": [
            178,
            223,
            219
          ],
          "color": "#B2DFDB"
        },
        {
          "name": "200",
          "rgb": [
            128,
            203,
            196
          ],
          "color": "#80CBC4"
        },
        {
          "name": "300",
          "rgb": [
            77,
            182,
            172
          ],
          "color": "#4DB6AC"
        },
        {
          "name": "400",
          "rgb": [
            38,
            166,
            154
          ],
          "color": "#26A69A"
        },
        {
          "name": "500",
          "rgb": [
            0,
            150,
            136
          ],
          "color": "#009688"
        },
        {
          "name": "600",
          "rgb": [
            0,
            137,
            123
          ],
          "color": "#00897B"
        },
        {
          "name": "700",
          "rgb": [
            0,
            121,
            107
          ],
          "color": "#00796B"
        },
        {
          "name": "800",
          "rgb": [
            0,
            105,
            92
          ],
          "color": "#00695C"
        },
        {
          "name": "900",
          "rgb": [
            0,
            77,
            64
          ],
          "color": "#004D40"
        },
        {
          "name": "A100",
          "rgb": [
            167,
            255,
            235
          ],
          "color": "#A7FFEB"
        },
        {
          "name": "A200",
          "rgb": [
            100,
            255,
            218
          ],
          "color": "#64FFDA"
        },
        {
          "name": "A400",
          "rgb": [
            29,
            233,
            182
          ],
          "color": "#1DE9B6"
        },
        {
          "name": "A700",
          "rgb": [
            0,
            191,
            165
          ],
          "color": "#00BFA5"
        }
      ]
    },
    {
      "name": "Green",
      "colors": [
        {
          "name": "50",
          "rgb": [
            232,
            245,
            233
          ],
          "color": "#E8F5E9"
        },
        {
          "name": "100",
          "rgb": [
            200,
            230,
            201
          ],
          "color": "#C8E6C9"
        },
        {
          "name": "200",
          "rgb": [
            165,
            214,
            167
          ],
          "color": "#A5D6A7"
        },
        {
          "name": "300",
          "rgb": [
            129,
            199,
            132
          ],
          "color": "#81C784"
        },
        {
          "name": "400",
          "rgb": [
            102,
            187,
            106
          ],
          "color": "#66BB6A"
        },
        {
          "name": "500",
          "rgb": [
            76,
            175,
            80
          ],
          "color": "#4CAF50"
        },
        {
          "name": "600",
          "rgb": [
            67,
            160,
            71
          ],
          "color": "#43A047"
        },
        {
          "name": "700",
          "rgb": [
            56,
            142,
            60
          ],
          "color": "#388E3C"
        },
        {
          "name": "800",
          "rgb": [
            46,
            125,
            50
          ],
          "color": "#2E7D32"
        },
        {
          "name": "900",
          "rgb": [
            27,
            94,
            32
          ],
          "color": "#1B5E20"
        },
        {
          "name": "A100",
          "rgb": [
            185,
            246,
            202
          ],
          "color": "#B9F6CA"
        },
        {
          "name": "A200",
          "rgb": [
            105,
            240,
            174
          ],
          "color": "#69F0AE"
        },
        {
          "name": "A400",
          "rgb": [
            0,
            230,
            118
          ],
          "color": "#00E676"
        },
        {
          "name": "A700",
          "rgb": [
            0,
            200,
            83
          ],
          "color": "#00C853"
        }
      ]
    },
    {
      "name": "Light Green",
      "colors": [
        {
          "name": "50",
          "rgb": [
            241,
            248,
            233
          ],
          "color": "#F1F8E9"
        },
        {
          "name": "100",
          "rgb": [
            220,
            237,
            200
          ],
          "color": "#DCEDC8"
        },
        {
          "name": "200",
          "rgb": [
            197,
            225,
            165
          ],
          "color": "#C5E1A5"
        },
        {
          "name": "300",
          "rgb": [
            174,
            213,
            129
          ],
          "color": "#AED581"
        },
        {
          "name": "400",
          "rgb": [
            156,
            204,
            101
          ],
          "color": "#9CCC65"
        },
        {
          "name": "500",
          "rgb": [
            139,
            195,
            74
          ],
          "color": "#8BC34A"
        },
        {
          "name": "600",
          "rgb": [
            124,
            179,
            66
          ],
          "color": "#7CB342"
        },
        {
          "name": "700",
          "rgb": [
            104,
            159,
            56
          ],
          "color": "#689F38"
        },
        {
          "name": "800",
          "rgb": [
            85,
            139,
            47
          ],
          "color": "#558B2F"
        },
        {
          "name": "900",
          "rgb": [
            51,
            105,
            30
          ],
          "color": "#33691E"
        },
        {
          "name": "A100",
          "rgb": [
            204,
            255,
            144
          ],
          "color": "#CCFF90"
        },
        {
          "name": "A200",
          "rgb": [
            178,
            255,
            89
          ],
          "color": "#B2FF59"
        },
        {
          "name": "A400",
          "rgb": [
            118,
            255,
            3
          ],
          "color": "#76FF03"
        },
        {
          "name": "A700",
          "rgb": [
            100,
            221,
            23
          ],
          "color": "#64DD17"
        }
      ]
    },
    {
      "name": "Lime",
      "colors": [
        {
          "name": "50",
          "rgb": [
            249,
            251,
            231
          ],
          "color": "#F9FBE7"
        },
        {
          "name": "100",
          "rgb": [
            240,
            244,
            195
          ],
          "color": "#F0F4C3"
        },
        {
          "name": "200",
          "rgb": [
            230,
            238,
            156
          ],
          "color": "#E6EE9C"
        },
        {
          "name": "300",
          "rgb": [
            220,
            231,
            117
          ],
          "color": "#DCE775"
        },
        {
          "name": "400",
          "rgb": [
            212,
            225,
            87
          ],
          "color": "#D4E157"
        },
        {
          "name": "500",
          "rgb": [
            205,
            220,
            57
          ],
          "color": "#CDDC39"
        },
        {
          "name": "600",
          "rgb": [
            192,
            202,
            51
          ],
          "color": "#C0CA33"
        },
        {
          "name": "700",
          "rgb": [
            175,
            180,
            43
          ],
          "color": "#AFB42B"
        },
        {
          "name": "800",
          "rgb": [
            158,
            157,
            36
          ],
          "color": "#9E9D24"
        },
        {
          "name": "900",
          "rgb": [
            130,
            119,
            23
          ],
          "color": "#827717"
        },
        {
          "name": "A100",
          "rgb": [
            244,
            255,
            129
          ],
          "color": "#F4FF81"
        },
        {
          "name": "A200",
          "rgb": [
            238,
            255,
            65
          ],
          "color": "#EEFF41"
        },
        {
          "name": "A400",
          "rgb": [
            198,
            255,
            0
          ],
          "color": "#C6FF00"
        },
        {
          "name": "A700",
          "rgb": [
            174,
            234,
            0
          ],
          "color": "#AEEA00"
        }
      ]
    },
    {
      "name": "Yellow",
      "colors": [
        {
          "name": "50",
          "rgb": [
            255,
            253,
            231
          ],
          "color": "#FFFDE7"
        },
        {
          "name": "100",
          "rgb": [
            255,
            249,
            196
          ],
          "color": "#FFF9C4"
        },
        {
          "name": "200",
          "rgb": [
            255,
            245,
            157
          ],
          "color": "#FFF59D"
        },
        {
          "name": "300",
          "rgb": [
            255,
            241,
            118
          ],
          "color": "#FFF176"
        },
        {
          "name": "400",
          "rgb": [
            255,
            238,
            88
          ],
          "color": "#FFEE58"
        },
        {
          "name": "500",
          "rgb": [
            255,
            235,
            59
          ],
          "color": "#FFEB3B"
        },
        {
          "name": "600",
          "rgb": [
            253,
            216,
            53
          ],
          "color": "#FDD835"
        },
        {
          "name": "700",
          "rgb": [
            251,
            192,
            45
          ],
          "color": "#FBC02D"
        },
        {
          "name": "800",
          "rgb": [
            249,
            168,
            37
          ],
          "color": "#F9A825"
        },
        {
          "name": "900",
          "rgb": [
            245,
            127,
            23
          ],
          "color": "#F57F17"
        },
        {
          "name": "A100",
          "rgb": [
            255,
            255,
            141
          ],
          "color": "#FFFF8D"
        },
        {
          "name": "A200",
          "rgb": [
            255,
            255,
            0
          ],
          "color": "#FFFF00"
        },
        {
          "name": "A400",
          "rgb": [
            255,
            234,
            0
          ],
          "color": "#FFEA00"
        },
        {
          "name": "A700",
          "rgb": [
            255,
            214,
            0
          ],
          "color": "#FFD600"
        }
      ]
    },
    {
      "name": "Amber",
      "colors": [
        {
          "name": "50",
          "rgb": [
            255,
            248,
            225
          ],
          "color": "#FFF8E1"
        },
        {
          "name": "100",
          "rgb": [
            255,
            236,
            179
          ],
          "color": "#FFECB3"
        },
        {
          "name": "200",
          "rgb": [
            255,
            224,
            130
          ],
          "color": "#FFE082"
        },
        {
          "name": "300",
          "rgb": [
            255,
            213,
            79
          ],
          "color": "#FFD54F"
        },
        {
          "name": "400",
          "rgb": [
            255,
            202,
            40
          ],
          "color": "#FFCA28"
        },
        {
          "name": "500",
          "rgb": [
            255,
            193,
            7
          ],
          "color": "#FFC107"
        },
        {
          "name": "600",
          "rgb": [
            255,
            179,
            0
          ],
          "color": "#FFB300"
        },
        {
          "name": "700",
          "rgb": [
            255,
            160,
            0
          ],
          "color": "#FFA000"
        },
        {
          "name": "800",
          "rgb": [
            255,
            143,
            0
          ],
          "color": "#FF8F00"
        },
        {
          "name": "900",
          "rgb": [
            255,
            111,
            0
          ],
          "color": "#FF6F00"
        },
        {
          "name": "A100",
          "rgb": [
            255,
            229,
            127
          ],
          "color": "#FFE57F"
        },
        {
          "name": "A200",
          "rgb": [
            255,
            215,
            64
          ],
          "color": "#FFD740"
        },
        {
          "name": "A400",
          "rgb": [
            255,
            196,
            0
          ],
          "color": "#FFC400"
        },
        {
          "name": "A700",
          "rgb": [
            255,
            171,
            0
          ],
          "color": "#FFAB00"
        }
      ]
    },
    {
      "name": "Orange",
      "colors": [
        {
          "name": "50",
          "rgb": [
            255,
            243,
            224
          ],
          "color": "#FFF3E0"
        },
        {
          "name": "100",
          "rgb": [
            255,
            224,
            178
          ],
          "color": "#FFE0B2"
        },
        {
          "name": "200",
          "rgb": [
            255,
            204,
            128
          ],
          "color": "#FFCC80"
        },
        {
          "name": "300",
          "rgb": [
            255,
            183,
            77
          ],
          "color": "#FFB74D"
        },
        {
          "name": "400",
          "rgb": [
            255,
            167,
            38
          ],
          "color": "#FFA726"
        },
        {
          "name": "500",
          "rgb": [
            255,
            152,
            0
          ],
          "color": "#FF9800"
        },
        {
          "name": "600",
          "rgb": [
            251,
            140,
            0
          ],
          "color": "#FB8C00"
        },
        {
          "name": "700",
          "rgb": [
            245,
            124,
            0
          ],
          "color": "#F57C00"
        },
        {
          "name": "800",
          "rgb": [
            239,
            108,
            0
          ],
          "color": "#EF6C00"
        },
        {
          "name": "900",
          "rgb": [
            230,
            81,
            0
          ],
          "color": "#E65100"
        },
        {
          "name": "A100",
          "rgb": [
            255,
            209,
            128
          ],
          "color": "#FFD180"
        },
        {
          "name": "A200",
          "rgb": [
            255,
            171,
            64
          ],
          "color": "#FFAB40"
        },
        {
          "name": "A400",
          "rgb": [
            255,
            145,
            0
          ],
          "color": "#FF9100"
        },
        {
          "name": "A700",
          "rgb": [
            255,
            109,
            0
          ],
          "color": "#FF6D00"
        }
      ]
    },
    {
      "name": "Deep Orange",
      "colors": [
        {
          "name": "50",
          "rgb": [
            251,
            233,
            231
          ],
          "color": "#FBE9E7"
        },
        {
          "name": "100",
          "rgb": [
            255,
            204,
            188
          ],
          "color": "#FFCCBC"
        },
        {
          "name": "200",
          "rgb": [
            255,
            171,
            145
          ],
          "color": "#FFAB91"
        },
        {
          "name": "300",
          "rgb": [
            255,
            138,
            101
          ],
          "color": "#FF8A65"
        },
        {
          "name": "400",
          "rgb": [
            255,
            112,
            67
          ],
          "color": "#FF7043"
        },
        {
          "name": "500",
          "rgb": [
            255,
            87,
            34
          ],
          "color": "#FF5722"
        },
        {
          "name": "600",
          "rgb": [
            244,
            81,
            30
          ],
          "color": "#F4511E"
        },
        {
          "name": "700",
          "rgb": [
            230,
            74,
            25
          ],
          "color": "#E64A19"
        },
        {
          "name": "800",
          "rgb": [
            216,
            67,
            21
          ],
          "color": "#D84315"
        },
        {
          "name": "900",
          "rgb": [
            191,
            54,
            12
          ],
          "color": "#BF360C"
        },
        {
          "name": "A100",
          "rgb": [
            255,
            158,
            128
          ],
          "color": "#FF9E80"
        },
        {
          "name": "A200",
          "rgb": [
            255,
            110,
            64
          ],
          "color": "#FF6E40"
        },
        {
          "name": "A400",
          "rgb": [
            255,
            61,
            0
          ],
          "color": "#FF3D00"
        },
        {
          "name": "A700",
          "rgb": [
            221,
            44,
            0
          ],
          "color": "#DD2C00"
        }
      ]
    },
    {
      "name": "Brown",
      "colors": [
        {
          "name": "50",
          "rgb": [
            239,
            235,
            233
          ],
          "color": "#EFEBE9"
        },
        {
          "name": "100",
          "rgb": [
            215,
            204,
            200
          ],
          "color": "#D7CCC8"
        },
        {
          "name": "200",
          "rgb": [
            188,
            170,
            164
          ],
          "color": "#BCAAA4"
        },
        {
          "name": "300",
          "rgb": [
            161,
            136,
            127
          ],
          "color": "#A1887F"
        },
        {
          "name": "400",
          "rgb": [
            141,
            110,
            99
          ],
          "color": "#8D6E63"
        },
        {
          "name": "500",
          "rgb": [
            121,
            85,
            72
          ],
          "color": "#795548"
        },
        {
          "name": "600",
          "rgb": [
            109,
            76,
            65
          ],
          "color": "#6D4C41"
        },
        {
          "name": "700",
          "rgb": [
            93,
            64,
            55
          ],
          "color": "#5D4037"
        },
        {
          "name": "800",
          "rgb": [
            78,
            52,
            46
          ],
          "color": "#4E342E"
        },
        {
          "name": "900",
          "rgb": [
            62,
            39,
            35
          ],
          "color": "#3E2723"
        }
      ]
    },
    {
      "name": "Grey",
      "colors": [
        {
          "name": "50",
          "rgb": [
            250,
            250,
            250
          ],
          "color": "#FAFAFA"
        },
        {
          "name": "100",
          "rgb": [
            245,
            245,
            245
          ],
          "color": "#F5F5F5"
        },
        {
          "name": "200",
          "rgb": [
            238,
            238,
            238
          ],
          "color": "#EEEEEE"
        },
        {
          "name": "300",
          "rgb": [
            224,
            224,
            224
          ],
          "color": "#E0E0E0"
        },
        {
          "name": "400",
          "rgb": [
            189,
            189,
            189
          ],
          "color": "#BDBDBD"
        },
        {
          "name": "500",
          "rgb": [
            158,
            158,
            158
          ],
          "color": "#9E9E9E"
        },
        {
          "name": "600",
          "rgb": [
            117,
            117,
            117
          ],
          "color": "#757575"
        },
        {
          "name": "700",
          "rgb": [
            97,
            97,
            97
          ],
          "color": "#616161"
        },
        {
          "name": "800",
          "rgb": [
            66,
            66,
            66
          ],
          "color": "#424242"
        },
        {
          "name": "900",
          "rgb": [
            33,
            33,
            33
          ],
          "color": "#212121"
        }
      ]
    },
    {
      "name": "Blue Grey",
      "colors": [
        {
          "name": "50",
          "rgb": [
            236,
            239,
            241
          ],
          "color": "#ECEFF1"
        },
        {
          "name": "100",
          "rgb": [
            207,
            216,
            220
          ],
          "color": "#CFD8DC"
        },
        {
          "name": "200",
          "rgb": [
            176,
            190,
            197
          ],
          "color": "#B0BEC5"
        },
        {
          "name": "300",
          "rgb": [
            144,
            164,
            174
          ],
          "color": "#90A4AE"
        },
        {
          "name": "400",
          "rgb": [
            120,
            144,
            156
          ],
          "color": "#78909C"
        },
        {
          "name": "500",
          "rgb": [
            96,
            125,
            139
          ],
          "color": "#607D8B"
        },
        {
          "name": "600",
          "rgb": [
            84,
            110,
            122
          ],
          "color": "#546E7A"
        },
        {
          "name": "700",
          "rgb": [
            69,
            90,
            100
          ],
          "color": "#455A64"
        },
        {
          "name": "800",
          "rgb": [
            55,
            71,
            79
          ],
          "color": "#37474F"
        },
        {
          "name": "900",
          "rgb": [
            38,
            50,
            56
          ],
          "color": "#263238"
        }
      ]
    }
  ]

  private latestMainColorIndex: number = -1;
  
  constructor() { }

  getColorByName(name: string): IMaterialMainColor {
    var color = null;

    if (name != null && name.length > 0) {
      color = this.colors.find(c => c.name.toLowerCase() === name.toLowerCase());
    }

    return color;
  };

  getSubColorByName(name: string, subName: string): IMaterialSubColor {
    var subColor = null;
    var color = this.getColorByName(name);

    if (color != null) {
      subColor = color.colors.find(c => c.name.toLowerCase() === subName.toLowerCase());
    }

    return subColor;
  };

  getByColorCode(hexCode: string): IMaterialSubColor {
    var subColor = null;

    for (let i = 0; i < this.colors.length; i++) {
      const mainColor = this.colors[i];
      
      subColor = mainColor.colors.find(c => c.color === hexCode);
      if (subColor != null) {
        break;
      }
    }

    return subColor;
  }

  getPalette(): IMaterialMainColor[] {
    return this.colors;
  }

  getNextMainColorForParentCategory(): IMaterialSubColor {
    let subColor: IMaterialSubColor = null;
    let nColors = this.colors.length;

    this.latestMainColorIndex += 1;
    if (this.latestMainColorIndex >= nColors) {
      this.latestMainColorIndex = 0;
    }

    let mainColor = this.colors[this.latestMainColorIndex];
    if (mainColor != null) {
      subColor = mainColor.colors.find(c => c.name === "900");
    }

    return subColor;
  }

  getNextSubColorForParentCategory(hexCode: string): IMaterialSubColor {
    let subColor: IMaterialSubColor = null;

    for (let i = 0; i < this.colors.length; i++) {
      const mainColor = this.colors[i];
      
      for (let j = 0; j < mainColor.colors.length; j++) {
        const color = mainColor.colors[j];
        
        if (color.color === hexCode) {
          // found the right color
          
          if (j === 0) {
            // our color is already a 50, 
            // we need restart from 900
            subColor = mainColor.colors[9];
          } else {
            subColor = mainColor.colors[j-1];
          }

          break;
        }
      }
    }

    return subColor;
  }

  resetMainColorIndex() {
    this.latestMainColorIndex = -1;
  }
}
