import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import * as possetupActions from './possetup.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SetupService } from 'src/app/services/setup.service';
import { MenuPlanService } from 'src/app/services/menu-plan.service';
import { SalesAreasService } from 'src/app/services/sales-areas.service';
import { PosSetupService } from 'src/app/services/pos-setup.service';
import { IPosSetup } from 'src/app/models/IPosSetup';
import { PrintersService } from 'src/app/services/printers.service';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';

@Injectable()
export class PosSetupEffects {
  constructor(
    private action$: Actions,
    private setupService: SetupService,
    private estateService: EstateService,
    private printersService: PrintersService,
    private menuPlanService: MenuPlanService,
    private salesAreasService: SalesAreasService,
    private posSetupService: PosSetupService
  ) {}

  loadPosSetup$ = createEffect(
    () => this.action$.pipe(
    ofType(possetupActions.PosSetupActionTypes.LoadPosSetup),
    mergeMap((action: possetupActions.LoadPosSetup) =>
      this.posSetupService.GetPosSetup(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((possetup: IPosSetup[]) => new possetupActions.LoadPosSetupSuccessful(possetup)),
        catchError((error: HttpErrorResponse) => of(new possetupActions.LoadPosSetupFailed(interpretateApiError(error))))
      )
    ))
  );

  savePosSetup$ = createEffect(
    () => this.action$.pipe(
    ofType(possetupActions.PosSetupActionTypes.SavePosSetup),
    mergeMap((action: possetupActions.SavePosSetup) => {
      action.newData.forEach((pos) => {
        if (pos.selectedMenu != null) {
          pos.defaultMenuGuid = pos.selectedMenu.menuGuid;
        }
      });

      return this.posSetupService
        .SavePosSetup(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.oldData, action.newData).pipe(
          map((possetup: IPosSetup[]) => new possetupActions.SavePosSetupSuccessful(possetup)),
          catchError((error: HttpErrorResponse) => of(new possetupActions.SavePosSetupFailed(interpretateApiError(error, HttpVerbType.POST), action.newData)))
        );
    }))
  );
}
