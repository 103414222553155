import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WeightMeasuresActions, WeightMeasuresActionTypes } from './weightmeasures.actions';
import { IMeasure } from 'src/app/models/IMeasure';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    weightMeasures: WeightMeasuresState;
}

export interface WeightMeasuresState extends IGardiffStoreSliceBase {
    weightMeasures: IMeasure[];
}

const initialState: WeightMeasuresState = {
    weightMeasures: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getWeightMeasuresFeatureState = createFeatureSelector<WeightMeasuresState>('weightMeasures');
export const getWeightMeasuresState = createSelector(
    getWeightMeasuresFeatureState,
    state => state
);

export const getWeightMeasures = createSelector(
    getWeightMeasuresFeatureState,
    state => state.weightMeasures
);

export const getApiError = createSelector(
  getWeightMeasuresFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getWeightMeasuresFeatureState,
  state => state.status
);

export function reducer(state: WeightMeasuresState = initialState, action: WeightMeasuresActions): WeightMeasuresState {
    switch (action.type) {
        case WeightMeasuresActionTypes.LoadWeightMeasures:
            return {
                ...state,
                weightMeasures: null,
                apiError: null,
                status: null
            };

        case WeightMeasuresActionTypes.LoadWeightMeasuresSuccessful:
            return {
                ...state,
                weightMeasures: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case WeightMeasuresActionTypes.LoadWeightMeasuresFailed:
            return {
                ...state,
                weightMeasures: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case WeightMeasuresActionTypes.SaveWeightMeasures:
            return {
                ...state,
                weightMeasures: null,
                apiError: null,
                status: null
            };

        case WeightMeasuresActionTypes.SaveWeightMeasuresSuccessful:
            return {
                ...state,
                weightMeasures: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case WeightMeasuresActionTypes.SaveWeightMeasuresFailed:
            return {
                ...state,
                weightMeasures: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case WeightMeasuresActionTypes.ResetStore:
            return {
                ...state,
                weightMeasures: initialState.weightMeasures,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
