import { ChangeType } from "../enums/ChangeType";
import { ITaxBandDTO } from "./DTOs/ITaxBandDTO";
import { IChangeState } from "./Extensions/IChangeState";
import { IItemSelectable } from "./Extensions/IItemSelectable";
import { ISavingIssue } from "./Extensions/ISavingIssue";

export interface ITaxBand extends ITaxBandDTO, IChangeState, IItemSelectable, ISavingIssue {

}

export class TaxBand implements ITaxBand {
  id: string;
  name: string;
  percentage: number;
  isSelected: boolean;
  changeState: ChangeType;
  hasSavingError: boolean;
  savingErrorDescription: string;

  constructor() {
    this.id = ''
    this.name = '';
    this.percentage = 0;
    this.isSelected = false;
    this.changeState = ChangeType.None;
    this.hasSavingError = false;
    this.savingErrorDescription = '';
  }
}
