import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import * as menusActions from './menus.actions';

import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { EstateService } from 'src/app/services/estate.service';
import { MenuPlanService } from 'src/app/services/menu-plan.service';
import { IMenu } from 'src/app/models/IMenu';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';

@Injectable()
export class MenusEffects {
  constructor(private action$: Actions,
    private estateService: EstateService,
    private menuPlanService: MenuPlanService) {

  }

  loadMenuPlan$ = createEffect(
    () => this.action$.pipe(
    ofType(menusActions.MenusActionTypes.LoadMenus),
    mergeMap((action: menusActions.LoadMenus) =>
      this.menuPlanService.GetMenuPlan(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((menus: IMenu[]) => (new menusActions.LoadMenusSuccessful(menus))),
        catchError((error: HttpErrorResponse) => of(new menusActions.LoadMenusFailed(interpretateApiError(error))))
      )
    ))
  );

  saveMenus$ = createEffect(
    () => this.action$.pipe(
    ofType(menusActions.MenusActionTypes.SaveMenus),
    mergeMap((action: menusActions.SaveMenus) =>
      this.menuPlanService.SaveMenuPlan(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.oldData, action.newData).pipe(
        map((menus: IMenu[]) => new menusActions.SaveMenusSuccessful(menus)),
        catchError((error: HttpErrorResponse) => of(new menusActions.SaveMenusFailed(interpretateApiError(error, HttpVerbType.POST), action.newData)))
      )
    ))
  );
}
