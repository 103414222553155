import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import * as monitorActions from './monitor.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CorporationsService } from 'src/app/services/corporations.service';
import { ICorporation } from 'src/app/models/ICorporation';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { EstateView } from 'src/app/models/IEstateView';

@Injectable()
export class MonitorEffects {
  constructor(private action$: Actions, private corporationsService: CorporationsService, private estateService: EstateService) {

  }

  loadCorporations$ = createEffect(
    () => this.action$.pipe(
    ofType(monitorActions.MonitorActionTypes.LoadCorporations),
    mergeMap((action: monitorActions.LoadCorporations) =>
      this.corporationsService.GetCorporations().pipe(
        map((corporations: ICorporation[]) => new monitorActions.LoadCorporationsSuccessful(corporations)),
        catchError((error: HttpErrorResponse) => of(new monitorActions.LoadCorporationsFailed(interpretateApiError(error))))
      )
    ))
  );

}
