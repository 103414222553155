import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { IAddon } from 'src/app/models/IAddon';
import { AddonsService } from 'src/app/services/addons.service';
import { EstateService } from 'src/app/services/estate.service';
import * as addonsActions from './addons.actions';

@Injectable()
export class AddonEffects {
  constructor(
    private action$: Actions,
    private estateService: EstateService,
    private addonsService: AddonsService
  ) {}

  loadAddons$ = createEffect(
    () => this.action$.pipe(
    ofType(addonsActions.AddonsActionTypes.LoadAddons),
    mergeMap((action: addonsActions.LoadAddons) =>
      this.addonsService.GetAddons(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((Addons: IAddon[]) => new addonsActions.LoadAddonsSuccessful(Addons)),
        catchError((error: HttpErrorResponse) => of(new addonsActions.LoadAddonsFailed(interpretateApiError(error))))
      )
    ))
  );

  restoreBackup$ = createEffect(
    () => this.action$.pipe(
    ofType(addonsActions.AddonsActionTypes.RestoreBackup),
    mergeMap((action: addonsActions.RestoreBackup) =>
      this.addonsService.GetBackup().pipe(
        map((Addons: IAddon[]) => new addonsActions.RestoreBackupSuccessful(Addons)),
        catchError((error) => of(new addonsActions.RestoreBackupFailed()))
      )
    ))
  );

  saveAddons$ = createEffect(
    () => this.action$.pipe(
    ofType(addonsActions.AddonsActionTypes.SaveAddons),
    mergeMap((action: addonsActions.SaveAddons) =>
      this.addonsService
        .SaveAddons(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.payload)
        .pipe(
          map((addons: IAddon[]) => new addonsActions.SaveAddonsSuccessful(addons)),
          catchError((error: HttpErrorResponse) => of(new addonsActions.SaveAddonsFailed(interpretateApiError(error, HttpVerbType.POST), action.payload)))
        )
    ))
  );
}
