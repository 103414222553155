import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import * as coursesActions from './courses.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CoursesService } from 'src/app/services/courses.service';
import { ICourse } from 'src/app/models/ICourse';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';

@Injectable()
export class CoursesEffects {
  constructor(private action$: Actions,
    private estateService: EstateService,
    private coursesService: CoursesService) {

  }

  loadCourses$ = createEffect(
    () => this.action$.pipe(
    ofType(coursesActions.CoursesActionTypes.LoadCourses),
    mergeMap((action: coursesActions.LoadCourses) =>
      this.coursesService.GetCourses(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((courses: ICourse[]) => new coursesActions.LoadCoursesSuccessful(courses)),
        catchError((error: HttpErrorResponse) => of(new coursesActions.LoadCoursesFailed(interpretateApiError(error))))
      )
    ))
  );

  saveCourses$ = createEffect(
    () => this.action$.pipe(
    ofType(coursesActions.CoursesActionTypes.SaveCourses),
    mergeMap((action: coursesActions.SaveCourses) =>
      this.coursesService.SaveCourses(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.oldData, action.newData).pipe(
        map((courses: ICourse[]) => new coursesActions.SaveCoursesSuccessful(courses)),
        catchError((error: HttpErrorResponse) => of(new coursesActions.SaveCoursesFailed(interpretateApiError(error, HttpVerbType.POST), action.newData)))
      )
    ))
  );
}
