import { PaymentsReportGroupOptionType } from 'src/app/enums/PaymentsReportGroupOptionType';

export interface IPaymentsReportDefinitionDTO {
  filterCategoryGuid: string;
  filterCourseGuid: string;
  filterParentCategoryGuid: string;
  filterPosDeviceGuid: string;
  filterUserGuid: string;
  hideGroupDetail: boolean;
  mainGroup: PaymentsReportGroupOptionType;
  salesDateFrom: Date;
  salesDateTo: Date;
  timeZone: string;
}

export class PaymentsReportDefinitionDTO implements IPaymentsReportDefinitionDTO {
  filterCategoryGuid: string;
  filterCourseGuid: string;
  filterParentCategoryGuid: string;
  filterPosDeviceGuid: string;
  filterUserGuid: string;
  hideGroupDetail: boolean;
  mainGroup: PaymentsReportGroupOptionType;
  salesDateFrom: Date;
  salesDateTo: Date;
  timeZone: string;

  constructor() {
    this.filterCategoryGuid = '00000000-0000-0000-0000-000000000000';
    this.filterCourseGuid = '00000000-0000-0000-0000-000000000000';
    this.filterParentCategoryGuid = '00000000-0000-0000-0000-000000000000';
    this.filterPosDeviceGuid = '00000000-0000-0000-0000-000000000000';
    this.filterUserGuid = '00000000-0000-0000-0000-000000000000';
    this.hideGroupDetail = false;
    this.mainGroup = PaymentsReportGroupOptionType.None;
    this.salesDateFrom = new Date();
    this.salesDateTo = new Date();
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
