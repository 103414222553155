import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { LoginService } from './login.service';
import { environment } from '../../environments/environment';

import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { IEstateView } from 'src/app/models/IEstateView';
import { Brand, IBrand } from 'src/app/models/IBrand';
import { IArea } from 'src/app/models/IArea';
import { ISite } from 'src/app/models/ISite';
import { ISiteDetails } from '../models/ISiteDetails';
import { IMonitorEnvelope } from '../models/IMonitorEnvelope';
import { ISiteDetailsDTO } from '../models/DTOs/ISiteDetailsDTO';
import { ChangeType } from '../enums/ChangeType';

const APIEndpoint = environment.APIEndpoint;
@Injectable({
  providedIn: 'root'
})
export class EstateService  {
  private localStorageEstateProperty = 'estateView';
  private localStorageSelectedBrandProperty = 'selectedBrand';
  private localStorageSelectedAreaProperty = 'selectedAread';
  private localStorageSelectedSiteProperty = 'selectedSited';

  public set SelectedBrand(value: IBrand) {
    if (value != null) {
      this.storeSelectedBrand(value);
    } else {
      this.deleteSelectedBrand();
    }
  }
  public get SelectedBrand(): IBrand {
    return this.getStoredSelectedBrand();
  }

  public set SelectedArea(value: IArea) {
    if (value != null) {
      this.storeSelectedArea(value);
    } else {
      this.deleteSelectedArea();
    }
  }
  public get SelectedArea(): IArea {
    return this.getStoredSelectedArea();
  }

  public set SelectedSite(value: ISite) {
    if (value != null) {
      this.storeSelectedSite(value);
    } else {
      this.deleteSelectedSite();
    }
  }
  public get SelectedSite(): ISite {
    return this.getStoredSelectedSite();
  }

  public get CorporationID(): string {
    let bResult = '';

    if (this.estateView != null && this.estateView.corporationId !== '') {
      bResult = this.estateView.corporationId;
    }

    return bResult;
  }

  private set estateView(value: IEstateView) {
    if (value != null) {
      this.storeEstateView(value);
    } else {
      this.deleteEstateView();
    }
  }
  private get estateView(): IEstateView {
    return this.getStoredEstateView();
  }

  constructor(private http: HttpClient, private loginService: LoginService) {

  }

  getUserEstate(): Observable<IEstateView> {
    // check if the user is logged in
    if (this.loginService.IsLoggedIn()) {

      if (this.loginService.AccessTokenPayload.role === 'ClientUser' || this.loginService.AccessTokenPayload.role === 'SupportClientUser') {
        // normal user

        return this.http.get<IEstateView>(APIEndpoint + 'frontend/estateview/', {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.loginService.AccessToken.authToken )
        }).pipe(
          map((data: IEstateView) => {
            const view = this.sortEstateView(data);

            this.estateView = view;
            return view;
          }),
          catchError((err: HttpErrorResponse) => {
            console.log('estate service error : ' + JSON.stringify(err.message));

            return throwError(() => err.message);
          })
        );

      } else {
        // Developers, Admins, Support
        if (this.estateView != null) {
          //console.log('GEtting Dummy Estate View');
          return this.getMonitorEstateView(this.estateView.corporationId).pipe(
            map(data => {
              return data;
            })
          );
        }
      }


    } else {
      // return a null object
      return new Observable<IEstateView>().pipe(map(() => null));
    }

  }

  private sortEstateView(estate: IEstateView): IEstateView {
    estate.brands = estate.brands.sort((brand1: IBrand, brand2: IBrand) => {
      if (brand1.brandName.toLowerCase() > brand2.brandName.toLowerCase()) {
        return 1;
      }

      if (brand1.brandName.toLowerCase() < brand2.brandName.toLowerCase()) {
        return -1;
      }
    });

    estate.brands.forEach(brand => {
      brand.areas = brand.areas.sort((area1: IArea, area2: IArea) => {
        if (area1.areaName.toLowerCase() > area2.areaName.toLowerCase()) {
          return 1;
        }

        if (area1.areaName.toLowerCase() < area2.areaName.toLowerCase()) {
          return -1;
        }
      });

      brand.areas.forEach(area => {
        area.sites = area.sites.sort((site1: ISite, site2: ISite) => {
          if (site1.siteName.toLowerCase() > site2.siteName.toLowerCase()) {
            return 1;
          }

          if (site1.siteName.toLowerCase() < site2.siteName.toLowerCase()) {
            return -1;
          }
        });
      });

    });

    return estate;
  }

  getInitialSite(): Observable<IEstateView> {
      let BrandID = '';
      let AreaID = '';
      let SiteID = '';

      if (this.estateView != null && this.estateView.corporationId !== '') {
        if (this.SelectedBrand != null) { BrandID = this.SelectedBrand.brandId; }
        if (this.SelectedArea != null) { AreaID = this.SelectedArea.areaId; }
        if (this.SelectedSite != null) { SiteID = this.SelectedSite.siteId; }

        //now we saved the old selection
      }

      return this.getUserEstate().pipe(
        map((data: IEstateView) => {

          this.estateView = data;
          if (!this.setOldSelection(BrandID, AreaID, SiteID)) {
            this.selectFirstSite();
          }

          return this.estateView;
      }), catchError((err: HttpErrorResponse) => {
        this.SelectedBrand = null;
        this.SelectedArea = null;
        this.SelectedSite = null;

        return throwError(() => err.message);
      }));
    //}

  }

  getSiteDetails(corporationId: string, siteId: string): Observable<ISiteDetails> {

    // check if the user is logged in
    if (this.loginService.IsLoggedIn()) {

      const Token: string = this.loginService.AccessToken.authToken;
      const endPoint: string = APIEndpoint + 'frontend/corporation/'+ corporationId + '/site/' + siteId;

      // tslint:disable-next-line: max-line-length
      return this.http.get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<ISiteDetailsDTO>) => {

          if (data.ok) {
            return this.formatData(data.body);
          }
          
        }), catchError((err: HttpErrorResponse) => {
          return throwError(() => err.message);
        }));

    } else {
      // return a null object
      return new Observable<IEstateView>().pipe(map(() => null));
    }

  }

  private formatData(data: ISiteDetailsDTO): ISiteDetails {
    return {
      id: data.id,
      siteGuid: data.siteGuid,
      companyName: data.companyName,
      siteName: data.siteName,
      siteId: data.siteId,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      addressLine3: data.addressLine3,
      town: data.town,
      postCode: data.postCode,
      county: data.county,
      phonePrimary: data.phonePrimary,
      phoneSecondary: data.phoneSecondary,
      emailPrimary: data.emailPrimary,
      emailSecondary: data.emailSecondary,
      website: data.website,
      vatNumber: data.vatNumber,
      changeState: ChangeType.None,
    }
  }

  saveSiteDetails(corporationId: string, siteInfo: ISiteDetails): Observable<ISiteDetails> {

    // check if the user is logged in
    if (this.loginService.IsLoggedIn()) {

      const Token: string = this.loginService.AccessToken.authToken;
      const endPoint: string = APIEndpoint + 'frontend/corporation/'+ corporationId + '/site/' + siteInfo.siteId;
      const data = this.deflateData(siteInfo);

      // tslint:disable-next-line: max-line-length
      return this.http.post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<ISiteDetailsDTO>) => {
          
          if (data.ok) {
            return this.formatData(data.body);
          }
          
        }), catchError((err: HttpErrorResponse) => {
          console.log('site details error : ' + JSON.stringify(err.message));

          return throwError(() => err.message);
        }));

    } else {
      // return a null object
      return new Observable<IEstateView>().pipe(map(() => null));
    }

  }

  private deflateData(data: ISiteDetails): ISiteDetailsDTO {
    return {
      id: data.id,
      siteGuid: data.siteGuid,
      companyName: data.companyName,
      siteName: data.siteName,
      siteId: data.siteId,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      addressLine3: data.addressLine3,
      town: data.town,
      postCode: data.postCode,
      county: data.county,
      phonePrimary: data.phonePrimary,
      phoneSecondary: data.phoneSecondary,
      emailPrimary: data.emailPrimary,
      emailSecondary: data.emailSecondary,
      website: data.website,
      vatNumber: data.vatNumber
    }
  }

  private selectFirstSite(): ISite {
    let result: ISite = null;

    if (this.estateView != null && this.estateView.brands != null && this.estateView.brands.length > 0) {
      this.SelectedBrand = this.estateView.brands[0];

      if (this.SelectedBrand != null && this.SelectedBrand.areas != null && this.SelectedBrand.areas.length > 0) {
        this.SelectedArea = this.SelectedBrand.areas[0];

        if (this.SelectedArea != null && this.SelectedArea.sites != null && this.SelectedArea.sites.length > 0) {
          this.SelectedSite = this.SelectedArea.sites[0];

          result = this.SelectedSite;
        }
      }
    }

    return result;
  }

  public IsEstateViewValid(): boolean {
    let bResult = false;

    if (this.estateView != null && this.estateView.corporationId !== '') {
      bResult = true;
    }

    return bResult;
  }

  private setOldSelection(BrandID: string, AreaID: string, SiteID: string): boolean {
    let bResult = false;

    if (this.estateView != null && this.estateView.corporationId !== '' && BrandID !== '' && AreaID !== '' && SiteID !== '') {
      this.estateView.brands.forEach((brand: IBrand) => {
        if (brand.brandId === BrandID) {

          // we found the old brand
          brand.areas.forEach((area: IArea) => {
            if (area.areaId === AreaID) {

              // we found the old area
              area.sites.forEach((site: ISite) => {
                if (site.siteId === SiteID) {

                  // we found the old site
                  bResult = true;

                }
              });
            }
          });
        }
      });
    }

    return bResult;
  }

  getMonitorEstateView(CorporationID: string): Observable<IEstateView> {
    // check if the user is logged in
    if (this.loginService.IsLoggedIn()) {

      const endpoint = APIEndpoint + 'frontend/gardiff/GenerateEstateView/' + CorporationID;

      return this.http.get<IEstateView>(endpoint, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.loginService.AccessToken.authToken )
      }).pipe(
        map((data: IEstateView) => {
          //console.log('new object: ' + JSON.stringify(data));
          const view = this.sortEstateView(data);

          this.estateView = view;
          return view;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('estate service error : ' + JSON.stringify(err.message));

          return throwError(() => err.message);
        })
      );

    } else {
      // return a null object
      return new Observable<IEstateView>().pipe(map(() => null));
    }

  }

  clearSelectionCache() { 
    this.deleteEstateView();
  }

  //#region Store Estate View in localstorage
  private storeEstateView(estate: IEstateView) {
    if (estate != null) {
      localStorage.setItem(this.localStorageEstateProperty, JSON.stringify(estate));
    }
  }

  private getStoredEstateView(): IEstateView {
    const storedEstateView: IEstateView = JSON.parse(localStorage.getItem(this.localStorageEstateProperty));

    return storedEstateView;
  }

  private deleteEstateView() {
    localStorage.removeItem(this.localStorageEstateProperty);
  }
  //#endregion

  //#region Store SelectedBrand View in localstorage
  private storeSelectedBrand(brand: IBrand) {
    if (brand != null) {
      localStorage.setItem(this.localStorageSelectedBrandProperty, JSON.stringify(brand));
    }
  }

  private getStoredSelectedBrand(): IBrand {
    const storedBrand: IBrand = JSON.parse(localStorage.getItem(this.localStorageSelectedBrandProperty));

    return storedBrand;
  }

  private deleteSelectedBrand() {
    localStorage.removeItem(this.localStorageSelectedBrandProperty);
  }
  //#endregion

  //#region Store SelectedArea View in localstorage
  private storeSelectedArea(area: IArea) {
    if (area != null) {
      localStorage.setItem(this.localStorageSelectedAreaProperty, JSON.stringify(area));
    }
  }

  private getStoredSelectedArea(): IArea {
    const storedArea: IArea = JSON.parse(localStorage.getItem(this.localStorageSelectedAreaProperty));

    return storedArea;
  }

  private deleteSelectedArea() {
    localStorage.removeItem(this.localStorageSelectedAreaProperty);
  }
  //#endregion

  //#region Store SelectedSite View in localstorage
  private storeSelectedSite(site: ISite) {
    if (site != null) {
      localStorage.setItem(this.localStorageSelectedSiteProperty, JSON.stringify(site));
    }
  }

  private getStoredSelectedSite(): ISite {
    const storedSite: ISite = JSON.parse(localStorage.getItem(this.localStorageSelectedSiteProperty));

    return storedSite;
  }

  private deleteSelectedSite() {
    localStorage.removeItem(this.localStorageSelectedSiteProperty);
  }
  //#endregion
}
