import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CoursesActions, CoursesActionTypes } from './courses.actions';
import { ICourse } from 'src/app/models/ICourse';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    courses: CoursesState;
}

export interface CoursesState extends IGardiffStoreSliceBase {
    courses: ICourse[];
}

const initialState: CoursesState = {
    courses: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getCoursesFeatureState = createFeatureSelector<CoursesState>('courses');
export const getCoursesState = createSelector(
    getCoursesFeatureState,
    state => state
);

export const getCourses = createSelector(
    getCoursesFeatureState,
    state => state.courses
);

export const getApiError = createSelector(
  getCoursesFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getCoursesFeatureState,
  state => state.status
);

export function reducer(state: CoursesState = initialState, action: CoursesActions): CoursesState {
    switch (action.type) {
        case CoursesActionTypes.LoadCourses:
            return {
                ...state,
                courses: null,
                apiError: null,
                status: null
            };

        case CoursesActionTypes.LoadCoursesSuccessful:
            return {
                ...state,
                courses: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case CoursesActionTypes.LoadCoursesFailed:
            return {
                ...state,
                courses: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case CoursesActionTypes.SaveCourses:
            return {
                ...state,
                courses: null,
                apiError: null,
                status: null
            };

        case CoursesActionTypes.SaveCoursesSuccessful:
            return {
                ...state,
                courses: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case CoursesActionTypes.SaveCoursesFailed:
            return {
                ...state,
                courses: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case CoursesActionTypes.ResetStore:
            return {
                ...state,
                courses: initialState.courses,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
