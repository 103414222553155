import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import { ITablePlanArea } from 'src/app/models/ITablePlanArea';
import { TablePlanActions, TablePlanActionTypes } from './tableplan.actions';

export interface State extends fromRoot.State {
  tablePlan: TablePlanState;
}

export interface TablePlanState extends IGardiffStoreSliceBase {
  tablePlan: ITablePlanArea[];
}

const initialState: TablePlanState = {
  tablePlan: [],
  apiError: null,
  status: new GardiffStoreSliceStatus()
};

// create the selectors
const getTablePlanFeatureState = createFeatureSelector<TablePlanState>('tablePlan');
export const getTablePlanState = createSelector(
  getTablePlanFeatureState,
  state => state
);

export const getTablePlan = createSelector(
  getTablePlanFeatureState,
  state => state.tablePlan
);

export const getApiError = createSelector(
getTablePlanFeatureState,
state => state.apiError
);

export const getStatus = createSelector(
getTablePlanFeatureState,
state => state.status
);

export function reducer(state: TablePlanState = initialState, action: TablePlanActions): TablePlanState {
  switch (action.type) {
      case TablePlanActionTypes.LoadTablePlan:
          return {
              ...state,
              tablePlan: null,
              apiError: null,
              status: null
          };

      case TablePlanActionTypes.LoadTablePlanSuccessful:
          return {
              ...state,
              tablePlan: action.payload,
              apiError: null,
              status: new GardiffStoreSliceStatus(true)
          };

      case TablePlanActionTypes.LoadTablePlanFailed:
          return {
              ...state,
              tablePlan: [],
              apiError: action.payload,
              status: new GardiffStoreSliceStatus()
          };

      case TablePlanActionTypes.SaveTablePlan:
          return {
              ...state,
              tablePlan: null,
              apiError: null,
              status: null
          };

      case TablePlanActionTypes.SaveTablePlanSuccessful:
          return {
              ...state,
              tablePlan: action.payload,
              apiError: null,
              status: new GardiffStoreSliceStatus(true)
          };

      case TablePlanActionTypes.SaveTablePlanFailed:
          return {
              ...state,
              tablePlan: action.oldData,
              apiError: action.error,
              status: new GardiffStoreSliceStatus()
          };

      case TablePlanActionTypes.ResetStore:
          return {
              ...state,
              tablePlan: initialState.tablePlan,
              apiError: initialState.apiError,
              status: initialState.status
          };

      default:
          return state;
  }
}
