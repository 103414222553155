import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeType } from '../enums/ChangeType';
import { getTextColorContrast } from '../feature-modules/shared/colorUtils';
import { empty_ObjectId } from '../feature-modules/shared/constants';
import { checkHexColor } from '../helpers/ColorHelper';
import { ICategoryDTO } from '../models/DTOs/ICategoryDTO';
import { IEnvelopeOutDTO } from '../models/DTOs/IEnvelopeOutDTO';
import { IParentCategoryDTO } from '../models/DTOs/IParentCategoryDTO';
import { IParentCategoryItemDTO } from '../models/DTOs/IParentCategoryItemDTO';
import { ICategory } from '../models/ICategory';
import { IParentCategory } from '../models/IParentCategory';
import { IParentCategoryItem } from '../models/IParentCategoryItem';
import { LoginService } from './login.service';
import { IParentCategorySlim, ParentCategorySlim } from '../models/IParentCategorySlim';
import { MaterialColorPaletteService } from './material-color-palette.service';
import { IEditDataList } from '../models/DTOs/IEditDataList';
import { ITaxBand } from '../models/ITaxBand';
import { ITaxBandDTO } from '../models/DTOs/ITaxBandDTO';

@Injectable({
  providedIn: 'root'
})
export class ParentCategoriesService {
  private ParentCategoriesEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/ParentCategories';
  private ParentCategoriesFlatListEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/ParentCategories/FlatList';

  constructor(private loginService: LoginService, private http: HttpClient, private materialColors: MaterialColorPaletteService) { }

  GetParentCategories(CorporationID: string, SiteID: string): Observable<IParentCategory[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.ParentCategoriesEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http
        .get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IParentCategoryDTO[]>) => {

            if (data.ok) {
              // reset the service for the Color palette
              this.materialColors.resetMainColorIndex();

              // format and return the data
              return this.formatData(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  private formatData(dataList: IParentCategoryDTO[], mainColor: string = null): IParentCategory[] {
    let list: IParentCategory[] = [];

    // convert list
    dataList.forEach(item => {
      let parentCategoryColor: string = null;
      let textColor: string = null;

      if (mainColor == null) {
        // it's a TopLevel category, we need to get a new color
        parentCategoryColor = this.materialColors.getNextMainColorForParentCategory().color;
      } else {
        parentCategoryColor = this.materialColors.getNextSubColorForParentCategory(mainColor).color;
      }

      textColor = getTextColorContrast(parentCategoryColor);

      list.push({
        id: item.id,
        description: item.description,
        parentCategoryGuid: item.parentCategoryGuid,
        parentCategories: this.formatData(item.parentCategories, parentCategoryColor),
        categories: this.formatCategoryData(item.categories),
        color: parentCategoryColor,
        colorText: textColor,
        isSelected: false,
        changeState: ChangeType.None
      });
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: IParentCategory[]): IParentCategory[] {
    return list.sort((Item1: IParentCategory, Item2: IParentCategory) => {
      if (Item1.description.toLowerCase() > Item2.description.toLowerCase()) {
        return 1;
      }

      if (Item1.description.toLowerCase() < Item2.description.toLowerCase()) {
        return -1;
      }
    });
  }

  private formatCategoryData(dataList: ICategoryDTO[]): ICategory[] {
    let list: ICategory[] = [];

    // convert list
    dataList.forEach(item => {
      let parentCategorySlim: IParentCategorySlim = null
      let taxBand: ITaxBand = null

      if (item.parentCategory != null){
        parentCategorySlim = {
          id: item.parentCategory.id,
          parentCategoryGuid: item.parentCategory.parentCategoryGuid,
          description: item.parentCategory.description
        }
      }

      if (item.taxBand != null) {
        taxBand = {
          id: item.taxBand.id,
          name: item.taxBand.name,
          percentage: item.taxBand.percentage,
          isSelected: false,
          changeState: ChangeType.None,
          hasSavingError: false,
          savingErrorDescription: ''
        }
      }

      list.push({
        id: item.id,
        categoryGuid: item.categoryGuid,
        parentCategory: parentCategorySlim,
        taxBand: taxBand,
        categoryName: item.categoryName,
        colorCode: checkHexColor(item.colorCode),
        colorCodeText: getTextColorContrast(item.colorCode),
        isActive: item.isActive,
        measurements: [],
        isSelected: false,
        changeState: ChangeType.None
      });
    });

    // sort data
    list = this.sortCategoryData(list);

    return list;
  }

  private sortCategoryData(list: ICategory[]): ICategory[] {
    return list.sort((Item1: ICategory, Item2: ICategory) => {
      if (Item1.categoryName.toLowerCase() > Item2.categoryName.toLowerCase()) {
        return 1;
      }

      if (Item1.categoryName.toLowerCase() < Item2.categoryName.toLowerCase()) {
        return -1;
      }
    });
  }

  SaveParentCategories(CorporationID: string, SiteID: string, Data: IParentCategory[]): Observable<IParentCategory[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.ParentCategoriesEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IParentCategoryDTO[] = this.DeflateItems(Data);

      return this.http
        .post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IParentCategoryDTO[]>) => {

            if (data.ok) {
              // reset the service for the Color palette
              this.materialColors.resetMainColorIndex();

              // format and return the data
              return this.formatData(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        );
    }
  }

  // Methods dedicate for Parent Categories Flat List endPoint
  GetParentCategoriesFlatList(CorporationID: string, SiteID: string): Observable<IParentCategoryItem[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.ParentCategoriesFlatListEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http.get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<IParentCategoryItemDTO[]>) => {

          if (data.ok) {
            return this.formatFlatListData(data.body);
          }

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private formatFlatListData(dataList: IParentCategoryItemDTO[]): IParentCategoryItem[] {
    const list: IParentCategoryItem[] = [];

    if (dataList != null) {
      dataList.forEach(parent => {
        list.push({
          id: parent.id,
          description: parent.description,
          parentCategoryGuid: parent.parentCategoryGuid,
          parentParentCategoryGuid: parent.parentCategoryGuid
        });
      });
    }

    return this.sortFlatListData(list);
  }

  private sortFlatListData(list: IParentCategoryItem[]): IParentCategoryItem[] {
    list.sort((Item1: IParentCategoryItem, Item2: IParentCategoryItem) => {
      if (Item1.description > Item2.description) {
        return 1;
      }

      if (Item1.description < Item2.description) {
        return -1;
      }
    });

    return list;
  }

  private DeflateItems(list: IParentCategory[]): IParentCategoryDTO[] {
    const newData: IParentCategoryDTO[] = [];

    if (list != null) {
      list.forEach(item => {

        let newItem: IParentCategoryDTO = {
          id: item.id,
          parentCategoryGuid: item.parentCategoryGuid,
          description: item.description,
          categories: [],
          parentCategories: []
        };



        // deflating the categories for the Parent Category
        item.categories.forEach(categoryModel => {
          newItem.categories.push({
            id: categoryModel.id,
            categoryGuid: categoryModel.categoryGuid,
            categoryName: categoryModel.categoryName,
            colorCode: categoryModel.colorCode,
            isActive: categoryModel.isActive,
            measurements: [],
            parentCategory: {
              id: newItem.id,
              description: newItem.description,
              parentCategoryGuid: newItem.parentCategoryGuid
            },
            taxBand: null
          })
        })

        newItem.parentCategories = this.DeflateItems(item.parentCategories);

        newData.push(newItem);
      });
    }

    return newData;
  }


}
