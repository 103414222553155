export enum VoidReportField
{
  None,
  DisplayName,
  Quantity,
  LineItemTotalPrice,
  CreatedDate,
  CreatedByUser,
  CreatedByPos,
  VoidedDate,
  VoidedByUser,
  VoidedByPos
}

export const VoidReportFieldLabel = new Map<number, string>([
  [VoidReportField.None, 'None'],
  [VoidReportField.DisplayName, 'Product'],
  [VoidReportField.Quantity, 'Quantity'],
  [VoidReportField.LineItemTotalPrice, 'Price'],
  [VoidReportField.CreatedDate, 'Date Added'],
  [VoidReportField.CreatedByUser, 'Added By User'],
  [VoidReportField.CreatedByPos, 'Added On Pos'],
  [VoidReportField.VoidedDate, 'Date Voided'],
  [VoidReportField.VoidedByUser, 'Voided By User'],
  [VoidReportField.VoidedByPos, 'Voided On Pos']
]);
