import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChangeType } from '../enums/ChangeType';
import { checkHexColor } from '../helpers/ColorHelper';
import { IEditDataList, EditDataList } from '../models/DTOs/IEditDataList';
import { IMenuDTO, MenuDTO } from '../models/DTOs/IMenuDTO';
import { IMenuItemDTO } from '../models/DTOs/IMenuItemDTO';
import { IMenu } from '../models/IMenu';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class LogonService {
  private LogonEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/LogOnScreen';

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetLogOnScreen(CorporationID: string, SiteID: string): Observable<IMenu> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.LogonEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http.get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<IMenuDTO>) => {
          
          if (data.ok) {
            return this.formatData(data.body);
          }
          
        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private formatData(logOnScreenDTO: IMenuDTO): IMenu {
    let logOnScreen: IMenu = this.ConvertMenu(logOnScreenDTO);

    return logOnScreen;
  }

  private ConvertMenu(item: IMenuDTO): IMenu {
    const newItem: IMenu = {
      id: item.id,
      menuGuid: item.menuGuid,
      description: item.description,
      isFood: item.isFood,
      numberOfColumns: item.numberOfColumns,
      isSubMenu: item.isSubMenu,
      menuOrder: item.menuOrder,
      items: [],
      isSelected: false,
      changeState: ChangeType.None
    };

    if (item.items != null) {
      item.items.forEach(menuItem => {
        newItem.items.push({
          id: menuItem.id,
          menuItemGuid: menuItem.menuItemGuid,
          menuItemType: menuItem.menuItemType,
          itemGuid: menuItem.itemGuid,
          colour: checkHexColor(menuItem.colour),
          menuItemMeasureGuid: menuItem.menuItemMeasureGuid,
          menuItemOrder: menuItem.menuItemOrder,
          description: '',
          colorText: '#fff',
          isActive: true,
          isSelected: false
        });
      });
    }

    return newItem;
  }

  SaveLogonScreen(CorporationID: string, SiteID: string, newData: IMenu): Observable<IMenu> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.LogonEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IMenuDTO = this.DeflateData(newData);

      return this.http.post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<IMenuDTO>) => {
          
          if (data.ok) {
            return this.formatData(data.body);
          }

        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private DeflateData(data: IMenu): IMenuDTO {
    let newData: IMenuDTO;

    if (data != null) {

      newData = {
        id: data.id,
        menuGuid: data.menuGuid,
        description: data.description,
        isFood: data.isFood,
        numberOfColumns: data.numberOfColumns,
        isSubMenu: data.isSubMenu,
        menuOrder: data.menuOrder,
        items: []
      }

      data.items.forEach(menuItem => {
        if (newData.id !== '' && menuItem.menuItemGuid !== '') {
          newData.items.push({
            id: menuItem.id,
            menuItemGuid: menuItem.menuItemGuid,
            menuItemType: menuItem.menuItemType,
            itemGuid: menuItem.itemGuid,
            colour: menuItem.colour,
            menuItemMeasureGuid: menuItem.menuItemMeasureGuid,
            menuItemOrder: menuItem.menuItemOrder
          });
        }
      });
    }

    return newData;
  }

}
