import { Action } from '@ngrx/store';
import { ICategory } from 'src/app/models/ICategory';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';

export enum CategoriesActionTypes {
    LoadCategories = '[Categories] Load Categories',
    LoadCategoriesSuccessful = '[Categories] Load Categories - Successful',
    LoadCategoriesFailed = '[Categories] Load Categories - Failed',

    SaveCategories = '[Categories] Save Categories',
    SaveCategoriesSuccessful = '[Categories] Save Categories - Successful',
    SaveCategoriesFailed = '[Categories] Save Categories - Failed',

    SaveCategoriesWithoutTax = '[Categories] Save Categories (No Tax)',

    RestoreBackup = '[Categories] Restore Backup',
    RestoreBackupSuccessful = '[Categories] Restore Backup - Successful',
    RestoreBackupFailed = '[Categories] Restore Backup - Failed',

    ResetStore = '[Categories] Reset Store',
}

export class LoadCategories implements Action {
    readonly type = CategoriesActionTypes.LoadCategories;

    constructor() { }
}

export class LoadCategoriesSuccessful implements Action {
    readonly type = CategoriesActionTypes.LoadCategoriesSuccessful;

    constructor(public payload: ICategory[]) { }
}

export class LoadCategoriesFailed implements Action {
    readonly type = CategoriesActionTypes.LoadCategoriesFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveCategories implements Action {
  readonly type = CategoriesActionTypes.SaveCategories;

  constructor(public payload: ICategory[]) { }
}

export class SaveCategoriesSuccessful implements Action {
  readonly type = CategoriesActionTypes.SaveCategoriesSuccessful;

  constructor(public payload: ICategory[]) { }
}

export class SaveCategoriesFailed implements Action {
  readonly type = CategoriesActionTypes.SaveCategoriesFailed;

  constructor(public error: IGenericError, public oldData: ICategory[]) { }
}

export class SaveCategoriesWithoutTax implements Action {
  readonly type = CategoriesActionTypes.SaveCategoriesWithoutTax;

  constructor(public payload: ICategory[]) { }
}

export class RestoreBackup implements Action {
  readonly type = CategoriesActionTypes.RestoreBackup;

  constructor() { }
}

export class RestoreBackupSuccessful implements Action {
  readonly type = CategoriesActionTypes.RestoreBackupSuccessful;

  constructor(public payload: ICategory[]) { }
}

export class RestoreBackupFailed implements Action {
  readonly type = CategoriesActionTypes.RestoreBackupFailed;

  constructor() { }
}

export class ResetStore implements Action {
    readonly type = CategoriesActionTypes.ResetStore;

    constructor() { }
}

export type CategoriesActions = LoadCategories
    | LoadCategoriesSuccessful
    | LoadCategoriesFailed
    | SaveCategories
    | SaveCategoriesSuccessful
    | SaveCategoriesFailed
    | SaveCategoriesWithoutTax
    | RestoreBackup
    | RestoreBackupSuccessful
    | RestoreBackupFailed
    | ResetStore;
