import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DiscountsActions, DiscountsActionTypes } from './discounts.actions';
import { IDiscount } from 'src/app/models/IDiscount';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    discounts: DiscountsState;
}

export interface DiscountsState extends IGardiffStoreSliceBase {
    discounts: IDiscount[];
}

const initialState: DiscountsState = {
    discounts: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getDiscountsFeatureState = createFeatureSelector<DiscountsState>('discounts');
export const getDiscountsState = createSelector(
    getDiscountsFeatureState,
    state => state
);

export const getDiscounts = createSelector(
    getDiscountsFeatureState,
    state => state.discounts
);

export const getApiError = createSelector(
  getDiscountsFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getDiscountsFeatureState,
  state => state.status
);

export function reducer(state: DiscountsState = initialState, action: DiscountsActions): DiscountsState {
    switch (action.type) {
        case DiscountsActionTypes.LoadDiscounts:
            return {
                ...state,
                discounts: null,
                apiError: null,
                status: null
            };

        case DiscountsActionTypes.LoadDiscountsSuccessful:
            return {
                ...state,
                discounts: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case DiscountsActionTypes.LoadDiscountsFailed:
            return {
                ...state,
                discounts: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case DiscountsActionTypes.SaveDiscounts:
            return {
                ...state,
                discounts: null,
                apiError: null,
                status: null
            };

        case DiscountsActionTypes.SaveDiscountsSuccessful:
            return {
                ...state,
                discounts: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case DiscountsActionTypes.SaveDiscountsFailed:
            return {
                ...state,
                discounts: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case DiscountsActionTypes.RestoreBackup:
            return {
                ...state,
                discounts: null
            };

        case DiscountsActionTypes.RestoreBackupSuccessful:
            return {
                ...state,
                discounts: action.payload
            };

        case DiscountsActionTypes.RestoreBackupFailed:
            return {
                ...state,
                discounts: []
            };

        case DiscountsActionTypes.ResetStore:
            return {
                ...state,
                discounts: initialState.discounts,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
