import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMenu, Menu } from 'src/app/models/IMenu';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import { LogonActionTypes, LogonScreenActions } from './logon.actions';

export interface State extends fromRoot.State {
    LogonScreen: LogonScreenState;
}

export interface LogonScreenState extends IGardiffStoreSliceBase {
    logon: IMenu;
}

const initialState: LogonScreenState = {
    logon: new Menu(),
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getLogonScreenFeatureState = createFeatureSelector<LogonScreenState>('logonScreen');
export const getLogonScreenState = createSelector(
    getLogonScreenFeatureState,
    state => state
);

export const getLogonScreen = createSelector(
    getLogonScreenFeatureState,
    state => state.logon
);

export const getApiError = createSelector(
  getLogonScreenFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getLogonScreenFeatureState,
  state => state.status
);

export function reducer(state: LogonScreenState = initialState, action: LogonScreenActions): LogonScreenState {
    switch (action.type) {

        case LogonActionTypes.LoadLogonScreen:
            return {
                ...state,
                logon: null,
                apiError: null,
                status: null
            };

        case LogonActionTypes.LoadLogonScreenSuccessful:
            return {
                ...state,
                logon: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case LogonActionTypes.LoadLogonScreenFailed:
            return {
                ...state,
                logon: new Menu(),
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case LogonActionTypes.SaveLogonScreen:
            return {
                ...state,
                logon: null,
                apiError: null,
                status: null
            };

        case LogonActionTypes.SaveLogonScreenSuccessful:
            return {
                ...state,
                logon: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case LogonActionTypes.SaveLogonScreenFailed:
            return {
                ...state,
                logon: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case LogonActionTypes.ResetStore:
            return {
                ...state,
                logon: initialState.logon,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
