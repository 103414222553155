import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import * as PaymentsReportActions from './payments-report.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { of } from 'rxjs';
import { PaymentsReportService } from 'src/app/services/payments-report.service';
import { IPaymentsReportDocument } from 'src/app/models/IPaymentsReportDocument';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';

@Injectable()
export class PaymentsReportEffects {
    constructor(private action$: Actions, private paymentsReportService: PaymentsReportService) { }

    loadReport$ = createEffect(
      () => this.action$.pipe(
        ofType(PaymentsReportActions.PaymentsReportActionTypes.LoadPaymentsReport),
        mergeMap((action: PaymentsReportActions.LoadPaymentsReport) => {
            let fromDate: Date;
            let toDate: Date;

            fromDate = action.payload.searchFromDate;
            fromDate.setHours( Number(action.payload.searchFromHour), Number(action.payload.searchFromMinute), 0, 0);

            toDate = action.payload.searchToDate;
            toDate.setHours( Number(action.payload.searchToHour), Number(action.payload.searchToMinute), 59, 999);

            // console.log('from Date : ' + fromDate.toISOString());
            // console.log('to Date : ' + toDate.toISOString());

            // store the parameters into the localstorage
            action.payload.storeParameters();

            return this.paymentsReportService.getPaymentsReport(fromDate,
                                                          toDate,
                                                          action.payload.groupBy,
                                                          action.payload.selectedPosMachineID,
                                                          action.payload.selectedClerkID).pipe(
                map((report: IPaymentsReportDocument) => (new PaymentsReportActions.LoadPaymentsReportSuccessful(report)),
                catchError((error: HttpErrorResponse) => of(new PaymentsReportActions.LoadPaymentsReportFailed(interpretateApiError(error))))
            ));
        }
      ))
    );

    downloadSalesReportFile$ = createEffect(
      () => this.action$.pipe(
        ofType(PaymentsReportActions.PaymentsReportActionTypes.DownloadEOD),
        mergeMap((action: PaymentsReportActions.DownloadEOD) => {

          let fromDate: Date;
          let toDate: Date;

          fromDate = action.payload.searchFromDate;
          fromDate.setHours( Number(action.payload.searchFromHour), Number(action.payload.searchFromMinute), 0, 0);

          toDate = action.payload.searchToDate;
          toDate.setHours( Number(action.payload.searchToHour), Number(action.payload.searchToMinute), 59, 999);

          return this.paymentsReportService.getReportFilePDF(fromDate,
                                                          toDate,
                                                          action.payload.groupBy,
                                                          action.payload.selectedPosMachineID,
                                                          action.payload.selectedClerkID).pipe(
            map((file: ArrayBuffer) => (new PaymentsReportActions.DownloadEODSuccessful(file)),
            catchError((error: HttpErrorResponse) => of(new PaymentsReportActions.DownloadEODFailed(interpretateApiError(error))))
          ));
        }
      ))
    );
}
