import * as fromRoot from '../../../NGRX/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AddonsEditorActions, AddonsEditorActionTypes } from './addons-editor.actions';

export interface State extends fromRoot.State {
  addonsEditor: AddonsEditorState;
}

export interface AddonsEditorState {
  selectedTab: number;
}

const initialState: AddonsEditorState = {
  selectedTab: 0,
};

// create the selectors
const getAddonsFeatureState = createFeatureSelector<AddonsEditorState>('addonsEditor');
export const getProductEditorState = createSelector(
  getAddonsFeatureState,
  (state) => state
);

export const getSelectedTab = createSelector(
  getAddonsFeatureState,
  state => state.selectedTab
);

export function reducer(state: AddonsEditorState = initialState, action: AddonsEditorActions): AddonsEditorState {
  switch (action.type) {

    case AddonsEditorActionTypes.SelectTab:
      return {
        ...state,
        selectedTab: action.payload
      };

    case AddonsEditorActionTypes.ResetStore:
      return {
        ...state,
        selectedTab: initialState.selectedTab
      };

    default:
      return state;
  }
}
