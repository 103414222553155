import { HostListener, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { IGardiffEditor } from '../models/IGardiffEditor';
import { Observable } from 'rxjs';
import { ChangeType } from '../enums/ChangeType';

@Injectable({
  providedIn: 'root'
})
export class EditorPendingChangesService implements CanDeactivate<IGardiffEditor> {

  constructor() { }

  canDeactivate(component: IGardiffEditor, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const canILeave = true;

    if (nextState != null) {

      let currentModuleList = currentState.url.split('/');
      let nextModuleList = nextState.url.split('/');

      if (currentModuleList.length >= 2 && nextModuleList.length >= 2) {
        let currentModule = currentModuleList[1];
        let nextModule = nextModuleList[1];
  
        if (currentModule != nextModule) {
          /*
            current Module and Next Module are different
            it means we are navigating out the current module
          */
          const check = !component.nothingToSave();
          if (check) {
            // we found some local changes, we need to ask to the user what to do.
            return component.canILeave();
          }
        }
      }
    }

    return canILeave;
  }
}
