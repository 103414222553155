import { PaymentReportGroupBy } from '../enums/PaymentReportGroupByType';

export interface IPaymentsReportFormParameters {
  searchFromDate: Date;
  searchFromHour: string;
  searchFromMinute: string;
  searchToDate: Date;
  searchToHour: string;
  searchToMinute: string;
  selectedClerkID: string;
  selectedPosMachineID: string;
  groupBy: PaymentReportGroupBy;
}

const localStorageFormParametersProperty = 'PaymentsFormParameters';

export class PaymentsReportFormParameters implements IPaymentsReportFormParameters {
  searchFromDate: Date;
  searchFromHour: string;
  searchFromMinute: string;
  searchToDate: Date;
  searchToHour: string;
  searchToMinute: string;
  selectedClerkID: string;
  selectedPosMachineID: string;
  groupBy: PaymentReportGroupBy;

  constructor() {
      this.getParameters();
  }

  public storeParameters() {
      localStorage.setItem(localStorageFormParametersProperty, JSON.stringify(this));
  }

  private getParameters() {
    const storedParameters: IPaymentsReportFormParameters = JSON.parse(localStorage.getItem(localStorageFormParametersProperty));

    if (storedParameters != null) {
      // we found the parameters into the local storage
      this.searchFromDate = new Date(storedParameters.searchFromDate);
      this.searchFromHour = storedParameters.searchFromHour;
      this.searchFromMinute = storedParameters.searchFromMinute;
      this.searchToDate = new Date(storedParameters.searchToDate);
      this.searchToHour = storedParameters.searchToHour;
      this.searchToMinute = storedParameters.searchToMinute;
      this.selectedClerkID = storedParameters.selectedClerkID;
      this.selectedPosMachineID = storedParameters.selectedPosMachineID;
      this.groupBy = <PaymentReportGroupBy>storedParameters.groupBy;
    } else {
      // we need to generate default value
      this.searchFromDate = new Date(new Date().setDate(new Date().getDate() - 7));
      this.searchFromHour = '00';
      this.searchFromMinute = '00';
      this.searchToDate = new Date();
      this.searchToHour = '23';
      this.searchToMinute = '59';
      this.selectedClerkID = '00000000-0000-0000-0000-000000000000';
      this.selectedPosMachineID = '00000000-0000-0000-0000-000000000000';
      this.groupBy = PaymentReportGroupBy.None;

      this.storeParameters();
    }

  }
}
