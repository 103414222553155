import { Action } from '@ngrx/store';
import { ICategory } from 'src/app/models/ICategory';
import { IProduct } from 'src/app/models/IProduct';

export enum ProductEditorActionTypes {
    SelectCategory = '[Product Editor] Select Category',
    SelectProduct = '[Product Editor] Select Product',
    ResetSlice = '[Product Editor] Reset Slice'
}

export class SelectCategory implements Action {
    readonly type = ProductEditorActionTypes.SelectCategory;

    constructor(public payload: ICategory) { }
}

export class SelectProduct implements Action {
    readonly type = ProductEditorActionTypes.SelectProduct;

    constructor(public payload: IProduct) { }
}

export class ResetStore implements Action {
    readonly type = ProductEditorActionTypes.ResetSlice;

    constructor() { }
}

export type ProductEditorActions = SelectCategory
    | SelectProduct
    | ResetStore;
