import { HttpErrorResponse } from '@angular/common/http';
import { HttpVerbType } from '../enums/HttpVerbType';
import { IGenericError } from '../models/apiErrorDTOs/IGenericError';

export function interpretateApiError(apiError: HttpErrorResponse, verb: HttpVerbType = HttpVerbType.GET): IGenericError {
  let genericError: IGenericError = null;

  if (apiError != null) {
    switch (apiError.status) {
      case 400:
        genericError = {
          error: true,
          reason: 'Bad request',
          apiVerb: verb
        };
        break;

      case 401:
        genericError = {
          error: true,
          reason: 'User Unauthorized',
          apiVerb: verb
        };
        break;

      case 403:
        genericError = {
          error: true,
          reason: 'Forbidden',
          apiVerb: verb
        };
        break;

      case 404:
        genericError = {
          error: true,
          reason: 'No Data',
          apiVerb: verb
        };
        break;

      case 409:
        genericError = {
          error: true,
          reason: 'Syncing progress',
          apiVerb: verb
        };
        break;

      case 500:
        genericError = {
          error: true,
          reason: 'Server error',
          apiVerb: verb
        };
        break;

      default:
        genericError = apiError.error;
        break;
    }

  }

  return genericError;
}
