import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EowFormParameters, IEowFormParameters } from 'src/app/models/IEowFormParameters';
import { IEOWItem } from 'src/app/models/IEOWItem';
import { EowReportDocument, IEowReportDocument } from 'src/app/models/IEowReportDocument';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import * as fromRoot from '../../../NGRX/app.state';
import { EowReportActions, EowReportActionTypes } from './eowReport.actions';

export interface State extends fromRoot.State {
  eowReport: EowReportState;
}

export interface EowReportState extends IGardiffStoreSliceBase {
  searchParameters: IEowFormParameters;
  searchResult: IEOWItem[];
  selectedEowItem: IEowReportDocument;
  downloadedFile: ArrayBuffer;
}

const initialState: EowReportState = {
  searchParameters: new EowFormParameters(),
  searchResult: [],
  selectedEowItem: new EowReportDocument(),
  apiError: null,
  status: new GardiffStoreSliceStatus(),
  downloadedFile: null
};

// create the selectors
const getEowReportFeatureState = createFeatureSelector<EowReportState>('eowReport');
export const getEowReportState = createSelector(
    getEowReportFeatureState,
    state => state
);

export const getSearchParameters = createSelector(
    getEowReportFeatureState,
    state => state.searchParameters
);

export const getSearchResult = createSelector(
    getEowReportFeatureState,
    state => state.searchResult
);

export const getSelectedEowItem = createSelector(
    getEowReportFeatureState,
    state => state.selectedEowItem
);

export const getDownloadedFile = createSelector(
  getEowReportFeatureState,
  state => state.downloadedFile
);

export const getApiError = createSelector(
  getEowReportFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getEowReportFeatureState,
  state => state.status
);

export function reducer(state: EowReportState = initialState, action: EowReportActions): EowReportState {
  switch (action.type) {
      case EowReportActionTypes.LoadEOW:
          return {
              ...state,
              searchParameters: action.payload,
              searchResult: null,
              apiError: null,
              status: null
          };

      case EowReportActionTypes.LoadEOWSuccessful:
          return {
              ...state,
              searchResult: action.payload,
              apiError: null,
              status: new GardiffStoreSliceStatus(true)
          };

      case EowReportActionTypes.LoadEOWFailed:
          return {
              ...state,
              searchResult: [],
              apiError: action.payload,
              status: new GardiffStoreSliceStatus()
          };

      case EowReportActionTypes.SelectEOW:
          return {
              ...state,
              selectedEowItem: null
          };

      case EowReportActionTypes.SelectedEOW:
          return {
              ...state,
              selectedEowItem: action.payload
          };

      case EowReportActionTypes.SelectEOWFailed:
          return {
              ...state,
              selectedEowItem: initialState.selectedEowItem
          };

      case EowReportActionTypes.DownloadEOW:
          return {
              ...state,
              downloadedFile: null,
          };

      case EowReportActionTypes.DownloadEOWSuccessful:
          return {
              ...state,
              downloadedFile: action.payload,
          };

      case EowReportActionTypes.DownloadEOWFailed:
          return {
              ...state,
              downloadedFile: new ArrayBuffer(0)
          };

      case EowReportActionTypes.CleanUpFileBuffer:
          return {
              ...state,
              downloadedFile: initialState.downloadedFile
          };

      case EowReportActionTypes.ResetStore:
          return {
              ...state,
              searchParameters: initialState.searchParameters,
              searchResult: initialState.searchResult,
              selectedEowItem: initialState.selectedEowItem,
              apiError: initialState.apiError,
              status: initialState.status
          };

      case EowReportActionTypes.SaveSearchParameters:
          return {
              ...state,
              searchParameters: action.payload,
          };

      default:
          return state;
  }
}
