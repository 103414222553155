import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { EowFormParameters } from 'src/app/models/IEowFormParameters';
import { IEOWItem } from 'src/app/models/IEOWItem';
import { IEowReportDocument } from 'src/app/models/IEowReportDocument';

export enum EowReportActionTypes {
  LoadEOW = '[EOW Reports] Load EOWs',
  LoadEOWSuccessful = '[EOW Reports] Load EOWs - Successful',
  LoadEOWFailed = '[EOW Reports] Load EOWs - Failed',
  SelectEOW = '[EOW Reports] Select EOW',
  SelectedEOW = '[EOW Reports] Selected EOW',
  SelectEOWFailed = '[EOW Reports] Select EOW - Failed',
  ResetStore = '[EOW Reports] Reset Store',
  SaveSearchParameters = '[EOW Reports] Save Search Parameters',

  DownloadEOW = '[EOW Reports] Download EOW',
  DownloadEOWSuccessful = '[EOW Reports] Download EOW - Successful',
  DownloadEOWFailed = '[EOW Reports] Download EOW - Failed',
  CleanUpFileBuffer = '[EOD Reports] Clean up file buffer'
}

export class LoadEOW implements Action {
  readonly type = EowReportActionTypes.LoadEOW;

  constructor(public payload: EowFormParameters) { }
}

export class LoadEOWSuccessful implements Action {
  readonly type = EowReportActionTypes.LoadEOWSuccessful;

  constructor(public payload: IEOWItem[]) { }
}

export class LoadEOWFailed implements Action {
  readonly type = EowReportActionTypes.LoadEOWFailed;

  constructor(public payload: IGenericError) { }
}

export class SelectEOW implements Action {
  readonly type = EowReportActionTypes.SelectEOW;

  constructor(public payload: IEOWItem) { }
}

export class SelectedEOW implements Action {
  readonly type = EowReportActionTypes.SelectedEOW;

  constructor(public payload: IEowReportDocument) { }
}

export class SelectEOWFailed implements Action {
  readonly type = EowReportActionTypes.SelectEOWFailed;

  constructor(public payload: IGenericError) { }
}

export class ResetStore implements Action {
  readonly type = EowReportActionTypes.ResetStore;

  constructor() { }
}

export class SaveSearchParameters implements Action {
  readonly type = EowReportActionTypes.SaveSearchParameters;

  constructor(public payload: EowFormParameters) { }
}

export class DownloadEOW implements Action {
  readonly type = EowReportActionTypes.DownloadEOW;

  constructor(public payload: string) { }
}

export class DownloadEOWSuccessful implements Action {
  readonly type = EowReportActionTypes.DownloadEOWSuccessful;

  constructor(public payload: ArrayBuffer) { }
}

export class DownloadEOWFailed implements Action {
  readonly type = EowReportActionTypes.DownloadEOWFailed;

  constructor(public payload: IGenericError) { }
}

export class CleanUpFileBuffer implements Action {
  readonly type = EowReportActionTypes.CleanUpFileBuffer;

  constructor() { }
}

export type EowReportActions = LoadEOW
  | LoadEOWSuccessful
  | LoadEOWFailed
  | SelectEOW
  | SelectedEOW
  | SelectEOWFailed
  | ResetStore
  | SaveSearchParameters
  | DownloadEOW
  | DownloadEOWSuccessful
  | DownloadEOWFailed
  | CleanUpFileBuffer;
