import { IPaymentsReportFilter } from './IPaymentsReportFilter';
import { IPaymentsReportItem } from './IPaymentsReportItem';

export interface IPaymentsReportDocument {
  filters: IPaymentsReportFilter[];
  items: IPaymentsReportItem[];
  reportVersion: number;
  salesDateFrom: Date;
  salesDateTo: Date;
  groupBy: string;
  subGroupBy: string;
  subGroupGrandTotals: IPaymentsReportItem[];
  totalAmount: number;
  totalQuantity: number;
}

export class PaymentsReportDocument implements IPaymentsReportDocument {
  filters: IPaymentsReportFilter[];
  items: IPaymentsReportItem[];
  reportVersion: number;
  salesDateFrom: Date;
  salesDateTo: Date;
  groupBy: string;
  subGroupBy: string;
  subGroupGrandTotals: IPaymentsReportItem[];
  totalAmount: number;
  totalQuantity: number;

  constructor() {
    this.filters = [];
    this.items = [];
    this.reportVersion = 0;
    this.salesDateFrom = new Date();
    this.salesDateTo = new Date();
    this.groupBy = '';
    this.subGroupBy = '';
    this.subGroupGrandTotals = [];
    this.totalAmount = 0;
    this.totalQuantity = 0;
  }
}
