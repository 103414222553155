import { IEodReportDocumentDTO } from './DTOs/IEodReportDocumentDTO';
import { IEopReportSection } from './IEopReportSection';

export interface IEodReportDocument extends IEodReportDocumentDTO {
  reportGuid: string;
  createdByUser: string;
  createdOnPosDevice: string;
  posDevicesIncluded: number[];
  paymentTotals: { [key: string]: number; };
  productSalesTotal: number;
  reportDate: Date;
  reportDescription: string;
  reportNumber: number;
  reportVersion: number;
  sections: IEopReportSection[];
}

export class EodReportDocument implements IEodReportDocument {
  public reportGuid: string;
  public createdByUser: string;
  public createdOnPosDevice: string;
  public posDevicesIncluded: number[];
  public paymentTotals: { [key: string]: number; };
  public productSalesTotal: number;
  public reportDate: Date;
  public reportDescription: string;
  public reportNumber: number;
  public reportVersion: number;
  public sections: IEopReportSection[];

  constructor() {
    this.paymentTotals = {};
    this.sections = [];
  }
}
