import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, catchError, throwError, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChangeType } from '../enums/ChangeType';
import { IDiscountDTO } from '../models/DTOs/IDiscountDTO';
import { IEditDataList, EditDataList } from '../models/DTOs/IEditDataList';
import { IDiscount } from '../models/IDiscount';
import { Product } from '../models/IProduct';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountsService {
  private DiscountsEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/Discounts';

  private discounts: IDiscount[] = [];

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetDiscounts(CorporationID: string, SiteID: string): Observable<IDiscount[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.DiscountsEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http.get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IDiscountDTO[]>) => {

            if (data.ok) {
              // store a copy for backup
              this.discounts = this.formatData(data.body);
              return JSON.parse(JSON.stringify(this.discounts));
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  private formatData(dataList: IDiscountDTO[]): IDiscount[] {
    let list: IDiscount[] = [];

    // convert list
    dataList.forEach(item => {
      const newDiscountModel: IDiscount = {
        id: item.id,
        discountGuid: item.discountGuid,
        discountDescription: item.discountDescription,
        discountType: item.discountType,
        discountParameterJSON: item.discountParameterJSON,
        allowFor: item.allowFor,
        isActive: item.isActive,
        isMappedToProducts: item.isMappedToProducts,
        useWithOtherDiscounts: item.useWithOtherDiscounts,
        roles: [],
        mappedProducts: [],
        isSelected: false,
        changeState: ChangeType.None
      };

      item.roles.forEach(role => {
        newDiscountModel.roles.push({
          id: role.id,
          role_GUID: role.role_GUID,
          name: role.name
        });
      });

      item.mappedProducts.forEach(product => {
        newDiscountModel.mappedProducts.push({
          id: product.id,
          productGuid: product.productGuid,
          name: product.name,
          displayName: product.displayName,
          createdBy: product.createdBy,
          createdDate: product.createdDate,
          isActive: product.isActive,
          isOpenPrice: product.isOpenPrice,
          isMeasureVisible: product.isMeasureVisible
        });
      });

      list.push(newDiscountModel);
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: IDiscount[]): IDiscount[] {
    return list.sort((Item1: IDiscount, Item2: IDiscount) => {
      if (Item1.discountDescription.toLowerCase() > Item2.discountDescription.toLowerCase()) {
        return 1;
      }

      if (Item1.discountDescription.toLowerCase() < Item2.discountDescription.toLowerCase()) {
        return -1;
      }
    });
  }

  SaveDiscounts(CorporationID: string, SiteID: string, newData: IDiscount[]): Observable<IDiscount[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.DiscountsEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IEditDataList<IDiscountDTO> = this.SplitItems(newData);

      return this.http.post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IDiscountDTO[]>) => {

            if (data.ok) {
              // store a copy for backup
              this.discounts = this.formatData(data.body);
              return JSON.parse(JSON.stringify(this.discounts));
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        );
    }
  }

  private DeflateItems(list: IDiscount[]): IDiscountDTO[] {
    const newData: IDiscountDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        const newDiscountDTO: IDiscountDTO = {
          id: item.id,
          discountGuid: item.discountGuid,
          discountDescription: item.discountDescription,
          discountType: item.discountType,
          discountParameterJSON: item.discountParameterJSON,
          allowFor: item.allowFor,
          isActive: item.isActive,
          isMappedToProducts: item.isMappedToProducts,
          useWithOtherDiscounts: item.useWithOtherDiscounts,
          roles: [],
          mappedProducts: []
        };

        item.roles.forEach(role => {
          newDiscountDTO.roles.push({
            id: role.id,
            role_GUID: role.role_GUID,
            name: role.name
          });
        });
  
        item.mappedProducts.forEach(product => {
          newDiscountDTO.mappedProducts.push({
            id: product.id,
            productGuid: product.productGuid,
            name: product.name,
            displayName: product.displayName,
            createdBy: product.createdBy,
            createdDate: product.createdDate,
            isActive: product.isActive,
            isOpenPrice: product.isOpenPrice,
            isMeasureVisible: product.isMeasureVisible
          });
        });

        newData.push(newDiscountDTO);
      });
    }

    return newData;
  }

  private SplitItems(data: IDiscount[]): IEditDataList<IDiscountDTO> {
    let newData: IEditDataList<IDiscountDTO> = new EditDataList<IDiscountDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }

  GetBackup(): Observable<IDiscount[]> {
    const backup: IDiscount[] = JSON.parse(JSON.stringify(this.discounts));
    return new Observable<IDiscount[]>((subscriber: Subscriber<IDiscount[]>) => subscriber.next(backup));
  }
}
