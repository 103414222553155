import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { IEposSetting } from 'src/app/models/IEposSetting';

export enum EposSettingsActionTypes {
    LoadEposSettings = '[Epos Settings] Load Epos Settings',
    LoadEposSettingsSuccessful = '[Epos Settings] Load Epos Settings - Successful',
    LoadEposSettingsFailed = '[Epos Settings] Load Epos Settings - Failed',
    SaveEposSettings = '[Epos Settings] Save Epos Settings',
    SaveEposSettingsSuccessful = '[Epos Settings] Save Epos Settings - Successful',
    SaveEposSettingsFailed = '[Epos Settings] Save Epos Settings - Failed',
    ResetStore = '[Epos Settings] Reset Store',
}

export class LoadEposSettings implements Action {
    readonly type = EposSettingsActionTypes.LoadEposSettings;

    constructor() { }
}

export class LoadEposSettingsSuccessful implements Action {
    readonly type = EposSettingsActionTypes.LoadEposSettingsSuccessful;

    constructor(public payload: IEposSetting[]) { }
}

export class LoadEposSettingsFailed implements Action {
    readonly type = EposSettingsActionTypes.LoadEposSettingsFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveEposSettings implements Action {
    readonly type = EposSettingsActionTypes.SaveEposSettings;

    constructor(public payload: IEposSetting[]) { }
}

export class SaveEposSettingsSuccessful implements Action {
    readonly type = EposSettingsActionTypes.SaveEposSettingsSuccessful;

    constructor(public payload: IEposSetting[]) { }
}

export class SaveEposSettingsFailed implements Action {
    readonly type = EposSettingsActionTypes.SaveEposSettingsFailed;

    constructor(public payload: IGenericError) { }
}

export class ResetStore implements Action {
    readonly type = EposSettingsActionTypes.ResetStore;

    constructor() { }
}

export type EposSettingsActions = LoadEposSettings
    | LoadEposSettingsSuccessful
    | LoadEposSettingsFailed
    | SaveEposSettings
    | SaveEposSettingsSuccessful
    | SaveEposSettingsFailed
    | ResetStore;
