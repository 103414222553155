export function checkHexColor(value: string): string {
  // default color BLACK
  let hexCode = '#000000';

  // we can get 2 type of hex codes
  // RGB or ARGB

  if (value.length === 7) {
    // we have an RGB Code, we can return it as is
    hexCode = value;
  } else if (value.length > 7) {
    // we have an ARGB Code, we need to convert it in RGB for web browser compatibility
    // I will extract the values from a r g b channels and rebuild the string in r g b
    const a = value.substring(1, 3);
    const r = value.substring(3, 5);
    const g = value.substring(5, 7);
    const b = value.substring(7);

    hexCode = '#' + r + g + b;
  }

  return hexCode;
}
