import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeOperationType } from '../enums/ChangeOperationType';
import { ChangeType } from '../enums/ChangeType';
import { EditDataList, IEditDataList } from '../models/DTOs/IEditDataList';
import { IEnvelopeInDTO } from '../models/DTOs/IEnvelopeInDTO';
import { IEnvelopeOutDTO } from '../models/DTOs/IEnvelopeOutDTO';
import { IPriceLevelDTO } from '../models/DTOs/IPriceLevelDTO';
import { IPriceLevel } from '../models/IPriceLevel';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class PricelevelService {
  private PriceLevelsEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/PriceLevels';

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetPriceLevels(CorporationID: string, SiteID: string): Observable<IPriceLevel[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.PriceLevelsEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http
        .get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IPriceLevelDTO[]>) => {

            if (data.ok) {
              return this.formatData(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        );
    }
  }

  private formatData(dataList: IPriceLevelDTO[]): IPriceLevel[] {
    let list: IPriceLevel[] = [];

    // convert priceLevel items => IPriceLevelWrap
    dataList.forEach(pl => {
      list.push({
        id: pl.id,
        priceLevelGuid: pl.priceLevelGuid,
        description: pl.description,
        listPosition: pl.listPosition,
        isSelected: false,
        changeState: ChangeType.None
      });
    });

    //sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: IPriceLevel[]): IPriceLevel[] {
    return list.sort((Item1: IPriceLevel, Item2: IPriceLevel) => {
      if (Item1.listPosition > Item2.listPosition) {
        return 1;
      }

      if (Item1.listPosition < Item2.listPosition) {
        return -1;
      }
    });
  }

  SavePriceLevels(CorporationID: string, SiteID: string, oldData: IPriceLevel[], newData: IPriceLevel[]): Observable<IPriceLevel[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.PriceLevelsEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IEditDataList<IPriceLevelDTO> = this.SplitItems(newData);

      return this.http
        .post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IPriceLevelDTO[]>) => {

            if (data.ok) {
              return this.formatData(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        );
    }
  }

  private DeflateItems(list: IPriceLevel[]): IPriceLevelDTO[] {
    const newData: IPriceLevelDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        newData.push({
          id: item.id,
          priceLevelGuid: item.priceLevelGuid,
          description: item.description,
          listPosition: item.listPosition
        });
      });
    }

    return newData;
  }

  private SplitItems(data: IPriceLevel[]): IEditDataList<IPriceLevelDTO> {
    let newData: IEditDataList<IPriceLevelDTO> = new EditDataList<IPriceLevelDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }
}
