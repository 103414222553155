import { ISite } from './ISite';

export interface IArea {
    areaId: string;
    areaName: string;
    accessible: boolean;
    sites: ISite[];
}

export class Area {
    areaId: string;
    areaName: string;
    accessible: boolean;
    sites: ISite[];

    constructor() {
        this.areaId = '';
        this.areaName = '';
        this.accessible = false;
        this.sites = [];
    }
}
