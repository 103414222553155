export enum WODiscountReportField
{
  None,
  DiscountName,
  AppliedDate,
  User,
  Pos,
  DiscountAmount
}

export const WODiscountReportFieldLabel = new Map<number, string>([
  [WODiscountReportField.None, 'None'],
  [WODiscountReportField.DiscountName, 'Discount Name'],
  [WODiscountReportField.AppliedDate, 'Time Applied'],
  [WODiscountReportField.User, 'Applied by User'],
  [WODiscountReportField.Pos, 'Applied on Pos'],
  [WODiscountReportField.DiscountAmount, 'Discount Amount']
]);
