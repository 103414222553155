import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { IAddon } from 'src/app/models/IAddon';

export enum AddonsActionTypes {
  LoadAddons = '[Addons] Load Addons',
  LoadAddonsSuccessful = '[Addons] Load Addons - Successful',
  LoadAddonsFailed = '[Addons] Load Addons - Failed',

  SaveAddons = '[Addons] Save Addons',
  SaveAddonsSuccessful = '[Addons] Save Addons - Successful',
  SaveAddonsFailed = '[Addons] Save Addons - Failed',

  RestoreBackup = '[Addons] Restore Backup',
  RestoreBackupSuccessful = '[Addons] Restore Backup - Successful',
  RestoreBackupFailed = '[Addons] Restore Backup - Failed',

  ResetStore = '[Addons] Reset Store'
}

export class LoadAddons implements Action {
  readonly type = AddonsActionTypes.LoadAddons;

  constructor() { }
}

export class LoadAddonsSuccessful implements Action {
  readonly type = AddonsActionTypes.LoadAddonsSuccessful;

  constructor(public payload: IAddon[]) { }
}

export class LoadAddonsFailed implements Action {
  readonly type = AddonsActionTypes.LoadAddonsFailed;

  constructor(public payload: IGenericError) { }
}

export class SaveAddons implements Action {
  readonly type = AddonsActionTypes.SaveAddons;

  constructor(public payload: IAddon[]) { }
}

export class SaveAddonsSuccessful implements Action {
  readonly type = AddonsActionTypes.SaveAddonsSuccessful;

  constructor(public payload: IAddon[]) { }
}

export class SaveAddonsFailed implements Action {
  readonly type = AddonsActionTypes.SaveAddonsFailed;

  constructor(public error: IGenericError, public oldData: IAddon[]) { }
}

export class RestoreBackup implements Action {
  readonly type = AddonsActionTypes.RestoreBackup;

  constructor() { }
}

export class RestoreBackupSuccessful implements Action {
  readonly type = AddonsActionTypes.RestoreBackupSuccessful;

  constructor(public payload: IAddon[]) { }
}

export class RestoreBackupFailed implements Action {
  readonly type = AddonsActionTypes.RestoreBackupFailed;

  constructor() { }
}

export class ResetStore implements Action {
    readonly type = AddonsActionTypes.ResetStore;

    constructor() { }
}

export type AddonsActions = LoadAddons
    | LoadAddonsSuccessful
    | LoadAddonsFailed
    | SaveAddons
    | SaveAddonsSuccessful
    | SaveAddonsFailed
    | RestoreBackup
    | RestoreBackupSuccessful
    | RestoreBackupFailed
    | ResetStore;
