import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { IParentCategory } from 'src/app/models/IParentCategory';
import { IParentCategoryItem } from 'src/app/models/IParentCategoryItem';

export enum ParentCategoryActionTypes {
    LoadParentCategories = '[Parent Category] Load Parent Categories',
    LoadParentCategoriesSuccessful = '[Parent Category] Load Parent Categories - Successful',
    LoadParentCategoriesFailed = '[Parent Category] Load Parent Categories - Failed',
    SaveParentCategories = '[Parent Category] Save Parent Categories',
    SaveParentCategoriesSuccessful = '[Parent Category] Save Parent Categories - Successful',
    SaveParentCategoriesFailed = '[Parent Category] Save Parent Categories - Failed',
    LoadParentCategoriesFlatList = '[Parent Category] Load Parent Categories Flat List',
    LoadParentCategoriesFlatListSuccessful = '[Parent Category] Load Parent Categories Flat List - Successful',
    LoadParentCategoriesFlatListFailed = '[Parent Category] Load Parent Categories Flat List - Failed',
    ResetStore = '[Parent Category] Reset Store',
}

export class LoadParentCategories implements Action {
    readonly type = ParentCategoryActionTypes.LoadParentCategories;

    constructor() { }
}

export class LoadParentCategoriesSuccessful implements Action {
    readonly type = ParentCategoryActionTypes.LoadParentCategoriesSuccessful;

    constructor(public payload: IParentCategory[]) { }
}

export class LoadParentCategoriesFailed implements Action {
    readonly type = ParentCategoryActionTypes.LoadParentCategoriesFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveParentCategories implements Action {
  readonly type = ParentCategoryActionTypes.SaveParentCategories;

  constructor(public payload: IParentCategory[]) { }
}

export class SaveParentCategoriesSuccessful implements Action {
  readonly type = ParentCategoryActionTypes.SaveParentCategoriesSuccessful;

  constructor(public payload: IParentCategory[]) { }
}

export class SaveParentCategoriesFailed implements Action {
  readonly type = ParentCategoryActionTypes.SaveParentCategoriesFailed;

  constructor(public payload: IGenericError) { }
}

export class LoadParentCategoriesFlatList implements Action {
  readonly type = ParentCategoryActionTypes.LoadParentCategoriesFlatList;

  constructor() { }
}

export class LoadParentCategoriesFlatListSuccessful implements Action {
  readonly type = ParentCategoryActionTypes.LoadParentCategoriesFlatListSuccessful;

  constructor(public payload: IParentCategoryItem[]) { }
}

export class LoadParentCategoriesFlatListFailed implements Action {
  readonly type = ParentCategoryActionTypes.LoadParentCategoriesFlatListFailed;

  constructor(public payload: IGenericError) { }
}

export class ResetStore implements Action {
    readonly type = ParentCategoryActionTypes.ResetStore;

    constructor() { }
}

export type ParentCategoryActions = LoadParentCategories
    | LoadParentCategoriesSuccessful
    | LoadParentCategoriesFailed
    | SaveParentCategories
    | SaveParentCategoriesSuccessful
    | SaveParentCategoriesFailed
    | LoadParentCategoriesFlatList
    | LoadParentCategoriesFlatListSuccessful
    | LoadParentCategoriesFlatListFailed
    | ResetStore;
