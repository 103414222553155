import * as fromRoot from '../../NGRX/app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FunctionKeysActions, FunctionKeysActionTypes } from './function-keys.actions';
import { IFunctionKey } from 'src/app/models/IFunctionKey';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    roleFunctions: FunctionKeysState;
}

export interface FunctionKeysState extends IGardiffStoreSliceBase {
    functionKeys: IFunctionKey[];
}

const initialState: FunctionKeysState = {
    functionKeys: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getRoleFunctionsFeatureState = createFeatureSelector<FunctionKeysState>('functionKeys');
export const getRoleUsersState = createSelector(
    getRoleFunctionsFeatureState,
    state => state
);

export const getFunctionKeys = createSelector(
    getRoleFunctionsFeatureState,
    state => state.functionKeys
);

export const getApiError = createSelector(
  getRoleFunctionsFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getRoleFunctionsFeatureState,
  state => state.status
);

export function reducer(state: FunctionKeysState = initialState, action: FunctionKeysActions): FunctionKeysState {
    switch (action.type) {

        case FunctionKeysActionTypes.LoadFunctionKeys:
            return {
                ...state,
                functionKeys: null,
                apiError: null,
                status: null
            };

        case FunctionKeysActionTypes.LoadFunctionKeysSuccessful:
            return {
                ...state,
                functionKeys: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case FunctionKeysActionTypes.LoadFunctionKeysFailed:
            return {
                ...state,
                functionKeys: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case FunctionKeysActionTypes.ResetStore:
            return {
                ...state,
                functionKeys: initialState.functionKeys,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
