import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { IPriceLevel } from 'src/app/models/IPriceLevel';

export enum PriceLevelsActionTypes {
    LoadPriceLevels = '[Price Levels] Load Price Levels',
    LoadPriceLevelsSuccessful = '[Price Levels] Load Price Levels - Successful',
    LoadPriceLevelsFailed = '[Price Levels] Load Price Levels - Failed',
    SavePriceLevels = '[Price Levels] Save Price Levels',
    SavePriceLevelsSuccessful = '[Price Levels] Save Price Levels - Successful',
    SavePriceLevelsFailed = '[Price Levels] Save Price Levels - Failed',
    ResetStore = '[Price Levels] Reset Store',
}

export class LoadPriceLevels implements Action {
    readonly type = PriceLevelsActionTypes.LoadPriceLevels;

    constructor() { }
}

export class LoadPriceLevelsSuccessful implements Action {
    readonly type = PriceLevelsActionTypes.LoadPriceLevelsSuccessful;

    constructor(public payload: IPriceLevel[]) { }
}

export class LoadPriceLevelsFailed implements Action {
    readonly type = PriceLevelsActionTypes.LoadPriceLevelsFailed;

    constructor(public payload: IGenericError) { }
}

export class SavePriceLevels implements Action {
    readonly type = PriceLevelsActionTypes.SavePriceLevels;

    constructor(public oldData: IPriceLevel[], public newData: IPriceLevel[]) { }
}

export class SavePriceLevelsSuccessful implements Action {
    readonly type = PriceLevelsActionTypes.SavePriceLevelsSuccessful;

    constructor(public payload: IPriceLevel[]) { }
}

export class SavePriceLevelsFailed implements Action {
    readonly type = PriceLevelsActionTypes.SavePriceLevelsFailed;

    constructor(public error: IGenericError, public oldData: IPriceLevel[]) { }
}

export class ResetStore implements Action {
    readonly type = PriceLevelsActionTypes.ResetStore;

    constructor() { }
}

export type PriceLevelsActions = LoadPriceLevels
    | LoadPriceLevelsSuccessful
    | LoadPriceLevelsFailed
    | SavePriceLevels
    | SavePriceLevelsSuccessful
    | SavePriceLevelsFailed
    | ResetStore;
