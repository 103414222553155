import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import * as roleFunctionActions from './roles.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { IRole } from 'src/app/models/IRole';
import { IFunctionKey } from 'src/app/models/IFunctionKey';
import { EstateService } from 'src/app/services/estate.service';
import { IPriceLevel } from 'src/app/models/IPriceLevel';
import { PricelevelService } from 'src/app/services/pricelevel.service';
import { FunctionKeysService } from 'src/app/services/function-keys.service';
import { RolesService } from 'src/app/services/roles.service';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';

@Injectable()
export class RolesEffects {
  constructor(private action$: Actions,
    private estateService: EstateService,
    private pricelevelService: PricelevelService,
    private functionKeyService: FunctionKeysService,
    private roleService: RolesService) { }

  loadRoles$ = createEffect(
    () => this.action$.pipe(
    ofType(roleFunctionActions.RolesActionTypes.LoadRoles),
    mergeMap((action: roleFunctionActions.LoadRoles) =>
      this.roleService.GetRoles().pipe(
        map((roles: IRole[]) => (new roleFunctionActions.LoadRolesSuccessful(roles))),
        catchError((error: HttpErrorResponse) => of(new roleFunctionActions.LoadRolesFailed(interpretateApiError(error))))
      )
    ))
  );

  saveRoles$ = createEffect(
    () => this.action$.pipe(
    ofType(roleFunctionActions.RolesActionTypes.SaveRoles),
    mergeMap((action: roleFunctionActions.SaveRoles) =>
      this.roleService.SaveRoles(action.oldData, action.newData).pipe(
        map((roles: IRole[]) => new roleFunctionActions.SaveRolesSuccessful(roles)),
        catchError((error: HttpErrorResponse) => of(new roleFunctionActions.SaveRolesFailed(interpretateApiError(error, HttpVerbType.POST), action.newData)))
      )
    ))
  );
}
