export interface IReportPDFDefinition {
  siteName: string;
  groupedFieldName: string;
  filters: string[];
}

export class ReportPDFDefinition implements IReportPDFDefinition {
  siteName: string;
  groupedFieldName: string;
  filters: string[];

  constructor(){
    this.siteName = "";
    this.groupedFieldName = "";
    this.filters = [];
  }
}
