import { VoidReportField } from "src/app/enums/Report/ReportField/VoidReportField";
import { IReportSortDefinition } from "../Components/IReportSortDefinition";

const localStorageFormParametersProperty = 'VoidReportParameters';

export class VoidReportParameters {
  reportGroup: VoidReportField;
  createdPosId: number;
  createdClerkId: number;
  voidedDateFromDay: Date;
  voidedDateFromHour: string;
  voidedDateFromMinute: string;
  voidedDateToDay: Date;
  voidedDateToHour: string;
  voidedDateToMinute: string;
  voidedPosId: number;
  voidedClerkId: number;
  sort: IReportSortDefinition[];

  constructor() {
      this.getParameters();
  }

  public storeParameters() {
      localStorage.setItem(localStorageFormParametersProperty, JSON.stringify(this));
  }

  public clearParameters() {
      localStorage.removeItem(localStorageFormParametersProperty);
  }

  private getParameters() {
    const storedParameters: VoidReportParameters = JSON.parse(localStorage.getItem(localStorageFormParametersProperty));

    if (storedParameters != null) {
      // we found the parameters into the local storage
      this.reportGroup = <VoidReportField>storedParameters.reportGroup;
      this.createdPosId = storedParameters.createdPosId;
      this.createdClerkId = storedParameters.createdClerkId;
      this.voidedDateFromDay = new Date(storedParameters.voidedDateFromDay);
      this.voidedDateFromHour = storedParameters.voidedDateFromHour;
      this.voidedDateFromMinute = storedParameters.voidedDateFromMinute;
      this.voidedDateToDay = new Date(storedParameters.voidedDateToDay);
      this.voidedDateToHour = storedParameters.voidedDateToHour;
      this.voidedDateToMinute = storedParameters.voidedDateToMinute;
      this.voidedPosId = storedParameters.voidedPosId;
      this.voidedClerkId = storedParameters.voidedClerkId;

    } else {
      // we need to generate default value
      this.reportGroup = VoidReportField.None;
      this.createdPosId = -1;
      this.createdClerkId = -1;
      this.voidedDateFromDay = new Date(new Date().setDate(new Date().getDate() - 7));
      this.voidedDateFromHour = '00';
      this.voidedDateFromMinute = '00';
      this.voidedDateToDay = new Date();
      this.voidedDateToHour = '23';
      this.voidedDateToMinute = '59';
      this.voidedPosId = -1;
      this.voidedClerkId = -1;

      this.storeParameters();
    }
  }
}
