import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { TransactionReportParameters } from 'src/app/models/Report/ReportParameters/ITransactionReportParameters';
import { ITransactionReport } from 'src/app/models/Report/ITransactionReport';

export enum TransactionReportActionTypes {
    LoadTransactionReport = '[Transaction Report] Load Transaction Report',
    LoadTransactionReportSuccessful = '[Transaction Report] Load Transaction Report - Successful',
    LoadTransactionReportFailed = '[Transaction Report] Load Transaction Report - Failed',
    ResetStore = '[Transaction Report] Reset Store',
    SaveSearchParameters = '[Transaction Report] Save Search Parameters',
    DownloadPDF = '[Transaction Reports] Download Transaction Reports',
    DownloadPDFSuccessful = '[Transaction Reports] Download Transaction Reports - Successful',
    DownloadPDFFailed = '[Transaction Reports] Download Transaction Reports - Failed',
    DownloadReceiptPDF = '[Transaction Reports] Download Receipt',
    DownloadReceiptPDFSuccessful = '[Transaction Reports] Download Receipt - Successful',
    DownloadReceiptPDFFailed = '[Transaction Reports] Download Receipt - Failed',
    CleanUpFileBuffer = '[Transaction Reports] Clean up file buffer'
}

export class LoadTransactionReport implements Action {
    readonly type = TransactionReportActionTypes.LoadTransactionReport;

    constructor(public payload: TransactionReportParameters) { }
}

export class LoadTransactionReportSuccessful implements Action {
    readonly type = TransactionReportActionTypes.LoadTransactionReportSuccessful;

    constructor(public payload: ITransactionReport) { }
}

export class LoadTransactionReportFailed implements Action {
    readonly type = TransactionReportActionTypes.LoadTransactionReportFailed;

    constructor(public payload: IGenericError) { }
}

export class ResetStore implements Action {
    readonly type = TransactionReportActionTypes.ResetStore;

    constructor() { }
}

export class SaveSearchParameters implements Action {
    readonly type = TransactionReportActionTypes.SaveSearchParameters;

    constructor(public payload: TransactionReportParameters) { }
}

export class DownloadPDF implements Action {
  readonly type = TransactionReportActionTypes.DownloadPDF;

  constructor(public payload: TransactionReportParameters) { }
}

export class DownloadPDFSuccessful implements Action {
  readonly type = TransactionReportActionTypes.DownloadPDFSuccessful;

  constructor(public payload: ArrayBuffer) { }
}

export class DownloadPDFFailed implements Action {
  readonly type = TransactionReportActionTypes.DownloadPDFFailed;

  constructor(public payload: IGenericError) { }
}

export class CleanUpFileBuffer implements Action {
  readonly type = TransactionReportActionTypes.CleanUpFileBuffer;

  constructor() { }
}

export class DownloadReceiptPDF implements Action {
  readonly type = TransactionReportActionTypes.DownloadReceiptPDF;

  constructor(public payload: string) { }
}

export class DownloadReceiptPDFSuccessful implements Action {
  readonly type = TransactionReportActionTypes.DownloadReceiptPDFSuccessful;

  constructor(public payload: ArrayBuffer) { }
}

export class DownloadReceiptPDFFailed implements Action {
  readonly type = TransactionReportActionTypes.DownloadReceiptPDFFailed;

  constructor(public payload: IGenericError) { }
}

export type TransactionReportActions = LoadTransactionReport
    | LoadTransactionReportSuccessful
    | LoadTransactionReportFailed
    | ResetStore
    | SaveSearchParameters
    | DownloadPDF
    | DownloadPDFSuccessful
    | DownloadPDFFailed
    | CleanUpFileBuffer
    | DownloadReceiptPDF
    | DownloadReceiptPDFSuccessful
    | DownloadReceiptPDFFailed;
