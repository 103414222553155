import { Injectable } from '@angular/core';

import { IToken, Token } from '../models/IToken';
import { IAccessTokenPayload, AccessTokenPayload } from '../models/IAccessTokenPayload';
import { JwtHelperService } from '@auth0/angular-jwt';

import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ILoginCredential } from '../models/ILoginCredential';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { IResetPassword } from '../models/IResetPassword';
import { IIdTokenPayload } from '../models/IIdTokenPayload';
import { IAuth0TicketDTO } from '../models/DTOs/IAuth0TicketDTO';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private jwtService: JwtHelperService;

  private AccountEndPoint = environment.APIEndpoint +  'frontend/account/ChangePassword/';

  private previousUserName: string;

  constructor(private http: HttpClient) {
    this.jwtService = new JwtHelperService();

    this.previousUserName = '';
  }

  private set accessToken(value: IToken) {
    if (value != null) {
      this.storeAccessToken(value);
    } else {
      this.deleteAccessToken();
    }
  }
  private get accessToken(): IToken {
    return this.getStoredAccessToken();
  }

  private set idToken(value: IToken) {
    if (value != null) {
      this.storeIdToken(value);
    } else {
      this.deleteIdToken();
    }
  }
  private get idToken(): IToken {
    return this.getStoredIdToken();
  }

  // this method needs the AccessToken and IdToken from auth0
  loginWithAuth0(accessToken: string, idToken: string) {
    this.accessToken = {
      authToken: accessToken,
      tokenType: "Bearer",
      expiresInSeconds: 84600
    }

    this.idToken = {
      authToken: idToken,
      tokenType: "Bearer",
      expiresInSeconds: 0
    }

    this.resetLocalStorage();

    // console.log('Access Token: ' + accessToken);
    // console.log('Access Token Claims: ' + JSON.stringify(this.AccessTokenPayload));

    // console.log('Id Token: ' + idToken);
    // console.log('Id Token Claims: ' + JSON.stringify(this.IdTokenPayload));
  }

  // read only property, we will use this property in the future when we have to implement
  // user roles on the cloud frontend based on the claims inside the JWT
  public get AccessTokenPayload(): IAccessTokenPayload {
    if (this.accessToken == null || this.accessToken.authToken === '') {
      // token is not valid
      return null;
    } else {
      // we have a valid token
      // we can decode the JWToken
      return this.jwtService.decodeToken(this.accessToken.authToken);
    }
  }

  public get IdTokenPayload(): IIdTokenPayload {
    if (this.idToken == null || this.idToken.authToken === '') {
      // token is not valid
      return null;
    } else {
      // we have a valid token
      // we can decode the JWToken
      return this.jwtService.decodeToken(this.idToken.authToken);
    }
  }

  public get AccessToken(): IToken {
    return this.accessToken;
  }
  public set AccessToken(value: IToken) {
    this.accessToken = value;
  }

  public get IdToken(): IToken {
    return this.idToken;
  }
  public set IdToken(value: IToken) {
    this.idToken = value;
  }

  ChangePasswordForCurrentUser(): Observable<string> {
    const userId: string = this.IdTokenPayload.sub.replace('auth0|','');
    const clientId: string = environment.auth.clientId;

    const Token: string = this.accessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.AccountEndPoint + userId + '/' + clientId;


      return this.http
        .put(endPoint, null, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IAuth0TicketDTO>) => {

            if (data.ok) {
              return data.body.ticket;
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  private storeAccessToken(token: IToken) {
    if (token != null) {
      localStorage.setItem('accessToken', token.authToken);
      localStorage.setItem('accessTokenType', token.tokenType);
      localStorage.setItem('expiresInSeconds', token.expiresInSeconds.toString());

    }
  }

  private getStoredAccessToken(): IToken {
    const storedToken: IToken = {
      authToken: localStorage.getItem('accessToken'),
      tokenType: localStorage.getItem('accessTokenType'),
      expiresInSeconds: Number(localStorage.getItem('expiresInSeconds')),
    };

    return storedToken;
  }

  private deleteAccessToken() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessTokenType');
    localStorage.removeItem('expiresInSeconds');
  }

  private storeIdToken(token: IToken) {
    if (token != null) {
      localStorage.setItem('idToken', token.authToken);
      localStorage.setItem('idTokenType', token.tokenType);
    }
  }

  private getStoredIdToken(): IToken {
    const storedToken: IToken = {
      authToken: localStorage.getItem('idToken'),
      tokenType: localStorage.getItem('idTokenType'),
      expiresInSeconds: 0,
    };

    return storedToken;
  }

  private deleteIdToken() {
    localStorage.removeItem('idToken');
    localStorage.removeItem('idTokenType');
  }

  public IsAccessTokenExpired(): boolean {
    let bResult = true;

    if (this.accessToken != null && this.accessToken.authToken !== '') {
      bResult = this.jwtService.isTokenExpired(this.accessToken.authToken);
    }

    return bResult;
  }

  public IsLoggedIn(): boolean {
    return (this.accessToken != null && this.accessToken.authToken != null && this.accessToken.authToken.length > 0);
  }

  public LogOut() {
    this.AccessToken = null;
  }

  public get PreviousUserName(): string {
    return this.previousUserName;
  }
  public set PreviousUserName(value: string) {
    this.previousUserName = value;
  }

  public resetLocalStorage() {
    // reset default data for these objects into the Local storage :
    // PaymentsFormParameters
    // DiscountFormParameters
    // SalesFormParameters
    // EowFormParameters
    // EodFormParameters

    localStorage.setItem('PaymentsFormParameters', null);
    localStorage.setItem('DiscountFormParameters', null);
    localStorage.setItem('SalesFormParameters', null);
    localStorage.setItem('EowFormParameters', null);
    localStorage.setItem('EodFormParameters', null);
  }

  public get IsGardiffUser(): Boolean {
    var value = false;

    var payload = this.AccessTokenPayload
    if (payload.role == 'Developer' || payload.role == 'Admin' || payload.role == 'Support') {
      value = true
    }

    return value;
  }
}
