export const environment = {
  description: 'production',
  production: true,
  APIEndpoint: 'https://gardiff-as-prod-eposapi.azurewebsites.net/api/v1/',
  showProduct: true,
  dataTablePageSize: 10,
  dataTablePageSizeOptions: [ 5, 10, 20, 50, 100 ],
  auth: {
    domain: 'gardiffepos.uk.auth0.com',
    clientId: 'D8NVtYoXdBy79MmTPYEOBBkHLjaA1FjS',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: "https://api.gardiffepos.com"
    }
  },
  mongoDB: {
    cluster: 'mongodb-atlas',
    database: 'Site-Sync',
    appServiceName: 'live_dashboards-3_0-pfvgsrd',
    apiKeyName: 'LiveDashboardsKey',
    apiKeyValue: 'H6XxXcGJZhtmMfhrfQUd4hWM2n9kV1fxGB7F4c9yonj16bJQQcVzfrASfpuJ0iJM'
  },
  functionKeys: {
    error: 'ErrorCorrect',
    void: 'VoidProduct'
  },
  gSettings: {
    TradingEndHour: 'TradingEndHour',
    TradingEndMinute: 'TradingEndMinute'
  },
  widgetsColors: [ '#009688', /* Teal 500 */
                  '#ff5722', /* Deep Orange 500*/
                  '#f44336', /* Red 500 */
                  '#ffc107', /* Amber 500 */
                  '#4caf50', /* Green 500 */
                  '#03a9f4', /* Light Blue 500*/
                  '#e91e63', /* Pink 500 */
                  '#607d8b', /* Blue Grey 500*/
                  '#3f51b5', /* Indigo 500 */
                  '#00bcd4', /* Cyan 500 */
                  '#9c27b0', /* Purple 500 */],

  lineWidgetsColors: [ '#009688', /* Teal 500 */
                      '#ff5722', /* Deep Orange 500*/
                      '#ffc107'] /* Amber 500 */,

  primaryColor: '#0277bd' /* Light Blue 800*/,

  categoryColors:  [ '#EF9A9A', '#EF5350', '#F48FB1', '#EC407A', '#CE93D8', '#AB47BC',
                     '#B39DDB', '#9575CD', '#9FA8DA', '#5C6BC0', '#90CAF9', '#1E88E5',
                     '#81D4FA', '#039BE5', '#80DEEA', '#00ACC1', '#80CBC4', '#07c3b3',
                     '#A5D6A7', '#43A047', '#C5E1A5', '#7CB342', '#E6EE9C', '#C0CA33',
                     '#FFF59D', '#FDD835', '#FFE082', '#FFB300', '#FFCC80', '#FB8C00',
                     '#FFAB91', '#FF7043', '#b79464', '#C34121', '#B0BEC5', '#78909C',
                     '#50B5E9', '#33b6e3', '#33a4d9', '#69FF46', '#EDFD06', '#FFAAD3',
                     '#4DC6E1', '#FA6F2E', '#FF5156', '#FF6D22', '#FB1B5A'],
};
