import * as fromRoot from '../app.state';
import { IParentCategory } from 'src/app/models/IParentCategory';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ParentCategoryActions, ParentCategoryActionTypes, } from './parent-category.actions';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus, IGardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import { IParentCategoryItem } from 'src/app/models/IParentCategoryItem';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';

export interface State extends fromRoot.State {
  parentCategory: ParentCategoryState;
}

export interface ParentCategoryState extends IGardiffStoreSliceBase {
  parentCategories: IParentCategory[];
  parentCategoriesFlatList: IParentCategoryItem[];
  statusFlatList: IGardiffStoreSliceStatus;
  apiErrorFlatList: IGenericError;
}

const initialState: ParentCategoryState = {
  parentCategories: [],
  parentCategoriesFlatList: [],
  apiError: null,
  apiErrorFlatList: null,
  status: new GardiffStoreSliceStatus(),
  statusFlatList: new GardiffStoreSliceStatus(),
};

// create the selectors
const getParentCategoryFeatureState = createFeatureSelector<ParentCategoryState>(
  'parentCategory'
);
export const getParentCategoryState = createSelector(
  getParentCategoryFeatureState,
  (state) => state
);

export const getParentCategories = createSelector(
  getParentCategoryFeatureState,
  (state) => state.parentCategories
);

export const getParentCategoriesFlatList = createSelector(
  getParentCategoryFeatureState,
  (state) => state.parentCategoriesFlatList
);

export const getApiError = createSelector(
  getParentCategoryFeatureState,
  (state) => state.apiError
);

export const getApiErrorFlatList = createSelector(
  getParentCategoryFeatureState,
  (state) => state.apiErrorFlatList
);

export const getStatus = createSelector(
  getParentCategoryFeatureState,
  (state) => state.status
);

export const getStatusFlatList = createSelector(
  getParentCategoryFeatureState,
  (state) => state.statusFlatList
);

export function reducer(state: ParentCategoryState = initialState, action: ParentCategoryActions): ParentCategoryState {
  switch (action.type) {
    case ParentCategoryActionTypes.LoadParentCategories:
      return {
        ...state,
        parentCategories: null,
        apiError: null,
        status: null,
      };

    case ParentCategoryActionTypes.LoadParentCategoriesSuccessful:
      return {
        ...state,
        parentCategories: action.payload,
        apiError: null,
        status: new GardiffStoreSliceStatus(true),
      };

    case ParentCategoryActionTypes.LoadParentCategoriesFailed:
      return {
        ...state,
        parentCategories: [],
        apiError: action.payload,
        status: new GardiffStoreSliceStatus(),
      };

    case ParentCategoryActionTypes.SaveParentCategories:
      return {
        ...state,
        parentCategories: null,
        apiError: null,
        status: null,
      };

    case ParentCategoryActionTypes.SaveParentCategoriesSuccessful:
      return {
        ...state,
        parentCategories: action.payload,
        apiError: null,
        status: new GardiffStoreSliceStatus(true),
        parentCategoriesFlatList: initialState.parentCategoriesFlatList,
        apiErrorFlatList: initialState.apiErrorFlatList,
        statusFlatList: initialState.statusFlatList
      };

    case ParentCategoryActionTypes.SaveParentCategoriesFailed:
      return {
        ...state,
        parentCategories: [],
        apiError: action.payload,
        status: new GardiffStoreSliceStatus(),
      };
                                   
    case ParentCategoryActionTypes.LoadParentCategoriesFlatList:
      return {
        ...state,
        parentCategoriesFlatList: null,
        apiErrorFlatList: null,
        statusFlatList: null,
      };

    case ParentCategoryActionTypes.LoadParentCategoriesFlatListSuccessful:
      return {
        ...state,
        parentCategoriesFlatList: action.payload,
        apiErrorFlatList: null,
        statusFlatList: new GardiffStoreSliceStatus(true),
      };

    case ParentCategoryActionTypes.LoadParentCategoriesFlatListFailed:
      return {
        ...state,
        parentCategoriesFlatList: [],
        apiErrorFlatList: action.payload,
        statusFlatList: new GardiffStoreSliceStatus(),
      };

    case ParentCategoryActionTypes.ResetStore:
      return {
        ...state,
        parentCategories: initialState.parentCategories,
        parentCategoriesFlatList: initialState.parentCategoriesFlatList,
        apiError: initialState.apiError,
        apiErrorFlatList: initialState.apiErrorFlatList,
        status: initialState.status,
        statusFlatList: initialState.statusFlatList
      };

    default:
      return state;
  }
}
