import { Injectable } from '@angular/core';
import { Observable, throwError, Subscriber, map, catchError, ReplaySubject, of } from 'rxjs';
import { LoginService } from './login.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IEposManagerVersion } from '../models/IEposManagerVersion';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private eposVersionEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/eposManagerVersion';

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetEposManagerVersion(CorporationID: string, SiteID: string): Observable<string> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.eposVersionEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http.get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<string>) => {

          if (data.ok) {
            return data.body;
          }

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  SplitEposManagerVersion(data: string): Observable<IEposManagerVersion> {
    let details: IEposManagerVersion = {
      major: 0,
      minor: 0,
      patch: 0
    }

    if (data != null) {
      // F.G. I don't know why I have to convert a string using toString() method ...
      // it's the only way if I want use the method Split.
      let list = data.toString().split('.');

      if (list.length > 0) {
        details.major = Number(list[0])
      }

      if (list.length > 1) {
        details.minor = Number(list[1])
      }

      if (list.length > 2) {
        details.patch = Number(list[2])
      }
    }

    return of(details)
  }
}
