import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { ICourse } from 'src/app/models/ICourse';

export enum CoursesActionTypes {
    LoadCourses = '[Courses] Load Courses',
    LoadCoursesSuccessful = '[Courses] Load Courses - Successful',
    LoadCoursesFailed = '[Courses] Load Courses - Failed',
    SaveCourses = '[Courses] Save Courses',
    SaveCoursesSuccessful = '[Courses] Save Courses - Successful',
    SaveCoursesFailed = '[Courses] Save Courses - Failed',
    ResetStore = '[Courses] Reset Store',
}

export class LoadCourses implements Action {
    readonly type = CoursesActionTypes.LoadCourses;

    constructor() { }
}

export class LoadCoursesSuccessful implements Action {
    readonly type = CoursesActionTypes.LoadCoursesSuccessful;

    constructor(public payload: ICourse[]) { }
}

export class LoadCoursesFailed implements Action {
    readonly type = CoursesActionTypes.LoadCoursesFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveCourses implements Action {
    readonly type = CoursesActionTypes.SaveCourses;

    constructor(public oldData: ICourse[], public newData: ICourse[]) { }
}

export class SaveCoursesSuccessful implements Action {
    readonly type = CoursesActionTypes.SaveCoursesSuccessful;

    constructor(public payload: ICourse[]) { }
}

export class SaveCoursesFailed implements Action {
    readonly type = CoursesActionTypes.SaveCoursesFailed;

    constructor(public error: IGenericError, public oldData: ICourse[]) { }
}

export class ResetStore implements Action {
    readonly type = CoursesActionTypes.ResetStore;

    constructor() { }
}

export type CoursesActions = LoadCourses
    | LoadCoursesSuccessful
    | LoadCoursesFailed
    | SaveCourses
    | SaveCoursesSuccessful
    | SaveCoursesFailed
    | ResetStore;
