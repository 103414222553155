import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';

// Admin Store
import * as fromAdmin from './NGRX/admin/admin.reducer';
import * as adminActions from './NGRX/admin/admin.actions';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {

  private downloadingModule: boolean;

  private selectorDarkMode: Subscription;

  @HostBinding('class') className = '';
  darkClass = 'theme-dark'
  lightClass = 'theme-light'

  constructor(private router: Router,
              private titleService: Title,
              @Inject(DOCUMENT) private doc: Document,
              private storeAdmin: Store<fromAdmin.State>,
              private render: Renderer2) {

    this.titleService.setTitle('Gardiff Cloud');
  }

  ngOnInit() {
    this.selectorDarkMode = this.storeAdmin.pipe(select(fromAdmin.getDarkMode)).subscribe(
      (data) => {

        this.className = data? this.darkClass : this.lightClass;

        if (data) {
          this.render.removeClass(this.doc.body, this.lightClass);
          this.render.addClass(this.doc.body, this.darkClass);
        }
        else {
          this.render.removeClass(this.doc.body, this.darkClass);
          this.render.addClass(this.doc.body, this.lightClass);
        }

      }
    );

    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
          this.DownloadingModule = true;
      } else if (event instanceof RouteConfigLoadEnd) {
          this.DownloadingModule = false;
      }
  });
  }

  ngOnDestroy(): void {
    this.selectorDarkMode.unsubscribe();
  }

  public set DownloadingModule(v: boolean) {
    this.downloadingModule = v;
  }
  public get DownloadingModule(): boolean {
    return this.downloadingModule;
  }
}
