import { ChangeType } from "../enums/ChangeType";
import { OperationType } from "../enums/OperationType";

export function toChangeType(operationType: OperationType): ChangeType{
  switch(operationType){
    case OperationType.New:
      return ChangeType.New;
    case OperationType.Edited:
      return ChangeType.Edited;
    case OperationType.Deleted:
      return ChangeType.Deleted;
    default:
      return ChangeType.None;
  }
}
