import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import * as pricelevelSchedulesActions from './schedule-pricelevels.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ISchedulePriceLevel } from 'src/app/models/ISchedulePriceLevel';
import { SchedulePricelevelsService } from 'src/app/services/schedule-pricelevels.service';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';

@Injectable()
export class SchedulePriceLevelsEffects {
  constructor(private action$: Actions,
    private estateService: EstateService,
    private schedulePriceLevelService: SchedulePricelevelsService) { }

  loadSchedulePriceLevels$ = createEffect(
    () => this.action$.pipe(
    ofType(pricelevelSchedulesActions.SchedulePriceLevelsActionTypes.LoadSchedulePriceLevels),
    mergeMap((action: pricelevelSchedulesActions.LoadSchedulePriceLevels) =>
      this.schedulePriceLevelService.GetSchedulePriceLevels(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((schedules: ISchedulePriceLevel[]) => (new pricelevelSchedulesActions.LoadSchedulePriceLevelsSuccessful(schedules))),
        catchError((error: HttpErrorResponse) => of(new pricelevelSchedulesActions.LoadSchedulePriceLevelsFailed(interpretateApiError(error))))
      )
    ))
  );

  restoreBackup$ = createEffect(
    () => this.action$.pipe(
    ofType(pricelevelSchedulesActions.SchedulePriceLevelsActionTypes.RestoreBackup),
    mergeMap((action: pricelevelSchedulesActions.RestoreBackup) =>
      this.schedulePriceLevelService.GetBackup().pipe(
        map((schedules: ISchedulePriceLevel[]) => (new pricelevelSchedulesActions.RestoreBackupSuccessful(schedules))),
        catchError(error => of(new pricelevelSchedulesActions.RestoreBackupFailed()))
        ),
    ))
  );

  saveSchedulePriceLevels$ = createEffect(
    () => this.action$.pipe(
    ofType(pricelevelSchedulesActions.SchedulePriceLevelsActionTypes.SaveSchedulePriceLevels),
    mergeMap((action: pricelevelSchedulesActions.SaveSchedulePriceLevels) =>
      this.schedulePriceLevelService.SaveSchedulePriceLevels(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.payload).pipe(
        map((schedules: ISchedulePriceLevel[]) => new pricelevelSchedulesActions.SaveSchedulePriceLevelsSuccessful(schedules)),
        catchError((error: HttpErrorResponse) => of(new pricelevelSchedulesActions.SaveSchedulePriceLevelsFailed(interpretateApiError(error, HttpVerbType.POST), action.payload)))
      )
    ))
  );
}
