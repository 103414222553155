import { LIDiscountReportField } from "src/app/enums/Report/ReportField/LIDiscountReportField";
import { ReportSortDefinition } from "../Components/IReportSortDefinition";

const localStorageFormParametersProperty = 'DiscountReportParameters';

export class DiscountReportParameters {
  reportGroup: LIDiscountReportField;
  posId: number;
  clerkId: number;
  salesDateFromDay: Date;
  salesDateFromHour: string;
  salesDateFromMinute: string;
  salesDateToDay: Date;
  salesDateToHour: string;
  salesDateToMinute: string;
  sort: ReportSortDefinition[];

  constructor() {
      this.getParameters();
  }

  public storeParameters() {
      localStorage.setItem(localStorageFormParametersProperty, JSON.stringify(this));
  }

  public clearParameters() {
      localStorage.removeItem(localStorageFormParametersProperty);
  }

  private getParameters() {
    const storedParameters: DiscountReportParameters = JSON.parse(localStorage.getItem(localStorageFormParametersProperty));

    if (storedParameters != null) {
      // we found the parameters into the local storage
      this.reportGroup = <LIDiscountReportField>storedParameters.reportGroup;
      this.posId = storedParameters.posId;
      this.clerkId = storedParameters.clerkId;
      this.salesDateFromDay = new Date(storedParameters.salesDateFromDay);
      this.salesDateFromHour = storedParameters.salesDateFromHour;
      this.salesDateFromMinute = storedParameters.salesDateFromMinute;
      this.salesDateToDay = new Date(storedParameters.salesDateToDay);
      this.salesDateToHour = storedParameters.salesDateToHour;
      this.salesDateToMinute = storedParameters.salesDateToMinute;

    } else {
      // we need to generate default value
      this.reportGroup = LIDiscountReportField.None;
      this.posId = -1;
      this.clerkId = -1;
      this.salesDateFromDay = new Date(new Date().setDate(new Date().getDate() - 7));
      this.salesDateFromHour = '00';
      this.salesDateFromMinute = '00';
      this.salesDateToDay = new Date();
      this.salesDateToHour = '23';
      this.salesDateToMinute = '59';

      this.storeParameters();
    }
  }
}
