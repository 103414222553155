import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeType } from '../enums/ChangeType';
import { ITablePlanAreaDTO } from '../models/DTOs/ITablePlanAreaDTO';
import { ITablePlanArea } from '../models/ITablePlanArea';
import { LoginService } from './login.service';
import { DiningTableDTO, IDiningTableDTO } from '../models/DTOs/IDiningTableDTO';
import { IDiningTable } from '../models/IDiningTable';

@Injectable({
  providedIn: 'root'
})
export class TablePlanService {
  private TablePlanEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/TablePlan';

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetTablePlan(CorporationID: string, SiteID: string): Observable<ITablePlanArea[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.TablePlanEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http
        .get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<ITablePlanAreaDTO[]>) => {

            if (data.ok) {
              return this.formatData(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  private formatData(dataList: ITablePlanAreaDTO[]): ITablePlanArea[] {
    let list: ITablePlanArea[] = [];

    // convert list
    dataList.forEach(item => {
      list.push(
        this.ConvertArea(item)
      );
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: ITablePlanArea[]): ITablePlanArea[] {
    list.sort((Item1: ITablePlanArea, Item2: ITablePlanArea) => {
      if (Item1.name > Item2.name) {
        return 1;
      }

      if (Item1.name < Item2.name) {
        return -1;
      }
    });

    return list;
  }

  private sortDiningTables(tables: IDiningTable[], columns: number): IDiningTable[] {

    tables = tables.sort((item1: IDiningTable, item2: IDiningTable) => {
      // calculate the position in list for both items
      const item1Position = (item1.position.y * columns) + (item1.position.x + 1);
      const item2Position = (item2.position.y * columns) + (item2.position.x + 1);

      // then sort the items
      if (item1Position > item2Position) {
        return 1;
      } 
      else if (item1Position < item2Position) {
        return -1;
      } 
    });

    return tables;
  }

  private ConvertArea(item: ITablePlanAreaDTO): ITablePlanArea {
    const newItem: ITablePlanArea = {
      id: item.id,
      name: item.name,
      size: item.size,
      diningTables: [],
      isSelected: false,
      changeState: ChangeType.None
    };

    if (item.diningTables != null) {
      item.diningTables.forEach(table => {
        newItem.diningTables.push({
          shape: table.shape,
          position: table.position.toDiningTablePosition(),
          rotation: table.rotation,
          tableNumber: table.tableNumber,
          size: table.size * 10,
          borderColour: null,
          printGuestReceipt: table.printGuestReceipt,
          autoPromptOrderName: table.autoPromptOrderName,
          autoServiceCharge: table.autoServiceCharge,
          isSelected: false,
          changeState: ChangeType.None
        });
      });
    }

    const areaSize = newItem.size.split(',');
    
    newItem.diningTables = this.sortDiningTables(newItem.diningTables, Number(areaSize[0]));
    return newItem;
  }

  SaveTablePlan(CorporationID: string, SiteID: string, TablePlan: ITablePlanArea[]): Observable<ITablePlanArea[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.TablePlanEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: ITablePlanAreaDTO[] = this.DeflateItems(TablePlan);

      return this.http.post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<ITablePlanAreaDTO[]>) => {
          
            if (data.ok) {
                return this.formatData(data.body);
            }

        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private DeflateItems(list: ITablePlanArea[]): ITablePlanAreaDTO[] {
    const newData: ITablePlanAreaDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        const newArea: ITablePlanAreaDTO = {
          id: item.id,
          name: item.name,
          size: item.size,
          diningTables: []
        };

        item.diningTables.forEach(table => {
          newArea.diningTables.push({
            shape: table.shape,
            position: table.position.x.toString() + "," + table.position.y.toString(),
            rotation: table.rotation,
            tableNumber: table.tableNumber,
            size: table.size / 10,
            printGuestReceipt: table.printGuestReceipt,
            autoPromptOrderName: table.autoPromptOrderName,
            autoServiceCharge: table.autoServiceCharge
          });
        });

        const areaSize = newArea.size.split(',');

        newArea.diningTables = this.sortDiningTablesDTO(newArea.diningTables, Number(areaSize[1]));
        //newArea.diningTables = newArea.diningTables.reverse();

        newData.push(newArea);
      });
    }

    return newData;
  }

  private sortDiningTablesDTO(tables: IDiningTableDTO[], rows: number): IDiningTableDTO[] {

    tables = tables.sort((item1: IDiningTableDTO, item2: IDiningTableDTO) => {
      const cartesian1 = item1.position.split(',');
      const cartesian2 = item2.position.split(',');

      const item1X = Number(cartesian1[0]);
      const item1Y = Number(cartesian1[1]);
      const item2X = Number(cartesian2[0]);
      const item2Y = Number(cartesian2[1]);

      // calculate the position in list for both items
      const item1Position = (item1X * rows) + (item1Y + 1);
      const item2Position = (item2X * rows) + (item2Y + 1);

      // then sort the items
      if (item1Position > item2Position) {
        return 1;
      } 
      else if (item1Position < item2Position) {
        return -1;
      } 
    });

    return tables;
  }
}
