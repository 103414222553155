import * as fromRoot from '../../NGRX/app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TransactionReportActions, TransactionReportActionTypes } from './transaction-report.actions';
import { IReport, Report } from 'src/app/models/Report/Components/IReport';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import { TransactionReportParameters } from 'src/app/models/Report/ReportParameters/ITransactionReportParameters';
import { ITransactionReport, TransactionReport } from 'src/app/models/Report/ITransactionReport';

export interface State extends fromRoot.State {
  TransactionReports: TransactionReportState;
}

export interface TransactionReportState extends IGardiffStoreSliceBase {
  TransactionReportParameters: TransactionReportParameters;
  TransactionReport: ITransactionReport;
  downloadedReportFile: ArrayBuffer;
  downloadedReceiptFile: ArrayBuffer;
}

const initialState: TransactionReportState = {
  TransactionReportParameters: new TransactionReportParameters(),
  TransactionReport: null,
  downloadedReportFile: null,
  downloadedReceiptFile: null,
  apiError: null,
  status: new GardiffStoreSliceStatus()
};

// create the selectors
const getTransactionReportFeatureState = createFeatureSelector<TransactionReportState>('transactionReport');
export const getTransactionReportState = createSelector(
    getTransactionReportFeatureState,
    state => state
);

export const getParameters = createSelector(
    getTransactionReportFeatureState,
    state => state.TransactionReportParameters
);

export const getTransactionReport = createSelector(
    getTransactionReportFeatureState,
    state => state.TransactionReport
);

export const getDownloadedReportFile = createSelector(
    getTransactionReportFeatureState,
    state => state?.downloadedReportFile
);

export const getDownloadedReceiptFile = createSelector(
    getTransactionReportFeatureState,
    state => state?.downloadedReceiptFile
);

export const getApiError = createSelector(
    getTransactionReportFeatureState,
    state => state.apiError
);

export const getStatus = createSelector(
    getTransactionReportFeatureState,
    state => state.status
);

export function reducer(state: TransactionReportState = initialState, action: TransactionReportActions): TransactionReportState {
    switch (action.type) {
        case TransactionReportActionTypes.LoadTransactionReport:
            return {
                ...state,
                TransactionReportParameters: action.payload,
                TransactionReport: null,
                apiError: null,
                status: null
            };

        case TransactionReportActionTypes.LoadTransactionReportSuccessful:
            return {
                ...state,
                TransactionReport: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case TransactionReportActionTypes.LoadTransactionReportFailed:
            return {
                ...state,
                TransactionReport: new TransactionReport(),
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case TransactionReportActionTypes.ResetStore:
          initialState.TransactionReportParameters.clearParameters()
            return {
                ...state,
                TransactionReportParameters : new TransactionReportParameters(),
                TransactionReport: initialState.TransactionReport,
                apiError: initialState.apiError,
                status: initialState.status
            };

        case TransactionReportActionTypes.SaveSearchParameters:
            return {
                ...state,
                TransactionReportParameters: action.payload
            };

        case TransactionReportActionTypes.DownloadPDF:
          return {
              ...state,
              downloadedReportFile: null,
          };

        case TransactionReportActionTypes.DownloadPDFSuccessful:
            return {
                ...state,
                downloadedReportFile: action.payload,
            };

        case TransactionReportActionTypes.DownloadPDFFailed:
            return {
                ...state,
                downloadedReportFile: new ArrayBuffer(0)
            };

        case TransactionReportActionTypes.CleanUpFileBuffer:
            return {
                ...state,
                downloadedReportFile: initialState.downloadedReportFile,
                downloadedReceiptFile: initialState.downloadedReceiptFile
            };

        case TransactionReportActionTypes.DownloadReceiptPDF:
          return {
              ...state,
              downloadedReceiptFile: null,
          };

        case TransactionReportActionTypes.DownloadReceiptPDFSuccessful:
            return {
                ...state,
                downloadedReceiptFile: action.payload,
            };

        case TransactionReportActionTypes.DownloadReceiptPDFFailed:
            return {
                ...state,
                downloadedReceiptFile: new ArrayBuffer(0)
            };

        default:
            return state;
    }
}
