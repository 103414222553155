import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { LoginService } from './login.service';

import { environment } from '../../environments/environment';
import { IDashboardWidget } from '../models/IDashboardWidget';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardWidgetsService {
  private CloudEndPoint: string;
  private widgets: IDashboardWidget[];

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.CloudEndPoint = environment.APIEndpoint + 'frontend/corporation/{corporationId}/site/{{siteId}}/dashboardwidgets';
    this.widgets = [];
  }

  getWidgets(CorporationID: string, SiteID: string): Observable<IDashboardWidget[]> {
    if (!this.loginService.IsAccessTokenExpired() && SiteID != null && SiteID !== '') {
      const endPoint = this.CloudEndPoint.replace('{corporationId}', CorporationID).replace('{{siteId}}', SiteID);
      //console.log('widgets for : ' + endPoint);

      const token: string = this.loginService.AccessToken.authToken;

      return this.http.get<IDashboardWidget[]>(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token ) })
        .pipe(
          map((data: IDashboardWidget[]) => {
            this.widgets = data;

            return data;
          }),
          catchError((err: HttpErrorResponse) => {
            this.widgets = [];

            console.log('error : ' + JSON.stringify(err.message));
            return throwError(() => err.message);
          })
        );
    } else {
      console.log('user not logged in.');
      return throwError(() => 'user not logged in.');
    }
  }

  public get Widgets(): IDashboardWidget[] {
    return this.widgets;
  }
  
}
