import { Action } from "@ngrx/store";
import { ITaxBand } from "src/app/models/ITaxBand";
import { IGenericError } from "src/app/models/apiErrorDTOs/IGenericError";

export enum TaxBandsActionTypes {
    LoadTaxBands = '[TaxBands] Load Tax Bands',
    LoadTaxBandsSuccessful = '[TaxBands] Load Tax Bands - Successful',
    LoadTaxBandsFailed = '[TaxBands] Load Tax Bands - Failed',
    SaveTaxBands = '[TaxBands] Save Tax Bands',
    SaveTaxBandsSuccessful = '[TaxBands] Save Tax Bands - Successful',
    SaveTaxBandsFailed = '[TaxBands] Save Tax Bands - Failed',
    ResetStore = '[TaxBands] Reset Store',
}

export class LoadTaxBands implements Action {
    readonly type = TaxBandsActionTypes.LoadTaxBands;

    constructor() { }
}

export class LoadTaxBandsSuccessful implements Action {
    readonly type = TaxBandsActionTypes.LoadTaxBandsSuccessful;

    constructor(public payload: ITaxBand[]) { }
}

export class LoadTaxBandsFailed implements Action {
    readonly type = TaxBandsActionTypes.LoadTaxBandsFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveTaxBands implements Action {
    readonly type = TaxBandsActionTypes.SaveTaxBands;

    constructor(public payload: ITaxBand[]) { }
}

export class SaveTaxBandsSuccessful implements Action {
    readonly type = TaxBandsActionTypes.SaveTaxBandsSuccessful;

    constructor(public payload: ITaxBand[]) { }
}

export class SaveTaxBandsFailed implements Action {
    readonly type = TaxBandsActionTypes.SaveTaxBandsFailed;

    constructor(public error: IGenericError, public oldData: ITaxBand[]) { }
}

export class ResetStore implements Action {
    readonly type = TaxBandsActionTypes.ResetStore;

    constructor() { }
}

export type TaxBandsActions = LoadTaxBands
    | LoadTaxBandsSuccessful
    | LoadTaxBandsFailed
    | SaveTaxBands
    | SaveTaxBandsSuccessful
    | SaveTaxBandsFailed
    | ResetStore;
