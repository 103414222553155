import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import * as TransactionReportActions from './transaction-report.actions';
import { mergeMap, map, catchError, exhaustMap } from 'rxjs/operators';

import { of } from 'rxjs';
import { TransactionReportService } from 'src/app/services/transaction-report.service';
import { IReport } from 'src/app/models/Report/Components/IReport';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { ITransactionReport } from 'src/app/models/Report/ITransactionReport';

@Injectable()
export class TransactionReportEffects {
  constructor(private action$: Actions, private transactionReportService: TransactionReportService) { }

  loadReport$ = createEffect(
    () => this.action$.pipe(
      ofType(TransactionReportActions.TransactionReportActionTypes.LoadTransactionReport),
      mergeMap((action: TransactionReportActions.LoadTransactionReport) => {

          // store the parameters into the localstorage
          action.payload.storeParameters();

          return this.transactionReportService.getTransactionReport(action.payload).pipe(
              map((report: ITransactionReport) => (new TransactionReportActions.LoadTransactionReportSuccessful(report)),
              catchError((error: HttpErrorResponse) => of(new TransactionReportActions.LoadTransactionReportFailed(interpretateApiError(error))))
          ));
        }
      )
    )
  );

  downloadTransactionReportFile$ = createEffect(
    () => this.action$.pipe(
      ofType(TransactionReportActions.TransactionReportActionTypes.DownloadPDF),
      mergeMap((action: TransactionReportActions.DownloadPDF) => {

        return this.transactionReportService.getReportFilePDF(action.payload).pipe(
          map((file: ArrayBuffer) => (new TransactionReportActions.DownloadPDFSuccessful(file)),
          catchError((error: HttpErrorResponse) => of(new TransactionReportActions.DownloadPDFFailed(interpretateApiError(error))))
        ));
      }
    ))
  );

  downloadReceiptFile$ = createEffect(
    () => this.action$.pipe(
      ofType(TransactionReportActions.TransactionReportActionTypes.DownloadReceiptPDF),
      mergeMap((action: TransactionReportActions.DownloadReceiptPDF) => {

        return this.transactionReportService.getReceiptFilePDF(action.payload).pipe(
          map((file: ArrayBuffer) => (new TransactionReportActions.DownloadReceiptPDFSuccessful(file)),
          catchError((error: HttpErrorResponse) => of(new TransactionReportActions.DownloadReceiptPDFFailed(interpretateApiError(error))))
        ));
      }
    ))
  );
}
