import * as fromRoot from '../../../NGRX/app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EstateActions, EstateActionTypes } from './estate.actions';
import { IEstateView, EstateView } from 'src/app/models/IEstateView';
import { IBrand, Brand } from 'src/app/models/IBrand';
import { IArea, Area } from 'src/app/models/IArea';
import { ISite, Site } from 'src/app/models/ISite';
import { ISiteDetails, SiteDetails } from 'src/app/models/ISiteDetails';

export interface State extends fromRoot.State {
    estateSelector: EstateState;
}

export interface EstateState {
    estateView: IEstateView;
    selectedBrand: IBrand;
    selectedArea: IArea;
    selectedSite: ISite;
    selectedSiteDetails: ISiteDetails;
}

const initialState: EstateState = {
    estateView: new EstateView(),
    selectedBrand: new Brand(),
    selectedArea: new Area(),
    selectedSite: new Site(),
    selectedSiteDetails: new SiteDetails(),
};

// create the selectors
const getEstateFeatureState = createFeatureSelector<EstateState>('estateselector');
export const getEstateState = createSelector(
    getEstateFeatureState,
    state => state
);

export const getEstateView = createSelector(
    getEstateFeatureState,
    state => state.estateView
);

export const getSelectedBrand = createSelector(
    getEstateFeatureState,
    state => state.selectedBrand
);

export const getSelectedArea = createSelector(
    getEstateFeatureState,
    state => state.selectedArea
);

export const getSelectedSite = createSelector(
    getEstateFeatureState,
    state => state.selectedSite
);

export const getSelectedSiteDetails = createSelector(
    getEstateFeatureState,
    state => state.selectedSiteDetails
);

export function reducer(state: EstateState = initialState, action: EstateActions): EstateState {
    //console.log('estate selector initial state : ' + JSON.stringify(state) + ' / action : ' + action );
    switch (action.type) {
        case EstateActionTypes.LoadEstate:
            return {
                ...state,
                estateView: initialState.estateView
            };

        case EstateActionTypes.LoadEstateSuccessful:
            return {
                ...state,
                estateView: action.payload
            };

        case EstateActionTypes.LoadEstateFailed:
            return {
                ...state,
                estateView: initialState.estateView
            };

        case EstateActionTypes.LoadEstateViewMonitor:
            return {
                ...state,
                estateView: initialState.estateView
            };

        case EstateActionTypes.LoadEstateViewMonitorSuccessful:
            return {
                ...state,
                estateView: action.payload,
                selectedBrand: action.payload.brands[0],
                selectedArea: action.payload.brands[0].areas[0],
                selectedSite: action.payload.brands[0].areas[0].sites[0]
            };

        case EstateActionTypes.LoadEstateViewMonitorFailed:
            return {
                ...state,
                estateView: initialState.estateView
            };

        case EstateActionTypes.LoadInitialSite:
            return {
                ...state,
                estateView: null,
                selectedBrand: null,
                selectedArea: null,
                selectedSite: null
            };

        case EstateActionTypes.LoadInitialSiteSuccessful:
            return {
                ...state,
                estateView: action.estate,
                selectedBrand: action.selectedBrand,
                selectedArea: action.selectedArea,
                selectedSite: action.selectedSite
            };

        case EstateActionTypes.LoadInitialSiteFailed:
            return {
                ...state,
                estateView: initialState.estateView,
                selectedBrand: new Brand(),
                selectedArea: new Area(),
                selectedSite: new Site()
            };

        case EstateActionTypes.ChangeSite:
            return {
                ...state,
                selectedBrand: action.payload.brand,
                selectedArea: action.payload.area,
                selectedSite: action.payload.site
            };

        case EstateActionTypes.LoadSiteDetails:
            return {
                ...state,
                selectedSiteDetails: null
            };

        case EstateActionTypes.LoadSiteDetailsSuccessful:
            return {
                ...state,
                selectedSiteDetails: action.payload
            };

        case EstateActionTypes.LoadSiteDetailsFailed:
            return {
                ...state,
                selectedSiteDetails: initialState.selectedSiteDetails
            };

        case EstateActionTypes.SaveSiteDetails:
            return {
                ...state,
                selectedSiteDetails: null
            };

        case EstateActionTypes.SaveSiteDetailsSuccessful:
            return {
                ...state,
                selectedSiteDetails: action.payload
            };

        case EstateActionTypes.SaveSiteDetailsFailed:
            return {
                ...state,
                selectedSiteDetails: initialState.selectedSiteDetails
            };

        case EstateActionTypes.ResetStore:
            return {
                ...state,
                estateView: new EstateView(),
                selectedBrand: new Brand(),
                selectedArea: new Area(),
                selectedSite: new Site(),
                selectedSiteDetails: null,
            };

        case EstateActionTypes.ResetSiteDetails:
            return {
                ...state,
                selectedSiteDetails: new SiteDetails(),
            };

        default:
            return state;
    }
}
