import { ProductSalesReportGroupOptionType } from '../enums/ProductSalesReportGroupOptionType';
import { ProductSalesReportSubGroupOptionType } from '../enums/ProductSalesReportSubGroupOptionType';

export interface ISalesReportFormParameters {
  searchFromDate: Date;
  searchFromHour: string;
  searchFromMinute: string;
  searchToDate: Date;
  searchToHour: string;
  searchToMinute: string;
  selectedClerkID: string;
  selectedPosMachineID: string;
  selectedCategoryID: string;
  selectedParentCategoryID: string;
  selectedCourseID: string;
  groupBy: ProductSalesReportGroupOptionType;
  subGroupBy: ProductSalesReportSubGroupOptionType;
  showDetails: boolean;
  includeTax: boolean;
}

const localStorageFormParametersProperty = 'SalesFormParameters';

export class SalesReportFormParameters implements ISalesReportFormParameters {
  searchFromDate: Date;
  searchFromHour: string;
  searchFromMinute: string;
  searchToDate: Date;
  searchToHour: string;
  searchToMinute: string;
  selectedClerkID: string;
  selectedPosMachineID: string;
  selectedCategoryID: string;
  selectedParentCategoryID: string;
  selectedCourseID: string;
  groupBy: ProductSalesReportGroupOptionType;
  subGroupBy: ProductSalesReportSubGroupOptionType;
  showDetails: boolean;
  includeTax: boolean;

  constructor() {
      this.getParameters();
  }

  public storeParameters() {
      localStorage.setItem(localStorageFormParametersProperty, JSON.stringify(this));
  }

  private getParameters() {
    const storedParameters: ISalesReportFormParameters = JSON.parse(localStorage.getItem(localStorageFormParametersProperty));

    if (storedParameters != null) {
      // we found the parameters into the local storage
      this.searchFromDate = new Date(storedParameters.searchFromDate);
      this.searchFromHour = storedParameters.searchFromHour;
      this.searchFromMinute = storedParameters.searchFromMinute;
      this.searchToDate = new Date(storedParameters.searchToDate);
      this.searchToHour = storedParameters.searchToHour;
      this.searchToMinute = storedParameters.searchToMinute;
      this.selectedClerkID = storedParameters.selectedClerkID;
      this.selectedPosMachineID = storedParameters.selectedPosMachineID;
      this.selectedCategoryID = storedParameters.selectedCategoryID;
      this.selectedParentCategoryID = storedParameters.selectedParentCategoryID;
      this.selectedCourseID = storedParameters.selectedCourseID;
      this.groupBy = <ProductSalesReportGroupOptionType>storedParameters.groupBy;

      // check compatibility with older shape of parameters object

      // check if subGroupBy is valid
      if (storedParameters.subGroupBy == null) {
        this.subGroupBy = ProductSalesReportSubGroupOptionType.None;
      } else {
        this.subGroupBy = <ProductSalesReportSubGroupOptionType>storedParameters.subGroupBy;
      }

      // check if showDetails is valid
      if (storedParameters.showDetails == null) {
        this.showDetails = false;
      } else {
        this.showDetails = storedParameters.showDetails;
      }

      if (storedParameters.includeTax == null) {
        this.includeTax = true;
      } else {
        this.includeTax = storedParameters.includeTax;
      }


    } else {
      // we need to generate default value
      this.searchFromDate = new Date(new Date().setDate(new Date().getDate() - 7));
      this.searchFromHour = '00';
      this.searchFromMinute = '00';
      this.searchToDate = new Date();
      this.searchToHour = '23';
      this.searchToMinute = '59';
      this.selectedClerkID = '00000000-0000-0000-0000-000000000000';
      this.selectedPosMachineID = '00000000-0000-0000-0000-000000000000';
      this.selectedCategoryID = '00000000-0000-0000-0000-000000000000';
      this.selectedParentCategoryID = '00000000-0000-0000-0000-000000000000';
      this.selectedCourseID = '00000000-0000-0000-0000-000000000000';
      this.groupBy = ProductSalesReportGroupOptionType.None;
      this.subGroupBy = ProductSalesReportSubGroupOptionType.None;
      this.showDetails = false;
      this.includeTax = false;

      this.storeParameters();
    }

  }
}
