import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { IEopReportSectionDetailDTO } from './IEopReportSectionDetailDTO';

@JsonObject()
export class EopReportSectionDetailTaxtDTO implements IEopReportSectionDetailDTO {
  
  @JsonProperty('DC')
  public description: string;

  @JsonProperty('DA')
  public gross: number;

  @JsonProperty('DB')
  public net: number;

  @JsonProperty('DD')
  public tax: number;
}
