import { ChangeType } from "../enums/ChangeType";
import { ISiteDetailsDTO } from "./DTOs/ISiteDetailsDTO";
import { IChangeState } from "./Extensions/IChangeState";

export interface ISiteDetails extends ISiteDetailsDTO, IChangeState {
    
}

export class SiteDetails implements ISiteDetails {
    id: string;
    siteGuid: string;

    companyName: string;
    siteName: string;
    siteId: string;
    
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    town: string;
    postCode: string;
    county: string;
    
    phonePrimary: string;
    phoneSecondary: string;
    emailPrimary: string;
    emailSecondary: string;
    website: string;
    
    vatNumber: string;

    changeState: ChangeType;

    constructor() {
        this.id = '';
        this.siteGuid = '';
        
        this.companyName = '';
        this.siteName = '';
        this.siteId = '';

        this.addressLine1 = '';
        this.addressLine2 = '';
        this.addressLine3 = '';
        this.town = '';
        this.postCode = '';
        this.county = '';
        this.phonePrimary = '';
        this.phoneSecondary = '';
        this.emailPrimary = '';
        this.emailSecondary = '';
        this.website = '';
        this.vatNumber = '';

        this.changeState = ChangeType.None;
    }
}
