import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./feature-modules/login/login.module').then(m => m.LoginModule) },
  { path: 'Login', loadChildren: () => import('./feature-modules/login/login.module').then(m => m.LoginModule)},
  { path: 'Logout', loadChildren: () => import('./feature-modules/logout/logout.module').then(m => m.LogoutModule)},
  { path: 'ChangePassword', loadChildren: () => import('./feature-modules/change-password/change-password.module').then(m => m.ChangePasswordModule)},

  { path: 'Monitor', loadChildren: () => import('./feature-modules/monitor/monitor.module').then(m => m.MonitorModule) },
  { path: 'Home', loadChildren: () => import('./feature-modules/home/home.module').then(m => m.HomeModule) },
  { path: 'Estates', loadChildren: () => import('./feature-modules/estate/estate.module').then(m => m.EstateModule)},

  { path: 'SalesReport', loadChildren: () => import('./feature-modules/sales-report/sales-report.module').then(m => m.SalesReportModule)},
  { path: 'PaymentsReport', loadChildren: () => import('./feature-modules/payments-report/payments-report.module').then(m => m.PaymentsReportModule)},
  { path: 'EOD', loadChildren: () => import('./feature-modules/eod/eod.module').then(m => m.EodModule)},
  { path: 'EOW', loadChildren: () => import('./feature-modules/eow/eow.module').then(m => m.EowModule)},
  { path: 'DiscountReport', loadChildren: () => import('./feature-modules/discount-report/discount-report.module').then(m => m.DiscountReportModule)},
  { path: 'VoidReport', loadChildren: () => import('./feature-modules/void-report/void-report.module').then(m => m.VoidReportModule)},
  { path: 'TransactionReport', loadChildren: () => import('./feature-modules/transaction-report/transaction-report.module').then(m => m.TransactionReportModule)},
  
  { path: 'ProductEditor', loadChildren: () => import('./feature-modules/product-editor/product-editor.module').then(m => m.ProductEditorModule)},
  { path: 'MassPriceEditor', loadChildren: () => import('./feature-modules/mass-price-editor/mass-price-editor.module').then(m => m.MassPriceEditorModule)},
  { path: 'Categories', loadChildren: () => import('./feature-modules/category-editor/category-editor.module').then(m => m.CategoryEditorModule)},
  { path: 'ParentCategories', loadChildren: () => import('./feature-modules/parent-category-editor/parent-category-editor.module').then(m => m.ParentCategoryEditorModule)},
  { path: 'Courses', loadChildren: () => import('./feature-modules/courses/courses.module').then(m => m.CoursesModule)},
  { path: 'Pricelevels', loadChildren: () => import('./feature-modules/pricelevels/pricelevels.module').then(m => m.PricelevelsModule)},
  { path: 'SalesArea', loadChildren: () => import('./feature-modules/sales-area/sales-area.module').then(m => m.SalesAreaModule)},
  { path: 'PriceLevelSchedules', loadChildren: () => import('./feature-modules/pricelevel-schedules/pricelevel-schedules.module').then(m => m.PricelevelSchedulesModule)},
  { path: 'Addons', loadChildren: () => import('./feature-modules/addons/addons.module').then(m => m.AddonsModule)},
  { path: 'Discounts', loadChildren: () => import('./feature-modules/discounts/discounts.module').then(m => m.DiscountsModule)},
  { path: 'TaxBands', loadChildren: () => import('./feature-modules/tax-bands/tax-bands.module').then(m => m.TaxBandsModule)},

  { path: 'TablePlan', loadChildren: () => import('./feature-modules/table-plan/table-plan.module').then(m => m.TablePlanModule)},
  { path: 'MenuPlan', loadChildren: () => import('./feature-modules/menu-plan/menu-plan.module').then(m => m.MenuPlanModule)},
  { path: 'LogOnScreen', loadChildren: () => import('./feature-modules/logon-screen/logon-screen.module').then(m => m.LogonScreenModule)},

  { path: 'RoleFunctions', loadChildren: () => import('./feature-modules/role-functions/role-functions.module').then(m => m.RoleFunctionsModule) },
  { path: 'RoleUsers', loadChildren: () => import('./feature-modules/role-users/role-users.module').then(m => m.RoleUsersModule) },

  { path: 'EposSettings', loadChildren: () => import('./feature-modules/epos-settings/epos-settings.module').then(m => m.EposSettingsModule) },
  { path: 'PosSetup', loadChildren: () => import('./feature-modules/pos-setup/pos-setup.module').then(m => m.PosSetupModule) },
  { path: 'Printers', loadChildren: () => import('./feature-modules/printers/printers.module').then(m => m.PrintersModule) },
  { path: 'KitchenVideos', loadChildren: () => import('./feature-modules/kitchen-videos/kitchen-videos.module').then(m => m.KitchenVideosModule) },
  { path: 'SiteDetails', loadChildren: () => import('./feature-modules/site-details/site-details.module').then(m => m.SiteDetailsModule) },
  { path: 'WeightMeasures', loadChildren: () => import('./feature-modules/weight-measures/weight-measures.module').then(m => m.WeightMeasuresModule) },
  { path: 'ZReportSetup', loadChildren: () => import('./feature-modules/zreport-setup/zreport-setup.module').then(m => m.ZReportSetupModule) },

  // this instruction is for 'Path not found (404)'
  { path: '**', redirectTo: '' },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})],
})
export class AppRoutingModule {

}
