import { Action } from '@ngrx/store';
import { IPosSetup } from 'src/app/models/IPosSetup';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';

export enum PosSetupActionTypes {
    LoadPosSetup = '[Pos Setup] Load PosSetup',
    LoadPosSetupSuccessful = '[Pos Setup] Load PosSetup - Successful',
    LoadPosSetupFailed = '[Pos Setup] Load PosSetup - Failed',
    SavePosSetup = '[Pos Setup] Save PosSetup',
    SavePosSetupSuccessful = '[Pos Setup] Save PosSetup - Successful',
    SavePosSetupFailed = '[Pos Setup] Save PosSetup - Failed',
    ResetStore = '[Pos Setup] Reset Store',
}

export class LoadPosSetup implements Action {
    readonly type = PosSetupActionTypes.LoadPosSetup;

    constructor() { }
}

export class LoadPosSetupSuccessful implements Action {
    readonly type = PosSetupActionTypes.LoadPosSetupSuccessful;

    constructor(public payload: IPosSetup[]) { }
}

export class LoadPosSetupFailed implements Action {
    readonly type = PosSetupActionTypes.LoadPosSetupFailed;

    constructor(public payload: IGenericError) { }
}

export class SavePosSetup implements Action {
    readonly type = PosSetupActionTypes.SavePosSetup;

    constructor(public oldData: IPosSetup[], public newData: IPosSetup[]) { }
}

export class SavePosSetupSuccessful implements Action {
    readonly type = PosSetupActionTypes.SavePosSetupSuccessful;

    constructor(public payload: IPosSetup[]) { }
}

export class SavePosSetupFailed implements Action {
    readonly type = PosSetupActionTypes.SavePosSetupFailed;

    constructor(public error: IGenericError, public oldData: IPosSetup[]) { }
}

export class ResetStore implements Action {
    readonly type = PosSetupActionTypes.ResetStore;

    constructor() { }
}

export type PosSetupActions = LoadPosSetup
    | LoadPosSetupSuccessful
    | LoadPosSetupFailed
    | SavePosSetup
    | SavePosSetupSuccessful
    | SavePosSetupFailed
    | ResetStore;
