export interface IEowFormParameters {
  searchFromDate: Date;
  searchToDate: Date;
}

const localStorageFormParametersProperty = 'EowFormParameters';

export class EowFormParameters implements IEowFormParameters {
  searchFromDate: Date;
  searchToDate: Date;

  constructor() {
      this.getParameters();
  }

  public storeParameters() {
    localStorage.setItem(localStorageFormParametersProperty, JSON.stringify(this));
  }

  private getParameters() {
    const storedParameters: IEowFormParameters = JSON.parse(localStorage.getItem(localStorageFormParametersProperty));

    if (storedParameters != null) {
      // we found the parameters into the local storage
      this.searchFromDate = new Date(storedParameters.searchFromDate);
      this.searchToDate = new Date(storedParameters.searchToDate);
    } else {
      // we need to generate default value
      this.searchFromDate = new Date(new Date().setDate(new Date().getDate() - 7));
      this.searchToDate = new Date();

      this.storeParameters();
    }

  }
}
