import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { IEopReportSectionDetailDTO } from './IEopReportSectionDetailDTO';

@JsonObject()
export class EopReportSectionDetailDescriptionDTO implements IEopReportSectionDetailDTO {

  @JsonProperty('DC')
  public description: string;

}
