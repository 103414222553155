import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { ReportService } from '../../../services/report.service';

import * as eodReportActions from './eodReport.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { IEODItem } from '../../../models/IEODItem';

import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { IEodReportDocument } from 'src/app/models/IEodReportDocument';

@Injectable()
export class EodReportEffects {
    constructor(private action$: Actions, private eodReportService: ReportService) {

    }

    loadEodItems$ = createEffect(
      () => this.action$.pipe(
        ofType(eodReportActions.EodReportActionTypes.LoadEOD),
        mergeMap((action: eodReportActions.LoadEOD) => {
          let fromDate: Date;
          let toDate: Date;

          fromDate = action.payload.searchFromDate;
          fromDate.setHours( Number(action.payload.searchFromHour), Number(action.payload.searchFromMinute), 0, 0);

          toDate = action.payload.searchToDate;
          toDate.setHours( Number(action.payload.searchToHour), Number(action.payload.searchToMinute), 59, 999);

          // store the parameters into the localstorage
          action.payload.storeParameters();

          return this.eodReportService.getEODReportsList(fromDate, toDate).pipe(
            map((items: IEODItem[]) => (new eodReportActions.LoadEODSuccessful(items)),
            catchError((error: HttpErrorResponse) => of(new eodReportActions.LoadEODFailed(interpretateApiError(error))))
          ));
        }
        ))
    );

    selectEOD$ = createEffect(
      () => this.action$.pipe(
        ofType(eodReportActions.EodReportActionTypes.SelectEOD),
        mergeMap((action: eodReportActions.SelectEOD) => {
          return this.eodReportService.getEODReport(action.payload.eodId).pipe(
            map((eodDocument: IEodReportDocument) => new eodReportActions.SelectedEOD(eodDocument),
            catchError(err => of(new eodReportActions.SelectEODFailed()))
          ));
        }
      ))
    );

    downloadEodFile$ = createEffect(
      () => this.action$.pipe(
        ofType(eodReportActions.EodReportActionTypes.DownloadEOD),
        mergeMap((action: eodReportActions.DownloadEOD) =>
            this.eodReportService.getEodReportFile(action.payload).pipe(
                map((file: ArrayBuffer) => (new eodReportActions.DownloadEODSuccessful(file)),
                catchError((error: HttpErrorResponse) => of(new eodReportActions.DownloadEODFailed(interpretateApiError(error))))
        )),
      ))
    );
}
