import { Action } from '@ngrx/store';
import { ICategory } from 'src/app/models/ICategory';
import { IPrinter } from 'src/app/models/IPrinter';
import { IKitchenVideo } from 'src/app/models/IKitchenVideo';
import { IMeasure } from 'src/app/models/IMeasure';
import { IPriceLevel } from 'src/app/models/IPriceLevel';
import { ICourse } from 'src/app/models/ICourse';
import { IProduct } from 'src/app/models/IProduct';
import { ISchedulePriceLevel } from 'src/app/models/ISchedulePriceLevel';
import { IAddon } from 'src/app/models/IAddon';
import { IPosSetup } from 'src/app/models/IPosSetup';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';

export enum ProductsActionTypes {
    LoadProducts = '[Products] Load Products',
    LoadProductsSuccessful = '[Products] Load Products - Successful',
    LoadProductsFailed = '[Products] Load Products - Failed',

    SaveProducts = '[Products] Save Products',
    SaveProductsSuccessful = '[Products] Save Products - Successful',
    SaveProductsFailed = '[Products] Save Products - Failed',

    RestoreBackup = '[Products] Restore Backup',
    RestoreBackupSuccessful = '[Products] Restore Backup - Successful',
    RestoreBackupFailed = '[Products] Restore Backup - Failed',

    ResetStore = '[Products] Reset Store',
}

export class LoadProducts implements Action {
    readonly type = ProductsActionTypes.LoadProducts;

    constructor() { }
}

export class LoadProductsSuccessful implements Action {
    readonly type = ProductsActionTypes.LoadProductsSuccessful;

    constructor(public payload: IProduct[]) { }
}

export class LoadProductsFailed implements Action {
    readonly type = ProductsActionTypes.LoadProductsFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveProducts implements Action {
    readonly type = ProductsActionTypes.SaveProducts;

    constructor(public payload: IProduct[]) { }
}

export class SaveProductsSuccessful implements Action {
    readonly type = ProductsActionTypes.SaveProductsSuccessful;

    constructor(public payload: IProduct[]) { }
}

export class SaveProductsFailed implements Action {
    readonly type = ProductsActionTypes.SaveProductsFailed;

    constructor(public error: IGenericError, public oldData: IProduct[]) { }
}

export class RestoreBackup implements Action {
  readonly type = ProductsActionTypes.RestoreBackup;

  constructor() { }
}

export class RestoreBackupSuccessful implements Action {
  readonly type = ProductsActionTypes.RestoreBackupSuccessful;

  constructor(public payload: IProduct[]) { }
}

export class RestoreBackupFailed implements Action {
  readonly type = ProductsActionTypes.RestoreBackupFailed;

  constructor() { }
}

export class ResetStore implements Action {
    readonly type = ProductsActionTypes.ResetStore;

    constructor() { }
}

export type ProductsActions = LoadProducts
    | LoadProductsSuccessful
    | LoadProductsFailed
    | SaveProducts
    | SaveProductsSuccessful
    | SaveProductsFailed
    | RestoreBackup
    | RestoreBackupSuccessful
    | RestoreBackupFailed
    | ResetStore;
