import { Action } from '@ngrx/store';
import { IEposManagerVersion } from 'src/app/models/IEposManagerVersion';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';

export enum AdminActionTypes {
    LoadEposManagerVersion = '[Admin] Load Epos Manager Version',
    LoadEposManagerVersionSuccessful = '[Admin] Load Epos Manager Version - Successful',
    LoadEposManagerVersionFailed = '[Admin] Load Epos Manager Version - Failed',
    SplitEposManagerVersion = '[Admin] Split Epos Manager Version',
    SplitEposManagerVersionSuccessful = '[Admin] Split Epos Manager Version - Successful',
    SplitEposManagerVersionFailed = '[Admin] Split Epos Manager Version - Failed',
    ActivateTaxFeature = '[Admin] Activate Tax',
    ActivateLiveDashboardFeature = '[Admin] Activate Live Dashboard',
    ActivateVoidReportFeature = '[Admin] Activate Void Report',
    ActivateTransactionReportFeature = '[Admin] Activate Transaction Report',
    ActivateDiscountReportFeature = '[Admin] Activate Discount Report',
    ActivateDarkMode = '[Admin] Activate Dark Mode',
    ResetStore = '[Admin] Reset Store',
}

export class LoadEposManagerVersion implements Action {
    readonly type = AdminActionTypes.LoadEposManagerVersion;

    constructor() { }
}

export class LoadEposManagerVersionSuccessful implements Action {
    readonly type = AdminActionTypes.LoadEposManagerVersionSuccessful;

    constructor(public payload: string) { }
}

export class LoadEposManagerVersionFailed implements Action {
    readonly type = AdminActionTypes.LoadEposManagerVersionFailed;

    constructor(public payload: IGenericError) { }
}

export class SplitEposManagerVersion implements Action {
    readonly type = AdminActionTypes.SplitEposManagerVersion;

    constructor(public payload: string) { }
}

export class SplitEposManagerVersionSuccessful implements Action {
    readonly type = AdminActionTypes.SplitEposManagerVersionSuccessful;

    constructor(public payload: IEposManagerVersion) { }
}

export class SplitEposManagerVersionFailed implements Action {
    readonly type = AdminActionTypes.SplitEposManagerVersionFailed;

    constructor(public payload: IGenericError) { }
}

export class ActivateTaxFeature implements Action {
    readonly type = AdminActionTypes.ActivateTaxFeature;

    constructor(public payload: boolean) { }
}

export class ActivateLiveDashboardFeature implements Action {
    readonly type = AdminActionTypes.ActivateLiveDashboardFeature;

    constructor(public payload: boolean) { }
}

export class ActivateVoidReportFeature implements Action {
    readonly type = AdminActionTypes.ActivateVoidReportFeature;

    constructor(public payload: boolean) { }
}

export class ActivateTransactionReportFeature implements Action {
    readonly type = AdminActionTypes.ActivateTransactionReportFeature;

    constructor(public payload: boolean) { }
}

export class ActivateDiscountReportFeature implements Action {
    readonly type = AdminActionTypes.ActivateDiscountReportFeature;

    constructor(public payload: boolean) { }
}

export class ActivateDarkMode implements Action {
    readonly type = AdminActionTypes.ActivateDarkMode;

    constructor(public payload: boolean) { }
}

export class ResetStore implements Action {
    readonly type = AdminActionTypes.ResetStore;

    constructor() { }
}

export type AdminActions = LoadEposManagerVersion
    | LoadEposManagerVersionSuccessful
    | LoadEposManagerVersionFailed
    | SplitEposManagerVersion
    | SplitEposManagerVersionSuccessful
    | SplitEposManagerVersionFailed
    | ActivateTaxFeature
    | ActivateLiveDashboardFeature
    | ActivateVoidReportFeature
    | ActivateTransactionReportFeature
    | ActivateDiscountReportFeature
    | ActivateDarkMode
    | ResetStore;
