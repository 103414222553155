import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { DatePipe } from '@angular/common';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import { JwtModule } from '@auth0/angular-jwt';

import { ServicesModule } from './services/services.module';
import { StartupModuleModule } from './feature-modules/startup-module/startup-module.module';
import { NgrxStoreModule } from './NGRX/ngrx-store.module';
import { GardiffPreloadingStrategy } from './preloading-strategy';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from "@angular/router";

import * as Sentry from "@sentry/angular-ivy";

import { AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';

/*  Main module
    Browser, AppRouting, BrowserAnimations, HttpClient, JwtModule are fundamentals on the startup

    ServicesModule: it's a container for all services that we use in the application,
    if is not imported here we will get a different instance of the service for every module

    StartupModule: contains the loading component the we show while the app is downloading a module by lazy loading

    NgrxStoreModule: contains all the necessary imports for the NGRX store, Effects and Store DevTools,
    if is not imported here we will get a different store for every module
*/
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServicesModule,
    HttpClientModule,
    JwtModule,
    MatProgressSpinnerModule,
    NgrxStoreModule,
    AuthModule.forRoot({
      ...env.auth
    }),
  ],
  providers: [
    {
      provide: LOCALE_ID, useValue: 'en-GB'
    },
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {
  }
}
