export interface IEodFormParameters {
  searchFromDate: Date;
  searchFromHour: string;
  searchFromMinute: string;
  searchToDate: Date;
  searchToHour: string;
  searchToMinute: string;
}

const localStorageFormParametersProperty = 'EodFormParameters';

export class EodFormParameters implements IEodFormParameters {
  searchFromDate: Date;
  searchFromHour: string;
  searchFromMinute: string;
  searchToDate: Date;
  searchToHour: string;
  searchToMinute: string;

  constructor() {
      this.getParameters();
  }

  public storeParameters() {
      localStorage.setItem(localStorageFormParametersProperty, JSON.stringify(this));
  }

  private getParameters() {
    const storedParameters: IEodFormParameters = JSON.parse(localStorage.getItem(localStorageFormParametersProperty));

    if (storedParameters != null) {
      // we found the parameters into the local storage
      this.searchFromDate = new Date(storedParameters.searchFromDate);
      this.searchFromHour = storedParameters.searchFromHour;
      this.searchFromMinute = storedParameters.searchFromMinute;
      this.searchToDate = new Date(storedParameters.searchToDate);
      this.searchToHour = storedParameters.searchToHour;
      this.searchToMinute = storedParameters.searchToMinute;
    } else {
      // we need to generate default value
      this.searchFromDate = new Date(new Date().setDate(new Date().getDate() - 7));
      this.searchFromHour = '00';
      this.searchFromMinute = '00';
      this.searchToDate = new Date();
      this.searchToHour = '23';
      this.searchToMinute = '59';

      this.storeParameters();
    }

  }
}
