import { ChangeType } from '../enums/ChangeType';
import { IProductDTO } from './DTOs/IProductDTO';
import { IAddonDTO } from './DTOs/IAddonDTO';
import { IKitchenVideoInflated } from './IKitchenVideoInflated';
import { IPreparationItem } from './IPreparationItem';
import { IPriceMatrix, PriceMatrix } from './IPriceMatrix';
import { IPrinterInflated } from './IPrinterInflated';
import { IChangeState } from './Extensions/IChangeState';
import { IItemSelectable } from './Extensions/IItemSelectable';
import { IProductPrinterPosRule } from './IProductPrinterPosRule';
import { IKitchenVideoRule } from './IKitchenVideoRule';

export interface IProduct extends IProductDTO, IChangeState, IItemSelectable {
  priceMatrix: IPriceMatrix;
  kitchenVideos: IKitchenVideoInflated[];
  kitchenVideoRules: IKitchenVideoRule[];
  kitchenPrinters: IPrinterInflated[];
  kitchenPrinterRules: IProductPrinterPosRule[];
  preparationDevices: IPreparationItem[];
  priceToDisplay: number;
  categoryColor: string;
  categoryColorText: string;
}

export class Product implements IProduct {
  id: string;
  productGuid: string;
  name: string;
  displayName: string;
  isActive: boolean;
  createdDateTime: Date;
  categoryGuid: string;
  categoryName: string;
  courseGuid: string;
  courseName: string;
  createdBy: string;
  isOpenPrice: boolean;
  isMeasureVisible: boolean;
  priceMatrix: IPriceMatrix;
  kitchenVideos: IKitchenVideoInflated[];
  kitchenVideoRules: IKitchenVideoRule[];
  kitchenPrinters: IPrinterInflated[];
  kitchenPrinterRules: IProductPrinterPosRule[];
  addonHeaders: IAddonDTO[];
  preparationDevices: IPreparationItem[];
  changeState: ChangeType;
  isSelected: boolean;
  priceToDisplay: number;
  categoryColor: string;
  categoryColorText: string;

  constructor() {
      this.id = '';
      this.productGuid = '';
      this.name = '';
      this.displayName = '';
      this.isActive = false;
      this.createdDateTime = new Date();
      this.categoryGuid = '';
      this.categoryName = '';
      this.courseGuid = '';
      this.courseName = '';
      this.createdBy = '';
      this.isOpenPrice = false;
      this.isMeasureVisible = false;
      this.priceMatrix = new PriceMatrix();
      this.kitchenVideos = [];
      this.kitchenVideoRules = [];
      this.kitchenPrinters = [];
      this.kitchenPrinterRules = [];
      this.addonHeaders = [];
      this.preparationDevices = [];
      this.changeState = ChangeType.None;
      this.isSelected = false;
      this.priceToDisplay = 0.00;
      this.categoryColor = '#FFF';
      this.categoryColorText = '#000';
  }
}
