export interface IEditDataList<T> {
    newItems: T[];
    editItems: T[];
    deleteItems: T[];
}

export class EditDataList<T> implements IEditDataList<T> {
    newItems: T[];
    editItems: T[];
    deleteItems: T[];

    constructor() {
        this.newItems = [];
        this.editItems = [];
        this.deleteItems = [];
    }
}