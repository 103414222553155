import { Action } from '@ngrx/store';
import { ISchedulePriceLevel } from 'src/app/models/ISchedulePriceLevel';
import { IPriceLevel } from 'src/app/models/IPriceLevel';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';

export enum SchedulePriceLevelsActionTypes {
  LoadSchedulePriceLevels = '[Schedule Price Levels] Load Schedule Price Levels',
  LoadSchedulePriceLevelsSuccessful = '[Schedule Price Levels] Load Schedule Price Levels - Successful',
  LoadSchedulePriceLevelsFailed = '[Schedule Price Levels] Load Schedule Price Levels - Failed',
  SaveSchedulePriceLevels = '[Schedule Price Levels] Save Schedule Price Levels',
  SaveSchedulePriceLevelsSuccessful = '[Schedule Price Levels] Save Schedule Price Levels - Successful',
  SaveSchedulePriceLevelsFailed = '[Schedule Price Levels] Save Schedule Price Levels - Failed',
  RestoreBackup = '[Schedule Price Levels] Restore Backup',
  RestoreBackupSuccessful = '[Schedule Price Levels] Restore Backup - Successful',
  RestoreBackupFailed = '[Schedule Price Levels] Restore Backup - Failed',
  ResetStore = '[Schedule Price Levels] Reset Store',
}

export class LoadSchedulePriceLevels implements Action {
  readonly type = SchedulePriceLevelsActionTypes.LoadSchedulePriceLevels;

  constructor() { }
}

export class LoadSchedulePriceLevelsSuccessful implements Action {
  readonly type = SchedulePriceLevelsActionTypes.LoadSchedulePriceLevelsSuccessful;

  constructor(public payload: ISchedulePriceLevel[]) { }
}

export class LoadSchedulePriceLevelsFailed implements Action {
  readonly type = SchedulePriceLevelsActionTypes.LoadSchedulePriceLevelsFailed;

  constructor(public payload: IGenericError) { }
}

export class SaveSchedulePriceLevels implements Action {
  readonly type = SchedulePriceLevelsActionTypes.SaveSchedulePriceLevels;

  constructor(public payload: ISchedulePriceLevel[]) { }
}

export class SaveSchedulePriceLevelsSuccessful implements Action {
  readonly type = SchedulePriceLevelsActionTypes.SaveSchedulePriceLevelsSuccessful;

  constructor(public payload: ISchedulePriceLevel[]) { }
}

export class SaveSchedulePriceLevelsFailed implements Action {
  readonly type = SchedulePriceLevelsActionTypes.SaveSchedulePriceLevelsFailed;

  constructor(public error: IGenericError, public oldData: ISchedulePriceLevel[]) { }
}

export class RestoreBackup implements Action {
  readonly type = SchedulePriceLevelsActionTypes.RestoreBackup;

  constructor() { }
}

export class RestoreBackupSuccessful implements Action {
  readonly type = SchedulePriceLevelsActionTypes.RestoreBackupSuccessful;

  constructor(public payload: ISchedulePriceLevel[]) { }
}

export class RestoreBackupFailed implements Action {
  readonly type = SchedulePriceLevelsActionTypes.RestoreBackupFailed;

  constructor() { }
}

export class ResetStore implements Action {
  readonly type = SchedulePriceLevelsActionTypes.ResetStore;

  constructor() { }
}

export type SchedulePriceLevelsActions = LoadSchedulePriceLevels
  | LoadSchedulePriceLevelsSuccessful
  | LoadSchedulePriceLevelsFailed
  | SaveSchedulePriceLevels
  | SaveSchedulePriceLevelsSuccessful
  | SaveSchedulePriceLevelsFailed
  | RestoreBackup
  | RestoreBackupSuccessful
  | RestoreBackupFailed
  | ResetStore;
