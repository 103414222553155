import * as fromRoot from '../../../NGRX/app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CategoryEditorActions, CategoryEditorActionTypes } from './categories.actions';

export interface State extends fromRoot.State {
  categoryEditor: CategoryEditorState;
}

export interface CategoryEditorState {
  filterByName: string;
}

const initialState: CategoryEditorState = {
  filterByName: ''
};

// create the selectors
const getCategoriesFeatureState = createFeatureSelector<CategoryEditorState>('categoryEditor');
export const getCategoriesState = createSelector(
  getCategoriesFeatureState,
  (state) => state
);

export const getFilterByName = createSelector(
  getCategoriesFeatureState,
  (state) => state.filterByName
);

export function reducer(state: CategoryEditorState = initialState, action: CategoryEditorActions): CategoryEditorState {
  switch (action.type) {
    case CategoryEditorActionTypes.StoreFilterByName:
      return {
        ...state,
        filterByName: action.payload,
      };

    case CategoryEditorActionTypes.ResetStore:
      return {
        ...state,
        filterByName: initialState.filterByName,
      };

    default:
      return state;
  }
}


