import { JsonProperty, JsonObject } from 'typescript-json-serializer';
import { IEopReportSectionDTO } from './IEopReportSectionDTO';

export interface IEowReportDocumentDTO {
  createdByUser: string;
  createdOnPosDevice: string;
  productSalesTotal: number;
  reportDate: Date;
  reportDescription: string;
  reportNumber: number;
  reportVersion: number;
  sections: IEopReportSectionDTO[];
}

@JsonObject()
export class EowReportDocumentDTO implements IEowReportDocumentDTO {

  @JsonProperty('EA')
  public createdByUser: string;

  @JsonProperty('EC')
  public createdOnPosDevice: string;

  @JsonProperty('ED')
  public productSalesTotal: number;

  @JsonProperty('EB')
  public reportDate: Date;

  @JsonProperty('EE')
  public reportDescription: string;

  @JsonProperty('EF')
  public reportNumber: number;

  @JsonProperty('EZ')
  public reportVersion: number;

  @JsonProperty('EG')
  public sections: IEopReportSectionDTO[];

  constructor() {
    this.sections = [];
  }
}
