import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IPosUser } from '../models/IPosUser';
import { Observable, Subscriber, throwError } from 'rxjs';
import { EstateService } from './estate.service';
import { catchError, map } from 'rxjs/operators';
import { IEnvelopeOutDTO } from '../models/DTOs/IEnvelopeOutDTO';
import { IEnvelopeInDTO } from '../models/DTOs/IEnvelopeInDTO';
import { IPosUserDTO } from '../models/DTOs/IPosUserDTO';
import { ChangeType } from '../enums/ChangeType';
import { ChangeOperationType } from '../enums/ChangeOperationType';
import { EditDataList, IEditDataList } from '../models/DTOs/IEditDataList';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private RoleUsersEndPoint = environment.APIEndpoint + 'frontend/corporation/{corporationId}/site/{siteId}/PosUsers';

  constructor(private loginService: LoginService, 
              private http: HttpClient, 
              private estateService: EstateService) { }

  private users: IPosUser[] = [];

  GetRoleUsers(): Observable<IPosUser[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.RoleUsersEndPoint.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId);

      return this.http
        .get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IPosUserDTO[]>) => {

            if (data.ok) {
              // store a copy for backup
              this.users = this.formatData(data.body);
              return JSON.parse(JSON.stringify(this.users));
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  private formatData(dataList: IPosUserDTO[]): IPosUser[] {
    let list: IPosUser[] = [];

    // convert list
    dataList.forEach(item => {
      const newRole = this.convertDTO(item);

      list.push(newRole);
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: IPosUser[]): IPosUser[] {
    return list.sort((Item1: IPosUser, Item2: IPosUser) => {
      const name1 = (Item1.nameFirst + ' ' + Item1.nameLast).trim().toLowerCase();
      const name2 = (Item2.nameFirst + ' ' + Item2.nameLast).trim().toLowerCase();

      if (name1 > name2) { return 1; } else { return -1; }
    });
  }

  private convertDTO(item: IPosUserDTO): IPosUser {
    const newItem = {
      id: item.id,
      user_GUID: item.user_GUID,
      user_ID: item.user_ID,
      nameFirst: item.nameFirst,
      nameLast: item.nameLast,
      pin: item.pin,
      card: item.card,
      rfid: item.rfid,
      isActive: item.isActive,
      roles: [],
      isSelected: false,
      changeState: ChangeType.None
    };

    item.roles.forEach(role => {
      newItem.roles.push({
        id: role.id,
        role_GUID: role.role_GUID,
        name: role.name,
        functionKeys: [],
        priceLevels: [],
        isSelected: false,
        changeState: ChangeType.None
      });
    });

    return newItem;
  }

  SaveRoleUsers(newData: IPosUser[]): Observable<IPosUser[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.RoleUsersEndPoint.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId);

      const data: IEditDataList<IPosUserDTO> = this.SplitItems(newData);

      return this.http
        .post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IPosUserDTO[]>) => {

            if (data.ok) {
              // store a copy for backup
              this.users = this.formatData(data.body);
              return JSON.parse(JSON.stringify(this.users));
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        );
    }
  }

  private DeflateItems(list: IPosUser[]): IPosUserDTO[] {
    const newData: IPosUserDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        const newItem: IPosUserDTO = {
          id: item.id,
          user_GUID: item.user_GUID,
          user_ID: item.user_ID,
          nameFirst: item.nameFirst,
          nameLast: item.nameLast,
          pin: item.pin,
          card: item.card,
          rfid: item.rfid,
          isActive: item.isActive,
          roles: []
        };

        item.roles.forEach(role => {
          newItem.roles.push({
            id: role.id,
            role_GUID: role.role_GUID,
            name: role.name,
            functionKeys: [],
            priceLevels: []
          });
        });

        newData.push(newItem);
      });
    }

    return newData;
  }

  private SplitItems(data: IPosUser[]): IEditDataList<IPosUserDTO> {
    let newData: IEditDataList<IPosUserDTO> = new EditDataList<IPosUserDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }

  GetBackup(): Observable<IPosUser[]> {
    const backup: IPosUser[] = JSON.parse(JSON.stringify(this.users));
    return new Observable<IPosUser[]>((subscriber: Subscriber<IPosUser[]>) => subscriber.next(backup));
  }
}
