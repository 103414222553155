import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaxBandsActions, TaxBandsActionTypes } from './taxbands.actions';
import { ITaxBand } from 'src/app/models/ITaxBand';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    taxBands: TaxBandsState;
}

export interface TaxBandsState extends IGardiffStoreSliceBase {
    taxBands: ITaxBand[];
}

const initialState: TaxBandsState = {
    taxBands: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getTaxBandsFeatureState = createFeatureSelector<TaxBandsState>('taxBands');
export const getTaxBandsState = createSelector(
    getTaxBandsFeatureState,
    state => state
);

export const getTaxBands = createSelector(
    getTaxBandsFeatureState,
    state => state.taxBands
);

export const getApiError = createSelector(
  getTaxBandsFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getTaxBandsFeatureState,
  state => state.status
);

export function reducer(state: TaxBandsState = initialState, action: TaxBandsActions): TaxBandsState {
    switch (action.type) {
        case TaxBandsActionTypes.LoadTaxBands:
            return {
                ...state,
                taxBands: null,
                apiError: null,
                status: null
            };

        case TaxBandsActionTypes.LoadTaxBandsSuccessful:
            return {
                ...state,
                taxBands: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case TaxBandsActionTypes.LoadTaxBandsFailed:
            return {
                ...state,
                taxBands: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case TaxBandsActionTypes.SaveTaxBands:
            return {
                ...state,
                taxBands: null,
                apiError: null,
                status: null
            };

        case TaxBandsActionTypes.SaveTaxBandsSuccessful:
            return {
                ...state,
                taxBands: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case TaxBandsActionTypes.SaveTaxBandsFailed:
            return {
                ...state,
                taxBands: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case TaxBandsActionTypes.ResetStore:
            return {
                ...state,
                taxBands: initialState.taxBands,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
