import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DiscountReportDefinitionDTO, IDiscountReportDefinitionDTO } from '../models/DTOs/IDiscountReportDefinitionDTO';
import { IDiscountReportDocumentDTO } from '../models/DTOs/IDiscountReportDocumentDTO';
import { IDiscountReportDocument } from '../models/IDiscountReportDocument';
import { IDiscountReportMainSection } from '../models/IDiscountReportMainSection';
import { IDiscountReportSection } from '../models/IDiscountReportSection';
import { EstateService } from './estate.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountReportService {

  private token: string;
  private APIEndpoint = environment.APIEndpoint + 'frontend/corporation/{{corporationId}}/site/{{siteID}}/DiscountReport';

  constructor(private http: HttpClient,
    private loginService: LoginService,
    private estateService: EstateService) { }

    getDiscountsReport(datefrom: Date, dateto: Date): Observable<IDiscountReportDocument> {
      this.token = this.loginService.AccessToken.authToken;

      const parameters: IDiscountReportDefinitionDTO = this.generateDiscountsReportDefinition(datefrom, dateto);

      const _DiscountsReportURL = this.APIEndpoint.replace('{{corporationId}}', this.estateService.CorporationID).replace('{{siteID}}', this.estateService.SelectedSite.siteId);

      // tslint:disable-next-line: max-line-length
      return this.http.post<IDiscountReportDocumentDTO>(_DiscountsReportURL, parameters, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token) }).pipe(
        map((data: IDiscountReportDocumentDTO) => {

          const reportDocument = this.convertReportDocument(data);
          reportDocument.mainSections = this.sortMainSections(reportDocument.mainSections);
          reportDocument.summarySections = this.sortSummarySections(reportDocument.summarySections);

          return reportDocument;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }

    // return Discounts Report pdf file
    getReportFilePDF(datefrom: Date, dateto: Date): Observable<ArrayBuffer> {
      const Token: string = this.loginService.AccessToken.authToken;
      if (Token !== '') {
        const reportFileEndPoint = this.APIEndpoint.replace('{{corporationId}}', this.estateService.CorporationID).replace('{{siteID}}', this.estateService.SelectedSite.siteId) + '/PDF';

        const parameters: IDiscountReportDefinitionDTO = this.generateDiscountsReportDefinition(datefrom, dateto);

        return this.http
          .post(reportFileEndPoint, parameters, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), responseType: 'arraybuffer'}).pipe(
            map((data: ArrayBuffer) => {

              return data;

            }),
            catchError((err: HttpErrorResponse) => {
              return throwError(() => err);
            })
        );
      }
    }

    private generateDiscountsReportDefinition(datefrom: Date, dateto: Date): IDiscountReportDefinitionDTO {
      const parameters = new DiscountReportDefinitionDTO()

      parameters.salesDateFrom = datefrom,
      parameters.salesDateTo = dateto

      return parameters;
    }

    // convert the Document from DTO to Business object
    private convertReportDocument(report: IDiscountReportDocumentDTO): IDiscountReportDocument {
      let newReport: IDiscountReportDocument = null;

      if (report != null) {

        // starting migration data from simple properties
        newReport = {
          reportVersion: report.reportVersion,
          dateFrom: report.dateFrom,
          dateTo: report.dateTo,
          mainSections: [],
          summarySections: []
        };

        // convert Main section
        if (report.mainSections != null) {
          report.mainSections.forEach(mainSection => {
            const newMainSection: IDiscountReportMainSection = {
              eodNo: mainSection.eodNo,
              eodDate: mainSection.eodDate,
              discountTypeList: []
            };

            mainSection.discountTypeList.forEach(discount => {
              const newDiscountSection: IDiscountReportSection = {
                discountType: discount.discountType,
                displayNameList: []
              };

              discount.displayNameList.forEach(name => {
                newDiscountSection.displayNameList.push({
                  displayName: name.displayName,
                  count: name.count,
                  amount: name.amount
                });
              });

              newMainSection.discountTypeList.push(newDiscountSection);
            });

            newReport.mainSections.push(newMainSection);
          });
        }

        // convert summary section
        if (report.summarySections != null) {
          report.summarySections.forEach(section => {
            const newSummarySection: IDiscountReportSection = {
              discountType: section.discountType,
              displayNameList: []
            };

            section.displayNameList.forEach(name => {
              newSummarySection.displayNameList.push({
                displayName: name.displayName,
                count: name.count,
                amount: name.amount
              });
            });

            newReport.summarySections.push(newSummarySection);
          });
        }

      }

      return newReport;
    }

    private sortMainSections(list: IDiscountReportMainSection[]): IDiscountReportMainSection[] {
      list = list.sort((Item1: IDiscountReportMainSection, Item2: IDiscountReportMainSection) => {
        if (Item1.eodNo > Item2.eodNo) {
          return 1;
        }

        if (Item1.eodNo < Item2.eodNo) {
          return -1;
        }
      });

      if (list.length > 0 && list[0].eodNo === 0) {
        // Current period section is the first item
        // we need to move it at the bottom
        const lastItem = list.splice(0, 1)[0];
        list.push(lastItem);
      }

      return list;
    }

    private sortSummarySections(list: IDiscountReportSection[]): IDiscountReportSection[] {
      list = list.sort((Item1: IDiscountReportSection, Item2: IDiscountReportSection) => {
        if (Item1.discountType.toLowerCase() > Item2.discountType.toLowerCase()) {
          return 1;
        }

        if (Item1.discountType.toLowerCase() < Item2.discountType.toLowerCase()) {
          return -1;
        }
      });

      return list;
    }
}
