import * as fromRoot from '../app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AddonsActions, AddonsActionTypes } from './addons.actions';
import { IAddon } from 'src/app/models/IAddon';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
  addons: AddonsState;
}

export interface AddonsState extends IGardiffStoreSliceBase {
  addons: IAddon[];
}

const initialState: AddonsState = {
  addons: [],
  apiError: null,
  status: new GardiffStoreSliceStatus()
};

// create the selectors
const getAddonsFeatureState = createFeatureSelector<AddonsState>('addons');
export const getProductEditorState = createSelector(
  getAddonsFeatureState,
  (state) => state
);

export const getAddons = createSelector(
  getAddonsFeatureState,
  (state) => state.addons
);

export const getApiError = createSelector(
  getAddonsFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getAddonsFeatureState,
  state => state.status
);

export function reducer(state: AddonsState = initialState, action: AddonsActions): AddonsState {
  switch (action.type) {
    case AddonsActionTypes.LoadAddons:
      return {
        ...state,
        addons: null,
        apiError: null,
        status: null
      };

    case AddonsActionTypes.LoadAddonsSuccessful:
      return {
        ...state,
        addons: action.payload,
        apiError: null,
        status: new GardiffStoreSliceStatus(true)
      };

    case AddonsActionTypes.LoadAddonsFailed:
      return {
        ...state,
        addons: [],
        apiError: action.payload,
        status: new GardiffStoreSliceStatus()
      };

    case AddonsActionTypes.SaveAddons:
      return {
        ...state,
        addons: null,
        apiError: null,
        status: null
      };

    case AddonsActionTypes.SaveAddonsSuccessful:
      return {
        ...state,
        addons: action.payload,
        apiError: null,
        status: new GardiffStoreSliceStatus(true)
      };

    case AddonsActionTypes.SaveAddonsFailed:
      return {
        ...state,
        addons: action.oldData,
        apiError: action.error,
        status: new GardiffStoreSliceStatus()
      };

    case AddonsActionTypes.RestoreBackup:
      return {
          ...state,
          addons: null,
          apiError: null
      };

    case AddonsActionTypes.RestoreBackupSuccessful:
        return {
            ...state,
            addons: action.payload,
            apiError: null
        };

    case AddonsActionTypes.RestoreBackupFailed:
        return {
            ...state,
            addons: [],
            apiError: null
        };

    case AddonsActionTypes.ResetStore:
      return {
        ...state,
        addons: initialState.addons,
        apiError: initialState.apiError,
        status: initialState.status
      };

    default:
      return state;
  }
}
