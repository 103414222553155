import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import * as logonActions from './logon.actions';

import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { EstateService } from 'src/app/services/estate.service';
import { MenuPlanService } from 'src/app/services/menu-plan.service';
import { IMenu } from 'src/app/models/IMenu';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';
import { LogonService } from 'src/app/services/logon.service';

@Injectable()
export class LogonScreenEffects {
  constructor(private action$: Actions,
    private estateService: EstateService,
    private logonScreenService: LogonService) {

  }

  loadMenuPlan$ = createEffect(
    () => this.action$.pipe(
    ofType(logonActions.LogonActionTypes.LoadLogonScreen),
    mergeMap((action: logonActions.LoadLogonScreen) =>
      this.logonScreenService.GetLogOnScreen(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((logonScreen: IMenu) => (new logonActions.LoadLogonScreenSuccessful(logonScreen))),
        catchError((error: HttpErrorResponse) => of(new logonActions.LoadLogonScreenFailed(interpretateApiError(error))))
      )
    ))
  );

  saveMenus$ = createEffect(
    () => this.action$.pipe(
    ofType(logonActions.LogonActionTypes.SaveLogonScreen),
    mergeMap((action: logonActions.SaveLogonScreen) =>
      this.logonScreenService.SaveLogonScreen(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.payload).pipe(
        map((menus: IMenu) => new logonActions.SaveLogonScreenSuccessful(menus)),
        catchError((error: HttpErrorResponse) => of(new logonActions.SaveLogonScreenFailed(interpretateApiError(error, HttpVerbType.POST), action.payload)))
      )
    ))
  );
}
