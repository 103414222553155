
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular-ivy";

/** echarts extensions: */
/**  import 'echarts-gl'; */
import 'echarts/theme/shine.js';
import 'echarts/theme/roma.js';
import 'echarts/theme/vintage.js';
import 'echarts/theme/infographic.js';
import 'echarts/theme/macarons.js';
import 'echarts/dist/extension/bmap.min.js';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: "https://baec3f6319874a57bec66edc38fff978@o4505205529640960.ingest.sentry.io/4505209642287104",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.01, // Capture 100% of the transactions, reduce in production!

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.,
  environment: environment.description,
  ignoreErrors: [
    "Login Error", /^Failed$/
  ]
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

