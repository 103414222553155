import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProductSalesReportGroupOptionLabel, ProductSalesReportGroupOptionType } from '../enums/ProductSalesReportGroupOptionType';
import { ProductSalesReportSubGroupOptionLabel, ProductSalesReportSubGroupOptionType } from '../enums/ProductSalesReportSubGroupOptionType';
import { SalesReportItemType } from '../enums/SalesReportItemType';
import { ProductSalesReportDefinitionDTO } from '../models/DTOs/IProductSalesReportDefinitionDTO';
import { ISalesReportDetailDTO } from '../models/DTOs/ISalesReportDetailDTO';
import { ISalesReportDocumentDTO } from '../models/DTOs/ISalesReportDocumentDTO';
import { ISalesReportGroupDTO } from '../models/DTOs/ISalesReportGroupDTO';
import { ISalesReportItemDTO } from '../models/DTOs/ISalesReportItemDTO';
import { ISalesReportSubGroupDTO } from '../models/DTOs/ISalesReportSubGroupDTO';
import { ISalesReportDetail } from '../models/ISalesReportDetail';
import { ISalesReportDocument, SalesReportDocument } from '../models/ISalesReportDocument';
import { ISalesReportGroup, SalesReportGroup } from '../models/ISalesReportGroup';
import { ISalesReportItem } from '../models/ISalesReportItem';
import { ISalesReportSubGroup } from '../models/ISalesReportSubGroup';
import { EstateService } from './estate.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class SalesReportService {

  private token: string;
  private APIEndpoint = environment.APIEndpoint + 'frontend/corporation/{corporationId}/site/{siteId}/ProductSalesReport';

  constructor(private http: HttpClient, private loginService: LoginService, private estateService: EstateService) { }

  getSalesReport(datefrom: Date, dateto: Date, reportGroup: ProductSalesReportGroupOptionType, reportSubGroup: ProductSalesReportSubGroupOptionType, posId: string, clerkId: string, categoryId: string, parentCategoryId: string, courseId: string, showDetails: boolean, showTax: boolean): Observable<ISalesReportDocument> {
    this.token = this.loginService.AccessToken.authToken;

    const parameters: ProductSalesReportDefinitionDTO = this.generateProductSalesReportDefinition(datefrom, dateto, reportGroup, reportSubGroup, posId, clerkId, categoryId, parentCategoryId, courseId, showDetails, showTax);

    const _ProductSalesReportURL = this.APIEndpoint.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId);

    // tslint:disable-next-line: max-line-length
    return this.http.post<ISalesReportDocumentDTO>(_ProductSalesReportURL, parameters, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token) }).pipe(
      map((data: ISalesReportDocumentDTO) => {let document = this.convertReportDocument(data);

        if (document != null) {
          document.showDetails = showDetails;
          document.includeTax = showTax;
        } else {
          // no data from the api
          // build and empty report document
          document = new SalesReportDocument();
          document.salesDateFrom = datefrom;
          document.salesDateTo = dateto;
          document.groupBy = ProductSalesReportGroupOptionLabel.get(reportGroup);
          document.subGroupBy = ProductSalesReportSubGroupOptionLabel.get(reportSubGroup);
          document.showDetails = showDetails;
          document.includeTax = showTax;
        }

        return document;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(() => err);
      })
    );
  }

  // convert the Document from DTO to Business object
  private convertReportDocument(report: ISalesReportDocumentDTO): ISalesReportDocument {
    let newReport: ISalesReportDocument = null;

    if (report != null) {

      // starting migration data from simple properties
      newReport = {
        reportVersion: report.reportVersion,
        filters: [],
        salesDateFrom: report.salesDateFrom,
        salesDateTo: report.salesDateTo,
        groupBy: report.groupBy,
        subGroupBy: report.subGroupBy,
        items: [],
        subGroupGrandTotals: [],
        totalGrossAmount: report.totalGrossAmount,
        totalNetAmount: report.totalNetAmount,
        totalTaxAmount: report.totalTaxAmount,
        totalQuantity: report.totalQuantity,
        showDetails: false,
        includeTax: report.includeTax
      };

      // convert property Filters
      if (report.filters != null) {
        report.filters.forEach(filter => {
          newReport.filters.push({
            name: filter.name,
            value: filter.value
          });
        });
      }

      // Convert the Items
      newReport.items = this.convertItemsTopLevel(report.items);

      // Convert the SubGroups
      if (report.subGroupGrandTotals != null) {
        report.subGroupGrandTotals.forEach(subGroup => {
          newReport.subGroupGrandTotals.push(this.convertItem(subGroup));
        });
      }
    }

    return newReport;
  }

  // This Method convert a List of ISalesReportItemDTO into a List of ISalesReportItem
  private convertItemsTopLevel(items: ISalesReportItemDTO[]): ISalesReportItem[] {
    const newItems: ISalesReportItem[] = [];

    if (items != null) {
      items.forEach(item => {

        // on the topLevel we can have only ItemGroups and ItemDetails
        if (this.isDetail(item)) {

          // we found an Item Detail
          const newItem = this.convertItemDetail(item as ISalesReportDetailDTO);
          newItems.push(newItem);

        } else if (this.isItemGroup(item)) {

          // we found a Group
          const newItem = this.convertItemGroup(item as ISalesReportGroupDTO);
          newItems.push(newItem);
        }

      });
    }

    return newItems;
  }

  // This Method convert a List of ISalesReportItemDTO into a List of ISalesReportItem
  private convertItems(items: ISalesReportItemDTO[]): ISalesReportItem[] {
    const newItems: ISalesReportItem[] = [];

    if (items != null) {
      items.forEach(item => {
          newItems.push(this.convertItem(item));
      });
    }

    return newItems;
  }

  // This Method convert a single object of ISalesReportItemDTO into an object ISalesReportItem
  private convertItem(item: ISalesReportItemDTO): ISalesReportItem {
    let newItem: ISalesReportItem = null;

    if (this.isDetail(item)) {
      newItem = this.convertItemDetail(item as ISalesReportDetailDTO);

    } else if (this.isItemSubGroup(item)) {
        newItem = this.convertItemSubGroup(item as ISalesReportSubGroupDTO);
    }

    return newItem;
  }

  // This method convert an item from ISalesReportDetailDTO into ISalesReportDetail
  private convertItemDetail(item: ISalesReportDetailDTO): ISalesReportDetail {
    let newItem: ISalesReportDetail = null;

    if (item != null) {
      newItem = {
          caption: item.caption,
          quantity: item.quantity,
          gross: item.grossAmount,
          net: item.netAmount,
          tax: item.taxAmount,
          createdDate: item.createdDate,
          posName: item.posName,
          userName: item.userName,
          type: SalesReportItemType.Detail
      };
    }

    return newItem;
  }

  // This method convert an item from ISalesReportGroupDTO into ISalesReportGroup
  private convertItemGroup(item: ISalesReportGroupDTO): ISalesReportGroup {
    let newItem: ISalesReportGroup = null;

    if (item != null) {
      newItem = {
          caption: item.caption,
          items: this.convertItems(item.items),
          totalQuantity: item.totalQuantity,
          totalGross: item.totalGrossAmount,
          totalNet: item.totalNetAmount,
          totalTax: item.totalTaxAmount,
          type: SalesReportItemType.Group
      };
    }

    return newItem;
  }

  // This method convert an item from ISalesReportSubGroupDTO into ISalesReportSubGroup
  private convertItemSubGroup(item: ISalesReportSubGroupDTO): ISalesReportSubGroup {
    let newItem: ISalesReportSubGroup = null;

    if (item != null) {
      newItem = {
        caption: item.caption,
        items: this.convertItems(item.items),
        totalQuantity: item.totalQuantity,
        totalGross: item.totalGrossAmount,
        totalNet: item.totalNetAmount,
        totalTax: item.totalTaxAmount,
        type: SalesReportItemType.SubGroup
      };
    }

    return newItem;
  }

  private isDetail(item: ISalesReportItemDTO): boolean {
    const testItem: ISalesReportDetailDTO = item as ISalesReportDetailDTO;
    return (testItem.createdDate != null);
  }

  private isItemGroup(item: ISalesReportItemDTO): boolean {
    const testItem: ISalesReportGroupDTO = item as ISalesReportGroupDTO;
    return (testItem.totalGrossAmount != null);
  }

  private isItemSubGroup(item: ISalesReportItemDTO): boolean {
    const testItem: ISalesReportSubGroupDTO = item as ISalesReportSubGroupDTO;
    return (testItem.totalGrossAmount != null);
  }

  private generateProductSalesReportDefinition(datefrom: Date, dateto: Date, reportGroup: ProductSalesReportGroupOptionType, reportSubGroup: ProductSalesReportSubGroupOptionType, posId: string, clerkId: string, categoryId: string, parentCategoryId: string, courseId: string, showDetails: boolean, showTax: boolean): ProductSalesReportDefinitionDTO {
    const parameters = new ProductSalesReportDefinitionDTO();

    // Set any filters.
    parameters.filterUserGuid = clerkId;
    parameters.filterPosDeviceGuid = posId;
    parameters.filterCategoryGuid = categoryId;
    parameters.filterParentCategoryGuid = parentCategoryId;
    parameters.filterCourseGuid = courseId;

    // Specify the data range for the report.
    parameters.salesDateFrom = datefrom;
    parameters.salesDateTo = dateto;

    parameters.mainGroup = reportGroup;

    if (reportGroup !== ProductSalesReportGroupOptionType.None) {
      parameters.subGroup = reportSubGroup;
      parameters.hideGroupDetail = !showDetails;

      parameters.rollUpProducts = true;
    } else {
      // we are not grouping the data
      parameters.rollUpProducts = false;
    }

    // ask to D.D. what is this property
    // parameters.rollUpProducts = false;
    parameters.includeTax = showTax;

    return parameters;
  }

  // return Sales Report pdf file
  getReportFilePDF(datefrom: Date, dateto: Date, reportGroup: ProductSalesReportGroupOptionType, reportSubGroup: ProductSalesReportSubGroupOptionType, posId: string, clerkId: string, categoryId: string, parentCategoryId: string, courseId: string, showDetails: boolean, includeTax: boolean): Observable<ArrayBuffer> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const reportFileEndPoint = this.APIEndpoint.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId)  + '/PDF';

      const parameters: ProductSalesReportDefinitionDTO = this.generateProductSalesReportDefinition(datefrom, dateto, reportGroup, reportSubGroup, posId, clerkId, categoryId, parentCategoryId, courseId, showDetails, includeTax);

      return this.http
        .post(reportFileEndPoint, parameters, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), responseType: 'arraybuffer'}).pipe(
          map((data: ArrayBuffer) => {

            return data;

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }
}
