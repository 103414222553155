import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MenusActions, MenusActionTypes } from './menus.actions';
import { IMenu } from 'src/app/models/IMenu';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    Menus: MenusState;
}

export interface MenusState extends IGardiffStoreSliceBase {
    menus: IMenu[];
}

const initialState: MenusState = {
    menus: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getMenuPlanFeatureState = createFeatureSelector<MenusState>('menus');
export const getMenuPlanState = createSelector(
    getMenuPlanFeatureState,
    state => state
);

export const getMenus = createSelector(
    getMenuPlanFeatureState,
    state => state.menus
);

export const getApiError = createSelector(
  getMenuPlanFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getMenuPlanFeatureState,
  state => state.status
);

export function reducer(state: MenusState = initialState, action: MenusActions): MenusState {
    switch (action.type) {

        case MenusActionTypes.LoadMenus:
            return {
                ...state,
                menus: null,
                apiError: null,
                status: null
            };

        case MenusActionTypes.LoadMenusSuccessful:
            return {
                ...state,
                menus: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case MenusActionTypes.LoadMenusFailed:
            return {
                ...state,
                menus: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case MenusActionTypes.SaveMenus:
            return {
                ...state,
                menus: null,
                apiError: null,
                status: null
            };

        case MenusActionTypes.SaveMenusSuccessful:
            return {
                ...state,
                menus: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case MenusActionTypes.SaveMenusFailed:
            return {
                ...state,
                menus: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case MenusActionTypes.ResetStore:
            return {
                ...state,
                menus: initialState.menus,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
