import { IDataSection } from "./IDataSection";
import { ReportDefinition } from "./IReportDefinition";
import { ReportPDFDefinition } from "./IReportPDFDefinition";

export interface IReport {
  reportVersion: number;
  reportName: string;
  timeExecutedUTC: Date;
  reportDefinition: ReportDefinition;
  reportPDFDefinition: ReportPDFDefinition;
  sections: IDataSection[];
}

export class Report implements IReport {
  reportVersion: number;
  reportName: string;
  timeExecutedUTC: Date;
  reportDefinition: ReportDefinition;
  reportPDFDefinition: ReportPDFDefinition;
  sections: IDataSection[];
}
