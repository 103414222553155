import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import * as productEditorActions from './products.actions';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';

import { of } from 'rxjs';
import { ICategory } from 'src/app/models/ICategory';
import { EstateService } from 'src/app/services/estate.service';
import { IPrinter } from 'src/app/models/IPrinter';
import { IKitchenVideo } from 'src/app/models/IKitchenVideo';
import { IMeasure } from 'src/app/models/IMeasure';
import { IPriceLevel } from 'src/app/models/IPriceLevel';
import { PricelevelService } from 'src/app/services/pricelevel.service';
import { CategoriesService } from 'src/app/services/categories.service';
import { CoursesService } from 'src/app/services/courses.service';
import { MeasuresService } from 'src/app/services/measures.service';
import { ICourse } from 'src/app/models/ICourse';
import { IProduct } from 'src/app/models/IProduct';
import { ProductsService } from 'src/app/services/products.service';
import { PrintersService } from 'src/app/services/printers.service';
import { KitchenVideosService } from 'src/app/services/kitchen-videos.service';
import { SchedulePricelevelsService } from 'src/app/services/schedule-pricelevels.service';
import { ISchedulePriceLevel } from 'src/app/models/ISchedulePriceLevel';
import { AddonsService } from 'src/app/services/addons.service';
import { IAddon } from 'src/app/models/IAddon';
import { PosSetupService } from 'src/app/services/pos-setup.service';
import { IPosSetup } from 'src/app/models/IPosSetup';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';

@Injectable()
export class ProductsEffects {
  constructor(
    private action$: Actions,
    private productService: ProductsService,
    private estateService: EstateService
  ) {}

  loadProducts$ = createEffect(
    () => this.action$.pipe(
    ofType(productEditorActions.ProductsActionTypes.LoadProducts),
    mergeMap((action: productEditorActions.LoadProducts) =>
      this.productService.GetProducts(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((products: IProduct[]) => new productEditorActions.LoadProductsSuccessful(products)),
        catchError((error: HttpErrorResponse) => of(new productEditorActions.LoadProductsFailed(interpretateApiError(error))))
      )
    ))
  );

  restoreBackup$ = createEffect(
    () => this.action$.pipe(
    ofType(productEditorActions.ProductsActionTypes.RestoreBackup),
    mergeMap((action: productEditorActions.RestoreBackup) =>
      this.productService.GetBackup().pipe(
        map((products: IProduct[]) => new productEditorActions.RestoreBackupSuccessful(products)),
        catchError((err) => of(new productEditorActions.RestoreBackupFailed()))
      )
    ))
  );

  saveProducts$ = createEffect(
    () => this.action$.pipe(
    ofType(productEditorActions.ProductsActionTypes.SaveProducts),
    mergeMap((action: productEditorActions.SaveProducts) =>
      this.productService.SaveProducts(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.payload).pipe(
        map((result: IProduct[]) => new productEditorActions.SaveProductsSuccessful(result)),
        catchError((error: HttpErrorResponse) => of(new productEditorActions.SaveProductsFailed(interpretateApiError(error, HttpVerbType.POST), action.payload)))
      )
    ))
  );
}
