import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { IPosSetup } from 'src/app/models/IPosSetup';
import { IPrinter } from 'src/app/models/IPrinter';

export enum PrintersActionTypes {
    LoadPrinters = '[Printers] Load Printers',
    LoadPrintersSuccessful = '[Printers] Load Printers - Successful',
    LoadPrintersFailed = '[Printers] Load Printers - Failed',
    SavePrinters = '[Printers] Save Printers',
    SavePrintersSuccessful = '[Printers] Save Printers - Successful',
    SavePrintersFailed = '[Printers] Save Printers - Failed',
    ResetStore = '[Printers] Reset Store',
}

export class LoadPrinters implements Action {
    readonly type = PrintersActionTypes.LoadPrinters;

    constructor() { }
}

export class LoadPrintersSuccessful implements Action {
    readonly type = PrintersActionTypes.LoadPrintersSuccessful;

    constructor(public payload: IPrinter[]) { }
}

export class LoadPrintersFailed implements Action {
    readonly type = PrintersActionTypes.LoadPrintersFailed;

    constructor(public payload: IGenericError) { }
}

export class SavePrinters implements Action {
    readonly type = PrintersActionTypes.SavePrinters;

    constructor(public oldData: IPrinter[], public newData: IPrinter[]) { }
}

export class SavePrintersSuccessful implements Action {
    readonly type = PrintersActionTypes.SavePrintersSuccessful;

    constructor(public payload: IPrinter[]) { }
}

export class SavePrintersFailed implements Action {
    readonly type = PrintersActionTypes.SavePrintersFailed;

    constructor(public error: IGenericError, public oldData: IPrinter[]) { }
}

export class ResetStore implements Action {
    readonly type = PrintersActionTypes.ResetStore;

    constructor() { }
}

export type PrintersActions = LoadPrinters
    | LoadPrintersSuccessful
    | LoadPrintersFailed
    | SavePrinters
    | SavePrintersSuccessful
    | SavePrintersFailed
    | ResetStore;
