import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaymentReportGroupBy } from '../enums/PaymentReportGroupByType';
import { PaymentsReportGroupOptionType } from '../enums/PaymentsReportGroupOptionType';
import { IPaymentsReportDefinitionDTO, PaymentsReportDefinitionDTO } from '../models/DTOs/IPaymentsReportDefinitionDTO';
import { IPaymentsReportDetailDTO } from '../models/DTOs/IPaymentsReportDetailDTO';
import { IPaymentsReportDocumentDTO } from '../models/DTOs/IPaymentsReportDocumentDTO';
import { IPaymentsReportGroupDTO } from '../models/DTOs/IPaymentsReportGroupDTO';
import { IPaymentsReportItemDTO } from '../models/DTOs/IPaymentsReportItemDTO';
import { IPaymentsReportSubGroupDTO } from '../models/DTOs/IPaymentsReportSubGroupDTO';
import { IPaymentsReportDetail } from '../models/IPaymentsReportDetail';
import { IPaymentsReportDocument } from '../models/IPaymentsReportDocument';
import { IPaymentsReportGroup } from '../models/IPaymentsReportGroup';
import { IPaymentsReportItem } from '../models/IPaymentsReportItem';
import { IPaymentsReportOptions } from '../models/IPaymentsReportOptions';
import { IPaymentsReportSubGroup } from '../models/IPaymentsReportSubGroup';
import { EstateService } from './estate.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentsReportService {

  private token: string;
  private APIEndpoint = environment.APIEndpoint + 'frontend/corporation/{corporationId}/site/{siteId}/PaymentsReport';

  constructor(private http: HttpClient,
    private loginService: LoginService,
    private estateService: EstateService) { }

    getPaymentsReport(datefrom: Date, dateto: Date, reportGroup: PaymentReportGroupBy, PosId: string, clerkId: string): Observable<IPaymentsReportDocument> {
      this.token = this.loginService.AccessToken.authToken;

      const parameters: IPaymentsReportDefinitionDTO = this.generatePaymentsReportDefinition(datefrom, dateto, reportGroup, PosId, clerkId);

      const _PaymentsReportURL = this.APIEndpoint.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId);

      // tslint:disable-next-line: max-line-length
      return this.http.post<IPaymentsReportDocumentDTO>(_PaymentsReportURL, parameters, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token) }).pipe(
        map((data: IPaymentsReportDocumentDTO) => {

          return this.convertReportDocument(data);
        }), catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        }));
    }

    // convert the Document from DTO to Business object
    private convertReportDocument(report: IPaymentsReportDocumentDTO): IPaymentsReportDocument {
      let newReport: IPaymentsReportDocument = null;

      if (report != null) {

        // starting migration data from simple properties
        newReport = {
          reportVersion: report.reportVersion,
          filters: [],
          salesDateFrom: report.salesDateFrom,
          salesDateTo: report.salesDateTo,
          groupBy: report.groupBy,
          subGroupBy: report.subGroupBy,
          items: [],
          subGroupGrandTotals: [],
          totalAmount: report.totalAmount,
          totalQuantity: report.totalQuantity
        };

        // convert property Filters
        if (report.filters != null) {
          report.filters.forEach(filter => {
            newReport.filters.push({
              name: filter.name,
              value: filter.value
            });
          });
        }

        // Convert the Items
        newReport.items = this.convertItems(report.items);

        // Convert the SubGroups
        if (report.subGroupGrandTotals != null) {
          report.subGroupGrandTotals.forEach(subGroup => {
            newReport.items.push(this.convertItem(subGroup));
          });
        }

      }

      return newReport;
    }

    // This Method convert a List of IPaymentsReportItemDTO into a List of IPaymentsReportItem
    private convertItems(items: IPaymentsReportItemDTO[]): IPaymentsReportItem[] {
      const newItems: IPaymentsReportItem[] = [];

      if (items != null) {
        items.forEach(item => {
            newItems.push(this.convertItem(item));
        });
      }

      return newItems;
    }

    // This Method convert a single object of IPaymentsReportItemDTO into an object IPaymentsReportItem
    private convertItem(item: IPaymentsReportItemDTO): IPaymentsReportItem {
      let newItem: IPaymentsReportItem = null;


      if (this.isDetail(item)) {
        newItem = this.convertItemDetail(item as IPaymentsReportDetailDTO);

      } else if (this.isItemGroup(item)) {
          newItem = this.convertItemGroup(item as IPaymentsReportGroupDTO);

      } else if (this.isItemSubGroup(item)) {
          newItem = this.convertItemSubGroup(item as IPaymentsReportSubGroupDTO);
      }

      return newItem;
    }

    // This method convert an item from IPaymentsReportDetailDTO into IPaymentsReportDetail
    private convertItemDetail(item: IPaymentsReportDetailDTO): IPaymentsReportDetail {
      let newItem: IPaymentsReportDetail = null;

      if (item != null) {
        newItem = {
            caption: item.caption,
            quantity: item.quantity,
            amount: item.amount,
            createdDate: item.createdDate,
            posName: item.posName,
            userName: item.userName
        };
      }

      return newItem;
    }

    // This method convert an item from IPaymentsReportGroupDTO into IPaymentsReportGroup
    private convertItemGroup(item: IPaymentsReportGroupDTO): IPaymentsReportGroup {
      let newItem: IPaymentsReportGroup = null;

      if (item != null) {
        newItem = {
            caption: item.caption,
            items: this.convertItems(item.items),
            totalQuantity: item.totalQuantity,
            totalAmount: item.totalAmount
        };
      }

      return newItem;
    }

    // This method convert an item from IPaymentsReportSubGroupDTO into IPaymentsReportSubGroup
    private convertItemSubGroup(item: IPaymentsReportSubGroupDTO): IPaymentsReportSubGroup {
      let newItem: IPaymentsReportSubGroup = null;

      if (item != null) {
        newItem = {
          caption: item.caption,
          items: this.convertItems(item.items),
          totalQuantity: item.totalQuantity,
          totalAmount: item.totalAmount
      };
      }

      return newItem;
    }

    private isDetail(item: IPaymentsReportItemDTO): boolean {
      const testItem: IPaymentsReportDetailDTO = item as IPaymentsReportDetailDTO;
      return (testItem.createdDate != null);
    }

    private isItemGroup(item: IPaymentsReportItemDTO): boolean {
      const testItem: IPaymentsReportGroupDTO = item as IPaymentsReportGroupDTO;
      return (testItem.totalAmount != null);
    }

    private isItemSubGroup(item: IPaymentsReportItemDTO): boolean {
      const testItem: IPaymentsReportSubGroupDTO = item as IPaymentsReportSubGroupDTO;
      return (testItem.totalAmount != null);
    }

    private generatePaymentsReportDefinition(datefrom: Date, dateto: Date, reportGroup: PaymentReportGroupBy, PosId: string, clerkId: string): PaymentsReportDefinitionDTO {
      const parameters = new PaymentsReportDefinitionDTO();

      // Set any filters.
      parameters.filterUserGuid = clerkId;
      parameters.filterPosDeviceGuid = PosId;

      // Specify the data range for the report.
      parameters.salesDateFrom = datefrom;
      parameters.salesDateTo = dateto;

      switch (reportGroup) {
        case PaymentReportGroupBy.None:
          parameters.mainGroup = PaymentsReportGroupOptionType.None;
          break;

        case PaymentReportGroupBy.User:
          parameters.mainGroup = PaymentsReportGroupOptionType.User;
          parameters.hideGroupDetail = true;
          break;

        case PaymentReportGroupBy.Pos:
          parameters.mainGroup = PaymentsReportGroupOptionType.Pos;
          parameters.hideGroupDetail = true;
          break;

        case PaymentReportGroupBy.Payment_Type:
          parameters.mainGroup = PaymentsReportGroupOptionType.PaymentType;
          parameters.hideGroupDetail = true;
          break;


        default:
          break;
      }
      return parameters;
    }

    // return Payments Report pdf file
    getReportFilePDF(datefrom: Date, dateto: Date, reportGroup: PaymentReportGroupBy, PosId: string, clerkId: string): Observable<ArrayBuffer> {
      const Token: string = this.loginService.AccessToken.authToken;
      if (Token !== '') {
        const reportFileEndPoint = this.APIEndpoint.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId)  + '/PDF';

        const parameters: IPaymentsReportDefinitionDTO = this.generatePaymentsReportDefinition(datefrom, dateto, reportGroup, PosId, clerkId);

        return this.http
          .post(reportFileEndPoint, parameters, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), responseType: 'arraybuffer'}).pipe(
            map((data: ArrayBuffer) => {

              return data;

            }),
            catchError((err: HttpErrorResponse) => {
              return throwError(() => err);
            })
        );
      }
    }
}
