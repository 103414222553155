import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeOperationType } from '../enums/ChangeOperationType';
import { ChangeType } from '../enums/ChangeType';
import { EditDataList, IEditDataList } from '../models/DTOs/IEditDataList';
import { IEnvelopeInDTO } from '../models/DTOs/IEnvelopeInDTO';
import { IEnvelopeOutDTO } from '../models/DTOs/IEnvelopeOutDTO';
import { IRoleDTO } from '../models/DTOs/IRoleDTO';
import { IPriceLevel } from '../models/IPriceLevel';
import { IRole } from '../models/IRole';
import { EstateService } from './estate.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  private RoleFunctionsEndPoint = environment.APIEndpoint + 'frontend/corporation/{corporationId}/site/{siteId}/Roles';

  constructor(private loginService: LoginService, private http: HttpClient, private estateService: EstateService) { }

  GetRoles(): Observable<IRole[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.RoleFunctionsEndPoint.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId);

      return this.http
        .get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IRole[]>) => {

            if (data.ok) {
              return this.formatData(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  private formatData(dataList: IRoleDTO[]): IRole[] {
    let list: IRole[] = [];

    // convert list
    dataList.forEach(item => {
      const newRole = this.convertDTO(item);

      list.push(newRole);
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: IRole[]): IRole[] {
    list = list.sort((Item1: IRole, Item2: IRole) => {
      if (Item1.name.toLowerCase() > Item2.name.toLowerCase()) {
        return 1;
      }

      if (Item1.name.toLowerCase() < Item2.name.toLowerCase()) {
        return -1;
      }
    });

    list.forEach(role => {
      role.priceLevels.sort((Item1: IPriceLevel, Item2: IPriceLevel) => {
          if (Item1.listPosition > Item2.listPosition) {
              return 1;
          }

          if (Item1.listPosition < Item2.listPosition) {
              return -1;
          }
      });
    });

    return list;
  }

  private convertDTO(item: IRoleDTO): IRole {
    const newRole: IRole = {
      id: item.id,
      role_GUID: item.role_GUID,
      name: item.name,
      functionKeys: [],
      priceLevels: [],
      isSelected: false,
      changeState: ChangeType.None
    };

    item.priceLevels.forEach(pricelevel => {
      newRole.priceLevels.push({
        id: pricelevel.id,
        priceLevelGuid: pricelevel.priceLevelGuid,
        description: pricelevel.description,
        listPosition: pricelevel.listPosition,
        isSelected: false,
        changeState: ChangeType.None
      });
    });

    item.functionKeys.forEach(functionKey => {
      newRole.functionKeys.push({
        id: functionKey.id,
        functionKey_GUID: functionKey.functionKey_GUID,
        functionKey_ID: functionKey.functionKey_ID,
        name: functionKey.name,
        description: functionKey.description,
        isMultiKey: functionKey.isMultiKey,
        category: functionKey.category,
        actionNumber: functionKey.actionNumber,
        isSelected: false
      });
    });

    return newRole;
  }

  SaveRoles(oldData: IRole[], newData: IRole[]): Observable<IRole[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.RoleFunctionsEndPoint.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId);

      const data: IEditDataList<IRoleDTO> = this.SplitItems(newData);

      return this.http
        .post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IRoleDTO[]>) => {

            if (data.ok) {
              return this.formatData(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        );
    }
  }

  private DeflateItems(list: IRole[]): IRoleDTO[] {
    const newData: IRoleDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        const newItem: IRoleDTO = {
          id: item.id,
          role_GUID: item.role_GUID,
          name: item.name,
          functionKeys: [],
          priceLevels: []
        };

        item.priceLevels.forEach(pricelevel => {
          newItem.priceLevels.push({
            id: pricelevel.id,
            priceLevelGuid: pricelevel.priceLevelGuid,
            description: pricelevel.description,
            listPosition: pricelevel.listPosition
          });
        });

        item.functionKeys.forEach(functionKey => {
          newItem.functionKeys.push({
            id: functionKey.id,
            functionKey_GUID: functionKey.functionKey_GUID,
            functionKey_ID: functionKey.functionKey_ID,
            name: functionKey.name,
            description: functionKey.description,
            isMultiKey: functionKey.isMultiKey,
            category: functionKey.category,
            actionNumber: functionKey.actionNumber
          });
        });

        newData.push(newItem);
      });
    }

    return newData;
  }

  private SplitItems(data: IRole[]): IEditDataList<IRoleDTO> {
    let newData: IEditDataList<IRoleDTO> = new EditDataList<IRoleDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }
}
