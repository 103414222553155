import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { IEopReportSectionDetailDTO } from './IEopReportSectionDetailDTO';

@JsonObject()
export class EopReportSectionDetailAmountDTO implements IEopReportSectionDetailDTO {
  @JsonProperty('DA')
  public amount: number;

  @JsonProperty('DC')
  public description: string;
}
