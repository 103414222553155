import { Action } from '@ngrx/store';
import { ILoginCredential } from 'src/app/models/ILoginCredential';

export enum LoginActionTypes {
    Login = '[Login] Login',
    LoginSuccessful = '[Login] Login - Successful',
    LoginSuccessfulWithDifferentUser = '[Login] Login - Successful with a different user',
    LoginFailed = '[Login] Login - Failed',
    LogOut = '[Login] Log out',
    ResetStore = '[Login] Reset Store',
}

export class Login implements Action {
    readonly type = LoginActionTypes.Login;
    
    constructor(public payload: ILoginCredential) { }
}

export class LoginSuccessful implements Action {
    readonly type = LoginActionTypes.LoginSuccessful;

    constructor(public payload: string) { }
}

export class LoginSuccessfulWithDifferentUser implements Action {
    readonly type = LoginActionTypes.LoginSuccessfulWithDifferentUser;

    constructor(public payload: string) { }
}

export class LoginFailed implements Action {
    readonly type = LoginActionTypes.LoginFailed;

    constructor(public payload: string) { }
}

export class LogOut implements Action {
    readonly type = LoginActionTypes.LogOut;

    constructor() { }
}

export class ResetStore implements Action {
    readonly type = LoginActionTypes.ResetStore;

    constructor() { }
}

export type LoginActions = Login
    | LoginSuccessful
    | LoginSuccessfulWithDifferentUser
    | LoginFailed
    | LogOut
    | ResetStore;
