import * as fromRoot from '../../../NGRX/app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EodReportActions, EodReportActionTypes } from './eodReport.actions';
import { IEodReportDocument, EodReportDocument } from 'src/app/models/IEodReportDocument';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import { IEODItem } from 'src/app/models/IEODItem';
import { EodFormParameters, IEodFormParameters } from 'src/app/models/IEodFormParameters';

export interface State extends fromRoot.State {
    eodReport: EodReportState;
}

export interface EodReportState extends IGardiffStoreSliceBase {
    searchParameters: IEodFormParameters;
    searchResult: IEODItem[];
    selectedEodItem: IEodReportDocument;
    downloadedFile: ArrayBuffer;
}

const initialState: EodReportState = {
    searchParameters: new EodFormParameters(),
    searchResult: [],
    selectedEodItem: new EodReportDocument(),
    downloadedFile: null,
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getEodReportFeatureState = createFeatureSelector<EodReportState>('eodReport');
export const getEodReportState = createSelector(
    getEodReportFeatureState,
    state => state
);

export const getSearchParameters = createSelector(
    getEodReportFeatureState,
    state => state.searchParameters
);

export const getSearchResult = createSelector(
  getEodReportFeatureState,
  state => state.searchResult
);

export const getSelectedEodItem = createSelector(
    getEodReportFeatureState,
    state => state.selectedEodItem
);

export const getDownloadedFile = createSelector(
  getEodReportFeatureState,
  state => state.downloadedFile
);

export const getApiError = createSelector(
  getEodReportFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getEodReportFeatureState,
  state => state.status
);

export function reducer(state: EodReportState = initialState, action: EodReportActions): EodReportState {
    switch (action.type) {
        case EodReportActionTypes.LoadEOD:
            return {
                ...state,
                searchParameters: action.payload,
                searchResult: null,
                apiError: null,
                status: null
            };

        case EodReportActionTypes.LoadEODSuccessful:
            return {
                ...state,
                searchResult: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case EodReportActionTypes.LoadEODFailed:
            return {
                ...state,
                searchResult: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case EodReportActionTypes.SelectEOD:
            return {
                ...state,
                selectedEodItem: null
            };

        case EodReportActionTypes.SelectedEOD:
            return {
                ...state,
                selectedEodItem: action.payload
            };

        case EodReportActionTypes.SelectEODFailed:
            return {
                ...state,
                selectedEodItem: initialState.selectedEodItem
            };

        case EodReportActionTypes.ResetStore:
            return {
                ...state,
                searchParameters: initialState.searchParameters,
                searchResult: initialState.searchResult,
                selectedEodItem: initialState.selectedEodItem,
                downloadedFile: initialState.downloadedFile,
                apiError: initialState.apiError,
                status: initialState.status
            };

        case EodReportActionTypes.SaveSearchParameters:
            return {
                ...state,
                searchParameters: action.payload,
            };

        case EodReportActionTypes.DownloadEOD:
            return {
                ...state,
                downloadedFile: null,
            };

        case EodReportActionTypes.DownloadEODSuccessful:
            return {
                ...state,
                downloadedFile: action.payload,
            };

        case EodReportActionTypes.DownloadEODFailed:
            return {
                ...state,
                downloadedFile: new ArrayBuffer(0)
            };

        case EodReportActionTypes.CleanUpFileBuffer:
            return {
                ...state,
                downloadedFile: initialState.downloadedFile
            };

        default:
            return state;
    }
}
