import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import * as functionKeysActions from './function-keys.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { IFunctionKey } from 'src/app/models/IFunctionKey';
import { FunctionKeysService } from 'src/app/services/function-keys.service';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';

@Injectable()
export class FunctionKeysEffects {
  constructor(private action$: Actions,
    private functionKeyService: FunctionKeysService) { }

  loadFunctionKeys$ = createEffect(
    () => this.action$.pipe(
    ofType(functionKeysActions.FunctionKeysActionTypes.LoadFunctionKeys),
    mergeMap((action: functionKeysActions.LoadFunctionKeys) =>
      this.functionKeyService.GetFunctionKeys().pipe(
        map((functionKeys: IFunctionKey[]) => (new functionKeysActions.LoadFunctionKeysSuccessful(functionKeys))),
        catchError((error: HttpErrorResponse) => of(new functionKeysActions.LoadFunctionKeysFailed(interpretateApiError(error))))
      )
    ))
  );

}
