import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PosSetupActions, PosSetupActionTypes } from './possetup.actions';
import { IMenu } from 'src/app/models/IMenu';
import { IEFTTerminal } from 'src/app/models/IEFTTerminal';
import { IPosSetup } from 'src/app/models/IPosSetup';
import { ISalesArea } from 'src/app/models/ISalesArea';
import { IPrinter } from 'src/app/models/IPrinter';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    posSetups: PosSetupState;
}

export interface PosSetupState extends IGardiffStoreSliceBase {
    posSetups: IPosSetup[];
}

const initialState: PosSetupState = {
    posSetups: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getPosSetupFeatureState = createFeatureSelector<PosSetupState>('posSetup');
export const getPosSetupState = createSelector(
    getPosSetupFeatureState,
    state => state
);

export const getPosSetup = createSelector(
    getPosSetupFeatureState,
    state => state.posSetups
);

export const getApiError = createSelector(
  getPosSetupFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getPosSetupFeatureState,
  state => state.status
);

export function reducer(state: PosSetupState = initialState, action: PosSetupActions): PosSetupState {
    switch (action.type) {
        case PosSetupActionTypes.LoadPosSetup:
            return {
                ...state,
                posSetups: null,
                apiError: null,
                status: null
            };

        case PosSetupActionTypes.LoadPosSetupSuccessful:
            return {
                ...state,
                posSetups: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case PosSetupActionTypes.LoadPosSetupFailed:
            return {
                ...state,
                posSetups: [],
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case PosSetupActionTypes.SavePosSetup:
            return {
                ...state,
                posSetups: null,
                apiError: null,
                status: null
            };

        case PosSetupActionTypes.SavePosSetupSuccessful:
            return {
                ...state,
                posSetups: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case PosSetupActionTypes.SavePosSetupFailed:
            return {
                ...state,
                posSetups: action.oldData,
                apiError: action.error,
                status: new GardiffStoreSliceStatus()
            };

        case PosSetupActionTypes.ResetStore:
            return {
                ...state,
                posSetups: initialState.posSetups,
                apiError: initialState.apiError,
                status: initialState.status
            };

        default:
            return state;
    }
}
