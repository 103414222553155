import { Action } from '@ngrx/store';

export enum AddonsEditorActionTypes {
  SelectTab = '[Addons] Select Tab',
  ResetStore = '[Addons] Reset Store',
}

export class SelectTab implements Action {
  readonly type = AddonsEditorActionTypes.SelectTab;

  constructor(public payload: number) { }
}

export class ResetStore implements Action {
  readonly type = AddonsEditorActionTypes.ResetStore;

  constructor() { }
}

export type AddonsEditorActions = SelectTab
  | ResetStore;
