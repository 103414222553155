import { ChangeType } from '../enums/ChangeType';
import { IMenuDTO } from './DTOs/IMenuDTO';
import { IChangeState } from './Extensions/IChangeState';
import { IItemSelectable } from './Extensions/IItemSelectable';
import { IMenuItem } from './IMenuItem';

export interface IMenu extends IMenuDTO, IChangeState, IItemSelectable {
  items: IMenuItem[];
}

export class Menu implements IMenu {
  id: string;
  menuGuid: string;
  description: string;
  isFood: boolean;
  numberOfColumns: number;
  isSubMenu: boolean;
  menuOrder: number;
  items: IMenuItem[];
  isSelected: boolean;
  changeState: ChangeType;

  constructor() {
    this.id = '';
    this.menuGuid = '';
    this.description = '';
    this.isFood = false;
    this.numberOfColumns = 0;
    this.isSubMenu = false;
    this.menuOrder = 0;
    this.items = [];

    this.isSelected = false;
    this.changeState = ChangeType.None;
  }
}
