import { OperationType } from "src/app/enums/OperationType";

export interface IChangeDTO<T> {
  operation: OperationType;
  dto: T;
}

export class ChangeDTO<T> implements IChangeDTO<T> {
  operation: OperationType;
  dto: T;

  constructor(operation: OperationType, item: T) {
      this.operation = operation;
      this.dto = item
  }
}
