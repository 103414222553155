import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OperatorType } from '../enums/Report/Components/OperatorType';
import { TransactionReportField } from '../enums/Report/ReportField/TransactionReportField';
import { IReportDefinition, ReportDefinition } from '../models/Report/Components/IReportDefinition';
import { ReportPDFDefinition } from '../models/Report/Components/IReportPDFDefinition';
import { ReportSortDefinition } from '../models/Report/Components/IReportSortDefinition';
import { TransactionReportParameters } from '../models/Report/ReportParameters/ITransactionReportParameters';
import { EstateService } from './estate.service';
import { LoginService } from './login.service';
import { ITransactionReport, TransactionReport } from '../models/Report/ITransactionReport';
import { TimeZoneDTO } from '../models/DTOs/TimeZoneDTO';

@Injectable({
  providedIn: 'root'
})
export class TransactionReportService {

  private token: string;
  private TransactionReportEndpointTemplate = environment.APIEndpoint + 'frontend/corporation/{corporationId}/site/{siteId}/TransactionReport/';

  constructor(private http: HttpClient, private loginService: LoginService, private estateService: EstateService) { }

  getTransactionReport(parameter: TransactionReportParameters): Observable<ITransactionReport> {
    this.token = this.loginService.AccessToken.authToken;

    let definition: IReportDefinition = this.generateTransactionReportDefinition(parameter);

    const _TransactionReportURL = this.TransactionReportEndpointTemplate.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId);

    return this.http.post<ITransactionReport>(_TransactionReportURL, definition, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token) }).pipe(
      map((data: ITransactionReport) => {

        if (data == null) {
          data = new TransactionReport();
        }

        data.reportDefinition = definition
        data.reportPDFDefinition = new ReportPDFDefinition()

        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(() => err);
      })
    );
  }

  // Returns Transaction Report PDF
  getReportFilePDF(parameter: TransactionReportParameters): Observable<ArrayBuffer> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const _TransactionReportPDFURL = this.TransactionReportEndpointTemplate.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId)  + 'PDF';

      let definition: IReportDefinition = this.generateTransactionReportDefinition(parameter);

      return this.http
        .post(_TransactionReportPDFURL, definition, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), responseType: 'arraybuffer'}).pipe(
          map((data: ArrayBuffer) => {

            return data;

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  private generateTransactionReportDefinition(parameter: TransactionReportParameters): IReportDefinition {
    let reportDefinition = new ReportDefinition();

    reportDefinition.groupedFieldEnumValue = parameter.reportGroup

    let transactionDateFrom = this.getFullDate(parameter.transactionDateFromDay, parameter.transactionDateFromHour, parameter.transactionDateFromMinute)

    reportDefinition.filters.push({
      fieldEnumValue: TransactionReportField.Date,
      operatorType: OperatorType.GreaterThanOrEqual,
      value: transactionDateFrom
    });

    let transactionDateTo = this.getFullDate(parameter.transactionDateToDay, parameter.transactionDateToHour, parameter.transactionDateToMinute)

    reportDefinition.filters.push({
      fieldEnumValue: TransactionReportField.Date,
      operatorType: OperatorType.LessThan,
      value: transactionDateTo
    });

    if (parameter.posId > -1){
      reportDefinition.filters.push({
        fieldEnumValue: TransactionReportField.Pos,
        operatorType: OperatorType.Equal,
        value: parameter.posId
      });
    }

    if (parameter.clerkId > -1){
      reportDefinition.filters.push({
        fieldEnumValue: TransactionReportField.User,
        operatorType: OperatorType.Equal,
        value: parameter.clerkId
      });
    }

    if (parameter.tableNumber > -1 && parameter.tableNumber != null){
      reportDefinition.filters.push({
        fieldEnumValue: TransactionReportField.Table,
        operatorType: OperatorType.Equal,
        value: parameter.tableNumber
      });
    }

    //default sort
    reportDefinition.sectionSorts = []

    return reportDefinition;
  }

  private getFullDate(day: Date, hours: string, minutes: string): Date {

    let fullDate: Date;

    fullDate = day;
    fullDate.setHours( Number(hours), Number(minutes), 0, 0);

    return fullDate;
  }

  getReceiptFilePDF(orderId: string): Observable<ArrayBuffer> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const _ReceiptPdfURL = this.TransactionReportEndpointTemplate.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId)  + orderId + '/PDF';

      return this.http
        .post(_ReceiptPdfURL, new TimeZoneDTO(), { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), responseType: 'arraybuffer'}).pipe(
          map((data: ArrayBuffer) => {

            return data;

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }
}
