import { IReportFilterDefinition } from "./IReportFilterDefinition";
import { IReportSectionSortDefinition } from "./IReportSectionSortDefinition";

export interface IReportDefinition {
  filters: IReportFilterDefinition[];
  groupedFieldEnumValue: number;
  sectionSorts: IReportSectionSortDefinition[];
  timeZone: string;
}

export class ReportDefinition implements IReportDefinition {
  filters: IReportFilterDefinition[];
  groupedFieldEnumValue: number;
  sectionSorts: IReportSectionSortDefinition[];
  timeZone: string;

  constructor(){
    this.filters = [];
    this.groupedFieldEnumValue = 0;
    this.sectionSorts = [];
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
