export enum TransactionReportField
{
  None,
  Date,
  User,
  Pos,
  Table
}

export const TransactionReportFieldLabel = new Map<number, string>([
  [TransactionReportField.None, 'None'],
  [TransactionReportField.Date, 'Date Added'],
  [TransactionReportField.User, 'User'],
  [TransactionReportField.Pos, 'Pos'],
  [TransactionReportField.Table, 'Table'],
]);
