export enum LIDiscountReportField
{
  None,
  DiscountName,
  AppliedDate,
  User,
  Pos,
  ProductName,
  Quantity,
  DiscountAmount,
  LineItemTotalPrice
}

export const LIDiscountReportFieldLabel = new Map<number, string>([
  [LIDiscountReportField.None, 'None'],
  [LIDiscountReportField.DiscountName, 'Discount Name'],
  [LIDiscountReportField.AppliedDate, 'Time Applied'],
  [LIDiscountReportField.User, 'Applied by User'],
  [LIDiscountReportField.Pos, 'Applied on Pos'],
  [LIDiscountReportField.ProductName, 'Product Name'],
  [LIDiscountReportField.Quantity, 'Qty'],
  [LIDiscountReportField.DiscountAmount, 'Discount Amount'],
  [LIDiscountReportField.LineItemTotalPrice, 'Post Discount Price']
]);
