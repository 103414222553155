import { Action } from '@ngrx/store';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { IDiscount } from 'src/app/models/IDiscount';

export enum DiscountsActionTypes {
    LoadDiscounts = '[Discounts] Load Discounts',
    LoadDiscountsSuccessful = '[Discounts] Load Discounts - Successful',
    LoadDiscountsFailed = '[Discounts] Load Discounts - Failed',

    SaveDiscounts = '[Discounts] Save Discounts',
    SaveDiscountsSuccessful = '[Discounts] Save Discounts - Successful',
    SaveDiscountsFailed = '[Discounts] Save Discounts - Failed',

    RestoreBackup = '[Discounts] Restore Backup',
    RestoreBackupSuccessful = '[Discounts] Restore Backup - Successful',
    RestoreBackupFailed = '[Discounts] Restore Backup - Failed',

    ResetStore = '[Discounts] Reset Store',
}

export class LoadDiscounts implements Action {
    readonly type = DiscountsActionTypes.LoadDiscounts;

    constructor() { }
}

export class LoadDiscountsSuccessful implements Action {
    readonly type = DiscountsActionTypes.LoadDiscountsSuccessful;

    constructor(public payload: IDiscount[]) { }
}

export class LoadDiscountsFailed implements Action {
    readonly type = DiscountsActionTypes.LoadDiscountsFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveDiscounts implements Action {
    readonly type = DiscountsActionTypes.SaveDiscounts;

    constructor(public payload: IDiscount[]) { }
}

export class SaveDiscountsSuccessful implements Action {
    readonly type = DiscountsActionTypes.SaveDiscountsSuccessful;

    constructor(public payload: IDiscount[]) { }
}

export class SaveDiscountsFailed implements Action {
    readonly type = DiscountsActionTypes.SaveDiscountsFailed;

    constructor(public error: IGenericError, public oldData: IDiscount[]) { }
}

export class RestoreBackup implements Action {
    readonly type = DiscountsActionTypes.RestoreBackup;
  
    constructor() { }
}

export class RestoreBackupSuccessful implements Action {
    readonly type = DiscountsActionTypes.RestoreBackupSuccessful;

    constructor(public payload: IDiscount[]) { }
}

export class RestoreBackupFailed implements Action {
    readonly type = DiscountsActionTypes.RestoreBackupFailed;

    constructor() { }
}

export class ResetStore implements Action {
    readonly type = DiscountsActionTypes.ResetStore;

    constructor() { }
}

export type DiscountsActions = LoadDiscounts
    | LoadDiscountsSuccessful
    | LoadDiscountsFailed
    | SaveDiscounts
    | SaveDiscountsSuccessful
    | SaveDiscountsFailed
    | RestoreBackup
    | RestoreBackupSuccessful
    | RestoreBackupFailed
    | ResetStore;
