import { Action } from '@ngrx/store';
import { IRole } from 'src/app/models/IRole';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';

export enum RolesActionTypes {
    LoadRoles = '[Roles] Load Roles',
    LoadRolesSuccessful = '[Roles] Load Roles - Successful',
    LoadRolesFailed = '[Roles] Load Roles - Failed',
    SaveRoles = '[Roles] Save Roles',
    SaveRolesSuccessful = '[Roles] Save Roles - Successful',
    SaveRolesFailed = '[Roles] Save Roles - Failed',
    ResetStore = '[Roles] Reset Store',
}

export class LoadRoles implements Action {
    readonly type = RolesActionTypes.LoadRoles;

    constructor() { }
}

export class LoadRolesSuccessful implements Action {
    readonly type = RolesActionTypes.LoadRolesSuccessful;

    constructor(public payload: IRole[]) { }
}

export class LoadRolesFailed implements Action {
    readonly type = RolesActionTypes.LoadRolesFailed;

    constructor(public payload: IGenericError) { }
}

export class SaveRoles implements Action {
    readonly type = RolesActionTypes.SaveRoles;

    constructor(public oldData: IRole[], public newData: IRole[]) { }
}

export class SaveRolesSuccessful implements Action {
    readonly type = RolesActionTypes.SaveRolesSuccessful;

    constructor(public payload: IRole[]) { }
}

export class SaveRolesFailed implements Action {
    readonly type = RolesActionTypes.SaveRolesFailed;

    constructor(public error: IGenericError, public oldData: IRole[]) { }
}

export class ResetStore implements Action {
    readonly type = RolesActionTypes.ResetStore;

    constructor() { }
}

export type RolesActions = LoadRoles
    | LoadRolesSuccessful
    | LoadRolesFailed
    | SaveRoles
    | SaveRolesSuccessful
    | SaveRolesFailed
    | ResetStore;
