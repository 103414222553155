import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IReport, Report } from '../../../models/Report/Components/IReport';
import { EstateService } from '../../estate.service';
import { LoginService } from '../../login.service';
import { VoidReportField } from '../../../enums/Report/ReportField/VoidReportField';
import { IReportDefinition, ReportDefinition } from '../../../models/Report/Components/IReportDefinition';
import { OperatorType } from '../../../enums/Report/Components/OperatorType';
import { VoidReportParameters } from '../../../models/Report/ReportParameters/IVoidReportParameters';
import { IReportPDFDefinition, ReportPDFDefinition } from '../../../models/Report/Components/IReportPDFDefinition';
import { ReportPDFEnvelope } from '../../../models/Report/Components/IReportPDFEnvelope';
import { IReportSortDefinition } from '../../../models/Report/Components/IReportSortDefinition';
import { IReportSectionSortDefinition } from 'src/app/models/Report/Components/IReportSectionSortDefinition';

@Injectable({
  providedIn: 'root'
})
export class VoidReportService {

  private defaultSort: IReportSortDefinition = {
    fieldNumber: VoidReportField.VoidedDate - 1,
    isAscending: false
  };

  private token: string;
  private VoidReportEndpointTemplate = environment.APIEndpoint + 'frontend/corporation/{corporationId}/site/{siteId}/VoidReport/';

  constructor(private http: HttpClient, private loginService: LoginService, private estateService: EstateService) { }

  getVoidReport(parameter: VoidReportParameters): Observable<IReport> {
    this.token = this.loginService.AccessToken.authToken;

    let definition: IReportDefinition = this.generateVoidReportDefinition(parameter);

    const _VoidReportURL = this.VoidReportEndpointTemplate.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId);

    return this.http.post<IReport>(_VoidReportURL, definition, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token) }).pipe(
      map((data: IReport) => {

        if (data == null) {
          data = new Report();
        }

        data.reportDefinition = definition
        data.reportPDFDefinition = new ReportPDFDefinition()

        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(() => err);
      })
    );
  }

  // Returns Void Report PDF
  getReportFilePDF(definition: IReportDefinition, pdfDefinition: IReportPDFDefinition, sortDefinition: IReportSortDefinition[]): Observable<ArrayBuffer> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const _VoidReportPDFURL = this.VoidReportEndpointTemplate.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId)  + 'PDF';

      definition.sectionSorts = this.generateSectionSort(sortDefinition);

      let envelope: ReportPDFEnvelope = {
        reportDefinition: definition,
        reportPDFDefinition: pdfDefinition
      }

      return this.http.post(_VoidReportPDFURL, envelope, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), responseType: 'arraybuffer'}).pipe(
        map((data: ArrayBuffer) => {

          return data;

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private generateVoidReportDefinition(parameter: VoidReportParameters): IReportDefinition {
    let reportDefinition = new ReportDefinition();

    reportDefinition.groupedFieldEnumValue = parameter.reportGroup

    let voidedDateFrom = this.getFullDate(parameter.voidedDateFromDay, parameter.voidedDateFromHour, parameter.voidedDateFromMinute)

    reportDefinition.filters.push({
      fieldEnumValue: VoidReportField.VoidedDate,
      operatorType: OperatorType.GreaterThanOrEqual,
      value: voidedDateFrom
    });

    let voidedDateTo = this.getFullDate(parameter.voidedDateToDay, parameter.voidedDateToHour, parameter.voidedDateToMinute)

    reportDefinition.filters.push({
      fieldEnumValue: VoidReportField.VoidedDate,
      operatorType: OperatorType.LessThan,
      value: voidedDateTo
    });

    if (parameter.createdPosId != -1){
      reportDefinition.filters.push({
        fieldEnumValue: VoidReportField.CreatedByPos,
        operatorType: OperatorType.Equal,
        value: parameter.createdPosId
      });
    }

    if (parameter.createdClerkId != -1){
      reportDefinition.filters.push({
        fieldEnumValue: VoidReportField.CreatedByUser,
        operatorType: OperatorType.Equal,
        value: parameter.createdClerkId
      });
    }

    if (parameter.voidedPosId != -1){
      reportDefinition.filters.push({
        fieldEnumValue: VoidReportField.VoidedByPos,
        operatorType: OperatorType.Equal,
        value: parameter.voidedPosId
      });
    }

    if (parameter.voidedClerkId != -1){
      reportDefinition.filters.push({
        fieldEnumValue: VoidReportField.VoidedByUser,
        operatorType: OperatorType.Equal,
        value: parameter.voidedClerkId
      });
    }

    //default sort
    reportDefinition.sectionSorts = this.generateSectionSort(null)

    return reportDefinition;
  }

  private generateSectionSort(sortDefinition: IReportSortDefinition[]): IReportSectionSortDefinition[] {
    let sectionSorts = [{
      reportSectionName: "Voided Items",
      sorts: [this.defaultSort]
    }]

    if (sortDefinition != null){
      sectionSorts[0].sorts.push(sortDefinition[0])
    }

    return sectionSorts;
  }

  private getFullDate(day: Date, hours: string, minutes: string): Date {

    let fullDate: Date;

    fullDate = day;
    fullDate.setHours( Number(hours), Number(minutes), 0, 0);

    return fullDate;
  }
}
