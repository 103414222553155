import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SchedulePriceLevelsActions, SchedulePriceLevelsActionTypes } from './schedule-pricelevels.actions';
import { ISchedulePriceLevel } from 'src/app/models/ISchedulePriceLevel';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';

export interface State extends fromRoot.State {
    schedulePriceLevels: SchedulePriceLevelsState;
}

export interface SchedulePriceLevelsState extends IGardiffStoreSliceBase {
    schedulePriceLevels: ISchedulePriceLevel[];
}

const initialState: SchedulePriceLevelsState = {
    schedulePriceLevels: [],
    apiError: null,
    status: new GardiffStoreSliceStatus()
};

// create the selectors
const getSchedulePriceLevelsFeatureState = createFeatureSelector<SchedulePriceLevelsState>('schedulePriceLevels');
export const getSchedulePriceLevelsState = createSelector(
    getSchedulePriceLevelsFeatureState,
    state => state
);

export const getSchedulePriceLevels = createSelector(
    getSchedulePriceLevelsFeatureState,
    state => state.schedulePriceLevels
);

export const getApiError = createSelector(
  getSchedulePriceLevelsFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getSchedulePriceLevelsFeatureState,
  state => state.status
);

export function reducer(state: SchedulePriceLevelsState = initialState, action: SchedulePriceLevelsActions): SchedulePriceLevelsState {
  switch (action.type) {
    case SchedulePriceLevelsActionTypes.LoadSchedulePriceLevels:
      return {
        ...state,
        schedulePriceLevels: null,
        apiError: null,
        status: null
      };

    case SchedulePriceLevelsActionTypes.LoadSchedulePriceLevelsSuccessful:
      return {
        ...state,
        schedulePriceLevels: action.payload,
        apiError: null,
        status: new GardiffStoreSliceStatus(true)
      };

    case SchedulePriceLevelsActionTypes.LoadSchedulePriceLevelsFailed:
      return {
        ...state,
        schedulePriceLevels: [],
        apiError: action.payload,
        status: new GardiffStoreSliceStatus()
      };

    case SchedulePriceLevelsActionTypes.SaveSchedulePriceLevels:
      return {
        ...state,
        schedulePriceLevels: null,
        apiError: null,
        status: null
      };

    case SchedulePriceLevelsActionTypes.SaveSchedulePriceLevelsSuccessful:
      return {
        ...state,
        schedulePriceLevels: action.payload,
        apiError: null,
        status: new GardiffStoreSliceStatus(true)
      };

    case SchedulePriceLevelsActionTypes.SaveSchedulePriceLevelsFailed:
      return {
        ...state,
        schedulePriceLevels: action.oldData,
        apiError: action.error,
        status: new GardiffStoreSliceStatus()
      };

    case SchedulePriceLevelsActionTypes.RestoreBackup:
      return {
          ...state,
          schedulePriceLevels: null
      };

    case SchedulePriceLevelsActionTypes.RestoreBackupSuccessful:
        return {
            ...state,
            schedulePriceLevels: action.payload
        };

    case SchedulePriceLevelsActionTypes.RestoreBackupFailed:
        return {
            ...state,
            schedulePriceLevels: []
        };

    case SchedulePriceLevelsActionTypes.ResetStore:
      return {
        ...state,
        schedulePriceLevels: initialState.schedulePriceLevels,
        apiError: initialState.apiError,
        status: initialState.status
      };

    default:
      return state;
  }
}

// function updateSchedules(list: ISchedulePriceLevel[], priceLevel: ISchedulePriceLevel): ISchedulePriceLevel[] {
//   let bFound = false;

//   for (let p = 0; p < list.length; p++) {
//     const element = list[p];
//     if (element.priceLevelSchedule_GUID === priceLevel.priceLevelSchedule_GUID) {
//       list[p] = priceLevel;

//       bFound = true;
//       break;
//     }
//   }

//   if (!bFound) {
//     list.push(priceLevel);
//   }

//   return list;
// }
