import { Injectable } from '@angular/core';
import * as Realm from 'realm-web';
import { delay } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RealmAppService {
  private static app: Promise<Realm.App>;

  async getAppInstance() {
    if (!RealmAppService.app){
      RealmAppService.app = this.loadAppInstance();
    }

    return await RealmAppService.app;
  }

  async loadAppInstance() {
    const app: Realm.App = new Realm.App({ id: environment.mongoDB.appServiceName });

    const credentials = Realm.Credentials.apiKey(environment.mongoDB.apiKeyValue);
    await app.logIn(credentials);

    return app;
  }
}
