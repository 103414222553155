import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, Subscriber } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeOperationType } from '../enums/ChangeOperationType';
import { ChangeType } from '../enums/ChangeType';
import { checkHexColor } from '../helpers/ColorHelper';
import { EditDataList, IEditDataList } from '../models/DTOs/IEditDataList';
import { IEnvelopeInDTO } from '../models/DTOs/IEnvelopeInDTO';
import { IEnvelopeOutDTO } from '../models/DTOs/IEnvelopeOutDTO';
import { IMenuDTO } from '../models/DTOs/IMenuDTO';
import { IMenuItemDTO } from '../models/DTOs/IMenuItemDTO';
import { IMenu } from '../models/IMenu';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class MenuPlanService {
  private MenusEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/MenuPlan';

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetMenuPlan(CorporationID: string, SiteID: string): Observable<IMenu[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.MenusEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http.get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<IMenuDTO[]>) => {
          
          if (data.ok) {
            return this.formatData(data.body);
          }
          
        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private formatData(dataList: IMenuDTO[]): IMenu[] {
    let list: IMenu[] = [];

    // convert list
    dataList.forEach(item => {
      list.push(
        this.ConvertMenu(item)
      );
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: IMenu[]): IMenu[] {
    list.sort((Item1: IMenu, Item2: IMenu) => {
      if (Item1.menuOrder > Item2.menuOrder) {
        return 1;
      }

      if (Item1.menuOrder < Item2.menuOrder) {
        return -1;
      }
    });

    // sort by menuItemOrder the MenuItems list for every Menu
    list.forEach(menu => {
      menu.items.sort((Item1: IMenuItemDTO, Item2: IMenuItemDTO) => {
        if (Item1.menuItemOrder > Item2.menuItemOrder) {
          return 1;
        }

        if (Item1.menuItemOrder < Item2.menuItemOrder) {
          return -1;
        }
      });
    });

    return list;
  }

  private ConvertMenu(item: IMenuDTO): IMenu {
    const newItem: IMenu = {
      id: item.id,
      menuGuid: item.menuGuid,
      description: item.description,
      isFood: item.isFood,
      numberOfColumns: item.numberOfColumns,
      isSubMenu: item.isSubMenu,
      menuOrder: item.menuOrder,
      items: [],
      isSelected: false,
      changeState: ChangeType.None
    };

    if (item.items != null) {
      item.items.forEach(menuItem => {
        newItem.items.push({
          id: menuItem.id,
          menuItemGuid: menuItem.menuItemGuid,
          menuItemType: menuItem.menuItemType,
          itemGuid: menuItem.itemGuid,
          colour: checkHexColor(menuItem.colour),
          menuItemMeasureGuid: menuItem.menuItemMeasureGuid,
          menuItemOrder: menuItem.menuItemOrder,
          description: '',
          colorText: '#fff',
          isActive: true,
          isSelected: false
        });
      });
    }

    return newItem;
  }

  SaveMenuPlan(CorporationID: string, SiteID: string, OldData: IMenu[], newData: IMenu[]): Observable<IMenu[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.MenusEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IEditDataList<IMenuDTO> = this.SplitItems(newData);

      return this.http.post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<IMenuDTO[]>) => {
          
          if (data.ok) {
            return this.formatData(data.body);
          }

        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private DeflateItems(list: IMenu[]): IMenuDTO[] {
    const newData: IMenuDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        const newAddon: IMenuDTO = {
          id: item.id,
          menuGuid: item.menuGuid,
          description: item.description,
          isFood: item.isFood,
          numberOfColumns: item.numberOfColumns,
          isSubMenu: item.isSubMenu,
          menuOrder: item.menuOrder,
          items: []
        };

        item.items.forEach(menuItem => {
          if (menuItem.id !== '' && menuItem.menuItemGuid !== '') {
            newAddon.items.push({
              id: menuItem.id,
              menuItemGuid: menuItem.menuItemGuid,
              menuItemType: menuItem.menuItemType,
              itemGuid: menuItem.itemGuid,
              colour: menuItem.colour,
              menuItemMeasureGuid: menuItem.menuItemMeasureGuid,
              menuItemOrder: menuItem.menuItemOrder
            });
          }
        });

        newData.push(newAddon);
      });
    }

    return newData;
  }

  private SplitItems(data: IMenu[]): IEditDataList<IMenuDTO> {
    let newData: IEditDataList<IMenuDTO> = new EditDataList<IMenuDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }
}
