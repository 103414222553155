import { Action } from '@ngrx/store';
import { ISalesReportDocument } from 'src/app/models/ISalesReportDocument';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { SalesReportFormParameters } from 'src/app/models/ISalesReportFormParameters';

export enum SalesReportActionTypes {
    LoadSalesReport = '[Sales Report] Load Sales Report',
    LoadSalesReportSuccessful = '[Sales Report] Load Sales Report - Successful',
    LoadSalesReportFailed = '[Sales Report] Load Sales Report - Failed',
    ResetStore = '[Sales Report] Reset Store',
    SaveSearchParameters = '[Sales Report] Save Search Parameters',
    DownloadEOD = '[Sales Reports] Download Sales Reports',
    DownloadEODSuccessful = '[Sales Reports] Download Sales Reports - Successful',
    DownloadEODFailed = '[Sales Reports] Download Sales Reports - Failed',
    CleanUpFileBuffer = '[Sales Reports] Clean up file buffer'
}

export class LoadSalesReport implements Action {
    readonly type = SalesReportActionTypes.LoadSalesReport;

    constructor(public payload: SalesReportFormParameters) { }
}

export class LoadSalesReportSuccessful implements Action {
    readonly type = SalesReportActionTypes.LoadSalesReportSuccessful;

    constructor(public payload: ISalesReportDocument) { }
}

export class LoadSalesReportFailed implements Action {
    readonly type = SalesReportActionTypes.LoadSalesReportFailed;

    constructor(public payload: IGenericError) { }
}

export class ResetStore implements Action {
    readonly type = SalesReportActionTypes.ResetStore;

    constructor() { }
}

export class SaveSearchParameters implements Action {
    readonly type = SalesReportActionTypes.SaveSearchParameters;

    constructor(public payload: SalesReportFormParameters) { }
}

export class DownloadEOD implements Action {
  readonly type = SalesReportActionTypes.DownloadEOD;

  constructor(public payload: SalesReportFormParameters) { }
}

export class DownloadEODSuccessful implements Action {
  readonly type = SalesReportActionTypes.DownloadEODSuccessful;

  constructor(public payload: ArrayBuffer) { }
}

export class DownloadEODFailed implements Action {
  readonly type = SalesReportActionTypes.DownloadEODFailed;

  constructor(public payload: IGenericError) { }
}

export class CleanUpFileBuffer implements Action {
  readonly type = SalesReportActionTypes.CleanUpFileBuffer;

  constructor() { }
}

export type SalesReportActions = LoadSalesReport
    | LoadSalesReportSuccessful
    | LoadSalesReportFailed
    | ResetStore
    | SaveSearchParameters
    | DownloadEOD
    | DownloadEODSuccessful
    | DownloadEODFailed
    | CleanUpFileBuffer;
