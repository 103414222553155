import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import * as zreportSetupActions from './zreport.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { IEopSectionType } from 'src/app/models/IEopSectionType';
import { of } from 'rxjs';
import { SetupService } from 'src/app/services/setup.service';
import { getEodSections } from 'src/app/enums/EopSectionType';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';

@Injectable()
export class ZReportEffects {
    constructor(private action$: Actions, private setupService: SetupService, private estateService: EstateService) {

    }

    loadZReportSetup$ = createEffect(
      () => this.action$.pipe(
        ofType(zreportSetupActions.ZReportActionTypes.LoadZReport),
        mergeMap((action: zreportSetupActions.LoadZReport) =>
            this.setupService.GetZReportSetup(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
                map((sections: number[]) => {
                    return new zreportSetupActions.LoadZReportSuccessful(this.buildSectionList(sections));
                }),
                catchError((error: HttpErrorResponse) => of(new zreportSetupActions.LoadZReportFailed(interpretateApiError(error))))
            ),
      ))
    );

    saveZReportSetup$ = createEffect(
      () => this.action$.pipe(
        ofType(zreportSetupActions.ZReportActionTypes.SaveZReport),
        mergeMap((action: zreportSetupActions.SaveZReport) => {
          const newSections: number[] = [];

          action.payload.forEach(section => {
            if (section.isActive) {
              newSections.push(section.value);
            }
          });

          return this.setupService.SaveZReportSetup(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, newSections).pipe(
            map((result: number[]) =>  new zreportSetupActions.SaveZReportSuccessful(this.buildSectionList(result))),
            catchError((error: HttpErrorResponse) => of(new zreportSetupActions.SaveZReportFailed(interpretateApiError(error))))
          );
        },
      ))
    );

    private buildSectionList(activeSections: number[]): IEopSectionType[] {
        const sectionList: IEopSectionType[] = getEodSections().filter(s => s.value >= 0 && s.value < 50 && s.value != 13);
        const newSectionList: IEopSectionType[] = [];

        activeSections.forEach(sectionCode => {
            for (let i = 0; i < sectionList.length; i++) {
                const sectionObject = sectionList[i];

                if (sectionCode === sectionObject.value) {
                    sectionObject.isActive = true;

                    newSectionList.push(JSON.parse(JSON.stringify(sectionList[i])));
                    sectionList.splice(i, 1);
                }
            }
        });

        //now I have to append the Items not active
        sectionList.forEach(item => {
            newSectionList.push(JSON.parse(JSON.stringify(item)));
        });

        return newSectionList;
    }
}
