import { Action } from '@ngrx/store';
import { IEstateView } from 'src/app/models/IEstateView';
import { IBreadCrumb } from 'src/app/models/IBreadCrumb';
import { ISiteDetails } from 'src/app/models/ISiteDetails';
import { IBrand } from 'src/app/models/IBrand';
import { IArea } from 'src/app/models/IArea';
import { ISite } from 'src/app/models/ISite';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';

export enum EstateActionTypes {
    LoadEstate = '[Estate] Load Estate',
    LoadEstateSuccessful = '[Estate] Load Estate - Successful',
    LoadEstateFailed = '[Estate] Load Estate - Failed',
    LoadEstateViewMonitor = '[Monitor] Load Estate View',
    LoadEstateViewMonitorSuccessful = '[Monitor] Load Estate View - Successful',
    LoadEstateViewMonitorFailed = '[Monitor] Load Estate View - Failed',
    LoadInitialSite = '[Estate] Load Initial Site',
    LoadInitialSiteSuccessful = '[Estate] Load Initial Site - Successful',
    LoadInitialSiteFailed = '[Estate] Load Initial Site - Failed',
    ChangeSite = '[Estate] Change Site',
    LoadSiteDetails = '[Estate] Load Site Details',
    LoadSiteDetailsSuccessful = '[Estate] Load Site Details - Successful',
    LoadSiteDetailsFailed = '[Estate] Load Site Details - Failed',
    SaveSiteDetails = '[Estate] Save Site Details',
    SaveSiteDetailsSuccessful = '[Estate] Save Site Details - Successful',
    SaveSiteDetailsFailed = '[Estate] Save Site Details - Failed',
    ResetStore = '[Estate] Reset Store',
    ResetSiteDetails = '[Estate] Reset Site Details',
}

export class LoadEstate implements Action {
    readonly type = EstateActionTypes.LoadEstate;

    constructor() { }
}

export class LoadEstateSuccessful implements Action {
    readonly type = EstateActionTypes.LoadEstateSuccessful;

    constructor(public payload: IEstateView) { }
}

export class LoadEstateFailed implements Action {
    readonly type = EstateActionTypes.LoadEstateFailed;

    constructor() { }
}

export class LoadEstateViewMonitor implements Action {
  readonly type = EstateActionTypes.LoadEstateViewMonitor;

  constructor(public CorporationID: string) { }
}

export class LoadEstateViewMonitorSuccessful implements Action {
  readonly type = EstateActionTypes.LoadEstateViewMonitorSuccessful;

  constructor(public payload: IEstateView) { }
}

export class LoadEstateViewMonitorFailed implements Action {
  readonly type = EstateActionTypes.LoadEstateViewMonitorFailed;

  constructor(public payload: IGenericError) { }
}

export class LoadInitialSite implements Action {
    readonly type = EstateActionTypes.LoadInitialSite;

    constructor() { }
}

export class LoadInitialSiteSuccessful implements Action {
    readonly type = EstateActionTypes.LoadInitialSiteSuccessful;

    constructor(public estate: IEstateView,
                public selectedBrand: IBrand,
                public selectedArea: IArea,
                public selectedSite: ISite) { }
}

export class LoadInitialSiteFailed implements Action {
    readonly type = EstateActionTypes.LoadInitialSiteFailed;

    constructor() { }
}

export class ChangeSite implements Action {
    readonly type = EstateActionTypes.ChangeSite;

    constructor(public payload: IBreadCrumb) { }
}

export class LoadSiteDetails implements Action {
    readonly type = EstateActionTypes.LoadSiteDetails;

    constructor(public payload: string) { }
}

export class LoadSiteDetailsSuccessful implements Action {
    readonly type = EstateActionTypes.LoadSiteDetailsSuccessful;

    constructor(public payload: ISiteDetails) { }
}

export class LoadSiteDetailsFailed implements Action {
    readonly type = EstateActionTypes.LoadSiteDetailsFailed;

    constructor(public payload: string) { }
}

export class SaveSiteDetails implements Action {
    readonly type = EstateActionTypes.SaveSiteDetails;

    constructor(public payload: ISiteDetails) { }
}

export class SaveSiteDetailsSuccessful implements Action {
    readonly type = EstateActionTypes.SaveSiteDetailsSuccessful;

    constructor(public payload: ISiteDetails) { }
}

export class SaveSiteDetailsFailed implements Action {
    readonly type = EstateActionTypes.SaveSiteDetailsFailed;

    constructor(public payload: string) { }
}

export class ResetStore implements Action {
    readonly type = EstateActionTypes.ResetStore;

    constructor() { }
}

export class ResetSiteDetails implements Action {
    readonly type = EstateActionTypes.ResetSiteDetails;

    constructor() { }
}

export type EstateActions = LoadEstate
    | LoadEstateSuccessful
    | LoadEstateFailed
    | LoadEstateViewMonitor
    | LoadEstateViewMonitorSuccessful
    | LoadEstateViewMonitorFailed
    | LoadInitialSite
    | LoadInitialSiteSuccessful
    | LoadInitialSiteFailed
    | ChangeSite
    | LoadSiteDetails
    | LoadSiteDetailsSuccessful
    | LoadSiteDetailsFailed
    | SaveSiteDetails
    | SaveSiteDetailsSuccessful
    | SaveSiteDetailsFailed
    | ResetStore
    | ResetSiteDetails;
