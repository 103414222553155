import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { EstateService } from '../../services/estate.service';
import { AdminService } from '../../services/admin.service';

import * as adminActions from './admin.actions';
import { mergeMap, map, catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from '../../helpers/InterpretateApiError';
import { IEposManagerVersion } from 'src/app/models/IEposManagerVersion';

@Injectable()
export class AdminEffects {
  constructor(private action$: Actions,
    private estateService: EstateService,
    private adminService: AdminService) {

  }

  loadEposManagerVersion$ = createEffect(
    () => this.action$.pipe(
    ofType(adminActions.AdminActionTypes.LoadEposManagerVersion),
    mergeMap((action: adminActions.LoadEposManagerVersion) =>
      this.adminService.GetEposManagerVersion(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((version: string) => new adminActions.LoadEposManagerVersionSuccessful(version)),
        catchError((error: HttpErrorResponse) => of(new adminActions.LoadEposManagerVersionFailed(interpretateApiError(error))))
      )
    ))
  );

  loadEposManagerVersionDetails$ = createEffect(
    () => this.action$.pipe(
    ofType(adminActions.AdminActionTypes.LoadEposManagerVersionSuccessful),
    mergeMap((action: adminActions.LoadEposManagerVersionSuccessful) =>
      this.adminService.SplitEposManagerVersion(action.payload).pipe(
        switchMap((details: IEposManagerVersion) => {

          let enableTax: boolean = !(details.major == 2 && details.minor == 8)
          let enableLiveDashboard: boolean = false;
          let enableVoidReport: boolean = false;
          let enableTransactionReport: boolean = false;
          let enableDiscountReport: boolean = false;

          if (details.major >= 3) {
            enableLiveDashboard = true;
            enableVoidReport = true;
            enableTransactionReport = true;
            enableDiscountReport = true;
          }

          return of(
            new adminActions.SplitEposManagerVersionSuccessful(details),
            new adminActions.ActivateTaxFeature(enableTax),
            new adminActions.ActivateLiveDashboardFeature(enableLiveDashboard),
            new adminActions.ActivateVoidReportFeature(enableVoidReport),
            new adminActions.ActivateTransactionReportFeature(enableTransactionReport),
            new adminActions.ActivateDiscountReportFeature(enableDiscountReport)
          )
        }),
        catchError((error: HttpErrorResponse) => of(new adminActions.SplitEposManagerVersionFailed(interpretateApiError(error))))
      )
    ))
  );

  // this effect store observe ActivateDarkMode and store the payload into the local storage
  activateDarkMode$ = createEffect(() => this.action$.pipe(
    ofType(adminActions.AdminActionTypes.ActivateDarkMode),
    tap((action: adminActions.ActivateDarkMode) =>
      {
        // store the payload into the local storage
        localStorage.setItem('darkMode', action.payload.toString());
      })
    ), { dispatch: false }
  );

}
