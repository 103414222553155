import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import * as salesareaActions from './salesarea.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SalesAreasService } from 'src/app/services/sales-areas.service';
import { ISalesArea } from 'src/app/models/ISalesArea';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';

@Injectable()
export class SalesareaEffects {
  constructor(private action$: Actions,
    private estateService: EstateService,
    private salesAreasService: SalesAreasService) {

  }

  loadSalesarea$ = createEffect(
    () => this.action$.pipe(
    ofType(salesareaActions.SalesAreaActionTypes.LoadSalesArea),
    mergeMap((action: salesareaActions.LoadSalesArea) =>
      this.salesAreasService.GetSalesAreas(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((Salesarea: ISalesArea[]) => (new salesareaActions.LoadSalesAreaSuccessful(Salesarea))),
        catchError((error: HttpErrorResponse) => of(new salesareaActions.LoadSalesAreaFailed(interpretateApiError(error))))
      )
    ))
  );

  saveSalesarea$ = createEffect(
    () => this.action$.pipe(
    ofType(salesareaActions.SalesAreaActionTypes.SaveSalesArea),
    mergeMap((action: salesareaActions.SaveSalesArea) =>
      this.salesAreasService.SaveSalesAreas(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.oldData, action.newData).pipe(
        map((Salesarea: ISalesArea[]) => new salesareaActions.SaveSalesAreaSuccessful(Salesarea)),
        catchError((error: HttpErrorResponse) => of(new salesareaActions.SaveSalesAreaFailed(interpretateApiError(error, HttpVerbType.POST), action.newData)))
      )
    ))
  );
}
