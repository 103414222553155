import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeOperationType } from '../enums/ChangeOperationType';
import { ChangeType } from '../enums/ChangeType';
import { EditDataList, IEditDataList } from '../models/DTOs/IEditDataList';
import { IEnvelopeInDTO } from '../models/DTOs/IEnvelopeInDTO';
import { IEnvelopeOutDTO } from '../models/DTOs/IEnvelopeOutDTO';
import { IMeasureDTO } from '../models/DTOs/IMeasureDTO';
import { IMeasure } from '../models/IMeasure';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class MeasuresService {
  private MeasureSetupEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/Measures';

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetMeasures(CorporationID: string, SiteID: string): Observable<IMeasure[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.MeasureSetupEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http.get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<IMeasureDTO[]>) => {
          
          if (data.ok) {
            return this.formatData(data.body);
          }

        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private formatData(dataList: IMeasureDTO[]): IMeasure[] {
    let list: IMeasure[] = [];

    // convert list
    dataList.forEach(item => {
      list.push({
        id: item.id,
        measureGuid: item.measureGuid,
        description: item.description,
        type: item.type,
        isActive: item.isActive,
        volume: item.volume,
        system: item.system,
        buttonDescription: item.buttonDescription,
        isSelected: false,
        changeState: ChangeType.None
      });
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: IMeasure[]): IMeasure[] {
    return list.sort((Item1: IMeasure, Item2: IMeasure) => {
      if (Item1.description.toLowerCase() > Item2.description.toLowerCase()) {
        return 1;
      }

      if (Item1.description.toLowerCase() < Item2.description.toLowerCase()) {
        return -1;
      }
    });
  }

  SaveMeasures(CorporationID: string, SiteID: string, oldData: IMeasure[], newData: IMeasure[]): Observable<IMeasure[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.MeasureSetupEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IEditDataList<IMeasureDTO> = this.SplitItems(newData);

      return this.http.post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<IMeasureDTO[]>) => {
          
          if (data.ok) {
            return this.formatData(data.body);
          }

        }), 
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private DeflateItems(list: IMeasure[]): IMeasureDTO[] {
    const newData: IMeasureDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        newData.push({
          id: item.id,
          measureGuid: item.measureGuid,
          description: item.description,
          type: item.type,
          isActive: item.isActive,
          volume: item.volume,
          system: item.system,
          buttonDescription: item.buttonDescription
        });
      });
    }

    return newData;
  }

  private SplitItems(data: IMeasure[]): IEditDataList<IMeasureDTO> {
    let newData: IEditDataList<IMeasureDTO> = new EditDataList<IMeasureDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }
}
