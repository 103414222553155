import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import * as eposSettingsActions from './epossettings.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { SetupService } from 'src/app/services/setup.service';
import { IEposSetting } from 'src/app/models/IEposSetting';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';

@Injectable()
export class EposSettingsEffects {
    constructor(private action$: Actions, private setupService: SetupService, private estateService: EstateService) {

    }

    loadEposSettings$ = createEffect(
      () => this.action$.pipe(
        ofType(eposSettingsActions.EposSettingsActionTypes.LoadEposSettings),
        mergeMap((action: eposSettingsActions.LoadEposSettings) =>
            this.setupService.GetEPOSSettings(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
                map((settings: IEposSetting[]) => (new eposSettingsActions.LoadEposSettingsSuccessful(settings)),
                catchError((error: HttpErrorResponse) => of(new eposSettingsActions.LoadEposSettingsFailed(interpretateApiError(error))))
            ))
      ))
    );

    saveEposSettings$ = createEffect(
      () => this.action$.pipe(
        ofType(eposSettingsActions.EposSettingsActionTypes.SaveEposSettings),
        mergeMap((action: eposSettingsActions.SaveEposSettings) =>
            this.setupService.SaveEPOSSettings(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.payload).pipe(
              map((result: IEposSetting[]) => {
                if (result) {
                  return new eposSettingsActions.SaveEposSettingsSuccessful(result);
                }
              },
              catchError((error: HttpErrorResponse) => of(new eposSettingsActions.SaveEposSettingsFailed(interpretateApiError(error))))
        ))
      ))
    );
}
