import { IPriceMatrixDTO } from './DTOs/IPriceMatrixDTO';
import { ConvertPriceGroupList, ConvertPriceGroupListDTO, IPriceGroupList } from './IPriceGroupList';

export interface IPriceMatrix extends IPriceMatrixDTO {
  priceGroupList: IPriceGroupList[];
}

export class PriceMatrix implements IPriceMatrix {
  priceGroupList: IPriceGroupList[];

  constructor() {
      this.priceGroupList = [];
  }
}

export function ConvertPriceMatrixDTO(matrix: IPriceMatrixDTO): IPriceMatrix {
  const newMatrix: IPriceMatrix = {
    priceGroupList: ConvertPriceGroupListDTO(matrix.priceGroupList)
  };

  return newMatrix;
}

export function ConvertPriceMatrix(matrix: IPriceMatrix): IPriceMatrixDTO {
  const newMatrix: IPriceMatrixDTO = {
    priceGroupList: ConvertPriceGroupList(matrix.priceGroupList)
  };

  return newMatrix;
}
