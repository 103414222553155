import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeOperationType } from '../enums/ChangeOperationType';
import { ChangeType } from '../enums/ChangeType';
import { IAddonDTO } from '../models/DTOs/IAddonDTO';
import { EditDataList, IEditDataList } from '../models/DTOs/IEditDataList';
import { IEnvelopeInDTO } from '../models/DTOs/IEnvelopeInDTO';
import { IEnvelopeOutDTO } from '../models/DTOs/IEnvelopeOutDTO';
import { IAddon } from '../models/IAddon';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AddonsService {
  private AddonsEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/Addons';

  private addons: IAddon[] = [];

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetAddons(CorporationID: string, SiteID: string): Observable<IAddon[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.AddonsEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http
        .get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IAddonDTO[]>) => {

            if (data.ok) {
              // store a copy for backup
              this.addons = this.formatData(data.body);
              return JSON.parse(JSON.stringify(this.addons));
            }

          }), 
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  private formatData(dataList: IAddonDTO[]): IAddon[] {
    let list: IAddon[] = [];

    // convert list
    dataList.forEach(item => {
      list.push(
        this.ConvertAddon(item)
      );
    });

    // sort data
    list = this.sortData(list);

    return list;
  }

  private sortData(list: IAddon[]): IAddon[] {
    return list.sort((Item1: IAddon, Item2: IAddon) => {
      if (Item1.addonHeaderName.toLowerCase() > Item2.addonHeaderName.toLowerCase()) {
        return 1;
      }

      if (Item1.addonHeaderName.toLowerCase() < Item2.addonHeaderName.toLowerCase()) {
        return -1;
      }
    });
  }

  private ConvertAddon(item: IAddonDTO): IAddon {
    const newItem: IAddon = {
      id: item.id,
      addonHeaderGuid: item.addonHeaderGuid,
      addonHeaderName: item.addonHeaderName,
      isPaidAddon: item.isPaidAddon,
      numRepeats: item.numRepeats,
      addonDetails: [],
      isSelected: false,
      changeState: ChangeType.None
    };

    item.addonDetails.forEach(detail => {
      newItem.addonDetails.push({
        id: detail.id,
        addonDetailGuid: detail.addonDetailGuid,
        addonDetailName: detail.addonDetailName,
        price: detail.price,
        relatedProductGuid: detail.relatedProductGuid,
        isSelected: false,
        isChanged: ChangeType.None,
        product: null,
        category: null
      });
    });

    return newItem;
  }

  SaveAddons(CorporationID: string, SiteID: string, newAddons: IAddon[]): Observable<IAddon[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.AddonsEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IEditDataList<IAddonDTO> = this.SplitItems(newAddons);

      return this.http
        .post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IAddonDTO[]>) => {

            if (data.ok) {
              // store a copy for backup
              this.addons = this.formatData(data.body);
              return JSON.parse(JSON.stringify(this.addons));
            }

          }), 
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        );
    }
  }

  private DeflateItems(list: IAddon[]): IAddonDTO[] {
    const newData: IAddonDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        const newAddon: IAddonDTO = {
          id: item.id,
          addonDetails: [],
          addonHeaderGuid: item.addonHeaderGuid,
          addonHeaderName: item.addonHeaderName,
          isPaidAddon: item.isPaidAddon,
          numRepeats: item.numRepeats
        };

        item.addonDetails.forEach(detail => {
          newAddon.addonDetails.push({
            id: detail.id,
            addonDetailGuid: detail.addonDetailGuid,
            addonDetailName: detail.addonDetailName,
            price: detail.price,
            relatedProductGuid: detail.relatedProductGuid
          });
        });

        newData.push(newAddon);
      });
    }

    return newData;
  }

  private SplitItems(data: IAddon[]): IEditDataList<IAddonDTO> {
    let newData: IEditDataList<IAddonDTO> = new EditDataList<IAddonDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }

  GetBackup(): Observable<IAddon[]> {
    const backup: IAddon[] = JSON.parse(JSON.stringify(this.addons));
    return new Observable<IAddon[]>((subscriber: Subscriber<IAddon[]>) => subscriber.next(backup));
  }
}
