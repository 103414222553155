import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { ICategory } from 'src/app/models/ICategory';
import { CategoriesService } from 'src/app/services/categories.service';
import { MeasuresService } from 'src/app/services/measures.service';
import * as parentCategoryActions from '../parent-categories/parent-category.actions';
import * as categoryEditorActions from './categories.actions';

@Injectable()
export class CategoriesEffects {
  constructor(private action$: Actions,
    private estateService: EstateService,
    private categoriesService: CategoriesService,
    private measurementsService: MeasuresService) { }

  loadCategories$ = createEffect(
    () => this.action$.pipe(
    ofType(categoryEditorActions.CategoriesActionTypes.LoadCategories),
    mergeMap((action: categoryEditorActions.LoadCategories) =>
      this.categoriesService.GetCategories(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((categories: ICategory[]) => (new categoryEditorActions.LoadCategoriesSuccessful(categories))),
        catchError((error: HttpErrorResponse) => of(new categoryEditorActions.LoadCategoriesFailed(interpretateApiError(error))))
      ),
    ))
  );

  restoreBackup$ = createEffect(
    () => this.action$.pipe(
    ofType(categoryEditorActions.CategoriesActionTypes.RestoreBackup),
    mergeMap((action: categoryEditorActions.RestoreBackup) =>
      this.categoriesService.GetBackup().pipe(
        map((categories: ICategory[]) => (new categoryEditorActions.RestoreBackupSuccessful(categories))),
        catchError(error => of(new categoryEditorActions.RestoreBackupFailed()))
        ),
    ))
  );

  saveCategories$ = createEffect(
    () => this.action$.pipe(
    ofType(categoryEditorActions.CategoriesActionTypes.SaveCategories),
    mergeMap((action: categoryEditorActions.SaveCategories) =>
      this.categoriesService.SaveCategories(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.payload).pipe(
        map((categories: ICategory[]) => new categoryEditorActions.SaveCategoriesSuccessful(categories)),
        catchError((error: HttpErrorResponse) => of(new categoryEditorActions.SaveCategoriesFailed(interpretateApiError(error, HttpVerbType.POST), action.payload)))
      ),
    ))
  );

  saveCategoriesWithoutTax$ = createEffect(
    () => this.action$.pipe(
    ofType(categoryEditorActions.CategoriesActionTypes.SaveCategoriesWithoutTax),
    mergeMap((action: categoryEditorActions.SaveCategoriesWithoutTax) =>
      this.categoriesService.SaveCategoriesWithoutTax(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.payload).pipe(
        map((categories: ICategory[]) => new categoryEditorActions.SaveCategoriesSuccessful(categories)),
        catchError((error: HttpErrorResponse) => of(new categoryEditorActions.SaveCategoriesFailed(interpretateApiError(error, HttpVerbType.POST), action.payload)))
      ),
    ))
  );

  resetParentCategories$ = createEffect(
    () => this.action$.pipe(
      ofType(categoryEditorActions.CategoriesActionTypes.SaveCategoriesSuccessful),
      mergeMap(() =>
        of(new parentCategoryActions.ResetStore())
      )
    )
  )
}
