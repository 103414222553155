import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { EstateService } from 'src/app/services/estate.service';

import * as discountsActions from './discounts.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { DiscountsService } from 'src/app/services/discounts.service';
import { IDiscount } from 'src/app/models/IDiscount';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';
import { HttpVerbType } from 'src/app/enums/HttpVerbType';

@Injectable()
export class DiscountsEffects {
  constructor(private action$: Actions,
    private estateService: EstateService,
    private discountsService: DiscountsService) {

  }

  loadDiscounts$ = createEffect(
    () => this.action$.pipe(
    ofType(discountsActions.DiscountsActionTypes.LoadDiscounts),
    mergeMap((action: discountsActions.LoadDiscounts) =>
      this.discountsService.GetDiscounts(this.estateService.CorporationID, this.estateService.SelectedSite.siteId).pipe(
        map((discounts: IDiscount[]) => new discountsActions.LoadDiscountsSuccessful(discounts)),
        catchError((error: HttpErrorResponse) => of(new discountsActions.LoadDiscountsFailed(interpretateApiError(error))))
      )
    ))
  );

  restoreBackup$ = createEffect(
    () => this.action$.pipe(
    ofType(discountsActions.DiscountsActionTypes.RestoreBackup),
    mergeMap((action: discountsActions.RestoreBackup) =>
      this.discountsService.GetBackup().pipe(
        map((categories: IDiscount[]) => (new discountsActions.RestoreBackupSuccessful(categories))),
        catchError(error => of(new discountsActions.RestoreBackupFailed()))
        ),
    ))
  );

  saveDiscounts$ = createEffect(
    () => this.action$.pipe(
    ofType(discountsActions.DiscountsActionTypes.SaveDiscounts),
    mergeMap((action: discountsActions.SaveDiscounts) =>
      this.discountsService.SaveDiscounts(this.estateService.CorporationID, this.estateService.SelectedSite.siteId, action.payload).pipe(
        map((discounts: IDiscount[]) => new discountsActions.SaveDiscountsSuccessful(discounts)),
        catchError((error: HttpErrorResponse) => of(new discountsActions.SaveDiscountsFailed(interpretateApiError(error, HttpVerbType.POST), action.payload)))
      )
    ))
  );
}
