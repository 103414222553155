import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { IEopReportSectionDetailDTO } from './IEopReportSectionDetailDTO';

@JsonObject()
export class EopReportSectionDetailVarianceDTO implements IEopReportSectionDetailDTO {

  @JsonProperty('DA')
  public amount: string;

  @JsonProperty('DB')
  public expected: string;

  @JsonProperty('DC')
  public description: string;

  @JsonProperty('DD')
  public variance: string;

}
