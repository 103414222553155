import { ChangeType } from '../enums/ChangeType';
import { ICategoryDTO } from './DTOs/ICategoryDTO';
import { IChangeState } from './Extensions/IChangeState';
import { IItemSelectable } from './Extensions/IItemSelectable';
import { IParentCategorySlim, ParentCategorySlim } from './IParentCategorySlim';
import { IMeasure } from './IMeasure';
import { ITaxBand, TaxBand } from './ITaxBand';

export interface ICategory extends ICategoryDTO, IChangeState, IItemSelectable {
    measurements: IMeasure[];
    parentCategory: IParentCategorySlim;
    taxBand: ITaxBand;
    colorCodeText: string;
}

export class Category implements ICategory {
    id: string;
    categoryGuid: string;
    parentCategory: IParentCategorySlim;
    taxBand: ITaxBand;
    categoryName: string;
    colorCode: string;
    colorCodeText: string;
    isActive: boolean;
    measurements: IMeasure[];
    isSelected: boolean;
    changeState: ChangeType;

    constructor() {
        this.id = '';
        this.categoryGuid = '';
        this.parentCategory = new ParentCategorySlim;
        this.taxBand = new TaxBand;
        this.categoryName = '';
        this.colorCode = '#ffffff';
        this.colorCodeText = '#000000';
        this.isActive = false;
        this.measurements = [];
        this.isSelected = false;
        this.changeState = ChangeType.None;
    }
}
