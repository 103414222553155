import { EopSectionTypes } from 'src/app/enums/EopSectionType';
import { EopReportSectionDetailType } from 'src/app/enums/EopReportSectionDetailType';
import { JsonProperty, JsonObject } from 'typescript-json-serializer';
import { IEopReportSectionDetailDTO } from './IEopReportSectionDetailDTO';

export interface IEopReportSectionDTO {
  sectionDetails: IEopReportSectionDetailDTO[];
  sectionDetailType: EopReportSectionDetailType;
  sectionType: EopSectionTypes;
  showTotal: boolean;
  subSections: IEopReportSectionDTO[];
  title: string;
}

@JsonObject()
export class EopReportSectionDTO {

  @JsonProperty('SA')
  public sectionDetails: IEopReportSectionDetailDTO[];

  @JsonProperty('SF')
  public sectionDetailType: EopReportSectionDetailType;

  @JsonProperty('SB')
  public sectionType: EopSectionTypes;

  @JsonProperty('SC')
  public showTotal: boolean;

  @JsonProperty('SD')
  public subSections: EopReportSectionDTO[];

  @JsonProperty('SE')
  public title: string;

  constructor(

  ) { }
}
