import { IEopSectionType } from '../models/IEopSectionType';

export enum EopSectionTypes {
    Undefined = -1,
    Payments = 0,
    Top_Categories = 1,
    Categories = 2,
    Function_Key = 3,
    Discounts = 4,
    //Charges = 5, //Commented out enum means data is either missing or section is not coded properly for printouts.
    //Sales_Adjustment_Reason = 7,
    Users = 8,
    Service_Charges = 9,
    Gratuity = 10,
    Cashback = 11,
    //Products = 12,
    Tills_Included = 13,
    Currently_Open_Orders = 14,
    Deposits = 15,
    Withdrawals = 16,
    Expenses = 17,
    Purchases = 18,
    Income = 19,
    Banking = 20,
    Adjustments = 21,
    Refunds = 22,
    Declarations = 23,
    Taxes = 24,
    
    // Sections only for EOWs
    Eow_Eods_Included = 50,
    Eow_CurrentPeriod = 51,
    Eow_TrialBalance = 52,
    Eow_Incoming = 53,
    Eow_Outgoing = 54
}

export function getEodSections(): IEopSectionType[] {
    const list: IEopSectionType[] = [];
    let idx = 0;

    for (const n in EopSectionTypes) {

        try {
            var nValue = Number(n);
            var stringValue: string = EopSectionTypes[nValue];

            var description = stringValue;
            while (description.includes('_')) {
                description = description.replace('_', ' ');
            }

            list.push({
                value: EopSectionTypes[stringValue],
                description: description,
                isActive: false
            });
        } catch (error) {
            // do nothing
        }
    }

    return list;
}
