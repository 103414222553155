import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICorporation } from 'src/app/models/ICorporation';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import * as fromRoot from '../app.state';
import { MonitorActions, MonitorActionTypes } from './monitor.actions';

export interface State extends fromRoot.State {
  monitor: MonitorState;
}

export interface MonitorState extends IGardiffStoreSliceBase {
  corporations: ICorporation[];
}

const initialState: MonitorState = {
  corporations: [],
  apiError: null,
  status: new GardiffStoreSliceStatus()
};

// create the selectors
const getCorporationsFeatureState = createFeatureSelector<MonitorState>('monitor');
export const getCorporationsState = createSelector(
  getCorporationsFeatureState,
  state => state
);

export const getCorporations = createSelector(
  getCorporationsFeatureState,
  state => state.corporations
);

export const getApiError = createSelector(
getCorporationsFeatureState,
state => state.apiError
);

export const getStatus = createSelector(
getCorporationsFeatureState,
state => state.status
);

export function reducer(state: MonitorState = initialState, action: MonitorActions): MonitorState {
  switch (action.type) {
      case MonitorActionTypes.LoadCorporations:
          return {
              ...state,
              corporations: null,
              apiError: null,
              status: null
          };

      case MonitorActionTypes.LoadCorporationsSuccessful:
          return {
              ...state,
              corporations: action.payload,
              apiError: null,
              status: new GardiffStoreSliceStatus(true)
          };

      case MonitorActionTypes.LoadCorporationsFailed:
          return {
              ...state,
              corporations: [],
              apiError: action.payload,
              status: new GardiffStoreSliceStatus()
          };

      case MonitorActionTypes.ResetStore:
          return {
              ...state,
              corporations: initialState.corporations,
              apiError: initialState.apiError,
              status: initialState.status
          };

      default:
          return state;
  }
}
