import { Action } from '@ngrx/store';
import { IFunctionKey } from 'src/app/models/IFunctionKey';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';

export enum FunctionKeysActionTypes {
    LoadFunctionKeys = '[Function Keys] Load Function Keys',
    LoadFunctionKeysSuccessful = '[Function Keys] Load Function Keys - Successful',
    LoadFunctionKeysFailed = '[Function Keys] Load Function Keys - Failed',
    ResetStore = '[Function Keys] Reset Store',
}

export class LoadFunctionKeys implements Action {
    readonly type = FunctionKeysActionTypes.LoadFunctionKeys;

    constructor() { }
}

export class LoadFunctionKeysSuccessful implements Action {
    readonly type = FunctionKeysActionTypes.LoadFunctionKeysSuccessful;

    constructor(public payload: IFunctionKey[]) { }
}

export class LoadFunctionKeysFailed implements Action {
    readonly type = FunctionKeysActionTypes.LoadFunctionKeysFailed;

    constructor(public payload: IGenericError) { }
}

export class ResetStore implements Action {
    readonly type = FunctionKeysActionTypes.ResetStore;

    constructor() { }
}

export type FunctionKeysActions = LoadFunctionKeys
    | LoadFunctionKeysSuccessful
    | LoadFunctionKeysFailed
    | ResetStore;
